export const ROUTES = {
  ACCOUNT: '/v2/accounts/:id',
  ACCOUNT_TRACKING: '/v2/accounts/tracking/:id',
  ACCOUNTS: '/v2/accounts',
  ADGROUP: '/v2/campaigns/:campaign_id/groups/:id',
  ASSET: '/v2/assets/:id',
  ASSETS: '/v2/assets',
  ASSETS_COMPOSER: '/v2/assets/composer',
  BILLING: '/v2/billing',
  CAMPAIGN: '/v2/campaigns/:id',
  CAMPAIGNS: '/v2/campaigns',
  DASHBOARD: '/v2/dashboard',
  FORGOT: '/v2/forgot',
  LOGIN: '/v2/login',
  NETWORK: '/v2/networks/:id',
  NETWORKS: '/v2/networks',
  RESET: '/v2/reset/:token',
  ROOT: '/v2',
  USER: '/v2/users/:id',
  USERS: '/v2/users',
};

export const subRouteParams = (route: string, params: Record<string, string>) =>
  route.replace(/:[a-zA-Z_]+/g, token => params[token.replace(':', '')] || '');
