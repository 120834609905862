/**
 * SelectAdapter component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import CreatableSelect from 'react-select/creatable';

export default class SelectAdapter extends PureComponent<any> {
  static propTypes = {
    input: PropTypes.object.isRequired,
    selectType: PropTypes.oneOf([
      'async',
      'async-creatable',
      'creatable',
      'select',
    ]).isRequired,
  };

  render() {
    const { input, selectType, ...rest } = this.props;

    switch (selectType) {
      case 'async':
        return <AsyncSelect {...input} {...rest} />;
      case 'async-creatable':
        return <AsyncCreatableSelect {...input} {...rest} />;
      case 'creatable':
        return <CreatableSelect {...input} {...rest} />;
      case 'select':
      default:
        return <Select {...input} {...rest} />;
    }
  }
}
