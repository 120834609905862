import cx from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

interface State {
  accountsDropDownOpen: boolean;
  accountsList: Record<string, any>[];
  userDropDownOpen: boolean;
}

export default class Header extends PureComponent<any, State> {
  static propTypes = {
    accounts: PropTypes.object.isRequired,
    active: PropTypes.bool,
    getAccountsList: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    selectAccount: PropTypes.func.isRequired,
    setMenuToggleRef: PropTypes.func.isRequired,
    toggleMenu: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
  };

  static defaultProps = {
    active: false,
  };

  state: State = {
    accountsDropDownOpen: false,
    accountsList: [],
    userDropDownOpen: false,
  };

  componentDidMount() {
    document.addEventListener(
      'mousedown',
      this.handleClickOutsideAccountsDropDown,
    );
    document.addEventListener('mousedown', this.handleClickOutsideUserDropDown);
  }

  componentWillUnmount() {
    document.removeEventListener(
      'mousedown',
      this.handleClickOutsideAccountsDropDown,
    );
    document.removeEventListener(
      'mousedown',
      this.handleClickOutsideUserDropDown,
    );
  }

  accountsDropDownWrapperRef: any = null;

  accountsDropDownToggleRef: any = null;

  userDropDownWrapperRef: any = null;

  userDropDownToggleRef: any = null;

  setAccountsDropDownWrapperRef = node => {
    this.accountsDropDownWrapperRef = node;
  };

  setAccountsDropDownToggleRef = node => {
    this.accountsDropDownToggleRef = node;
  };

  setUserDropDownWrapperRef = node => {
    this.userDropDownWrapperRef = node;
  };

  setUserDropDownToggleRef = node => {
    this.userDropDownToggleRef = node;
  };

  handleClickOutsideAccountsDropDown = event => {
    if (
      this.accountsDropDownWrapperRef &&
      !this.accountsDropDownWrapperRef.contains(event.target) &&
      this.accountsDropDownToggleRef &&
      !this.accountsDropDownToggleRef.contains(event.target)
    ) {
      this.setState({
        accountsDropDownOpen: false,
      });
    }
  };

  handleClickOutsideUserDropDown = event => {
    if (
      this.userDropDownWrapperRef &&
      this.userDropDownToggleRef &&
      !this.userDropDownWrapperRef.contains(event.target) &&
      !this.userDropDownToggleRef.contains(event.target)
    ) {
      this.setState({
        userDropDownOpen: false,
      });
    }
  };

  toggleAccountsDropdown = async () => {
    const accountsList = !this.state.accountsDropDownOpen
      ? await this.props.getAccountsList()
      : {
          data: [],
        };

    this.setState(({ accountsDropDownOpen }) => ({
      accountsDropDownOpen: !accountsDropDownOpen,
      accountsList: accountsList.data,
    }));
  };

  toggleUserDropdown = () => {
    this.setState(({ userDropDownOpen }) => ({
      userDropDownOpen: !userDropDownOpen,
    }));
  };

  selectAccount = account => {
    this.props.selectAccount(account);
    this.toggleAccountsDropdown();
  };

  render() {
    const { accounts, active, logout, setMenuToggleRef, toggleMenu, user } =
      this.props;
    const { accountsList, accountsDropDownOpen, userDropDownOpen } = this.state;

    return (
      <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
        <div className="navbar-brand dropdown">
          <button
            ref={setMenuToggleRef}
            type="button"
            className={cx('btn dropdown-toggle text-white', {
              active: active,
            })}
            onClick={toggleMenu}
          >
            <i className="fab fa-opera" />
            <span>One Portal</span>
          </button>
        </div>
        <div className="ml-auto" />

        {accounts.current && (
          <div className="dropdown accounts-list">
            <button
              type="button"
              className={cx('btn dropdown-toggle text-white', {
                active: accountsDropDownOpen,
              })}
              onClick={this.toggleAccountsDropdown}
              ref={this.setAccountsDropDownToggleRef}
            >
              <span>{accounts.current.name}</span>
            </button>

            <div
              className={cx('dropdown-menu dropdown-menu-right', {
                show: accountsDropDownOpen,
              })}
              ref={this.setAccountsDropDownWrapperRef}
            >
              {accountsList.map(account => (
                <button
                  key={account.id}
                  type="button"
                  className={cx('dropdown-item', 'pl-5', {
                    active:
                      accounts.current && accounts.current.id === account.id,
                  })}
                  tabIndex={0}
                  onClick={() => this.selectAccount(account)}
                >
                  {account.name}
                </button>
              ))}
            </div>
          </div>
        )}

        <div className="dropdown">
          <button
            type="button"
            className={cx('btn dropdown-toggle text-white', {
              active: userDropDownOpen,
            })}
            ref={this.setUserDropDownToggleRef}
            onClick={this.toggleUserDropdown}
          >
            <i className="far fa-user" />
            <span>{user.firstname}</span>
          </button>

          <div
            className={cx('dropdown-menu dropdown-menu-right', {
              show: userDropDownOpen,
            })}
            ref={this.setUserDropDownWrapperRef}
          >
            <button
              type="button"
              className="dropdown-item"
              tabIndex={0}
              onClick={logout}
            >
              <i className="fas fa-sign-out-alt mr-3" />
              Logout
            </button>
          </div>
        </div>
      </nav>
    );
  }
}
