import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import 'moment-timezone';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';

import Layout from './layouts/Layout';
import { store } from './reducers/configureStore';
import { socket } from './ws';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK, {
  stripeAccount: process.env.REACT_APP_STRIPE_ACCT,
});

socket.connect();

ReactDOM.render(
  <React.StrictMode>
    <Elements stripe={stripePromise}>
      <Provider store={store}>
        <Layout />
      </Provider>
    </Elements>
  </React.StrictMode>,
  document.getElementById('root'),
);
