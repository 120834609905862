import { DefaultRootState } from 'react-redux';
import { Middleware } from 'redux';

export const downloadMiddleware: Middleware<any, DefaultRootState> =
  api => next => action => {
    if (action?.data?.download) {
      window.open(
        process.env.REACT_APP_API_URL.replace('/api', '') +
          action.data.download +
          '?xtoken=' +
          api.getState().auth.authToken,
        '_blank',
        'noreferrer',
      );
    }

    return next(action);
  };
