import { AnyAction } from 'redux';

import * as actions from '../actions/accounts.actions';

export interface AccountTrackingState {
  loading: boolean;
  dcmPrivate: string | null;
  networkSettings: any[];
  networkSettingsTypes: any[];
}

const INITIAL_STATE: Readonly<AccountTrackingState> = {
  dcmPrivate: null,
  loading: false,
  networkSettings: [],
  networkSettingsTypes: [],
};

export const accountTrackingReducer = (
  state: Readonly<AccountTrackingState> = INITIAL_STATE,
  action: AnyAction,
): Readonly<AccountTrackingState> => {
  switch (action.type) {
    case actions.ACCOUNT_GET_NETWORK_SETTINGS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case actions.ACCOUNT_GET_NETWORK_SETTINGS_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case actions.ACCOUNT_GET_NETWORK_SETTINGS_SUCCESS:
      return {
        ...state,
        dcmPrivate: action.data.dcmPrivate,
        loading: false,
        networkSettings: action.data.networkSettings,
        networkSettingsTypes: action.data.networkSettingsTypes,
      };

    case actions.ACCOUNT_UPDATE_NETWORK_SETTINGS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.ACCOUNT_UPDATE_NETWORK_SETTINGS_SUCCESS:
    case actions.ACCOUNT_UPDATE_NETWORK_SETTINGS_FAILURE:
      return {
        ...INITIAL_STATE,
      };

    default:
      return state;
  }
};
