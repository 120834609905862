import cx from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { getTypeId, imagePath } from '../../helpers/creative';

const ACCEPT_FILE_TYPES = ['image/png', 'image/jpg', 'image/jpeg'];

const IMAGE_SIZES = [
  {
    height: 250,
    key: 'cmImg250x250',
    width: 250,
  },
  {
    height: 250,
    key: 'cmImg300x250',
    width: 300,
  },
  {
    height: 600,
    key: 'cmImg300x600',
    width: 300,
  },
  {
    height: 90,
    key: 'cmImg728x90',
    width: 728,
  },
  {
    height: 600,
    key: 'cmImg160x600',
    width: 160,
  },
  {
    height: 50,
    key: 'cmImg300x50',
    width: 300,
  },
  {
    height: 50,
    key: 'cmImg320x50',
    width: 320,
  },
];

export default class ImagesInput extends PureComponent<any, any> {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    form: PropTypes.object.isRequired,
    max: PropTypes.number,
  };

  static defaultProps = {
    className: '',
    disabled: false,
    max: 5242880,
  };

  state = {};

  input: HTMLInputElement | null = null;

  dragCounter = 0;

  setInputRef = node => {
    this.input = node;
  };

  onClick = () => {
    if (this.props.disabled) {
      return false;
    }
    if (this.input) {
      this.input.click();
    }
  };

  onKeyDown = event => {
    if (event.keyCode === 13 || event.keyCode === 32) {
      this.onClick();
    }
  };

  onDrag = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  onDragIn = event => {
    event.preventDefault();
    event.stopPropagation();

    this.dragCounter++;
  };

  onDragOut = event => {
    event.preventDefault();
    event.stopPropagation();

    this.dragCounter--;
  };

  onDrop = event => {
    event.preventDefault();
    event.stopPropagation();

    if (this.props.disabled) {
      return false;
    }
    this.processImages(event.dataTransfer.files);
    event.dataTransfer.clearData();
  };

  onChange = event => {
    this.processImages(event.target.files);
    event.target.value = null;
  };

  processImages = images => {
    for (let i = 0; i < images.length; i++) {
      this.processImage(images[i]);
    }
  };

  processImage = image => {
    if (!image) {
      return false;
    }

    const { max, form } = this.props;

    if (image.size > max) {
      alert(`File too large, max size ${Math.floor(max / 1024 / 1024)}mb`);

      return false;
    }
    if (!ACCEPT_FILE_TYPES.includes(image.type)) {
      alert('Please use image file (.jpg, .jpeg, .png)');

      return false;
    }

    const reader = new FileReader();

    reader.addEventListener('load', async () => {
      try {
        const dimensions = await this.imageDimensions(reader.result);
        const key = IMAGE_SIZES.reduce(
          (prev, cur) =>
            prev +
            (cur.width === dimensions.width && cur.height === dimensions.height
              ? cur.key
              : ''),
          '',
        );

        if (!key) {
          throw new Error(
            `Incorrect image size ${dimensions.width}x${dimensions.height}`,
          );
        }

        this.setState({
          [key]: reader.result,
        });
        form.change('creative.' + key, reader.result);
      } catch (error) {
        alert(error);
      }
    });

    reader.readAsDataURL(image);
  };

  imageDimensions = (url, rejectTimeout?: number) =>
    new Promise<{ height: number; width: number }>((resolve, reject) => {
      let timer = null;
      const img = new Image();

      img.addEventListener('load', () => {
        if (timer) {
          clearTimeout(timer);
        }
        resolve({
          height: img.naturalHeight,
          width: img.naturalWidth,
        });
      });
      img.addEventListener('error', event => {
        if (timer) {
          clearTimeout(timer);
        }
        reject(`${event.type}: ${event.message}`);
      });
      img.src = url;
      if (rejectTimeout) {
        // @ts-ignore
        timer = setTimeout(() => reject('Timeout exception'), rejectTimeout);
      }
    });

  preventEvent = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  render() {
    const { disabled, max, className } = this.props;

    const images = IMAGE_SIZES.filter(item => this.state[item.key]).map(
      item => ({
        ...item,
        src: this.state[item.key],
      }),
    );

    return (
      <>
        <div
          className={cx(`dropzone dropzone-multiple ${className}`, {
            disabled,
          })}
          role="button"
          tabIndex={0}
          onClick={this.onClick}
          onDrop={this.onDrop}
          onDragOver={this.onDrag}
          onDragEnter={this.onDragIn}
          onDragLeave={this.onDragOut}
          onKeyDown={this.onKeyDown}
        >
          <input
            accept={ACCEPT_FILE_TYPES.join(',')}
            autoComplete="off"
            className="d-none"
            disabled={disabled}
            name="select_file_multiple"
            onChange={this.onChange}
            ref={this.setInputRef}
            tabIndex={-1}
            type="file"
            multiple
          />
          <button type="button" className="btn btn-secondary mb-1">
            {'Click here or drop file to add an image.\n'}
          </button>
          <pre className="text-center">
            {'Suggested sizes: ' +
              IMAGE_SIZES.map(item => `${item.width}x${item.height}`).join(
                ', ',
              ) +
              ' px\n'}
            {'Accepted file types: .jpg, .jpeg, .png\n'}
            {`Max size: ${Math.floor(max / 1024 / 1024)} MB`}
          </pre>
        </div>
        <div className="thumbnails">
          {images.map(image => (
            <div
              className="thumbnail"
              key={image.key}
              onClick={this.preventEvent}
              role="button"
              tabIndex={0}
              onDrop={this.preventEvent}
              onDragOver={this.preventEvent}
              onDragEnter={this.preventEvent}
              onDragLeave={this.preventEvent}
              onKeyDown={this.preventEvent}
            >
              <img
                src={imagePath({
                  imageLandscape: image.src,
                  type: getTypeId('image'),
                })}
                alt=""
              />
              {!disabled && (
                <div className="dropzone-actions-wrapper">
                  <button
                    key={image.key}
                    className="dropzone-button-edit btn btn-primary"
                    type="button"
                    onClick={() =>
                      this.setState({
                        [image.key]: undefined,
                      })
                    }
                  >
                    <i className="fa fa-trash m-r-5" />
                    &nbsp;&nbsp;Delete
                  </button>
                  {/*<Field
                                    name={ image.key }
                                >
                                    {(props) => (
                                        <div>
                                            <input { ...props.input } type="hidden" value={ image.src }/>
                                        </div>
                                    )}
                                </Field>*/}
                </div>
              )}
            </div>
          ))}
        </div>
      </>
    );
  }
}
