import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getClientSecret } from '../../actions/billing.actions';

import { StripeForm } from './StripeForm';

export const StripeFormConnected = connect(
  createStructuredSelector({
    clientSecret: (state: any) => state.billing.clientSecret,
    email: (state: any) => state.auth.user?.email,
    last4: (state: any) => state.billing.last4,
    stripeCardAdded: (state: any) => state.billing.stripeCardAdded,
  }),
  {
    getClientSecret,
  },
)(StripeForm);
