import { DefaultRootState } from 'react-redux';
import { Dispatch } from 'redux';

export const USERS_LIST_REQUEST = 'USERS_LIST_REQUEST';
export const USERS_LIST_SUCCESS = 'USERS_LIST_SUCCESS';
export const USERS_LIST_ERROR = 'USERS_LIST_ERROR';
export const USERS_LIST_CRITERIA = 'USERS_LIST_CRITERIA';

export const USER_ITEM_REQUEST = 'USER_ITEM_REQUEST';
export const USER_ITEM_SUCCESS = 'USER_ITEM_SUCCESS';
export const USER_ITEM_ERROR = 'USER_ITEM_ERROR';
export const USER_ITEM_RESET = 'USER_ITEM_RESET';

export const USER_CREATE_REQUEST = 'USER_CREATE_REQUEST';
export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS';
export const USER_CREATE_ERROR = 'USER_CREATE_ERROR';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_ERROR = 'USER_UPDATE_ERROR';

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_ERROR = 'USER_DELETE_ERROR';

export const loadUsers = (data: any) => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) =>
      axios.get('/admin/users', {
        params: data,
      }),
    requestType: 'USERS_LIST',
  });

export const loadUser = (id: string) => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) => axios.get(`/admin/users/${id}`),
    requestType: 'USER_ITEM',
  });

export const createUser = (data: any) => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) => axios.post('/admin/users', data),
    requestType: 'USER_CREATE',
  });

export const updateUser =
  (id: string, data: any) => (dispatch: Dispatch<any>) =>
    dispatch({
      request: (axios: AI) => axios.put(`/admin/users/${id}`, data),
      requestType: 'USER_UPDATE',
    });

export const deleteUser = (id: string) => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) => axios.delete(`/admin/users/${id}`),
    requestType: 'USER_DELETE',
    successCallback: (
      response: any,
      dispatch: Dispatch<any>,
      getState: () => DefaultRootState,
    ) => {
      dispatch(
        loadUsers({
          criteria: getState().users.criteria,
        }),
      );

      return {
        message: response.message,
      };
    },
  });

export const updateCriteria = (criteria: any) => ({
  criteria,
  type: USERS_LIST_CRITERIA,
});

export const updateUserStatus =
  (id: string, data: any) => (dispatch: Dispatch<any>) =>
    dispatch({
      request: (axios: AI) => axios.put(`/admin/user/${id}/status`, data),
      requestType: 'USER_UPDATE',
    });

export const updateUserRole = (data: any) => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) => axios.put('/admin/user/system/role', data),
    requestType: 'USER_UPDATE',
  });

export const getRoles = () => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) => axios.get('/admin/acl/system/roles'),
    requestType: 'USER_ITEM',
  });

export const updateUserPassword =
  (id: string, data: any) => (dispatch: Dispatch<any>) =>
    dispatch({
      request: (axios: AI) => axios.put(`/admin/user/${id}/password`, data),
      requestType: 'USER_UPDATE',
    });

export const resetUser = () => (dispatch: Dispatch<any>) =>
  dispatch({
    type: USER_ITEM_RESET,
  });
