import { AnyAction } from 'redux';

import {
  ASSET_CREATE_ERROR,
  ASSET_CREATE_REQUEST,
  ASSET_CREATE_SUCCESS,
  ASSET_ITEM_ERROR,
  ASSET_ITEM_REQUEST,
  ASSET_ITEM_RESET,
  ASSET_ITEM_SUCCESS,
  ASSET_UPDATE_ERROR,
  ASSET_UPDATE_REQUEST,
  ASSET_UPDATE_SUCCESS,
} from '../actions/assets.actions';

interface AssetState {
  data: {
    descriptions: string[];
    shortHeadlines: string[];
    type: string;
  };
  loading: boolean;
}

export const INITIAL_STATE: Readonly<AssetState> = {
  data: {
    descriptions: [''],
    shortHeadlines: [''],
    type: '1',
  },
  loading: false,
};

export default (
  state: Readonly<AssetState> = INITIAL_STATE,
  action: AnyAction,
): Readonly<AssetState> => {
  switch (action.type) {
    case ASSET_ITEM_REQUEST:
    case ASSET_CREATE_REQUEST:
    case ASSET_UPDATE_REQUEST:
      return { ...state, loading: true };

    case ASSET_ITEM_SUCCESS:
      return { ...state, data: action.data, loading: false };

    case ASSET_ITEM_ERROR:
    case ASSET_CREATE_SUCCESS:
    case ASSET_CREATE_ERROR:
    case ASSET_UPDATE_SUCCESS:
    case ASSET_UPDATE_ERROR:
      return { ...state, loading: false };

    case ASSET_ITEM_RESET:
      return { ...state, ...INITIAL_STATE };

    default:
      return state;
  }
};
