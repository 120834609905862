import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Dialog } from '@mui/material';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

import {
  getReportSchedule,
  getReportScheduleList,
  resetReportSchedule,
  saveReportSchedule,
  testReportSchedule,
} from '../../actions/reports.actions';
import { ScheduleForm } from '../Dashboard/PerformanceReport/Schedule/ScheduleForm';
import {
  formatForSave,
  formatSchedule,
  getDefaultSchedule,
} from '../Dashboard/PerformanceReport/Schedule/constants';
import { ISchedule } from '../Dashboard/PerformanceReport/Schedule/types';
import { ScheduleList } from '../Dashboard/PerformanceReport/Schedule/ScheduleList';
import { loadUserAccounts } from '../../actions/accounts.actions';

const paperComponentProps = { sx: { backgroundColor: 'rgba(0, 0, 0, 0.5)' } };

const Schedule = ({
  accountId,
  schedule,
  list,
  getReportSchedule,
  resetReportSchedule,
  saveReportSchedule,
  testReportSchedule,
  getReportScheduleList,
  loading,
  user,
}: ISchedule) => {
  const [scheduleOpen, setScheduleOpen] = useState<boolean>(false);

  const initSchedule = useMemo(() => formatSchedule(schedule), [schedule]);
  const defaultSchedule = useMemo(
    () => getDefaultSchedule(user, accountId),
    [user, accountId],
  );

  useEffect(() => {
    getReportScheduleList(accountId, user.id, true);
  }, [getReportScheduleList, accountId, user.id]);

  useEffect(
    () => () => {
      resetReportSchedule();
    },
    [resetReportSchedule],
  );

  const editSchedule = (id: number) => {
    getReportSchedule(id, accountId, user.id);
    setScheduleOpen(true);
  };
  const handleNew = () => {
    resetReportSchedule();
    setScheduleOpen(true);
  };
  const handleBack = () => {
    getReportScheduleList(accountId, user.id, true);
    setScheduleOpen(false);
  };
  const handleTest = id => {
    testReportSchedule({ account_id: accountId, schedule_id: id });
  };
  const handleSubmit = reportScheduleParams => {
    saveReportSchedule(
      formatForSave(
        reportScheduleParams,
        initSchedule?.account_id || defaultSchedule.account_id,
        user,
      ),
    );

    return {};
  };

  return (
    <>
      <ScheduleList
        schedules={list}
        loading={loading}
        onEditClick={editSchedule}
        onNewClick={handleNew}
        show={!scheduleOpen}
      />
      <Dialog
        fullScreen
        open={scheduleOpen}
        onClose={handleBack}
        PaperProps={paperComponentProps}
      >
        {scheduleOpen ? (
          <Form
            initialValues={initSchedule || defaultSchedule}
            mutators={{
              ...arrayMutators,
            }}
            onSubmit={handleSubmit}
            component={props => (
              <ScheduleForm
                {...props}
                cancel={handleBack}
                back={handleBack}
                test={handleTest}
                loading={loading}
              />
            )}
          />
        ) : null}
      </Dialog>
    </>
  );
};

export default connect(
  store => ({
    list: store.reports.scheduleList,
    loading: store.reports.scheduleLoading,
    perms: store.auth.user?.perms || {},
    schedule: store.reports.schedule,
  }),
  {
    getReportSchedule,
    getReportScheduleList,
    loadUserAccounts,
    resetReportSchedule,
    saveReportSchedule,
    testReportSchedule,
  },
)(Schedule);
