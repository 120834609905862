import { useMemo } from 'react';

import { BarChartReport } from '../BarChartReport/BarChartReport';
import { PieChartReport } from '../PieChartReport/PieChartReport';
import { AverageReports } from '../AverageReports/AverageReports';
import {
  computeBudgetSpentCampaignData,
  computeClicksCampaignData,
  computeClickServedData,
  computeCtcConversionsData,
  computeCtcOrderValueData,
  computeCtcRoiData,
} from '../chart-computations';
import {
  budgetSpentCampaign,
  clicksCampaign,
  clickServed,
  ctcConversions,
  ctcOrderValue,
  ctcRoi,
} from '../chart-settings';
import ss from '../PerformanceReport.module.scss';
import { IReportTab } from '../types';

export const Advanced = (props: IReportTab) => {
  const { records } = props;
  const clickServedData = useMemo(
    () => computeClickServedData(records),
    [records],
  );
  const ctcRoiData = useMemo(() => computeCtcRoiData(records), [records]);
  const ctcOrderValueData = useMemo(
    () => computeCtcOrderValueData(records),
    [records],
  );
  const budgetSpentCampaignData = useMemo(
    () => computeBudgetSpentCampaignData(records),
    [records],
  );
  const clicksCampaignData = useMemo(
    () => computeClicksCampaignData(records),
    [records],
  );
  const ctcConversionsData = useMemo(
    () => computeCtcConversionsData(records),
    [records],
  );

  return (
    <>
      <div className="mt-5 mb-5">
        <div className="row">
          <div
            className={`col-lg pr-lg-5 ${ss.chartsCol} ${ss.borderSeparator}`}
          >
            <div className={ss.chartContent}>
              <h3 className="text-center m-3">{ctcConversions.series.name}</h3>
              <BarChartReport dataset={ctcConversionsData} />
              <div className={ss.hLineSep} />
            </div>
          </div>
          <div className={`col-lg pl-lg-5 ${ss.chartsCol}`}>
            <div className={ss.chartContent}>
              <h3 className="text-center m-3">{ctcOrderValue.series.name}</h3>
              <div className={ss.chartGrow}>
                <PieChartReport
                  series={{ ...ctcOrderValue.series, data: ctcOrderValueData }}
                  verticalLegend
                />
              </div>
              <div className={ss.hLineSep} />
            </div>
          </div>
        </div>

        <div className="row">
          <div
            className={`col-lg pr-lg-5 ${ss.chartsCol} ${ss.borderSeparator}`}
          >
            <div className={ss.chartContent}>
              <PieChartReport
                series={{
                  ...budgetSpentCampaign.series,
                  data: budgetSpentCampaignData,
                }}
                verticalLegend
              />
              <h3 className="text-center">{budgetSpentCampaign.series.name}</h3>
              <div className={ss.hLineSep} />
            </div>
          </div>
          <div className={`col-lg pl-lg-5 ${ss.chartsCol}`}>
            <div className={ss.chartContent}>
              <PieChartReport
                series={{ ...clicksCampaign.series, data: clicksCampaignData }}
                verticalLegend
              />
              <h3 className="text-center">{clicksCampaign.series.name}</h3>
              <div className={ss.hLineSep} />
            </div>
          </div>
        </div>

        <div className="row">
          <div
            className={`col-lg pr-lg-5 ${ss.chartsCol} ${ss.borderSeparator}`}
          >
            <div className={ss.chartContent}>
              <PieChartReport
                series={{ ...clickServed.series, data: clickServedData }}
              />
              <h3 className="text-center mt-3">{clickServed.series.name}</h3>
              <div className={`${ss.hLineSep} ${ss.wideHide}`} />
            </div>
          </div>
          <div className={`col-lg pl-lg-5 ${ss.chartsCol}`}>
            <div className={ss.chartContent}>
              <PieChartReport series={{ ...ctcRoi.series, data: ctcRoiData }} />
              <h3 className="text-center mt-3">{ctcRoi.series.name}</h3>
            </div>
          </div>
        </div>
      </div>

      <div className={ss.hLineSep} />

      <AverageReports {...props} />
    </>
  );
};
