/**
 * UsersList component script class
 *
 * @package    Common
 * @author     Ivan Miltykh <ivan.miltykh@gmail.com>
 */
import crypto from 'crypto';

import cx from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import Modal from '../../components/Modal';
import Tooltip from '../../components/Tooltip';
import {
  deleteAccount,
  downloadOptag,
  loadAccounts,
  updateAccount,
  updateCriteria,
  uploadOptag,
} from '../../actions/accounts.actions';
import Grid from '../../components/Grid';
import { STATUS_ACTIVE, STATUS_PAUSED } from '../../constants';
import {
  ColumnOption,
  Criteria,
  Metadata,
} from '../../components/Grid/Grid.interfaces';
import { ROUTES, subRouteParams } from '../../constants/routes';

interface Props {
  criteria: Criteria;
  data: any[];
  deleteAccount: (id: string) => void;
  downloadOptag: () => any;
  history: Record<string, any>;
  loadAccounts: (data: any) => any;
  loading: boolean;
  metadata: Metadata;
  updateAccount: (id: string, data: any) => void;
  updateCriteria: (data: any) => void;
  uploadOptag: () => void;
}

interface State {
  selectedItem: Record<string, any> | null;
  showDeleteModal: boolean;
}

class List extends PureComponent<Props, State> {
  static propTypes = {
    criteria: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    deleteAccount: PropTypes.func.isRequired,
    downloadOptag: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    loadAccounts: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    metadata: PropTypes.object.isRequired,
    updateAccount: PropTypes.func.isRequired,
    updateCriteria: PropTypes.func.isRequired,
    uploadOptag: PropTypes.func.isRequired,
  } as never;

  state: State = {
    selectedItem: null,
    showDeleteModal: false,
  };

  addItem = () => {
    this.props.history.push(subRouteParams(ROUTES.ACCOUNT, { id: 'new' }));
  };

  editItem = ({ id }) => {
    this.props.history.push(subRouteParams(ROUTES.ACCOUNT, { id }));
  };

  setupTracking = ({ id }) => {
    this.props.history.push(subRouteParams(ROUTES.ACCOUNT_TRACKING, { id }));
  };

  deleteItem = () => {
    const { deleteAccount } = this.props;
    const { selectedItem } = this.state;

    // @ts-ignore
    deleteAccount(selectedItem.id);
    this.setState({
      selectedItem: null,
      showDeleteModal: false,
    });
  };

  showDeleteModal = selectedItem => {
    this.setState({
      selectedItem,
      showDeleteModal: true,
    });
  };

  hideDeleteModal = event => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      selectedItem: null,
      showDeleteModal: false,
    });
  };

  download = item => {
    const element = document.createElement('a');
    const hash = encodeURIComponent(
      crypto.createHash('sha256').update(item.id.toString()).digest('base64'),
    );
    const text = `
  //Below are two snippets of code to be placed on the client’s site.
  //Snippet A:  This code is to be placed on all pages throughout the site.
  //Snippet B: This code  is to be placed only on the checkout confirmation page.

  //***********
  //Snippet A
  //This code to be place on all pages throughout the site.
  //***********
  <script>
  (function (o, n, e, p, t) {
    "use strict";
    o.op ||
      ((o.oneportalEQ = o.oneportalEQ ? o.oneportalEQ : []),
      (o.oneportal = function () {
        o.oneportalP && o.oneportalP.oneportal
        ? o.oneportal.handleEvent(arguments)
        : o.oneportalEQ.push(arguments);
      }),
      (t = o.document.createElement(n)),
      (t.async = !0),
      (t.src = e),
      (p = o.document.getElementsByTagName(n)[0]),
      p.parentNode.insertBefore(t, p));
  })(window, "script", "https://cdn.oneportal.com/js/optag-${item.optagDigest}.js");
  oneportal("init", "${hash}");
  oneportal("pageview");
  </script>


  //***********
  //Snippet B
  //This code to be place on the checkout confirmation page.
  //***********
  <script>
  (function (o, n, e, p, t) {
    "use strict";
    o.op ||
      ((o.oneportalEQ = o.oneportalEQ ? o.oneportalEQ : []),
      (o.oneportal = function () {
        o.oneportalP && o.oneportalP.oneportal
        ? o.oneportal.handleEvent(arguments)
        : o.oneportalEQ.push(arguments);
      }),
      (t = o.document.createElement(n)),
      (t.async = !0),
      (t.src = e),
      (p = o.document.getElementsByTagName(n)[0]),
      p.parentNode.insertBefore(t, p));
  })(window, "script", "https://cdn.oneportal.com/js/optag-${item.optagDigest}.js");
  oneportal("init", "${hash}");
  oneportal("pageview");

  oneportal("purchase", {
    orderId:"ORDERID",
    orderValue:"ORDERVALUE",
    products:"PRODUCT1;PRODUCT2"
  })
  </script>
  `;

    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(text),
    );
    element.setAttribute(
      'download',
      `${item.id || 'client'}-${item.name.toLowerCase().replace(' ', '-')}.txt`,
    );

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  downloadOptag = async () => {
    const { data } = await this.props.downloadOptag();
    const element = document.createElement('a');

    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(data),
    );
    element.setAttribute('download', 'optag.js');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  toggleActive = async ({ id, status }) => {
    const { updateAccount } = this.props;

    await updateAccount(id, {
      status: status === STATUS_PAUSED ? STATUS_ACTIVE : STATUS_PAUSED,
    });
  };

  COLUMNS: ColumnOption[] = [
    {
      maxWidth: 90,
      name: 'id',
      sortable: true,
      title: 'Id',
    },
    {
      maxWidth: 75,
      name: 'status',
      sortable: true,
      title: 'Status',
      values: {
        [STATUS_ACTIVE]: {
          items: [
            {
              icon: 'fas fa-circle text-success',
              type: 'icon',
            },
            {
              action: this.toggleActive,
              icon: 'fas fa-pause',
              tooltip: 'Deactivate',
              type: 'icon',
            },
          ],
          type: 'list',
        },
        [STATUS_PAUSED]: {
          items: [
            {
              icon: 'fas fa-circle text-danger',
              type: 'icon',
            },
            {
              action: this.toggleActive,
              icon: 'fas fa-play',
              tooltip: 'Activate',
              type: 'icon',
            },
          ],
          type: 'list',
        },
      },
    },
    {
      name: 'name',
      sortable: true,
      title: 'Name',
    },
    {
      key: 'Hash',
      name: 'id',
      title: 'Hash',
      type: 'custom',
      value: data =>
        encodeURIComponent(
          crypto.createHash('sha256').update(data.toString()).digest('base64'),
        ),
    },
    {
      maxWidth: 60,
      name: 'network_accounts',
      title: 'Networks',
      type: 'custom',
      value: (data = [], val) => {
        const networkClasses = {
          1: 'network_icon network_icon_googleads',
          2: 'network_icon network_icon_dv360',
          3: 'network_icon network_icon_facebook',
          4: 'network_icon network_icon_amazon',
        };

        return data.map((network, index) => (
          <Tooltip
            key={[val?.id, network.id, index].join('')}
            tooltip={`${network.ad_network.name} (${
              network.status ? 'Active' : 'Inactive'
            })`}
          >
            <i
              className={cx(networkClasses[network.ad_network.id], {
                disabled: !network.status,
              })}
            />
          </Tooltip>
        ));
      },
    },
    {
      className: 'text-center',
      maxWidth: 140,
      name: 'created',
      sortable: true,
      title: 'Created date',
      type: 'date',
    },
    {
      className: 'text-center',
      items: [
        {
          action: this.download,
          disabled: item => !item.optagDigest,
          icon: 'fas fa-cloud-download-alt',
          tooltip: 'Download',
          type: 'icon',
        },
        {
          action: this.editItem,
          icon: 'fas fa-pen-alt mr-1',
          tooltip: 'Edit item',
          type: 'icon',
        },
        {
          action: this.setupTracking,
          icon: 'fas fa-location-dot mr-1',
          tooltip: 'Setup tracking',
          type: 'icon',
        },
        {
          action: this.showDeleteModal,
          icon: 'fas fa-trash',
          tooltip: 'Delete Item',
          type: 'icon',
        },
      ],
      maxWidth: 25,
      name: 'actions',
      title: <i className="fas fa-cogs" />,
      type: 'list',
    },
  ];

  render() {
    const { criteria, data, loadAccounts, loading, metadata, updateCriteria } =
      this.props;

    const { selectedItem, showDeleteModal } = this.state;

    return (
      <div className="page">
        <div className="row">
          <div className="col-12">
            <Modal
              close={this.hideDeleteModal}
              description={`Are you sure, you want remove "${
                selectedItem ? selectedItem.name : ''
              }"?`}
              title="Delete entity?"
              show={showDeleteModal}
              submit={this.deleteItem}
              submitLabel="deleteModal"
            />
            <Grid
              actions={[
                {
                  action: this.props.uploadOptag,
                  title: (
                    <>
                      <i className="fas fa-cloud-upload-alt mr-2" />
                      Upload OpTag.js to GD
                    </>
                  ),
                },
                {
                  action: this.downloadOptag,
                  title: (
                    <>
                      <i className="fas fa-cloud-download-alt mr-2" />
                      Download OpTag.js
                    </>
                  ),
                },
                {
                  action: this.addItem,
                  title: (
                    <>
                      <i className="fas fa-plus mr-2" />
                      Create new
                    </>
                  ),
                },
              ]}
              columns={this.COLUMNS}
              criteria={criteria}
              data={data}
              entity="accounts"
              icon="fas fa-building pr-2"
              loading={loading}
              loadList={loadAccounts}
              metadata={metadata}
              searchable={false}
              // @ts-ignore
              selectItem={this.selectItem}
              title="Accounts"
              updateCriteria={updateCriteria}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  store => ({
    criteria: store.accounts.criteria,
    data: store.accounts.data,
    loading: store.accounts.loading,
    metadata: store.accounts.metadata,
  }),
  {
    deleteAccount,
    downloadOptag,
    loadAccounts,
    updateAccount,
    updateCriteria,
    uploadOptag,
  },
)(List);
