/**
 * GridTableBodyRow component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */

import cx from 'classnames';
import { get, noop } from 'lodash';
import { PureComponent, ReactNode } from 'react';

import { ColumnOption, GridDataItem } from './Grid.interfaces';
import GridTableBodyRowColumn from './GridTableBodyRowColumn';

interface Props {
  className?: string;
  columns: ColumnOption[];
  row: GridDataItem;
  seemore?: (row: GridDataItem) => ReactNode;
  selected?: boolean;
  selectItem?: (row: GridDataItem) => void;
  selectable?: boolean;
  multiple?: boolean;
}

interface State {
  expanded: boolean;
}

export default class GridTableBodyRow extends PureComponent<Props, State> {
  static defaultProps = {
    className: '',
    seemore: undefined,
    selected: false,
    selectItem: undefined,
  };

  state: State = {
    expanded: false,
  };

  toggleExpanded = () => {
    this.setState(({ expanded }) => ({
      expanded: !expanded,
    }));
  };

  selectItem = event => {
    const { row, selectItem } = this.props;

    selectItem && selectItem(row);
  };

  render() {
    const {
      className,
      columns,
      row,
      seemore,
      selected,
      selectItem,
      selectable,
      multiple,
    } = this.props;
    const { expanded } = this.state;

    return (
      <>
        <tr
          className={cx('grid-table-row', className, {
            selected,
          })}
          tabIndex={selectItem ? 0 : undefined}
          onClick={this.selectItem}
        >
          {selectable && multiple && (
            <td>
              <div className="form-check form-check-inline networks">
                <input
                  className="form-check-input"
                  type="checkbox"
                  onChange={noop}
                  checked={selected}
                />
              </div>
            </td>
          )}
          {seemore && (
            <td className="text-center">
              <button type="button" onClick={this.toggleExpanded}>
                <i
                  className={cx('fas', {
                    'fa-chevron-down': expanded,
                    'fa-chevron-right': !expanded,
                  })}
                />
              </button>
            </td>
          )}
          {columns.map((column, idx) => (
            <GridTableBodyRowColumn
              offset={idx}
              key={column.key || column.name}
              column={column}
              data={get(row, column.name)}
              row={row}
            />
          ))}
        </tr>
        {seemore && expanded && (
          <tr>
            {/*
            // @ts-ignore */}
            <td colSpan={columns.length + (seemore ? 1 : 0)}>{seemore(row)}</td>
          </tr>
        )}
      </>
    );
  }
}
