/**
 * GridFooterText component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */

import { PureComponent } from 'react';

import { DEFAULT_PER_PAGE } from '../../constants';

interface Props {
  page: number;
  perPage: number;
  total: number;
}

export default class GridFooterText extends PureComponent<Props> {
  static defaultProps = {
    page: 1,
    perPage: DEFAULT_PER_PAGE,
    total: 0,
  };

  render() {
    const { page, perPage, total } = this.props;
    const from = 1 + (page - 1) * perPage;
    const to = page * perPage;

    return (
      <div className="col-12 text-center col-sm-5 text-sm-left grid-footer-text">
        Showing {from} to {to > total ? total : to} of {total} entries
      </div>
    );
  }
}
