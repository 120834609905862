import { get, max, min, sum } from 'lodash';
import { EChartsInstance } from 'echarts-for-react/src/types';
import * as echarts from 'echarts/core';

import {
  AD_TYPE_TITLES,
  FUNNEL_PROSPECTING,
  FUNNEL_RETARGETING,
  FUNNEL_TITLES,
  NETWORK_DV360,
  NETWORK_FB,
  NETWORK_GOOGLE_ADS,
  NETWORK_ID_TITLES,
  NETWORK_NONPROFITS,
  TYPE_DISCOVERY,
  TYPE_DISPLAY,
  TYPE_SEARCH,
  TYPE_VIDEO,
} from '../../../constants';

import {
  averageAmounts,
  chartOptionType,
  metrics,
  metricsObj,
  reportColorsSet,
} from './constants';
import {
  allMetricsColumns,
  averageDataTypes,
  averageMetricsData,
} from './report-settings';
import { ChartImage, ChartSelectedLegends, Record } from './types';

const summaryFunctions = {
  average: (data: number[]) => (data.length ? sum(data) / data.length : 0),
  count: (data: any[]) => data.length,
  max,
  min,
  sum,
} as const;

const defaultSort = (item1, item2) => (item1.value >= item2.value ? -1 : 1);

// Compute data for charts
export const computeOverallKpiRecords = records => {
  if (!records.length) {
    return [];
  }

  const summaryValues: number[] = allMetricsColumns.map(
    ({ name, summaryType }) => {
      if (!summaryType) {
        return NaN;
      }

      const summaryFunction = summaryFunctions[summaryType];

      return Number(
        summaryFunction(records.map(recordItem => recordItem[name])),
      );
    },
  );

  return allMetricsColumns
    .map(({ title, name, summaryRenderer }, index) => {
      const columnSummary = Number(summaryValues[index]);

      return {
        keyName: name,
        name: title,
        value: summaryRenderer
          ? summaryRenderer(columnSummary, summaryValues)
          : isFinite(columnSummary)
          ? columnSummary
          : null,
      };
    })
    .filter(({ value }) => value !== null);
};

// todo:
const filterTopLowRecords = records =>
  records.filter(record => {
    const funnel = get(record, metricsObj.funnel.value);

    try {
      return [FUNNEL_PROSPECTING, FUNNEL_RETARGETING].indexOf(funnel) > -1;
    } catch (err) {
      return false;
    }
  });

const computeMetricByFunnel = (
  dataArr: {
    funnel: number | undefined;
    value: number | string;
    computeValues?: any;
  }[],
  compute: ((funnelAcc: any, computeValues: any) => any) | null = null,
) =>
  dataArr.reduce(
    (acc, { funnel, value, computeValues = null }) => {
      let funnelAcc = acc.find(
        ({ name }) => funnel && name === FUNNEL_TITLES[funnel],
      );

      if (!funnelAcc) {
        return acc;
      }

      if (compute) {
        compute(funnelAcc, computeValues);
      } else if (typeof value === 'number') {
        funnelAcc.value += value;
      }

      return acc;
    },
    [
      { name: FUNNEL_TITLES[FUNNEL_PROSPECTING], value: 0 },
      { name: FUNNEL_TITLES[FUNNEL_RETARGETING], value: 0 },
    ],
  );

const computeMetricByMetric = (
  dataArr: {
    value: number | string;
    dsp?: number;
    ad_type?: number;
    computeValues?: any;
  }[],
  metricKey: string,
  titles: { [value: number | string]: string },
  compute: ((acc: any, computeValues: any) => any) | null = null,
  sortFn: ((a, b) => number) | null = null,
) =>
  dataArr
    .reduce(
      (acc, item) => {
        let funnelAcc = acc.find(
          ({ name }) => item[metricKey] && name === titles[item[metricKey]],
        );

        if (!funnelAcc) {
          return acc;
        }

        if (compute) {
          compute(funnelAcc, item.computeValues);
        } else if (typeof item.value === 'number') {
          funnelAcc.value += item.value;
        }

        return acc;
      },
      Object.values(titles).map(title => ({ name: title, value: 0 })),
    )
    .sort(sortFn || defaultSort);

export const computeBudgetSpentData = records =>
  computeMetricByFunnel(
    filterTopLowRecords(records).map(record => ({
      funnel: get(record, metricsObj.funnel.value),
      value: record[metricsObj.spend.value],
    })),
  );

const NETWORK_ID_ORDER = {
  [NETWORK_ID_TITLES[NETWORK_GOOGLE_ADS]]: 1,
  [NETWORK_ID_TITLES[NETWORK_DV360]]: 2,
  [NETWORK_ID_TITLES[NETWORK_FB]]: 3,
  [NETWORK_ID_TITLES[NETWORK_NONPROFITS]]: 4,
};

export const computeBudgetSpentDspData = records =>
  computeMetricByMetric(
    records.map(record => ({
      dsp: Number(get(record, metricsObj.dsp.value)),
      value: record[metricsObj.spend.value],
    })),
    'dsp',
    NETWORK_ID_TITLES,
    null,
    ({ name: name1 }, { name: name2 }) =>
      NETWORK_ID_ORDER[name1] < NETWORK_ID_ORDER[name2] ? -1 : 1,
  )
    .map((item, i) => ({
      ...item,
      itemStyle: { color: reportColorsSet[NETWORK_ID_ORDER[item.name] - 1] },
    }))
    .filter(({ value }) => Boolean(value));

const AD_TYPE_TITLES_ORDER = {
  [AD_TYPE_TITLES[TYPE_DISPLAY]]: 1,
  [AD_TYPE_TITLES[TYPE_VIDEO]]: 2,
  [AD_TYPE_TITLES[TYPE_DISCOVERY]]: 3,
  [AD_TYPE_TITLES[TYPE_SEARCH]]: 4,
};

export const computeBudgetSpentAdTypeData = records =>
  computeMetricByMetric(
    records.map(record => ({
      ad_type: Number(get(record, metricsObj.ad_type.value)),
      value: record[metricsObj.spend.value],
    })),
    'ad_type',
    AD_TYPE_TITLES,
    null,
    ({ name: name1 }, { name: name2 }) =>
      AD_TYPE_TITLES_ORDER[name1] < AD_TYPE_TITLES_ORDER[name2] ? -1 : 1,
  );

export const computeClickServedData = records =>
  computeMetricByFunnel(
    filterTopLowRecords(records).map(record => ({
      funnel: get(record, metricsObj.funnel.value),
      value: record[metricsObj.clk.value],
    })),
  );

export const computeCtcRoiData = records =>
  computeMetricByFunnel(
    filterTopLowRecords(records).map(record => ({
      computeValues: {
        ctc_ov: record[metricsObj.ctc_ov.value],
        spend: record[metricsObj.spend.value],
      },
      funnel: get(record, metricsObj.funnel.value),
      value: record[metricsObj.roi.value],
    })),
    (acc, { ctc_ov, spend }) => {
      if (!acc.values) {
        acc.values = { ctc_ov: 0, spend: 0 };
      }
      acc.values.ctc_ov += ctc_ov;
      acc.values.spend += spend;
      acc.value = acc.values.ctc_ov / acc.values.spend;

      return acc;
    },
  );

export const computeTcRoiData = records =>
  computeMetricByFunnel(
    filterTopLowRecords(records).map(record => ({
      computeValues: {
        spend: record[metricsObj.spend.value],
        tc_ov: record[metricsObj.tc_ov.value],
      },
      funnel: get(record, metricsObj.funnel.value),
      value: record[metricsObj.roi.value],
    })),
    (acc, { tc_ov, spend }) => {
      if (!acc.values) {
        acc.values = { spend: 0, tc_ov: 0 };
      }
      acc.values.tc_ov += tc_ov;
      acc.values.spend += spend;
      acc.value = acc.values.tc_ov / acc.values.spend;

      return acc;
    },
  );

export const computeBudgetSpentCampaignData = records =>
  records
    .map(record => ({
      name: get(record, metricsObj.name.value),
      value: record[metricsObj.spend.value],
    }))
    .filter(({ value }) => Boolean(value))
    .sort(defaultSort);
export const computeCtcOrderValueData = records =>
  records
    .map(record => ({
      name: get(record, metricsObj.name.value),
      value: record[metricsObj.ctc_ov.value],
    }))
    .filter(({ value }) => Boolean(value))
    .sort(defaultSort);
export const computeClicksCampaignData = records =>
  records
    .map(record => ({
      name: get(record, metricsObj.name.value),
      value: record[metricsObj.clk.value],
    }))
    .sort(defaultSort);
export const computeConversionsData = records =>
  records
    .map(record => [
      record[metricsObj.tc.value],
      get(record, metricsObj.name.value),
    ])
    .sort((item1, item2) => (item1[0] >= item2[0] ? -1 : 1))
    .filter(item => Boolean(item[0]));
export const computeCtcConversionsData = records =>
  records
    .map(record => [
      record[metricsObj.ctc.value],
      get(record, metricsObj.name.value),
    ])
    .sort((item1, item2) => (item1[0] >= item2[0] ? -1 : 1))
    .filter(item => Boolean(item[0]));
export const computeAverage = (records, metricKey) => {
  averageAmounts.forEach(days => computeAverageDays(records, metricKey, days));

  return records;
};
export const computeAverageDays = (records, metricKey, averageAmount = 7) => {
  const averageKey = `average${averageAmount}`;
  let startIndex = 0;

  if (!records.length) {
    return records;
  }

  records[startIndex][averageKey] = records[startIndex][metricKey];

  while (startIndex < records.length) {
    let spendSum = 0;
    let averageIndex = 0;

    for (let i = startIndex; i < startIndex + averageAmount; i++) {
      if (records[i]) {
        averageIndex = i;
        spendSum += records[i][metricKey];
      } else {
        averageIndex = i - 1;
        break;
      }
    }

    records[averageIndex][averageKey] =
      spendSum / (averageIndex - startIndex + 1);
    startIndex += averageAmount;
  }

  return records;
};
export const computeSpikes = (
  records,
  metricKey,
  ratio = 4,
  nextComparisonRatio = 1.5,
  compareZeroValue = 0.1,
  filterValue = 3,
) => {
  const spikes: { index: number; value: number }[] = [];
  const recordsWithSpikes = [...records];
  let startIndex = 1;

  while (startIndex < records.length) {
    const currentValue = records[startIndex][metricKey];
    const prevValue =
      records[startIndex - 1][metricKey] < 1
        ? compareZeroValue
        : records[startIndex - 1][metricKey];
    const nextValue =
      !records[startIndex + 1] || records[startIndex + 1][metricKey] < 1
        ? compareZeroValue
        : records[startIndex + 1][metricKey];

    if (currentValue / prevValue >= ratio) {
      if (nextValue / currentValue > nextComparisonRatio) {
        spikes.push({
          index: startIndex + 1,
          value: records[startIndex + 1][metricKey],
        });
      } else {
        spikes.push({
          index: startIndex,
          value: records[startIndex][metricKey],
        });
      }
    }
    startIndex++;
  }

  if (spikes.length) {
    let max = 0;

    spikes.forEach(({ value }, i) => {
      if (spikes[max].value < value) {
        max = i;
      }
    });

    const middleValue = spikes[max].value / filterValue;

    spikes.forEach(({ index, value }) => {
      if (value > middleValue) {
        recordsWithSpikes[index] = {
          ...records[index],
          [`spike${ratio}`]: value,
        };
        recordsWithSpikes[index] = { ...records[index], spike: value };
      }
    });
  }

  return recordsWithSpikes;
};

const setAverageDayLabel = (label, days) =>
  `${label}`.replace(`${averageAmounts[0]}`, `${days}`);

const averageLegendToKeyMap: { [label: string]: string } =
  averageAmounts.reduce((map, days) => {
    Object.keys(averageMetricsData).forEach(averageMetricsDataKey => {
      if (
        averageMetricsData[averageMetricsDataKey].type ===
        averageDataTypes.average
      ) {
        map[
          setAverageDayLabel(
            averageMetricsData[averageMetricsDataKey].label,
            days,
          )
        ] = `${averageDataTypes.average}${days}`;
      }
      if (
        averageMetricsData[averageMetricsDataKey].type ===
        averageDataTypes.spike
      ) {
        map[averageMetricsData[averageMetricsDataKey].label] =
          averageDataTypes.spike;
      }
      if (
        averageMetricsData[averageMetricsDataKey].type ===
        averageDataTypes.future
      ) {
        map[averageMetricsData[averageMetricsDataKey].label] =
          averageDataTypes.future;
      }
    });

    return map;
  }, {});

export const getMetricKeyByLegendName = (legend: string) => {
  const metric = metrics.find(({ label }) => label === legend);

  if (metric) {
    return metric.value;
  }

  const metricKey = averageLegendToKeyMap[legend];

  if (metricKey) {
    return metricKey;
  }

  return '';
};

export const setSelectedMetricKeysByLegend = (
  selectedLegend: ChartSelectedLegends,
) =>
  Object.keys(selectedLegend).reduce((selectedMetricKeys, legend) => {
    selectedMetricKeys[getMetricKeyByLegendName(legend)] =
      selectedLegend[legend];

    return selectedMetricKeys;
  }, {});

export const filterKeysFromRecordsData = (
  records: Record[],
  selected: ChartSelectedLegends,
) => {
  let keysToRemove: string[] = [];

  // Remove last averages when empty
  if (!selected || Object.keys(selected).length === 0) {
    keysToRemove = averageAmounts.reduce((keys: string[], days, index) => {
      if (!index) {
        return keys;
      }

      keys.push(`${averageDataTypes.average}${days}`);

      return keys;
    }, []);
  }

  if (keysToRemove.length === 0) {
    keysToRemove = Object.keys(selected).reduce(
      (keys: string[], selectedKey) => {
        if (!selected[selectedKey]) {
          keys.push(selectedKey);
        }

        return keys;
      },
      [],
    );
  }

  return records.map(record => {
    const filteredRecord = { ...record };

    keysToRemove.forEach(key => {
      delete filteredRecord[key];
    });

    return filteredRecord;
  });
};

export const filterMetricsForChartBySelected = (
  metrics,
  selectedMetricKeys,
) => {
  // Remove last averages when empty // todo: maybe set default values into selectedLegends state of PerformanceReport
  if (!selectedMetricKeys) {
    const defaultSelectedMetricKeys = averageAmounts.reduce(
      (keys: { [key: string]: boolean }, days, index) => {
        if (!index) {
          return keys;
        }

        keys[`${averageDataTypes.average}${days}`] = true;

        return keys;
      },
      {},
    );

    return metrics.filter(({ key }) => !defaultSelectedMetricKeys[key]);
  }

  return metrics.filter(({ key }) => selectedMetricKeys[key]);
};

export const loadImage = (src: string) =>
  new Promise((resolve: (value: HTMLImageElement) => void, reject) => {
    const img = new Image();

    img.onload = () => resolve(img);
    img.onerror = () => reject(null);
    img.src = src;
  });

export const getChartImage = async (
  option,
  height = 400,
  width = 600,
  createImage = false,
) => {
  const canvas = document.createElement('canvas');

  canvas.width = width;
  canvas.height = height;

  const chart: EChartsInstance = echarts.init(canvas, null, {
    devicePixelRatio: 2,
    height,
    renderer: 'canvas',
    ssr: true,
    width,
  });

  chart.setOption(option);

  let png;

  if (createImage) {
    png = await loadImage(chart.getDataURL());
  } else {
    png = { src: chart.getDataURL() };
  }

  chart.dispose();

  return png?.src;
};

export const getChart = async (
  option,
  height = 400,
  width = 600,
  createImage = false,
): Promise<ChartImage> => {
  const chartSeriesType = option?.series[0]?.type;

  if (
    chartSeriesType === chartOptionType.pie &&
    (!option?.series[0]?.data || option?.series[0]?.data?.length === 0)
  ) {
    return {
      notEnoughData: true,
      src: '',
    };
  }
  if (
    chartSeriesType === chartOptionType.bar &&
    (!option?.dataset || option?.dataset?.source?.length === 1)
  ) {
    return {
      notEnoughData: true,
      src: '',
    };
  }
  if (
    chartSeriesType === chartOptionType.line &&
    (!option?.series[0]?.data || option?.series[0]?.data?.length === 0)
  ) {
    return {
      notEnoughData: true,
      src: '',
    };
  }

  try {
    const image = await getChartImage(option, height, width, createImage);

    return {
      src: image,
    };
  } catch (err) {
    console.error(err);
  }

  return {
    renderError: true,
    src: '',
  };
};
