/**
 * AssetItemForm component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */

import cx from 'classnames';
import { filter, find, findIndex, get } from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent, ReactNode } from 'react';
import { Field } from 'react-final-form';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';

import FormError from '../../components/FormError';

export default class AccountItemForm extends PureComponent<any, any> {
  static propTypes = {
    addNotify: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    errors: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    loadAdWordsLabel: PropTypes.func.isRequired,
    networks: PropTypes.array.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    touched: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
  };

  static defaultProps = {
    disabled: false,
  };

  renderToggleInput = (field, form, values) => {
    const checked = get(values, field);

    return (
      <Toggle
        checked={Boolean(checked)}
        onChange={() => form.mutators.updateValue(field, !checked)}
        icons={false}
      />
    );
  };

  setAdWordsLabel = async (
    form,
    conversionIdSettingPath,
    conversionLabelSettingPath,
    adWordIdSettingValue,
  ) => {
    const res = await this.props.loadAdWordsLabel(adWordIdSettingValue);

    if (!res.data) {
      return;
    }

    form.mutators.updateValue(conversionIdSettingPath, res.data.id);
    form.mutators.updateValue(conversionLabelSettingPath, res.data.label);
  };

  getNetworkForm = (network, aIndex, form, values) => {
    const { network_settings: settings = [], id } = network;
    const cols =
      settings.length >= 4 ? 3 : Math.floor(12 / (settings.length + 1));
    let disabled = true;
    let conversionIdSettingPath,
      conversionLabelSettingPath,
      adWordIdSettingValue;

    if (network.id === 1) {
      const currNetworkSettings = get(
        values,
        `network_accounts[${aIndex}].ad_network.network_settings`,
        [],
      );
      const adWordIdSetting = find(currNetworkSettings, {
        name: 'AdWords ID',
      });

      if (adWordIdSetting) {
        adWordIdSettingValue = get(
          adWordIdSetting,
          'network_settings_data.data',
        );
        if (adWordIdSettingValue) {
          const conversionLabelSettingIndex = findIndex(currNetworkSettings, {
            name: 'Conversion Label',
          });
          const conversionIdSettingIndex = findIndex(currNetworkSettings, {
            name: 'Conversion Id',
          });

          disabled = false;
          conversionIdSettingPath = `network_accounts[${aIndex}].ad_network.network_settings[${conversionIdSettingIndex}].network_settings_data.data`;
          conversionLabelSettingPath = `network_accounts[${aIndex}].ad_network.network_settings[${conversionLabelSettingIndex}].network_settings_data.data`;
        }
      }
    }

    const getValueName = (setting, aIndex) => {
      const settingIndex = findIndex(
        get(
          values,
          `network_accounts[${aIndex}].ad_network.network_settings`,
          [],
        ),
        {
          id: setting.id,
        },
      );

      return `network_accounts[${aIndex}].ad_network.network_settings[${settingIndex}].network_settings_data.data`;
    };

    return (
      <>
        {settings
          .sort((a, b) => a.id - b.id)
          .map(setting => {
            const params: { onChange?: (data: any) => void } = {};
            let prefix: ReactNode = '';

            if (
              setting.name === 'AdWords ID' ||
              setting.name === 'DV360 Advertiser ID'
            ) {
              if (setting.name === 'AdWords ID') {
                prefix = <div className="field-prefix">AW-</div>;
              }
              params.onChange = el => {
                form.mutators.updateValue(
                  `network_accounts[${aIndex}.external_reference]`,
                  el.target.value,
                );
                form.mutators.updateValue(
                  getValueName(setting, aIndex),
                  el.target.value.replace(/[^0-9.]/g, ''),
                );
              };
            }

            return (
              <div className={`col-${cols}`} key={setting.id}>
                <div className="form-group required">
                  <label htmlFor={`account_${aIndex}_setting_${setting.id}`}>
                    {setting.name}
                  </label>
                  {prefix}
                  <Field
                    id={`account_${aIndex}_setting_${setting.id}`}
                    name={getValueName(setting, aIndex)}
                    component="input"
                    className={cx('form-control')}
                    maxLength={90}
                    {...params}
                  />
                  <FormError name={`account_${aIndex}_setting_${setting.id}`} />
                </div>
              </div>
            );
          })}
        {id === 1 && (
          <button
            type="button"
            disabled={disabled}
            onClick={event => {
              event.preventDefault();
              this.setAdWordsLabel(
                form,
                conversionIdSettingPath,
                conversionLabelSettingPath,
                adWordIdSettingValue,
              );
            }}
            className="btn btn-primary load-data-button"
          >
            Get AdWords Label
          </button>
        )}
        <div className="status">
          <Field
            name={`network_accounts[${aIndex}].status`}
            id={`network_accounts[${aIndex}].status`}
            type="checkbox"
            component={() =>
              this.renderToggleInput(
                `network_accounts[${aIndex}].status`,
                form,
                values,
              )
            }
          />
        </div>
      </>
    );
  };

  render() {
    const {
      disabled,
      errors,
      form,
      handleSubmit,
      pristine,
      submitting,
      touched,
      networks,
      values,
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <div className="card-body container-fluid">
          <div className="row">
            <div className="col-4">
              <div className="form-group required">
                <label htmlFor="name">Name</label>
                <Field
                  id="name"
                  name="name"
                  component="input"
                  className={cx('form-control', {
                    'is-invalid': touched.name && errors.name,
                  })}
                  maxLength={90}
                />
                <FormError name="name" />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group required">
                <label htmlFor="dcm_private">DCM Private</label>
                <div className="field-prefix">DC-</div>
                <Field
                  id="dcm_private"
                  name="dcm_private"
                  component="input"
                  className="form-control"
                  maxLength={90}
                />
                <FormError name="dcm_private" />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group required">
                <label htmlFor="margin">Margin</label>
                <Field
                  id="margin"
                  name="margin"
                  component="input"
                  className={cx('form-control', {
                    'is-invalid': touched.margin && errors.margin,
                  })}
                  maxLength={90}
                />
                <FormError name="margin" />
              </div>
            </div>
          </div>
          <h5>Network Accounts</h5>
          {networks.map(network => (
            <div key={network.module} className="row">
              <div className="col-12">
                <fieldset>
                  <legend>{network.name}</legend>
                  {values.network_accounts &&
                    values.network_accounts.map((account, index) =>
                      account.ad_network.id === network.id ? (
                        <div className="form-group controlled">
                          <div className="row">
                            {this.getNetworkForm(network, index, form, values)}
                            <button
                              type="button"
                              onClick={event => {
                                event.preventDefault();
                                form.mutators.removeAccount(index);
                              }}
                              className="btn btn-primary mb-2"
                            >
                              X
                            </button>
                          </div>
                        </div>
                      ) : null,
                    )}
                  {Boolean(
                    values.network_accounts &&
                      !filter(
                        values.network_accounts,
                        networkAccount =>
                          networkAccount.ad_network.id === network.id,
                      ).length,
                  ) && (
                    <button
                      type="button"
                      onClick={event => {
                        event.preventDefault();
                        form.mutators.addAccount(network.id);
                      }}
                      className="btn btn-primary add-btn"
                    >
                      + Add Account
                    </button>
                  )}
                </fieldset>
              </div>
            </div>
          ))}
        </div>
        <div className="card-footer">
          <div className="row">
            <div className="col-12">
              <button
                type="button"
                className="btn btn-secondary"
                disabled={pristine || submitting}
                onClick={form.reset}
              >
                Reset
              </button>
              <button
                type="submit"
                className="btn btn-primary float-right"
                disabled={disabled || pristine || submitting}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}
