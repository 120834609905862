import CryptoJS from 'crypto-js';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';

import { loadProfile } from '../actions/auth.actions';
import axiosMiddleware from '../middleware/axios';

import { INITIAL_STATE as AUTH_INITIAL_STATE } from './auth.reducer';
import { downloadMiddleware } from './downloadMiddleware';

import createRootReducer from '.';

const startup = () => {
  let initialState = {
    auth: AUTH_INITIAL_STATE,
  };

  const authToken = localStorage.getItem('x-auth-token');
  const data = localStorage.getItem('userData');

  if (authToken && data) {
    try {
      const user = JSON.parse(
        CryptoJS.AES.decrypt(data, authToken).toString(CryptoJS.enc.Utf8),
      );

      // @ts-ignore
      initialState.auth = {
        ...initialState.auth,
        ...{
          authToken,
          isLoggedIn: true,
          user,
        },
      };
    } catch (err) {
      localStorage.removeItem('x-auth-token');
      localStorage.removeItem('userData');
    }
  }

  return initialState;
};

let preloadedState = startup();

export const store = createStore(
  createRootReducer(),
  preloadedState,
  composeWithDevTools(
    applyMiddleware(thunk, axiosMiddleware([]), downloadMiddleware),
  ),
);

store.dispatch(loadProfile());
