import { combineReducers } from 'redux';

import account from './account.reducer';
import accounts from './accounts.reducer';
import { accountTrackingReducer } from './accountTracking.reducer';
import adgroup from './adgroup.reducer';
import adgroups from './adgroups.reducer';
import asset from './asset.reducer';
import assets from './assets.reducer';
import auth from './auth.reducer';
import billing from './billing.reducer';
import campaign from './campaign.reducer';
import campaigns from './campaigns.reducer';
import dashboard from './dashboard.reducer';
import network from './network.reducer';
import networks from './networks.reducer';
import notifications from './notifications.reducer';
import reports from './reports.reducer';
import roles from './roles.reducer';
import user from './user.reducer';
import users from './users.reducer';

const reducers = combineReducers({
  account,
  accounts,
  accountTracking: accountTrackingReducer,
  adgroup,
  adgroups,
  asset,
  assets,
  auth,
  billing,
  campaign,
  campaigns,
  dashboard,
  network,
  networks,
  notifications,
  reports,
  roles,
  user,
  users,
});

export type RootState = ReturnType<typeof reducers>;

export default () => reducers;
