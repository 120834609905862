export const formatNumber = (price: string | number, fractionalDigits = 2) =>
  Number(price)
    .toFixed(fractionalDigits)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const formatNumber0 = (num: string | number) => formatNumber(num, 0);

export const formatNumber2 = (num: string | number) => formatNumber(num, 2);

export const formatUSD = (price: string | number, fractionalDigits?: number) =>
  `$${formatNumber(price, fractionalDigits)}`;

export const formatPercent = (percent: number) =>
  (percent * 100).toFixed(2) + '%';
