/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * Image Input component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */

import cx from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Cropper from 'react-cropper';

import Modal from '../../components/Modal';
import { getTypeId, imagePath } from '../../helpers/creative';

const ACCEPT_FILE_TYPES = ['image/png', 'image/jpg', 'image/jpeg'];

interface Props {
  className?: string;
  disabled?: boolean;
  height: number;
  input: {
    onChange: any;
    value: any;
    name?: any;
  };
  max?: number;
  width: number;
}

export default class ImageInput extends PureComponent<Props, any> {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    height: PropTypes.number.isRequired,
    input: PropTypes.object.isRequired,
    max: PropTypes.number,
    width: PropTypes.number.isRequired,
  };

  static defaultProps = {
    className: '',
    disabled: false,
    max: 5242880,
  };

  state = {
    image: null,
    showModal: false,
  };

  inputRef: any = null;

  cropperRef: import('cropperjs').default | null = null;

  dragCounter = 0;

  setInputRef = node => {
    this.inputRef = node;
  };

  setCropperRef = node => {
    this.cropperRef = node;
  };

  onClick = () => {
    if (this.props.disabled) {
      return false;
    }
    if (this.inputRef) {
      this.inputRef.click();
    }
  };

  onKeyDown = event => {
    if (event.keyCode === 13 || event.keyCode === 32) {
      this.onClick();
    }
  };

  onDrag = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  onDragIn = event => {
    event.preventDefault();
    event.stopPropagation();

    this.dragCounter++;
  };

  onDragOut = event => {
    event.preventDefault();
    event.stopPropagation();

    this.dragCounter--;
  };

  onDrop = event => {
    event.preventDefault();
    event.stopPropagation();

    if (this.props.disabled) {
      return false;
    }
    this.processImage(event.dataTransfer.files[0]);
    event.dataTransfer.clearData();
  };

  onChange = event => {
    const file = event.target.files[0];

    this.processImage(file);
    event.target.value = null;
  };

  processImage = image => {
    if (!image) {
      return false;
    }

    const { max } = this.props;

    if (image.size > max!) {
      alert(`File too large, max size ${Math.floor(max! / 1024 / 1024)}mb`);

      return false;
    }
    if (!ACCEPT_FILE_TYPES.includes(image.type)) {
      alert('Please use image file (.jpg, .jpeg, .png)');

      return false;
    }

    const reader = new FileReader();

    reader.addEventListener('load', () => {
      this.setState({
        image: reader.result,
        showModal: true,
      });
    });
    reader.readAsDataURL(image);
  };

  saveImage = () => {
    const {
      input: { onChange },
      width,
    } = this.props;

    if (onChange && this.cropperRef) {
      onChange(
        this.cropperRef
          .getCroppedCanvas({
            minWidth: width,
          })
          .toDataURL(),
      );
    }

    this.setState({
      image: null,
      showModal: false,
    });
  };

  preventEvent = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  closeModal = () => {
    this.setState({
      image: null,
      showModal: false,
    });
  };

  render() {
    const { disabled, height, input, max, width, className, children } =
      this.props;
    const { image, showModal } = this.state;

    return (
      <>
        <div
          className={cx(`dropzone ${className}`, {
            disabled,
          })}
          role="button"
          tabIndex={0}
          onClick={this.onClick}
          onDrop={this.onDrop}
          onDragOver={this.onDrag}
          onDragEnter={this.onDragIn}
          onDragLeave={this.onDragOut}
          onKeyDown={this.onKeyDown}
        >
          <input
            accept={ACCEPT_FILE_TYPES.join(',')}
            autoComplete="off"
            className="d-none"
            disabled={disabled}
            name={input.name}
            onChange={this.onChange}
            ref={this.setInputRef}
            tabIndex={-1}
            type="file"
          />
          {children ? (
            children
          ) : (
            <>
              {!input.value && (
                <>
                  <button type="button" className="btn btn-secondary mb-1">
                    {'Click here or drop file to add an image.\n'}
                  </button>
                  <pre className="text-center">
                    {`Suggested size: ${width}px x ${height}px\n`}
                    {'File accepted type: .jpg, .jpeg, .png\n'}
                    {`Max size: ${Math.floor(max! / 1024 / 1024)}mb`}
                  </pre>
                </>
              )}
              {input.value && (
                <div className="dropzone-image-wrapper">
                  <img
                    src={imagePath({
                      imageLandscape: input.value,
                      type: getTypeId('image'),
                    })}
                    onClick={this.preventEvent}
                    alt=""
                  />
                </div>
              )}
              {input.value && !disabled && (
                <div className="dropzone-actions-wrapper">
                  <button
                    className="dropzone-button-edit btn btn-primary"
                    type="button"
                  >
                    <i className="fa fa-file-upload m-r-5" />
                    Change
                  </button>
                </div>
              )}
            </>
          )}
        </div>

        <Modal
          close={this.closeModal}
          show={showModal}
          size="xl"
          submit={this.saveImage}
          title="Upload an image file"
        >
          <pre className="text-center">
            {'Click here or drop file to add an image.\n'}
            {`Suggested size: ${width}px x ${height}px\n`}
            {'File accepted type: .jpg, .jpeg, .png\n'}
            {`Max size: ${Math.floor(max! / 1024 / 1024)}mb`}
          </pre>
          {image && (
            <Cropper
              aspectRatio={width / height}
              autoCropArea={1}
              guides={false}
              minContainerHeight={
                height < 200
                  ? 200
                  : width > 800
                  ? (height / width) * 800
                  : height
              }
              minContainerWidth={width > 800 ? 800 : width < 400 ? 400 : width}
              ref={this.setCropperRef}
              src={image}
              viewMode={2}
            />
          )}
        </Modal>
      </>
    );
  }
}
