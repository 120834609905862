/**
 * GridTable component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */

import { PureComponent, ReactNode } from 'react';

import {
  ColumnOption,
  GridColumnsSorting,
  GridDataItem,
} from './Grid.interfaces';
import GridTableBody from './GridTableBody';
import { GridTableFooter } from './GridTableFooter';
import GridTableHeader from './GridTableHeader';

interface Props {
  columns: ColumnOption[];
  data: GridDataItem[];
  onChange?: (rows: GridDataItem[], row: GridDataItem | null) => void;
  onSort?: (name: string) => void;
  seemore?: (row: GridDataItem) => ReactNode;
  selectable?: boolean;
  multiple?: boolean;
  sort?: GridColumnsSorting;
  blueRows?: boolean;
}

interface State {
  selectedRows: Set<GridDataItem>;
}

export default class GridTable extends PureComponent<Props, State> {
  static defaultProps = {
    onChange: undefined,
    seemore: undefined,
    selectedRows: null,
    sort: {},
  };

  state: State = {
    selectedRows: new Set(),
  };

  toggleAll = () => {
    this.setState(state => {
      const selectedRows = new Set(
        this.props.data.length === state.selectedRows.size
          ? undefined
          : this.props.data,
      );

      if (this.props.onChange) {
        this.props.onChange(Array.from(selectedRows), null);
      }

      return {
        ...state,
        selectedRows,
      };
    });
  };

  selectItem = (row: GridDataItem) => {
    const { onChange, multiple } = this.props;
    const { selectedRows } = this.state;

    const items = multiple ? new Set(selectedRows) : new Set<GridDataItem>();

    if (items.has(row)) {
      items.delete(row);
    } else {
      items.add(row);
    }

    this.setState({
      selectedRows: items,
    });

    if (onChange) {
      onChange(Array.from(items), row);
    }
  };

  render() {
    const {
      columns,
      data,
      onSort,
      seemore,
      sort,
      selectable,
      multiple,
      blueRows,
    } = this.props;

    const selectedAll =
      data.length !== 0 && data.length === this.state.selectedRows.size;

    return (
      <table
        className={`table table-striped nowrap dataTable table-hover ${
          blueRows && 'blue-rows'
        }`}
      >
        <GridTableHeader
          columns={columns}
          onSort={onSort}
          seemore={Boolean(seemore)}
          sort={sort}
          selectable={selectable}
          multiple={multiple}
          selectedAll={selectedAll}
          toggleAll={this.toggleAll}
        />
        <GridTableBody
          columns={columns}
          data={data}
          seemore={seemore}
          selectedRows={this.state.selectedRows}
          selectItem={this.selectItem}
          selectable={selectable}
          multiple={multiple}
        />
        <GridTableFooter
          columns={columns}
          data={data}
          selectable={selectable}
        />
      </table>
    );
  }
}
