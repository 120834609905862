import { Dispatch } from 'redux';

export const ADGROUPS_LIST_REQUEST = 'ADGROUPS_LIST_REQUEST';
export const ADGROUPS_LIST_SUCCESS = 'ADGROUPS_LIST_SUCCESS';
export const ADGROUPS_LIST_ERROR = 'ADGROUPS_LIST_ERROR';
export const ADGROUPS_LIST_CRITERIA = 'ADGROUPS_LIST_CRITERIA';

export const ADGROUP_ITEM_REQUEST = 'ADGROUP_ITEM_REQUEST';
export const ADGROUP_ITEM_SUCCESS = 'ADGROUP_ITEM_SUCCESS';
export const ADGROUP_ITEM_ERROR = 'ADGROUP_ITEM_ERROR';
export const ADGROUP_ITEM_RESET = 'ADGROUP_ITEM_RESET';

export const ADGROUP_CREATE_REQUEST = 'ADGROUP_CREATE_REQUEST';
export const ADGROUP_CREATE_SUCCESS = 'ADGROUP_CREATE_SUCCESS';
export const ADGROUP_CREATE_ERROR = 'ADGROUP_CREATE_ERROR';

export const ADGROUP_UPDATE_REQUEST = 'ADGROUP_UPDATE_REQUEST';
export const ADGROUP_UPDATE_SUCCESS = 'ADGROUP_UPDATE_SUCCESS';
export const ADGROUP_UPDATE_ERROR = 'ADGROUP_UPDATE_ERROR';

export const ADGROUP_DELETE_REQUEST = 'ADGROUP_DELETE_REQUEST';
export const ADGROUP_DELETE_SUCCESS = 'ADGROUP_DELETE_SUCCESS';
export const ADGROUP_DELETE_ERROR = 'ADGROUP_DELETE_ERROR';

export const loadItems = () => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) => axios.get('/campaign/adgroup'),
    requestType: 'ADGROUPS_LIST',
  });
export const loadItem = (id: string) => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) => axios.get(`/campaign/adgroup/${id}`),
    requestType: 'ADGROUP_ITEM',
  });

export const createItem =
  (campaignId: string, data: any) => (dispatch: Dispatch<any>) =>
    dispatch({
      request: (axios: AI) =>
        axios.post(`/campaign/${campaignId}/adgroup`, data),
      requestType: 'ADGROUP_CREATE',
    });

export const updateItem =
  (id: string, data: any) => (dispatch: Dispatch<any>) =>
    dispatch({
      request: (axios: AI) => axios.put(`/campaign/adgroup/${id}`, data),
      requestType: 'ADGROUP_UPDATE',
    });

export const deleteItem = (id: string) => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) => axios.delete(`/campaign/adgroup/${id}`),
    requestType: 'ADGROUP_DELETE',
    successCallback: (response: any, dispatch: Dispatch<any>) => {
      dispatch(loadItems());

      return {
        message: response.message,
      };
    },
  });

export const resetItem = () => (dispatch: Dispatch<any>) =>
  dispatch({
    type: ADGROUP_ITEM_RESET,
  });

export const loadTopics = (search: string) => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) =>
      axios.get('/campaign/verticals/search', {
        params: {
          limit: 25,
          query: search,
        },
      }),
    requestType: 'CAMPAIGNS_TOPICS_LIST',
  });

export const loadUserList =
  (accountId: string, search: string) => (dispatch: Dispatch<any>) =>
    dispatch({
      request: (axios: AI) =>
        axios.get(`/googleads/account/${accountId}/userlists`, {
          params: {
            limit: 25,
            query: search,
          },
        }),
      requestType: 'GOOGLE_ADS_USER_LIST',
    });
