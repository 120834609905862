/**
 * GridTableBodyRowColumn component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */

import { get } from 'lodash';
import { PureComponent } from 'react';

import { format } from '../../helpers/date';
import { formatPercent, formatUSD } from '../../helpers/number';
import Tooltip from '../Tooltip';

import { ColumnOption, GridDataItem } from './Grid.interfaces';

interface Props {
  column: ColumnOption;
  data: GridDataItem;
  // TODO: no usages
  editItem: () => void;
  offset: number;
  row: GridDataItem;
}

export default class GridTableBodyRowColumn extends PureComponent<Props> {
  static defaultProps = {
    data: undefined,
    editItem: undefined,
  };

  processColumn = (
    // TODO:
    column: ColumnOption | any,
    data: GridDataItem,
    row: any,
    key: string,
  ) => {
    let value;

    // TODO:
    const item = column.values
      ? // @ts-ignore
        (column.values[data] as any as ColumnOption)
      : column;

    if (!item) {
      console.error('Column data is empty');

      return;
    }

    const fieldValue =
      data && typeof data === 'object' && item.field
        ? get(data, item.field)
        : data;

    if (item.condition && !item.condition(row)) {
      return;
    }

    switch (item.type as ColumnOption['type']) {
      case 'percent':
        value = formatPercent(fieldValue);
        break;

      case 'date':
        value = fieldValue ? format(fieldValue) : '-';
        break;

      case 'icon':
        value = <i key={'icon-' + key} className={item.icon} />;
        if (item.tooltip) {
          let tooltip = item.tooltip;

          if (item.errorInTooltip && row.error) {
            tooltip += ` <strong>Error</strong>: ${row.error}`;
          }

          value = (
            <Tooltip key={'tooltip-' + key} tooltip={tooltip} button={false}>
              {value}
            </Tooltip>
          );
        }
        if (item.action) {
          const disabled = Boolean(item.disabled) && item.disabled(row);

          value = (
            <button
              key={'button-' + key}
              disabled={disabled}
              onClick={() => item.action && item.action(row)}
              type="button"
            >
              {value}
            </button>
          );
        }
        break;

      case 'price':
        value = formatUSD(fieldValue);
        break;

      case 'list':
        value = (
          <>
            {item.items.map((item, idx) =>
              this.processColumn(item, data, row, `list-${idx}-${key}`),
            )}
          </>
        );
        break;

      case 'dropbox':
        {
          const list =
            typeof item.list === 'function' ? item.list() : item.list;

          value = (
            <select
              name={item.name}
              key={'dropbox-' + key}
              id={item.name}
              className="form-control"
              defaultValue={fieldValue}
              onClick={data => item.action(data.currentTarget.value, row)}
            >
              {list.map(opt => (
                <option key={opt.value} value={opt.value}>
                  {opt.title}
                </option>
              ))}
            </select>
          );
        }
        break;

      case 'custom':
        value = item.value
          ? typeof item.value === 'function'
            ? item.value(data, row)
            : item.value
          : data;
        break;

      default:
        value = fieldValue;
    }

    return value;
  };

  render() {
    const { column, data, editItem, row, offset } = this.props;

    const value = this.processColumn(column, data, row, String(offset));
    const dynamicClassName = column.dataClassName
      ? column.dataClassName(data)
      : '';

    return (
      <td
        className={`${column.className || ''} ${dynamicClassName}`}
        tabIndex={column.link && editItem ? 0 : undefined}
        onClick={column.link ? editItem : undefined}
      >
        {value}
      </td>
    );
  }
}
