export interface PermissionsMap {
  [id: number]: string[];
}

export const allowed = (
  permsMap: PermissionsMap,
  permission: string,
  accountId?: number,
) =>
  !permsMap[0] && accountId
    ? permsMap[accountId]?.includes(permission)
    : permsMap[0]?.includes(permission);
