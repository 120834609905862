import { get } from 'lodash';

import {
  ColumnOption,
  GridColumnsSorting,
  GridDataItem,
} from './Grid.interfaces';

export const toggleSorting = (
  field: string,
  previousSorting: GridColumnsSorting,
  singleValue: boolean = false,
): GridColumnsSorting => {
  const sorting = singleValue
    ? { [field]: previousSorting[field] }
    : { ...previousSorting };

  if (sorting[field] === 'ASC') {
    sorting[field] = 'DESC';
  } else if (sorting[field] === 'DESC') {
    delete sorting[field];
  } else {
    sorting[field] = 'ASC';
  }

  return sorting;
};

export const applySorting = (
  data: GridDataItem[],
  columns: ColumnOption[],
  sorting: GridColumnsSorting,
  sortBy: string | undefined = Object.keys(sorting)[0],
) => {
  const column = columns.find(col => col.name === sortBy);

  if (!sortBy || !column) {
    return data;
  }

  const asc = sorting[sortBy] === 'ASC';

  const comparatorsMap = {
    numeric: (item1: GridDataItem, item2: GridDataItem) =>
      asc
        ? get(item1, column.name) - get(item2, column.name)
        : get(item2, column.name) - get(item1, column.name),
    text: (item1: GridDataItem, item2: GridDataItem) =>
      asc
        ? String(get(item1, column.name)).localeCompare(get(item2, column.name))
        : String(get(item2, column.name)).localeCompare(
            get(item1, column.name),
          ),
  };

  const comparator = column.sortingType
    ? comparatorsMap[column.sortingType] || comparatorsMap.numeric
    : comparatorsMap.numeric;

  return [...data].sort(comparator);
};
