import { createSelector } from 'reselect';

import { allowed } from '../helpers/permissions';
import { RootState } from '../reducers';
import { MANAGE_CAMPAIGN_DEFAULTS } from '../constants/permissions';

export const userPermsSelector = (store: RootState) => store.auth.user?.perms;

export const allowedManageCampaignDefaultsSelector = createSelector(
  userPermsSelector,
  perms => allowed(perms, MANAGE_CAMPAIGN_DEFAULTS),
);
