/**
 * AssetItemForm component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events, react/forbid-dom-props */

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import cx from 'classnames';

import FormError from '../../components/FormError';
import SelectAdapter from '../../components/SelectAdapter';

export default class AdgroupItemForm extends PureComponent<any, any> {
  static propTypes = {
    errors: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
  };

  render() {
    const { errors, handleSubmit, touched, values } = this.props;

    return (
      <form onSubmit={handleSubmit} id="groupSettingsForm">
        <div className="row">
          <div className="col">
            {values.network_id === 2 && (
              <>
                <div className="row">
                  <div className="form-group required col-12 col-md-6 col-lg-6">
                    <label htmlFor="settings.floodlightActivityTag">
                      Floodlight Activity Tag
                    </label>
                    <Field
                      id="settings.floodlightActivityTag"
                      name="settings.floodlightActivityTag"
                      component="input"
                      type="text"
                      className={cx('form-control', {
                        'is-invalid':
                          touched['settings.floodlightActivityTag'] &&
                          errors.settings &&
                          errors.settings.floodlightActivityTag,
                      })}
                    />
                    <FormError name="settings.floodlightActivityID" />
                  </div>
                  <div className="form-group required col-12 col-md-6 col-lg-6">
                    <label htmlFor="settings.bid">Floodlight Activity ID</label>
                    <Field
                      id="settings.floodlightActivityID"
                      name="settings.floodlightActivityID"
                      component="input"
                      type="text"
                      min={0}
                      className={cx('form-control', {
                        'is-invalid':
                          touched['settings.floodlightActivityID'] &&
                          errors.settings &&
                          errors.settings.floodlightActivityID,
                      })}
                    />
                    <FormError name="settings.floodlightActivityID" />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group required col-12 col-md-6 col-lg-6">
                    <label htmlFor="settings.postClickWindow">
                      Post-click window
                    </label>
                    <Field
                      id="settings.postClickWindow"
                      name="settings.postClickWindow"
                      component="input"
                      type="number"
                      min={0}
                      className={cx('form-control', {
                        'is-invalid':
                          touched['settings.postClickWindow'] &&
                          errors.settings &&
                          errors.settings.postClickWindow,
                      })}
                    />
                    <FormError name="settings.postClickWindow" />
                  </div>
                  <div className="form-group required col-12 col-md-6 col-lg-6">
                    <label htmlFor="settings.postViewWindow">
                      Post-view window
                    </label>
                    <Field
                      id="settings.postViewWindow"
                      name="settings.postViewWindow"
                      component="input"
                      type="number"
                      min={0}
                      className={cx('form-control', {
                        'is-invalid':
                          touched['settings.postViewWindow'] &&
                          errors.settings &&
                          errors.settings.postViewWindow,
                      })}
                    />
                    <FormError name="settings.postViewWindow" />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group required col-12 col-md-6 col-lg-6">
                    <label htmlFor="settings.conversionCounting">
                      Conversion Counting Type
                    </label>
                    <Field
                      id="settings.conversionCounting"
                      name="settings.conversionCounting"
                      component={SelectAdapter}
                      options={[
                        {
                          label: 'Count All conversions',
                          value: 100000,
                        },
                        {
                          label: 'Count only post-click conversions',
                          value: 0,
                        },
                        {
                          label: 'Count percentage of conversions',
                          value: 'custom',
                        },
                      ]}
                    />
                  </div>
                  {Boolean(
                    values.settings &&
                      values.settings.conversionCounting &&
                      values.settings.conversionCounting.value &&
                      values.settings.conversionCounting.value !== 0 &&
                      values.settings.conversionCounting.value !== 100000,
                  ) && (
                    <div className="form-group col-12 col-md-6 col-lg-6">
                      <label htmlFor="settings.conversionCounting.value">
                        &nbsp;
                      </label>
                      <Field
                        id="settings.conversionCounting.value"
                        name="settings.conversionCounting.value"
                        component="input"
                        type="number"
                        min={0}
                        max={100000}
                        className={cx('form-control', {
                          'is-invalid':
                            touched['settings.conversionCounting.value'] &&
                            errors.settings &&
                            errors.settings.value &&
                            errors.settings.conversionCounting.value,
                        })}
                      />
                      <FormError name="settings.conversionCounting" />
                    </div>
                  )}
                  {/*<div className="form-group required col-12 col-md-6 col-lg-6">
                                    <label htmlFor="settings.position">Attribution Model</label>
                                    <Field
                                        id="settings.position"
                                        name="settings.position"
                                        component={ SelectAdapter }
                                        isMulti
                                        options={ [{
                                            label: 'Primary model',
                                            value: 'ON_SCREEN_POSITION_UNKNOWN',
                                        }, {
                                            label: 'Floodlight Attribution Model',
                                            value: 'ON_SCREEN_POSITION_ABOVE_THE_FOLD',
                                        }] }
                                    />
                                    <FormError name="settings.position"/>
                                </div>*/}
                </div>
              </>
            )}
            <div className="row">
              <div className="form-group required col-12 col-md-6 col-lg-6">
                <label htmlFor="margin">Margin</label>
                <Field
                  id="margin"
                  name="margin"
                  component="input"
                  type="number"
                  step={0.01}
                  className={cx('form-control', {
                    'is-invalid': touched.margin && errors.margin,
                  })}
                />
                <FormError name="margin" />
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}
