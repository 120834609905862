import { useMemo } from 'react';
import { ButtonGroup, Button } from '@mui/material';

import { LineGraphCompare } from '../LineGraphCompare/LineGraphCompare';
import ss from '../PerformanceReport.module.scss';
import { averageMetricsData } from '../report-settings';
import { averageAmounts, spikeRatios } from '../constants';
import {
  spentVsConv,
  spentVsCpm,
  spentVsCtcConv,
  spentVsRoi,
} from '../chart-settings';
import { IReportTab } from '../types';

export const AverageReports = ({ metricCharts = {}, options }: IReportTab) => {
  const { spikeRatio, setSpikeRatio, setChartSelectedLegends } = options;

  const spendVsTcChart = useMemo(
    () => spentVsConv.compute(metricCharts[spentVsConv.key] || []),
    [metricCharts],
  );

  const spendVsCtcChart = useMemo(
    () => spentVsCtcConv.compute(metricCharts[spentVsCtcConv.key] || []),
    [metricCharts],
  );

  const spendVsRoiChart = useMemo(
    () => spentVsRoi.compute(metricCharts[spentVsRoi.key] || [], spikeRatio),
    [metricCharts, spikeRatio],
  );

  const spendVsCpmChart = useMemo(
    () => spentVsCpm.compute(metricCharts[spentVsCpm.key] || []),
    [metricCharts],
  );

  return (
    <>
      <div className="row mt-5 mb-5">
        <div className="col">
          <h3 className="text-center m-3">{spentVsConv.series.name}</h3>
          <LineGraphCompare
            chartId={spentVsConv.id}
            chart={spendVsTcChart}
            metrics={spentVsConv.metricParams}
            customOptions={{
              legend: {
                selected: averageAmounts.reduce((acc, days) => {
                  acc[
                    `${averageMetricsData.averageSpend.label}`.replace(
                      '7',
                      `${days}`,
                    )
                  ] = days <= averageAmounts[0];

                  return acc;
                }, {}),
              },
            }}
            legendSelected={setChartSelectedLegends}
          />
        </div>
      </div>

      <div className="row mt-5 mb-5">
        <div className="col">
          <h3 className="text-center m-3">{spentVsCtcConv.series.name}</h3>
          <LineGraphCompare
            chartId={spentVsCtcConv.id}
            chart={spendVsCtcChart}
            metrics={spentVsCtcConv.metricParams}
            customOptions={{
              legend: {
                selected: averageAmounts.reduce((acc, days) => {
                  acc[
                    `${averageMetricsData.averageCtc.label}`.replace(
                      '7',
                      `${days}`,
                    )
                  ] = days <= averageAmounts[0];

                  return acc;
                }, {}),
              },
            }}
            legendSelected={setChartSelectedLegends}
          />
        </div>
      </div>

      <div className={ss.hLineSep} />

      <div className="row mt-5 mb-5">
        <div className="col">
          <h3 className="text-center m-3">{spentVsRoi.series.name}</h3>
          <div className={ss.ratioSelector}>
            <ButtonGroup
              variant="outlined"
              aria-label="outlined primary button group"
            >
              {spikeRatios.map(ratio => (
                <Button
                  key={`ratio-selector-${ratio}`}
                  className={`${ratio === spikeRatio ? ss.selected : ''}`}
                  onClick={() => setSpikeRatio(ratio)}
                >
                  x{ratio}
                </Button>
              ))}
            </ButtonGroup>
          </div>
          <LineGraphCompare
            chartId={spentVsRoi.id}
            chart={spendVsRoiChart}
            metrics={spentVsRoi.metricParams}
            legendSelected={setChartSelectedLegends}
          />
        </div>
      </div>

      <div className="row mt-5 mb-5">
        <div className="col">
          <h3 className="text-center m-3">{spentVsCpm.series.name}</h3>
          <LineGraphCompare
            chartId={spentVsCpm.id}
            chart={spendVsCpmChart}
            metrics={spentVsCpm.metricParams}
            customOptions={{
              legend: {
                selected: averageAmounts.reduce((acc, days) => {
                  acc[
                    `${averageMetricsData.averageCpm.label}`.replace(
                      `${averageAmounts[0]}`,
                      `${days}`,
                    )
                  ] = days <= averageAmounts[0];

                  return acc;
                }, {}),
              },
            }}
            legendSelected={setChartSelectedLegends}
          />
        </div>
      </div>
    </>
  );
};
