/* eslint-disable jsx-a11y/label-has-associated-control */
import { ChangeEvent, useCallback, useRef, useState } from 'react';
import Toggle from 'react-toggle';
import { ColorResult } from 'react-color';

import { ColorPicker } from '../../components/ColorPicker';
import {
  SimpleTextEditor,
  SimpleTextEditorChangeData,
} from '../../components/SimpleTextEditor/SimpleTextEditor';
import { AdType } from '../../external/google-ads-constants';
import ImageInput from '../Assets/ImageInput';

import {
  AssetLayout,
  AssetsBlobsMap,
  AssetsMap,
  ASSET_LAYOUTS_ORDERED,
  ASSET_TYPES_LIST,
  CTA_OPTIONS,
  CTA_SHAPES_OPTIONS,
} from './data';
import { PreviewGrid } from './PreviewGrid';
import { AssetComposerStage } from './AssetComposerStage';

interface IProps {
  submitAsset: (data: any) => any;
  addNotify: (text: string) => any;
}

export const AssetComposer = ({ submitAsset, addNotify }: IProps) => {
  const formRef = useRef<HTMLFormElement>(null);
  const submitRef = useRef<HTMLButtonElement>(null);
  const stageRef = useRef<import('konva').default.Stage>(null);

  const [backgroundImage, setBackgroundImage] =
    useState<HTMLImageElement | null>(null);
  const [logoImage, setLogoImage] = useState<HTMLImageElement | null>(null);
  const [backgroundColorEnabled, setBackgroundColorEnabled] = useState(true);
  const [backgroundColor, setBackgroundColor] = useState('#ffffff');
  const [ctaButtonColor, setCtaButtonColor] = useState('#ffffff');
  const [ctaButtonTextColor, setCtaButtonTextColor] = useState('#000000');
  const [layout, setLayout] = useState<AssetLayout | null>(null);
  const [ctaValue, setCtaValue] = useState('');
  const [ctaShape, setCtaShape] = useState('');
  const [titleText, setTitleText] = useState<SimpleTextEditorChangeData | null>(
    null,
  );
  const [additionalText, setAdditionalText] =
    useState<SimpleTextEditorChangeData | null>(null);
  const [assetsMap, setAssetsMap] = useState<AssetsMap>({});
  const [blobsMap, setBlobsMap] = useState<AssetsBlobsMap>({});

  const setInitialValues = () => {
    setBackgroundImage(null);
    setLogoImage(null);
    setBackgroundColorEnabled(true);
    setBackgroundColor('#ffffff');
    setCtaButtonColor('#ffffff');
    setCtaButtonTextColor('#000000');
    setLayout(null);
    setCtaValue('');
    setCtaShape('');
    setTitleText(null);
    setAdditionalText(null);
    setAssetsMap({});
    setBlobsMap({});
  };

  const submit = () => {
    if (!formRef.current) {
      return;
    }

    const formData = new FormData(formRef.current);

    for (const [id, image] of Object.entries(blobsMap)) {
      formData.append(id, image);
    }

    // TODO:
    formData.append('callToAction', ctaValue);
    formData.append('networkGoogle', '1');
    formData.append('googleType', String(AdType.IMAGE_AD));

    submitAsset(formData).then((action: any) => {
      if (action.error) {
        return;
      }

      addNotify('Saved to the Asset library');
      setInitialValues();
    });
  };

  const { width = 0, height = 0, id: layoutId = '' } = layout || {};

  const setBackgroundColorEnabledMemo = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setBackgroundColorEnabled(event.target.checked);
      setBackgroundImage(null);
    },
    [],
  );
  const setBackgroundColorMemo = useCallback((color: ColorResult) => {
    setBackgroundColor(color.hex);
  }, []);
  const setCtaButtonColorMemo = useCallback((color: ColorResult) => {
    setCtaButtonColor(color.hex);
  }, []);
  const setCtaButtonTextColorMemo = useCallback((color: ColorResult) => {
    setCtaButtonTextColor(color.hex);
  }, []);
  const setCtaValueMemo = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      setCtaValue(event.target.value);
    },
    [],
  );
  const setLayoutMemo = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
    setBackgroundImage(null);
    setLogoImage(null);

    setLayout(
      ASSET_LAYOUTS_ORDERED.find(({ id }) => id === event.target.value) || null,
    );
  }, []);

  const addToLibrary = () => {
    if (!stageRef.current || !layout) {
      return;
    }

    setBackgroundImage(null);

    const completedLayouts = [...Object.keys(assetsMap), layout.id];
    const nextLayout =
      ASSET_LAYOUTS_ORDERED.find(
        layout => !completedLayouts.includes(layout.id),
      ) || null;

    stageRef.current.toCanvas().toBlob(blob => {
      setBlobsMap({ ...blobsMap, [layout.id]: blob });
      setLayout(nextLayout);
    });

    stageRef.current.toImage({
      callback: img => {
        setAssetsMap({ ...assetsMap, [layout.id]: img });
        setLayout(nextLayout);
      },
    });
  };

  const clearAsset = useCallback(
    (id: string) => {
      setAssetsMap(
        Object.fromEntries(
          Object.entries(assetsMap).filter(([assetId]) => assetId !== id),
        ),
      );
      setBlobsMap(
        Object.fromEntries(
          Object.entries(blobsMap).filter(([assetId]) => assetId !== id),
        ),
      );
    },
    [assetsMap, blobsMap],
  );

  const backgroundDimensions =
    layout &&
    (backgroundColorEnabled ? layout.backgroundAlt : layout.background);

  return (
    <div className="container">
      <form
        className="d-none"
        ref={formRef}
        onSubmit={event => {
          event.preventDefault();
          submit();
        }}
      >
        <button ref={submitRef} type="submit" />
      </form>
      <div className="row">
        <div className="col-6">
          <div className="row mt-3">
            <div className="col">
              <h4>Background</h4>

              <div className="card p-3">
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <select
                        onChange={setLayoutMemo}
                        value={layoutId}
                        className="form-control"
                      >
                        <option value="">Choose size of creative</option>
                        {ASSET_LAYOUTS_ORDERED.map(({ id }) => (
                          <option key={id} value={id}>
                            {id.replace(/^ga/, '')}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="d-flex align-items-center">
                        <span className="mr-2">Background color</span>
                        <Toggle
                          checked={backgroundColorEnabled}
                          onChange={setBackgroundColorEnabledMemo}
                          icons={false}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <label className="d-flex align-items-center">
                        <span className="mr-2">Choose background color</span>
                        <ColorPicker
                          color={backgroundColor}
                          onChange={setBackgroundColorMemo}
                        />
                      </label>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <ImageInput
                        width={backgroundDimensions?.width || 0}
                        height={backgroundDimensions?.height || 0}
                        disabled={
                          !layout ||
                          !(backgroundColorEnabled
                            ? layout.backgroundAlt
                            : layout.background)
                        }
                        input={{
                          onChange: (dataUrl: string) => {
                            const img = new Image();

                            img.src = dataUrl;
                            setBackgroundImage(img);
                          },
                          value: '',
                        }}
                      >
                        <label className="btn btn-primary btn-sm">
                          Upload creative image
                        </label>
                      </ImageInput>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <ImageInput
                        width={layout?.logo.width || 0}
                        height={layout?.logo.height || 0}
                        disabled={!layout}
                        input={{
                          onChange: (dataUrl: string) => {
                            const img = new Image();

                            img.src = dataUrl;
                            setLogoImage(img);
                          },
                          value: '',
                        }}
                      >
                        <label className="btn btn-primary btn-sm">
                          Upload logo image
                        </label>
                      </ImageInput>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    {(layout ? !layout.backgroundAlt : false) && (
                      <>
                        Only one option is available in size 728x90, 300x50,
                        320x50. Please choose a Creative image OR Background
                        color.
                      </>
                    )}
                  </div>
                </div>
              </div>

              <h4 className="mt-3">CTA Button</h4>

              <div className="card p-3">
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <select
                        value={ctaShape}
                        onChange={event => {
                          setCtaShape(event.target.value);
                        }}
                        className="form-control"
                      >
                        <option value="">Choose CTA button shape</option>
                        {CTA_SHAPES_OPTIONS.map(shape => (
                          <option key={shape.value} value={shape.value}>
                            {shape.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col">
                    <div className="form-group">
                      <select
                        value={ctaValue}
                        onChange={setCtaValueMemo}
                        className="form-control"
                        disabled={
                          layout?.backgroundAltCta
                            ? !backgroundColorEnabled
                            : false
                        }
                      >
                        <option value="">Choose CTA button text</option>
                        {CTA_OPTIONS.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="d-flex align-items-center">
                        <span className="mr-2">Choose CTA button color</span>
                        <ColorPicker
                          color={ctaButtonColor}
                          onChange={setCtaButtonColorMemo}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <label className="d-flex align-items-center">
                        <span className="mr-2">
                          Choose CTA button text color
                        </span>
                        <ColorPicker
                          color={ctaButtonTextColor}
                          onChange={setCtaButtonTextColorMemo}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <h4 className="mt-3">Text</h4>

              <div className="card p-3">
                <div className="row">
                  <div className="col">
                    <label>Title:</label>
                    <SimpleTextEditor onChange={setTitleText} />
                  </div>
                </div>
                {(!layout || layout.additional) && (
                  <div className="row mt-3">
                    <div className="col">
                      <label>Additional text:</label>
                      <SimpleTextEditor onChange={setAdditionalText} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="row p-3 text-center">
            <div className="col">
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => setInitialValues()}
                disabled={!layout}
              >
                Clear all
              </button>
            </div>
            <div className="col">
              <button
                onClick={addToLibrary}
                type="button"
                className="btn btn-primary btn-sm"
                disabled={!layout}
              >
                Save Creative
              </button>
            </div>
            <div className="col">
              <button
                onClick={() => submitRef.current?.click()}
                disabled={!ASSET_TYPES_LIST.some(id => id in assetsMap)}
                type="submit"
                className="btn btn-primary btn-sm"
              >
                Add To Library
              </button>
            </div>
          </div>
        </div>

        <div className="col-6">
          <br />
          <br />
          <br />
          <AssetComposerStage
            additionalText={additionalText}
            backgroundColor={backgroundColor}
            backgroundColorEnabled={backgroundColorEnabled}
            backgroundImage={backgroundImage}
            height={height}
            layout={layout}
            logoImage={logoImage}
            stageRef={stageRef}
            titleText={titleText}
            width={width}
            ctaButtonColor={ctaButtonColor}
            ctaButtonTextColor={ctaButtonTextColor}
            ctaValue={ctaValue}
            ctaShape={ctaShape}
          />
          <br />
          <br />
        </div>
      </div>
      <PreviewGrid clearAsset={clearAsset} assetsMap={assetsMap} />
    </div>
  );
};
