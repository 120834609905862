/**
 * Notification component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import HistoryItem from './HistoryItem';
import './styles.css';

class HistoryList extends PureComponent<any, any> {
  static propTypes = {
    items: PropTypes.array.isRequired,
  };

  state = {
    expanded: false,
  };

  render() {
    const { items } = this.props;
    const { expanded } = this.state;

    return (
      <div className="changes-list">
        {items.slice(0, !expanded ? 10 : items.length).map(item => (
          <HistoryItem item={item} key={item.id} />
        ))}
        {Boolean(!expanded && items.length > 10) && (
          // @ts-ignore
          <botton
            className="btn page-link"
            onClick={() =>
              this.setState({
                expanded: true,
              })
            }
          >
            See more ...
            {/* @ts-ignore */}
          </botton>
        )}
      </div>
    );
  }
}

export default HistoryList;
