import { AnyAction } from 'redux';

import {
  ADGROUPS_LIST_CRITERIA,
  ADGROUPS_LIST_ERROR,
  ADGROUPS_LIST_REQUEST,
  ADGROUPS_LIST_SUCCESS,
} from '../actions/adgroups.actions';
import { DEFAULT_METADATA, DEFAULT_PER_PAGE } from '../constants';

interface AdgroupsState {
  criteria: any;
  data: any[];
  loading: boolean;
  metadata: any;
}

export const INITIAL_STATE: Readonly<AdgroupsState> = {
  criteria: {
    page: 1,
    perPage: DEFAULT_PER_PAGE,
    sort: {
      name: 'ASC',
    },
    where: {},
  },
  data: [],
  loading: false,
  metadata: DEFAULT_METADATA,
};

export default (
  state: Readonly<AdgroupsState> = INITIAL_STATE,
  action: AnyAction,
): Readonly<AdgroupsState> => {
  switch (action.type) {
    case ADGROUPS_LIST_REQUEST:
      return { ...state, loading: true };

    case ADGROUPS_LIST_SUCCESS: {
      return { ...state, data: action.data, loading: false };
    }

    case ADGROUPS_LIST_ERROR:
      return { ...state, loading: false };

    case ADGROUPS_LIST_CRITERIA:
      return {
        ...state,
        criteria: { ...state.criteria, ...action.criteria },
      };

    default:
      return state;
  }
};
