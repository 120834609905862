import { DefaultRootState } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { IQuickActionsPanelData } from './QuickActionsPanel';

export default createStructuredSelector<
  DefaultRootState,
  Pick<IQuickActionsPanelData, 'selectedAccountId' | 'userData'>
>({
  selectedAccountId: store => store.accounts.current?.id || undefined,
  userData: store => store.auth?.user || {},
});
