/**
 * GridFooterPerPageSelector component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */
/* eslint-disable jsx-a11y/no-onchange */

import { PureComponent } from 'react';

import { DEFAULT_PER_PAGE, PAGE_SIZES } from '../../constants';

interface Props {
  onChange: (page: number) => void;
  perPage: number;
}

export default class GridFooterPerPageSelector extends PureComponent<
  Props,
  any
> {
  static defaultProps = {
    perPage: DEFAULT_PER_PAGE,
  };

  onChange = event => {
    const target = event.target;
    const value = target.value;
    const { onChange } = this.props;

    onChange(Number(value));
  };

  render() {
    const { perPage } = this.props;

    return (
      <div className="col-12 col-sm-2 grid-footer-per-page-selector">
        <div className="form-inline">
          <div className="form-group m-auto">
            <select
              name="perPage"
              className="form-control input-sm"
              value={perPage}
              onChange={this.onChange}
            >
              {PAGE_SIZES.map(size => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  }
}
