export const STATUS_PAUSED = 0;
export const STATUS_ACTIVE = 1;
export const STATUS_ACTIVE2 = 2;
export const STATUS_PENDING = 3;
export const STATUS_STOPPED = 4;

export const TYPE_DISPLAY = 1;
export const TYPE_VIDEO = 2;
export const TYPE_DISCOVERY = 3;
export const TYPE_SEARCH = 4;

export const FUNNEL_PROSPECTING = 1;
export const FUNNEL_RETARGETING = 2;

export const NETWORK_GOOGLE_ADS = 1;
export const NETWORK_DV360 = 2;
export const NETWORK_FB = 3;
export const NETWORK_NONPROFITS = 5;

export const CAMPAIGN_TYPES_LIST = [
  {
    description: 'Create visually appealing ads that serve across the web.',
    id: TYPE_DISPLAY,
    name: 'Display',
  },
  {
    description:
      'Create video ads that are designed to increase reach and awareness, encourage engagement, or drive conversions.',
    id: TYPE_VIDEO,
    name: 'Video',
  },
  {
    // TODO:
    description: '',
    id: TYPE_DISCOVERY,
    name: 'Discovery',
  },
  {
    // TODO:
    description: '',
    id: TYPE_SEARCH,
    name: 'Search',
  },
];

export const CAMPAIGN_TYPES_MAP = CAMPAIGN_TYPES_LIST.reduce(
  (acc, type) => ({ ...acc, [type.id]: type }),
  {},
);

export const FUNNEL_TITLES = {
  [FUNNEL_PROSPECTING]: 'Prospecting',
  [FUNNEL_RETARGETING]: 'Retargeting',
};

export const NETWORK_ID_TITLES = {
  [NETWORK_DV360]: 'DV360',
  [NETWORK_FB]: 'Facebook',
  [NETWORK_GOOGLE_ADS]: 'GDN',
  [NETWORK_NONPROFITS]: 'Non-Profits',
};
export const AD_TYPE_TITLES = {
  [TYPE_DISCOVERY]: 'Discovery',
  [TYPE_DISPLAY]: 'Display',
  [TYPE_SEARCH]: 'Search',
  [TYPE_VIDEO]: 'Video',
};
