/**
 * Notification Item component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */

import cx from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

export default class NotificationItem extends PureComponent<any, any> {
  static propTypes = {
    deleteNotify: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired,
  };

  state = {
    show: false,
  };

  componentDidMount() {
    this.timeout = setTimeout(() => {
      this.setState({
        show: true,
      });
      this.timeout = setTimeout(() => {
        this.deleteNotify();
      }, 5000);
    });
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  timeout: any = null;

  deleteNotify = () => {
    this.setState(
      {
        show: false,
      },
      () => {
        this.timeout = setTimeout(() => {
          this.props.deleteNotify(this.props.item.id);
        }, 200);
      },
    );
  };

  render() {
    const {
      item: { message, type },
    } = this.props;
    const { show } = this.state;

    return (
      <div
        className={cx('alert', `alert-${type === 'error' ? 'danger' : type}`, {
          show,
        })}
      >
        {message}
        <button className="close" onClick={this.deleteNotify} type="button">
          <i className="fas fa-xs fa-times" />
        </button>
      </div>
    );
  }
}
