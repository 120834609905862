import { createStructuredSelector } from 'reselect';

import { RootState } from '../../../reducers';
import { allowedManageCampaignDefaultsSelector } from '../../../selectors/auth';

import { Props } from './GroupsList';

export default createStructuredSelector<
  RootState,
  Pick<Props, 'marginColumnShown' | 'settingsShown'>
>({
  marginColumnShown: allowedManageCampaignDefaultsSelector,
  settingsShown: allowedManageCampaignDefaultsSelector,
});
