/**
 * Axios middleware script file
 *
 * @package    Middleware
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */

import axios from 'axios';
import Qs from 'qs';

import { logout } from '../actions/auth.actions';
import { addNotify } from '../actions/notifications.actions';
import { UNAUTHORIZED_ERR } from '../constants/user';

const CHECK_PROFILE_URL = '/auth/status';

export default interceptors => store => {
  const axiosInstance = () => {
    const state = store.getState();

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    if (state.auth.authToken) {
      headers['x-auth-token'] = state.auth.authToken;
    }

    const instance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers,
      paramsSerializer: params => {
        if (params.criteria) {
          let { include, page, perPage, sort, where } = params.criteria;

          params.criteria = {
            include,
            page,
            perPage,
            sort: Array.isArray(sort)
              ? sort.reduce((sort, { desc, id }) => {
                  sort[id] = desc ? 'DESC' : 'ASC';

                  return sort;
                }, {})
              : sort,
            where: {
              ...where,
            },
          };
          if (params.criteria.where && params.criteria.where.search) {
            params.criteria.where.search = `%${params.criteria.where.search}%`;
          }
        }

        return Qs.stringify(params, {
          arrayFormat: 'comma',
          encodeValuesOnly: true,
        });
      },
      responseType: 'json',
      transformResponse: [
        data => {
          if (data.code === 401) {
            store.dispatch(logout());
          }
          if (data.code === 403 && data.error === UNAUTHORIZED_ERR) {
            store.dispatch(logout());
          }
          if ([400, 500].includes(data.code)) {
            store.dispatch(addNotify('Oops! Something went wrong!', 'error'));
          }

          return data;
        },
      ],
    });

    if (interceptors) {
      interceptors.map(interceptor => interceptor(instance));
    }

    return instance;
  };

  return next => action => {
    if (action.request && !action.type) {
      return apiCall(action)(
        next,
        axiosInstance(),
        store.dispatch,
        store.getState,
      );
    }

    const result = next(action);

    if (typeof result === 'function') {
      return result(axiosInstance());
    }

    return result;
  };
};

const defaultCallback = response => {
  if (!response) {
    return {};
  }

  const { data, details, message, metadata, error } = response;
  let result = {
    data,
    details,
    error,
    message,
    metadata,
  };

  Object.keys(result).forEach(key =>
    result[key] === undefined ? delete result[key] : '',
  );

  return result;
};

const apiCall =
  ({
    graphql,
    requestType,
    types,
    request,
    successCallback = defaultCallback as any,
    errorCallback = defaultCallback as any,
  }) =>
  (next, axiosInstance, dispatch, getState) => {
    const [typePending, typeSucceed, typeFailed] = types || [
      `${requestType}_REQUEST`,
      `${requestType}_SUCCESS`,
      `${requestType}_ERROR`,
    ];

    next({
      type: typePending,
    });

    return request(
      graphql
        ? axios.create({
            ...axiosInstance.defaults,
            baseURL: axiosInstance.defaults.baseURL.replace('/api', '/'),
          })
        : axiosInstance,
    )
      .then(response => {
        try {
          const payload = successCallback(response.data, dispatch, getState);

          if (payload.message) {
            dispatch(addNotify(payload.message));
          }
          if (payload) {
            return next({
              type: typeSucceed,
              ...payload,
            });
          }
        } catch (error) {
          if (process.env.NODE_ENV !== 'production') {
            console.log(error); // eslint-disable-line
          }

          return next({
            payload: {},
            type: typeFailed,
          });
        }
      })
      .catch(error => {
        if (!error.response) {
          return next({
            type: typeFailed,
          });
        }

        const payload = errorCallback(error.response.data, dispatch, getState);

        const requestedUrl = error.response.config?.url;

        if (
          (payload.message || payload.error) &&
          requestedUrl !== CHECK_PROFILE_URL
        ) {
          dispatch(addNotify(payload.message || payload.error, 'error'));
        }
        if (payload) {
          return next({
            error: true,
            type: typeFailed,
            ...payload,
          });
        }
      });
  };
