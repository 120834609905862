/* eslint-disable react/forbid-dom-props */
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import { ColorPicker } from '../ColorPicker';

import styles from './SimpleTextEditor.module.scss';

export interface SimpleTextEditorChangeData {
  bold: boolean;
  font: string;
  fontColor: string;
  fontSize: number;
  italic: boolean;
  text: string;
  underline: boolean;
}

interface Props {
  disabled?: boolean;
  onChange: (data: SimpleTextEditorChangeData) => void;
}

const fonts = ['Arial', 'Calibri'];

const defaultFontSize = 18;
const defaultFont = fonts[0];
const defaultFontColor = '#000';

export const SimpleTextEditor = ({ onChange, disabled = false }: Props) => {
  const fontSizeRef = useRef<HTMLInputElement>(null);

  const [bold, setBold] = useState(false);
  const [italic, setItalic] = useState(false);
  const [underline, setUnderline] = useState(false);
  const [text, setText] = useState('');
  const [font, setFont] = useState(defaultFont);
  const [fontSize, setFontSize] = useState(defaultFontSize);
  const [fontColor, setFontColor] = useState(defaultFontColor);

  useEffect(() => {
    onChange({ bold, font, fontColor, fontSize, italic, text, underline });
  }, [bold, font, fontColor, fontSize, italic, text, underline, onChange]);

  return (
    <div className="card p-3">
      <div className={cx('flex mb-3', styles.controls)}>
        <select
          value={font}
          onChange={(event: ChangeEvent<HTMLSelectElement>) => {
            setFont(event.target.value);
          }}
        >
          {fonts.map(font => (
            <option value={font} key={font}>
              {font}
            </option>
          ))}
        </select>

        <div className="btn-group btn-group-toggle" data-toggle="buttons">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              fontSizeRef.current?.stepDown();
            }}
          >
            -
          </button>
          <input
            autoComplete="off"
            className={styles.input}
            max={48}
            min={8}
            name="options"
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const { value, max, min } = event.target;
              const { valid, rangeOverflow, rangeUnderflow } =
                event.target.validity;

              setFontSize(
                valid
                  ? parseInt(value)
                  : rangeOverflow
                  ? parseInt(max)
                  : rangeUnderflow
                  ? parseInt(min)
                  : parseInt(min),
              );
            }}
            ref={fontSizeRef}
            required
            type="number"
            value={fontSize}
          />
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              fontSizeRef.current?.stepUp();
            }}
          >
            +
          </button>
        </div>

        <button
          onClick={() => {
            setBold(!bold);
          }}
          type="button"
          className={cx('btn btn-primary btm-sm', { active: bold })}
        >
          B
        </button>
        <button
          onClick={() => {
            setItalic(!italic);
          }}
          type="button"
          className={cx('btn btn-primary btm-sm', { active: italic })}
        >
          I
        </button>
        <button
          onClick={() => {
            setUnderline(!underline);
          }}
          type="button"
          className={cx('btn btn-primary btm-sm', { active: underline })}
        >
          U
        </button>
        <ColorPicker
          color={fontColor}
          onChange={color => {
            setFontColor(color.hex);
          }}
        />
      </div>

      <textarea
        cols={30}
        disabled={disabled}
        onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
          setText(event.target.value);
        }}
        rows={3}
      />
    </div>
  );
};
