import { IScheduleList } from './types';

export const ScheduleList = ({
  schedules,
  show,
  onEditClick,
  onNewClick,
  onCloseClick,
  loading,
}: IScheduleList) => {
  if (!show) {
    return null;
  }

  return (
    <div className="page p-1 p-sm-3 p-xl-5">
      <div className="card">
        <div className="card-header bg-primary text-white p-2 pl-4">
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="mb-0">Schedule list</h4>
            <div>
              <button
                type="button"
                className="btn btn-info"
                onClick={onNewClick}
              >
                New
              </button>
              {onCloseClick ? (
                <button
                  type="button"
                  className="btn btn-light ml-3"
                  disabled={loading}
                  onClick={onCloseClick}
                >
                  Close
                </button>
              ) : null}
            </div>
          </div>
        </div>
        <div className="card-body container-fluid">
          <div className="row d-flex justify-content-center">
            <div className="col">
              <div className="list-group">
                {schedules.map(({ id, options, active }) => (
                  <button
                    key={id}
                    type="button"
                    className="list-group-item list-group-item-action d-flex justify-content-between"
                    onClick={() => onEditClick(id)}
                    disabled={loading}
                  >
                    <span>
                      #{id}. {options?.report_name}{' '}
                    </span>
                    <span
                      className={`badge ${
                        active ? 'bg-success' : 'bg-warning'
                      }`}
                    >
                      {active ? 'Active' : 'Paused'}
                    </span>
                  </button>
                ))}
              </div>

              <div className="d-flex justify-content-center align-items-center p-4">
                <button
                  type="button"
                  className="btn btn-info p-3"
                  onClick={onNewClick}
                >
                  Add new
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
