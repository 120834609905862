/**
 * AssetItemForm component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events, react/forbid-dom-props */

import cx from 'classnames';
import PropTypes from 'prop-types';
import qs from 'qs';
import { PureComponent } from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { OnChange } from 'react-final-form-listeners';

import AssetPreview from '../../components/AssetPreview';
import { NETWORK_GOOGLE_ADS } from '../../constants';
import {
  getDaysList,
  getEnvironmentsList,
  getPositionsList,
  getTimeOfDayList,
  getViewabilityList,
} from '../../helpers/dictionaries';
import {
  composeValidators,
  maxLength,
  min,
  minLength,
  required,
  startsWithHttpProtocols,
} from '../../helpers/validation';
import FormError from '../../components/FormError';
import SelectAdapter from '../../components/SelectAdapter';

import ImagesInput from './ImagesInput';

const STEPS = [
  {
    label: 'AdGroup Configuration',
    name: 'adgroup',
  },
  {
    label: 'Creatives Configuration',
    name: 'creatives',
  },
];

const RADIOBUTTON_SELECT_EXISTED = 'select-existed';
const RADIOBUTTON_BATCH_UPLOAD = 'batch-upload';

const getUrlPreview = (values: any) =>
  values.settings?.url && values.settings?.utm
    ? `${values.settings.url}?${qs.stringify({
        utm_custom: values.settings.utm,
        utm_medium: values.network_id === 1 ? 'display' : 'video',
        utm_source: 'oneportal',
      })}`
    : '';

interface IState {
  selectedRadioButton: string;
  step: number;
}

export default class AdgroupItemForm extends PureComponent<any, IState> {
  static propTypes = {
    errors: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    loadAssets: PropTypes.func.isRequired,
    loadTopics: PropTypes.func.isRequired,
    loadUserList: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    touched: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
  };

  state = {
    selectedRadioButton: RADIOBUTTON_SELECT_EXISTED,
    step: 1,
  };

  componentDidMount = () => {
    if (this.props.values.creative_id) {
      this.setState({
        selectedRadioButton: RADIOBUTTON_SELECT_EXISTED,
      });
    }
  };

  setStep = (event: any) => {
    const { index } = event.currentTarget.dataset;

    this.setState({
      step: Number(index) + 1,
    });
  };

  nextStep = () => {
    this.setState(({ step }) => ({
      step: step + 1,
    }));
  };

  prevStep = () => {
    this.setState(({ step }) => ({
      step: step - 1,
    }));
  };

  render() {
    const {
      errors,
      form,
      handleSubmit,
      loadTopics,
      loadAssets,
      loadUserList,
      submitting,
      touched,
      values,
    } = this.props;
    const { step } = this.state;
    const progress = Math.round((step / 2) * 100);
    let valid = true;

    if (step === 1) {
      if (errors.name) {
        valid = false;
      }
      if (errors.settings && errors.settings.bid) {
        valid = false;
      }
      if (errors.settings && errors.settings.url) {
        valid = false;
      }
    }

    return (
      <form onSubmit={handleSubmit}>
        <OnChange name="settings.url">
          {() => form.change('urlPreview', getUrlPreview(values))}
        </OnChange>
        <OnChange name="settings.utm">
          {() => form.change('urlPreview', getUrlPreview(values))}
        </OnChange>
        <OnChange name="network_id">
          {() => form.change('urlPreview', getUrlPreview(values))}
        </OnChange>
        <div className="card-body container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card border-0 w-100">
                <h5 className="card-header bg-primary text-white">
                  <i className="fas fa-list pr-2" />
                  Steps
                </h5>
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 form-step">
                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          aria-valuenow={progress}
                          aria-valuemin={0}
                          aria-valuemax={100}
                          style={{
                            width: `${progress}%`,
                          }}
                        />
                      </div>
                      <div className="row">
                        {STEPS.map(({ name, label }, index) => (
                          <div
                            key={name}
                            className="col-6 text-center form-step-item"
                            role="button"
                            data-index={index}
                            tabIndex={0}
                          >
                            <span className="form-step-item-circle">
                              {index + 1}
                            </span>
                            <span className="form-step-item-label">
                              {label}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {step === 1 && (
            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="form-group required col-12 col-md-6 col-lg-4">
                    <label htmlFor="name">Name</label>
                    <Field
                      id="name"
                      name="name"
                      component="input"
                      type="text"
                      className={cx('form-control', {
                        'is-invalid': touched.name && errors.name,
                      })}
                      maxLength={60}
                      validate={composeValidators(
                        required,
                        minLength(3),
                        maxLength(60),
                      )}
                    />
                    <FormError name="name" />
                  </div>
                  <div className="form-group required col-12 col-md-6 col-lg-4">
                    <label htmlFor="settings.bid">Bid (Fixed CPC)</label>
                    <Field
                      id="settings.bid"
                      name="settings.bid"
                      component="input"
                      type="number"
                      min={0}
                      className={cx('form-control', {
                        'is-invalid':
                          touched['settings.bid'] &&
                          errors.settings &&
                          errors.settings.bid,
                      })}
                      validate={composeValidators(required, min(0))}
                    />
                    <FormError name="settings.bid" />
                  </div>
                  <div className="form-group required col-12 col-md-6 col-lg-4">
                    <label htmlFor="settings.url">URL</label>
                    <Field
                      id="settings.url"
                      name="settings.url"
                      component="input"
                      type="text"
                      spellCheck={false}
                      min={0}
                      className={cx('form-control', {
                        'is-invalid':
                          touched['settings.url'] &&
                          errors.settings &&
                          errors.settings.url,
                      })}
                      validate={composeValidators(
                        required,
                        startsWithHttpProtocols,
                      )}
                    />
                    <FormError name="settings.url" />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-12 col-md-6 col-lg-4">
                    <label htmlFor="settings.userlist">
                      Audience User List
                    </label>
                    <Field
                      id="settings.userlist"
                      name="settings.userlist"
                      component={SelectAdapter}
                      selectType="async"
                      placeholder="All Visitors (AdWords)"
                      isMulti
                      cacheOptions
                      defaultOptions
                      loadOptions={loadUserList}
                    />
                  </div>
                  <div className="form-group col-12 col-md-6 col-lg-4">
                    <label htmlFor="settings.keywords">Keywords</label>
                    <Field
                      id="settings.keywords"
                      name="settings.keywords"
                      component={SelectAdapter}
                      isMulti
                      selectType="creatable"
                      menuPlacement="auto"
                    />
                  </div>
                  <div className="form-group col-12 col-md-6 col-lg-4">
                    <label htmlFor="settings.verticals">Topics</label>
                    <Field
                      id="settings.verticals"
                      name="settings.verticals"
                      component={SelectAdapter}
                      isMulti
                      cacheOptions
                      defaultOptions
                      loadOptions={loadTopics}
                      selectType="async-creatable"
                      menuPlacement="auto"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group required col-12 col-md-6 col-lg-4">
                    <label htmlFor="settings.utm">UTM</label>
                    <Field
                      id="settings.utm"
                      name="settings.utm"
                      component="input"
                      type="text"
                      spellCheck={false}
                      className={cx('form-control', {
                        'is-invalid': touched.name && errors.name,
                      })}
                      maxLength={60}
                      validate={composeValidators(
                        required,
                        minLength(3),
                        maxLength(60),
                      )}
                    />
                    <FormError name="settings.utm" />
                  </div>
                  <div className="form-group col-12 col-md-6 col-lg-4">
                    <label htmlFor="urlPreview">Full URL preview</label>
                    <Field
                      id="urlPreview"
                      name="urlPreview"
                      component="input"
                      type="text"
                      className={cx('form-control', {
                        'is-invalid': touched.name && errors.name,
                      })}
                      maxLength={60}
                    >
                      {({ input, meta, ...rest }) => (
                        <input {...input} {...rest} readOnly />
                      )}
                    </Field>
                  </div>
                </div>
                {values.network_id === 2 && (
                  <div className="row">
                    <div className="col-4">
                      <div className="form-group required">
                        <label htmlFor="settings.environment">
                          Environment
                        </label>
                        <Field
                          id="settings.environment"
                          name="settings.environment"
                          component={SelectAdapter}
                          selectType="select"
                          isMulti
                          options={getEnvironmentsList()}
                        />
                        <FormError name="settings.environment" />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group required">
                        <label htmlFor="settings.position">Position</label>
                        <Field
                          id="settings.position"
                          name="settings.position"
                          component={SelectAdapter}
                          selectType="select"
                          isMulti
                          options={getPositionsList()}
                        />
                        <FormError name="settings.position" />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group required">
                        <label htmlFor="settings.viewability">
                          Viewability
                        </label>
                        <Field
                          id="settings.viewability"
                          name="settings.viewability"
                          component={SelectAdapter}
                          selectType="select"
                          options={getViewabilityList()}
                        />
                        <FormError name="settings.viewability" />
                      </div>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-12">
                    <fieldset>
                      <legend>Day & Time</legend>
                      <FieldArray name="settings.schedule">
                        {({ fields }) => (
                          <>
                            {fields.map((item, index) => (
                              <div
                                key={item}
                                className="form-group controlled col-12 col-lg-6 mb-0"
                              >
                                <div className="row">
                                  <div className="col-6">
                                    <div className="form-group required">
                                      <Field
                                        id={`${item}.dayOfWeek`}
                                        name={`${item}.dayOfWeek`}
                                        component={SelectAdapter}
                                        options={getDaysList()}
                                      />
                                      <FormError name={`${item}.dayOfWeek`} />
                                    </div>
                                  </div>
                                  <div className="col-3">
                                    <div className="form-group required">
                                      <Field
                                        id={`${item}.startHour`}
                                        name={`${item}.startHour`}
                                        component={SelectAdapter}
                                        options={getTimeOfDayList()}
                                      />
                                      <FormError name={`${item}.startHour`} />
                                    </div>
                                  </div>
                                  <div className="col-3">
                                    <div className="form-group required">
                                      <Field
                                        id={`${item}.endHour`}
                                        name={`${item}.endHour`}
                                        component={SelectAdapter}
                                        options={getTimeOfDayList()}
                                      />
                                      <FormError name={`${item}.endHour`} />
                                    </div>
                                  </div>
                                  <button
                                    type="button"
                                    onClick={() => fields.remove(index)}
                                    className="btn btn-primary mb-2 pos-t0"
                                  >
                                    X
                                  </button>
                                </div>
                              </div>
                            ))}
                            <button
                              type="button"
                              onClick={() =>
                                fields.push({
                                  dayOfWeek: 'MONDEY',
                                  endHour: 0,
                                  startHour: 0,
                                })
                              }
                              className="btn btn-primary add-btn"
                            >
                              + Add Interval
                            </button>
                          </>
                        )}
                      </FieldArray>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          )}
          {step === 2 && (
            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="form-group col-12">
                    <div className="form-check">
                      <Field
                        id={RADIOBUTTON_SELECT_EXISTED}
                        name={RADIOBUTTON_SELECT_EXISTED}
                        checked={
                          this.state.selectedRadioButton ===
                          RADIOBUTTON_SELECT_EXISTED
                        }
                        onChange={() =>
                          this.setState({
                            selectedRadioButton: RADIOBUTTON_SELECT_EXISTED,
                          })
                        }
                        component="input"
                        type="radio"
                        className="form-check-input"
                      />
                      <label htmlFor={RADIOBUTTON_SELECT_EXISTED}>
                        Select existed
                      </label>
                    </div>
                  </div>
                  {this.state.selectedRadioButton ===
                    RADIOBUTTON_SELECT_EXISTED && (
                    <div className="col-12">
                      <div className="form-group col-12 col-md-5 col-lg-4 required">
                        <label htmlFor="targeting_keywords">
                          Select creative for ad
                        </label>
                        <Field
                          id="creative_id"
                          name="creative_id"
                          component={SelectAdapter}
                          selectType="async"
                          defaultOptions
                          loadOptions={loadAssets({
                            account_id: values.account_id,
                            network_id: values.network_id,
                          })}
                          className={cx('selectbox', {
                            'is-invalid':
                              touched.creative_id && errors.creative_id,
                          })}
                          validate={required}
                        />
                        <FormError name="creative_id" />
                      </div>

                      {values.creative_id && (
                        <div className="form-group col-12 col-md-7 col-lg-6 offset-lg-1">
                          <AssetPreview data={values.creative_id} />
                        </div>
                      )}
                    </div>
                  )}
                </div>

                {values.network_id !== NETWORK_GOOGLE_ADS && (
                  <div className="row">
                    <div className="form-group col-12">
                      <div className="form-check">
                        <Field
                          id={RADIOBUTTON_BATCH_UPLOAD}
                          name={RADIOBUTTON_BATCH_UPLOAD}
                          checked={
                            this.state.selectedRadioButton ===
                            RADIOBUTTON_BATCH_UPLOAD
                          }
                          onChange={() =>
                            this.setState({
                              selectedRadioButton: RADIOBUTTON_BATCH_UPLOAD,
                            })
                          }
                          component="input"
                          type="radio"
                          className="form-check-input"
                        />
                        <label htmlFor={RADIOBUTTON_BATCH_UPLOAD}>
                          Batch upload
                        </label>
                      </div>
                    </div>
                    {this.state.selectedRadioButton ===
                      RADIOBUTTON_BATCH_UPLOAD && (
                      <div className="form-group col-12">
                        <ImagesInput form={form} />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="row">
            <div className="col">
              {step !== 1 && (
                <button
                  type="button"
                  className="btn btn-secondary float-left"
                  onClick={this.prevStep}
                >
                  Prev
                </button>
              )}
              {step !== 2 && (
                <button
                  type="button"
                  className="btn btn-primary float-right"
                  onClick={this.nextStep}
                  disabled={!valid}
                >
                  Next
                </button>
              )}
              {step === 2 && (
                <button
                  type="submit"
                  className="btn btn-primary float-right"
                  disabled={submitting}
                >
                  <i className="fas fa-running mr-2" />
                  {values.id ? 'Update' : 'Create Adgroup'}
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
    );
  }
}
