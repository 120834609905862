/**
 * UsersList component script class
 *
 * @package    Common
 * @author     Ivan Miltykh <ivan.miltykh@gmail.com>
 */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import Modal from '../../components/Modal';
import {
  deleteItem,
  loadItems,
  updateCriteria,
  updateItem,
} from '../../actions/networks.actions';
import Grid from '../../components/Grid';
import { STATUS_ACTIVE, STATUS_PAUSED } from '../../constants';
import { ColumnOption } from '../../components/Grid/Grid.interfaces';
import { ROUTES, subRouteParams } from '../../constants/routes';

interface State {
  selectedItem: Record<string, any> | null;
  showDeleteModal: boolean;
}

class List extends PureComponent<any, State> {
  static propTypes = {
    criteria: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    deleteItem: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    loadItems: PropTypes.func.isRequired,
    metadata: PropTypes.object.isRequired,
    updateCriteria: PropTypes.func.isRequired,
    updateItem: PropTypes.func.isRequired,
  };

  state: State = {
    selectedItem: null,
    showDeleteModal: false,
  };

  addItem = () => {
    this.props.history.push(subRouteParams(ROUTES.NETWORK, { id: 'new' }));
  };

  editItem = ({ id }) => {
    this.props.history.push(subRouteParams(ROUTES.NETWORK, { id }));
  };

  deleteItem = () => {
    const { deleteItem } = this.props;
    const { selectedItem } = this.state;

    deleteItem(selectedItem);
    this.setState({
      selectedItem: null,
      showDeleteModal: false,
    });
  };

  showDeleteModal = selectedItem => {
    this.setState({
      selectedItem,
      showDeleteModal: true,
    });
  };

  hideDeleteModal = event => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      selectedItem: null,
      showDeleteModal: false,
    });
  };

  toggleActive = async ({ id, status }) => {
    const { updateItem } = this.props;

    await updateItem(id, {
      status:
        parseInt(status, 10) === STATUS_PAUSED ? STATUS_ACTIVE : STATUS_PAUSED,
    });
  };

  COLUMNS: ColumnOption[] = [
    {
      maxWidth: 90,
      name: 'id',
      sortable: true,
      title: 'Id',
    },
    {
      maxWidth: 75,
      name: 'status',
      sortable: true,
      title: 'Status',
      values: {
        [STATUS_ACTIVE]: {
          items: [
            {
              icon: 'fas fa-circle text-success',
              type: 'icon',
            },
            {
              action: this.toggleActive,
              icon: 'fas fa-pause',
              tooltip: 'Deactivate',
              type: 'icon',
            },
          ],
          type: 'list',
        },
        [STATUS_PAUSED]: {
          items: [
            {
              icon: 'fas fa-circle text-danger',
              type: 'icon',
            },
            {
              action: this.toggleActive,
              icon: 'fas fa-play',
              tooltip: 'Activate',
              type: 'icon',
            },
          ],
          type: 'list',
        },
      },
    },
    {
      name: 'name',
      sortable: true,
      title: 'Name',
    },
    {
      name: 'module',
      sortable: true,
      title: 'Module',
    },
    {
      className: 'text-center',
      items: [
        {
          action: this.editItem,
          icon: 'fas fa-pen-alt mr-1',
          tooltip: 'Edit item',
          type: 'icon',
        },
        {
          action: this.showDeleteModal,
          icon: 'fas fa-trash',
          tooltip: 'Delete Item',
          type: 'icon',
        },
      ],
      maxWidth: 25,
      name: 'actions',
      title: <i className="fas fa-cogs" />,
      type: 'list',
    },
  ];

  render() {
    const { criteria, data, loadItems, loading, metadata, updateCriteria } =
      this.props;

    const { selectedItem, showDeleteModal } = this.state;

    return (
      <div className="page">
        <div className="row">
          <div className="col-12">
            <Modal
              close={this.hideDeleteModal}
              description={`Are you sure, you want remove "${
                selectedItem ? selectedItem.name : ''
              }"?`}
              title="Delete entity?"
              show={showDeleteModal}
              submit={this.deleteItem}
              submitLabel="deleteModal"
            />
            <Grid
              actions={[
                {
                  action: this.addItem,
                  title: (
                    <>
                      <i className="fas fa-plus mr-2" />
                      Create new
                    </>
                  ),
                },
              ]}
              columns={this.COLUMNS}
              criteria={criteria}
              data={data}
              entity="networks"
              icon="fas fa-building pr-2"
              loading={loading}
              loadList={loadItems}
              metadata={metadata}
              searchable={false}
              // @ts-ignore
              selectItem={this.selectItem}
              title="Ad Networks"
              updateCriteria={updateCriteria}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  store => ({
    criteria: store.networks.criteria,
    data: store.networks.data,
    loading: store.networks.loading,
    metadata: store.networks.metadata,
  }),
  {
    deleteItem,
    loadItems,
    updateCriteria,
    updateItem,
  },
)(List);
