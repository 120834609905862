/**
 * Loader component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */

import cx from 'classnames';
import PropTypes from 'prop-types';
import { ReactNode, useState } from 'react';

import './styles.css';

interface IProps {
  header: any;
  showArrow: boolean;
  children: ReactNode;
  isActive: boolean;
  onClick: (expanded: boolean) => void;
}

const ExpandableBlock = ({
  header,
  showArrow,
  children,
  isActive,
  onClick,
}: IProps) => {
  const [expanded, setExpanded] = useState(Boolean(isActive));

  if (!isActive && expanded) {
    setExpanded(false);
  }

  return (
    <div
      className={cx('expandable-container', {
        expanded,
      })}
    >
      <div className="expandable-header">
        {showArrow && (
          <i
            className={cx('header-arrow fas', {
              'fa-chevron-down': expanded,
              'fa-chevron-right': !expanded,
            })}
            role="button"
            tabIndex={0}
            onClick={() => {
              setExpanded(!expanded);
              onClick(!expanded);
            }}
            onKeyDown={() => {
              setExpanded(!expanded);
              onClick(!expanded);
            }}
          />
        )}
        <div className="header-body">{header}</div>
      </div>
      <div className="expandable-body-container">
        <div className="expandable-body">{children}</div>
      </div>
    </div>
  );
};

ExpandableBlock.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  header: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  showArrow: PropTypes.bool,
};

ExpandableBlock.defaultProps = {
  children: '',
  header: '',
  isActive: false,
  onClick: () => {},
  showArrow: true,
};

export default ExpandableBlock;
