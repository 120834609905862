import { Dispatch } from 'redux';

export const NETWORKS_LIST_REQUEST = 'NETWORKS_LIST_REQUEST';
export const NETWORKS_LIST_SUCCESS = 'NETWORKS_LIST_SUCCESS';
export const NETWORKS_LIST_ERROR = 'NETWORKS_LIST_ERROR';
export const NETWORKS_LIST_CRITERIA = 'NETWORKS_LIST_CRITERIA';

export const NETWORK_ITEM_REQUEST = 'NETWORK_ITEM_REQUEST';
export const NETWORK_ITEM_SUCCESS = 'NETWORK_ITEM_SUCCESS';
export const NETWORK_ITEM_ERROR = 'NETWORK_ITEM_ERROR';
export const NETWORK_ITEM_RESET = 'NETWORK_ITEM_RESET';

export const NETWORK_CREATE_REQUEST = 'NETWORK_CREATE_REQUEST';
export const NETWORK_CREATE_SUCCESS = 'NETWORK_CREATE_SUCCESS';
export const NETWORK_CREATE_ERROR = 'NETWORK_CREATE_ERROR';

export const NETWORK_UPDATE_REQUEST = 'NETWORK_UPDATE_REQUEST';
export const NETWORK_UPDATE_SUCCESS = 'NETWORK_UPDATE_SUCCESS';
export const NETWORK_UPDATE_ERROR = 'NETWORK_UPDATE_ERROR';

export const NETWORK_DELETE_REQUEST = 'NETWORK_DELETE_REQUEST';
export const NETWORK_DELETE_SUCCESS = 'NETWORK_DELETE_SUCCESS';
export const NETWORK_DELETE_ERROR = 'NETWORK_DELETE_ERROR';

export const loadItems = () => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) => axios.get('/admin/networks'),
    requestType: 'NETWORKS_LIST',
  });
export const loadItem = (id: string) => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) => axios.get(`/admin/networks/${id}`),
    requestType: 'NETWORK_ITEM',
  });

export const createItem = (data: any) => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) => axios.post('/admin/networks', data),
    requestType: 'NETWORK_CREATE',
  });

export const updateItem =
  (id: string, data: any) => (dispatch: Dispatch<any>) =>
    dispatch({
      request: (axios: AI) => axios.put(`/admin/networks/${id}`, data),
      requestType: 'NETWORK_UPDATE',
    });

export const deleteItem = (id: string) => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) => axios.delete(`/admin/networks/${id}`),
    requestType: 'NETWORK_DELETE',
    successCallback: (response: any, dispatch: Dispatch<any>) => {
      dispatch(loadItems());

      return {
        message: response.message,
      };
    },
  });

export const updateCriteria = (criteria: any) => ({
  criteria,
  type: NETWORKS_LIST_CRITERIA,
});

export const resetItem = () => (dispatch: Dispatch<any>) =>
  dispatch({
    type: NETWORK_ITEM_RESET,
  });
