/* eslint-disable react/forbid-dom-props */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable id-length */
import reactCSS from 'reactcss';
import { ColorResult, CustomPicker, InjectedColorProps } from 'react-color';
import {
  EditableInput,
  Hue,
  Saturation,
} from 'react-color/lib/components/common';
import { useCallback, useState } from 'react';

const CustomColorPicker = (props: InjectedColorProps) => {
  const { hex, onChange } = props;
  const styles = reactCSS({
    default: {
      color: {
        background: hex,
        borderRadius: '7px',
        margin: '-7px',
        padding: '7px',
      },
      cover: {
        bottom: '0px',
        left: '0px',
        position: 'fixed',
        right: '0px',
        top: '0px',
        zIndex: 1,
      },
      hue: {
        marginLeft: '5px',
        position: 'relative',
        width: '5px',
      },
      input: {
        border: `1px solid ${hex}`,
        height: 34,
        marginTop: 5,
        paddingLeft: 10,
        width: 110,
      },
      palette: {
        display: 'flex',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      saturation: {
        height: 100,
        position: 'relative',
        width: 100,
      },
      swatch: {
        background: `#${hex}`,
        borderRadius: '10px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        cursor: 'pointer',
        display: 'inline-block',
        padding: '10px',
      },
    },
  } as const);

  const onChangeMemo = useCallback(
    (color: ColorResult) => {
      if (onChange) {
        onChange(color);
      }
    },
    [onChange],
  );

  const [pickerIsShown, setPickerIsShown] = useState(false);

  const setPickerIsShownMemo = useCallback(() => {
    setPickerIsShown(!pickerIsShown);
  }, [pickerIsShown]);

  const hidePickerMemo = useCallback(() => {
    setPickerIsShown(false);
  }, []);

  return (
    <>
      <div style={styles.swatch} onClick={setPickerIsShownMemo}>
        <div style={styles.color} />
      </div>
      {pickerIsShown ? (
        <>
          <div style={styles.cover} onMouseDown={hidePickerMemo} />
          <div className="card p-2" style={styles.popover}>
            <div style={styles.palette}>
              <div style={styles.saturation}>
                <Saturation {...props} onChange={onChangeMemo} />
              </div>
              <div style={styles.hue}>
                <Hue
                  {...props}
                  direction="vertical"
                  styles={styles}
                  onChange={onChangeMemo}
                />
              </div>
            </div>
            <EditableInput
              style={{ input: styles.input }}
              value={hex}
              onChange={onChange}
            />
          </div>
        </>
      ) : null}
    </>
  );
};

export const ColorPicker = CustomPicker(CustomColorPicker);
