/**
 * AssetItemForm component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */

import cx from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import 'react-toggle/style.css';

import FormError from '../../components/FormError';

export default class AccountItemForm extends PureComponent<any, any> {
  static propTypes = {
    disabled: PropTypes.bool,
    errors: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    touched: PropTypes.object.isRequired,
  };

  static defaultProps = {
    disabled: false,
  };

  render() {
    const {
      disabled,
      errors,
      form,
      handleSubmit,
      pristine,
      submitting,
      touched,
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <div className="card-body container-fluid">
          <div className="row">
            <div className="col-6">
              <div className="form-group required">
                <label htmlFor="name">Name</label>
                <Field
                  id="name"
                  name="name"
                  component="input"
                  className={cx('form-control', {
                    'is-invalid': touched.name && errors.name,
                  })}
                  maxLength={90}
                />
                <FormError name="name" />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group required">
                <label htmlFor="module">Module</label>
                <Field
                  id="module"
                  name="module"
                  component="input"
                  className={cx('form-control', {
                    'is-invalid': touched.name && errors.name,
                  })}
                  maxLength={90}
                />
                <FormError name="module" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <fieldset>
                <legend>Settings</legend>
                <FieldArray name="network_settings">
                  {({ fields }) => (
                    <>
                      {fields.map((setting, index) => (
                        <div key={setting} className="form-group controlled">
                          <div className="row">
                            <div className="col-6">
                              <div className="form-group required">
                                <label htmlFor={`${setting}.name`}>Name</label>
                                <Field
                                  id={`${setting}.name`}
                                  name={`${setting}.name`}
                                  className="form-control"
                                  component="input"
                                  maxLength={90}
                                />
                                <FormError name={`${setting}.name`} />
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="form-group required">
                                <label
                                  htmlFor={`network_settings[${index}].type`}
                                >
                                  Type
                                </label>
                                <Field
                                  id={`${setting}.type`}
                                  name={`${setting}.type`}
                                  className="form-control"
                                  component="input"
                                  maxLength={90}
                                />
                                <FormError name={`${setting}.type`} />
                              </div>
                            </div>
                            <button
                              type="button"
                              onClick={() => fields.remove(index)}
                              className="btn btn-primary mb-2"
                            >
                              X
                            </button>
                          </div>
                        </div>
                      ))}
                      <button
                        type="button"
                        onClick={() =>
                          fields.push({
                            module: '',
                            name: '',
                            status: true,
                          })
                        }
                        className="btn btn-primary add-btn"
                      >
                        + Add Field
                      </button>
                    </>
                  )}
                </FieldArray>
              </fieldset>
            </div>
          </div>
        </div>
        <div className="card-footer">
          <div className="row">
            <div className="col-12">
              <button
                type="button"
                className="btn btn-secondary"
                disabled={pristine || submitting}
                onClick={form.reset}
              >
                Reset
              </button>
              <button
                type="submit"
                className="btn btn-primary float-right"
                disabled={disabled || pristine || submitting}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}
