import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { resetPasswordRequest } from '../../actions/auth.actions';
import { addNotify } from '../../actions/notifications.actions';
import Loader from '../../components/Loader';
import { ROUTES } from '../../constants/routes';

import ForgotForm from './Form';

class Forgot extends PureComponent<any, any> {
  static propTypes = {
    addNotify: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    resetPasswordRequest: PropTypes.func.isRequired,
  };

  state = {
    sent: false,
  };

  handleSubmit = async values => {
    const { addNotify, resetPasswordRequest } = this.props;

    try {
      const result = await resetPasswordRequest({
        email: values.email,
      });

      if (result.error) {
        addNotify(result.error, 'error');
      }

      if (!result.error) {
        addNotify('Reset success');
        this.setState({ sent: true });
      }

      return result;
    } catch (error) {
      addNotify('Oops! Something went wrong!', 'error');

      return error;
    }
  };

  render() {
    const { loading } = this.props;
    const { sent } = this.state;

    if (sent) {
      return (
        <div className="page forgot-page">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <p>Your request was sent. Check your email to proceed.</p>
                <Link to={ROUTES.LOGIN} className="text-primary">
                  Back to login
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="page forgot-page">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {loading && <Loader />}
            <Form
              onSubmit={this.handleSubmit}
              // @ts-ignore
              component={ForgotForm}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  store => ({
    loading: store.auth.loading,
  }),
  {
    addNotify,
    resetPasswordRequest,
  },
)(Forgot);
