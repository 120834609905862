import { AnyAction } from 'redux';

import {
  NETWORK_CREATE_ERROR,
  NETWORK_CREATE_REQUEST,
  NETWORK_CREATE_SUCCESS,
  NETWORK_ITEM_ERROR,
  NETWORK_ITEM_REQUEST,
  NETWORK_ITEM_RESET,
  NETWORK_ITEM_SUCCESS,
  NETWORK_UPDATE_ERROR,
  NETWORK_UPDATE_REQUEST,
  NETWORK_UPDATE_SUCCESS,
} from '../actions/networks.actions';

interface NetworkState {
  data: {
    network_settings: any[];
  };
  loading: boolean;
}

export const INITIAL_STATE: Readonly<NetworkState> = {
  data: {
    network_settings: [],
  },
  loading: false,
};

export default (
  state: Readonly<NetworkState> = INITIAL_STATE,
  action: AnyAction,
): Readonly<NetworkState> => {
  switch (action.type) {
    case NETWORK_ITEM_REQUEST:
    case NETWORK_CREATE_REQUEST:
    case NETWORK_UPDATE_REQUEST:
      return { ...state, loading: true };

    case NETWORK_ITEM_SUCCESS:
      return { ...state, data: action.data, loading: false };

    case NETWORK_ITEM_ERROR:
    case NETWORK_CREATE_SUCCESS:
    case NETWORK_CREATE_ERROR:
    case NETWORK_UPDATE_SUCCESS:
    case NETWORK_UPDATE_ERROR:
      return { ...state, loading: false };

    case NETWORK_ITEM_RESET:
      return { ...state, ...INITIAL_STATE };

    default:
      return state;
  }
};
