import { AnyAction } from 'redux';

import {
  ACCOUNTS_ACTION_ERROR,
  ACCOUNTS_ACTION_REQUEST,
  ACCOUNTS_ACTION_SUCCESS,
  ACCOUNTS_LIST_CRITERIA,
  ACCOUNTS_LIST_ERROR,
  ACCOUNTS_LIST_REQUEST,
  ACCOUNTS_LIST_SUCCESS,
  ACCOUNTS_USER_LIST_REQUEST,
  ACCOUNTS_USER_LIST_ERROR,
  ACCOUNTS_USER_LIST_SUCCESS,
  ACCOUNTS_NETWORK_UPDATE_ERROR,
  ACCOUNTS_NETWORK_UPDATE_REQUEST,
  ACCOUNTS_NETWORK_UPDATE_SUCCESS,
  ACCOUNTS_SELECT,
  ACCOUNT_UPDATE_ERROR,
  ACCOUNT_UPDATE_REQUEST,
  ACCOUNT_UPDATE_SUCCESS,
} from '../actions/accounts.actions';
import { DEFAULT_METADATA, DEFAULT_PER_PAGE } from '../constants';

interface AccountsState {
  criteria: any;
  current: any;
  data: any[]; // manage
  selectable: any[]; // header selection, but currently loads separately
  loading: boolean;
  metadata: any;
}

export const INITIAL_STATE: Readonly<AccountsState> = {
  criteria: {
    page: 1,
    perPage: DEFAULT_PER_PAGE,
    sort: {
      id: 'ASC',
    },
    where: {},
  },
  current: null,
  data: [],
  loading: false,
  metadata: DEFAULT_METADATA,
  selectable: [],
};

export default (
  state: Readonly<AccountsState> = INITIAL_STATE,
  action: AnyAction,
): Readonly<AccountsState> => {
  switch (action.type) {
    case ACCOUNTS_NETWORK_UPDATE_REQUEST:
    case ACCOUNT_UPDATE_REQUEST:
    case ACCOUNTS_LIST_REQUEST:
    case ACCOUNTS_USER_LIST_REQUEST:
    case ACCOUNTS_ACTION_REQUEST:
      return { ...state, loading: true };

    case ACCOUNTS_LIST_SUCCESS: {
      const accountId = Number(localStorage.getItem('saved-account-id'));
      const accounts = action.data || [];
      const account = accountId
        ? // TODO: not default `accounts[0]`
          accounts.find(({ id }) => id === accountId) || accounts[0] || null
        : accounts[0] || null;

      return {
        ...state,
        current: account,
        data: action.data,
        loading: false,
        metadata: action.metadata,
      };
    }

    case ACCOUNTS_USER_LIST_SUCCESS: {
      const accountId = Number(localStorage.getItem('saved-account-id'));
      const userAccounts = action.data || [];
      const selected = accountId
        ? // TODO: not default `accounts[0]`
          userAccounts.find(({ id }) => id === accountId) ||
          userAccounts[0] ||
          null
        : userAccounts[0] || null;

      return {
        ...state,
        current: selected,
        loading: false,
        selectable: action.data,
      };
    }

    case ACCOUNTS_NETWORK_UPDATE_ERROR:
    case ACCOUNT_UPDATE_ERROR:
    case ACCOUNTS_LIST_ERROR:
    case ACCOUNTS_USER_LIST_ERROR:
    case ACCOUNTS_ACTION_ERROR:
    case ACCOUNTS_ACTION_SUCCESS:
      return { ...state, loading: false };

    case ACCOUNTS_SELECT:
      localStorage.setItem('saved-account-id', action.data.id);

      return { ...state, current: action.data };

    case ACCOUNT_UPDATE_SUCCESS:
      return {
        ...state,
        data: state.data.map(item =>
          item.id === action.data.id ? action.data : item,
        ),
        loading: false,
      };

    case ACCOUNTS_NETWORK_UPDATE_SUCCESS:
      return {
        ...state,
        data: state.data.map(item =>
          parseInt(item.id, 10) === parseInt(action.data.account_id, 10)
            ? {
                ...item,
                network_accounts: item.network_accounts.map(item =>
                  parseInt(item.id, 10) === parseInt(action.data.id, 10)
                    ? action.data
                    : item,
                ),
              }
            : item,
        ),
        loading: false,
      };

    case ACCOUNTS_LIST_CRITERIA:
      return {
        ...state,
        criteria: { ...state.criteria, ...action.criteria },
      };

    default:
      return state;
  }
};
