import ReactEcharts from 'echarts-for-react';
import { useCallback, useEffect, useState } from 'react';
import { EChartsInstance } from 'echarts-for-react/src/types';

import { VerticalLegend } from '../VerticalLegend/VerticalLegend';
import { defaultPieOption, defaultPieVertical } from '../chart-settings';
import ss from '../PerformanceReport.module.scss';
import { EmptyChart } from '../EmptyChart/EmptyChart';

export const preparePieChartOption = (series, verticalLegend = false) => ({
  ...defaultPieOption,
  legend: verticalLegend ? defaultPieVertical.legend : defaultPieOption.legend,
  series: [
    {
      ...defaultPieOption.series[0],
      ...(verticalLegend ? defaultPieVertical.series : {}),
      ...series,
    },
  ],
});

export const PieChartReport = ({
  series,
  verticalLegend = false,
}: {
  series: { data: any[]; name: string; radius?: string[] };
  verticalLegend?: boolean;
}) => {
  const [option, setOption] = useState(defaultPieOption);
  const [eChartObj, setEChartObj] = useState<EChartsInstance | null>(null);

  useEffect(() => {
    setOption(preparePieChartOption(series, verticalLegend));
  }, [series, verticalLegend]);

  const onChartReady = eChart => {
    setEChartObj(eChart);
  };

  const highlight = useCallback(
    (name: string | number, downplay: boolean = false) =>
      eChartObj.dispatchAction({
        name,
        type: downplay ? 'downplay' : 'highlight',
      }),
    [eChartObj],
  );

  if (!series || !series.data || series.data.length === 0) {
    return <EmptyChart />;
  }

  return (
    <div className={ss.cont}>
      <ReactEcharts onChartReady={onChartReady} option={option} />
      {verticalLegend ? (
        <VerticalLegend highlight={highlight} list={series.data} />
      ) : null}
    </div>
  );
};
