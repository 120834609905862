/**
 * FormError component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Field } from 'react-final-form';

export default class FormError extends PureComponent<any, any> {
  static propTypes = {
    name: PropTypes.string.isRequired,
  };

  render() {
    const { name } = this.props;

    return (
      <Field
        name={name}
        subscribe={{
          error: true,
          touched: true,
        }}
        render={({ meta: { touched, error } }) =>
          touched && error ? (
            <div className="invalid-feedback">{error}</div>
          ) : null
        }
      />
    );
  }
}
