import moment from 'moment';

export const query1 = (
  accountId: number,
  startDate: string,
  endDate: string,
) => ({
  query: `{report(accountId: ${accountId}, segments: ["adgroup_id", "network_campaign_id", "campaign_id"], startDate: "${startDate}", endDate: "${endDate}") { adgroup_id, network_campaign { funnel }, network_adgroup { name, network_id }, spend, clk, imp, ctc, vtc, tc, ctc_ov, vtc_ov, tc_ov, ctr, cpm, cpc, rpv, cpa, cr, roi, campaign { campaign_type } }}`,
});

export const query2 = (
  accountId: number,
  startDate: string,
  endDate: string,
  metric1: string,
  metric2: string,
  agids: number[],
  byHours: boolean = moment(endDate).diff(startDate, 'hours') <= 24,
) => ({
  query:
    `{report(
    startDate: "${startDate}", endDate: "${endDate}", accountId: ${accountId}, ` +
    (agids.length ? `agids: [${agids.join(', ')}], ` : '') +
    `segments: ["${
      byHours ? 'reportDateHour' : 'reportDate'
    }"]) { reportDate, ${metric1}, ${metric2} }}`,
});
