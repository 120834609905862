import cx from 'classnames';

import ss from './Dashboard.module.scss';
import { PerformanceReportConnected } from './PerformanceReport';
import { QuickActionsPanelConnected as QuickActionsPanel } from './QuickActionsPanel';

export interface IDashboardData {}

export const Dashboard = () => (
  <div className={cx('page p-4', ss.dashboard)}>
    <div className="row">
      <div className="col">
        <QuickActionsPanel hidden={true} /> {/*temporary hidden*/}
      </div>
    </div>
    <div className="row">
      <div className="col">
        <PerformanceReportConnected />
      </div>
    </div>
  </div>
);
