import { io } from 'socket.io-client';

import {
  patchCampaign,
  patchNetworkAdgroup,
} from '../actions/campaigns.actions';
import { store } from '../reducers/configureStore';

import { ADGROUP_UPDATED, CAMPAIGN_UPDATED } from './messageTypes';

const REACT_APP_WS_URL = process.env.REACT_APP_WS_URL;

if (!REACT_APP_WS_URL) {
  throw new Error('Application websocket endpoint missing');
}

const urlObj = new URL(REACT_APP_WS_URL);

export const socket = io(urlObj.origin, {
  path: urlObj.pathname,
  transports: ['websocket'],
});

socket.on(ADGROUP_UPDATED, (data: any) => {
  store.dispatch(patchNetworkAdgroup(data));
});

socket.on(CAMPAIGN_UPDATED, (data: any) => {
  store.dispatch(patchCampaign(data));
});
