/**
 * UsersList component script class
 *
 * @package    Common
 * @author     Ivan Miltykh <ivan.miltykh@gmail.com>
 */
import moment from 'moment';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import HistoryList from '../../components/HistoryList';
import Modal from '../../components/Modal';
import { loadRoles } from '../../actions/roles.actions';
import { loadAccounts } from '../../actions/accounts.actions';
import {
  deleteUser,
  loadUsers,
  updateCriteria,
  updateUserPassword,
  updateUserRole,
  updateUserStatus,
} from '../../actions/users.actions';
import Grid from '../../components/Grid';
import { STATUS_ACTIVE, STATUS_PAUSED } from '../../constants';
import {
  ColumnOption,
  Criteria,
  ListRequestParams,
  Metadata,
  UpdateRequestParams,
} from '../../components/Grid/Grid.interfaces';
import { ROUTES, subRouteParams } from '../../constants/routes';

import UserPasswordForm from './FormPassword';

interface Props {
  account: any;
  criteria: Criteria;
  data: any[];
  deleteUser: (id: string) => void;
  history: string;
  loading: boolean;
  loadRoles: () => void;
  loadUsers: (data: ListRequestParams) => void;
  loadAccounts: (data: any) => void;
  metadata: Metadata;
  rolesList: any[];
  updateCriteria: (data: UpdateRequestParams) => void;
  updateUserPassword: (id: string, data: any) => void;
  updateUserRole: (data: any) => void;
  updateUserStatus: (id: string, data: any) => void;
}

interface State {
  COLUMNS: ColumnOption[];
  selectedItem: any | null;
  showDeleteModal: boolean;
  showHistoryModal: boolean;
  showPasswordModal: boolean;
}

class List extends PureComponent<Props & RouteComponentProps, State> {
  static propTypes = {
    account: PropTypes.object.isRequired,
    criteria: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    deleteUser: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    loadAccounts: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    loadRoles: PropTypes.func.isRequired,
    loadUsers: PropTypes.func.isRequired,
    metadata: PropTypes.object.isRequired,
    rolesList: PropTypes.array.isRequired,
    updateCriteria: PropTypes.func.isRequired,
    updateUserPassword: PropTypes.func.isRequired,
    updateUserRole: PropTypes.func.isRequired,
    updateUserStatus: PropTypes.func.isRequired,
  } as never;

  state: State = {
    COLUMNS: [],
    selectedItem: null as any,
    showDeleteModal: false,
    showHistoryModal: false,
    showPasswordModal: false,
  };

  async componentDidMount() {
    await this.props.loadRoles();
    await this.props.loadAccounts({});
    this.setState({
      COLUMNS: this.getColumns(),
    });
  }

  addItem = () => {
    this.props.history.push(subRouteParams(ROUTES.USER, { id: 'new' }));
  };

  toggleActive = async ({ id, status }) => {
    const { updateUserStatus } = this.props;

    await updateUserStatus(id, {
      status: status === STATUS_PAUSED ? STATUS_ACTIVE : STATUS_PAUSED,
    });
  };

  updateUserPassword = data => {
    const { updateUserPassword } = this.props;
    const { selectedItem } = this.state;

    updateUserPassword(selectedItem.id, data);
    this.setState({
      selectedItem: null,
      showPasswordModal: false,
    });
  };

  showPasswordModal = row => {
    this.setState({
      selectedItem: row,
      showPasswordModal: true,
    });
  };

  hidePasswordModal = () => {
    this.setState({
      selectedItem: null,
      showPasswordModal: false,
    });
  };

  showHistoryModal = selectedItem => {
    this.setState({
      selectedItem,
      showHistoryModal: true,
    });
  };

  hideHistoryModal = event => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      selectedItem: null,
      showHistoryModal: false,
    });
  };

  editItem = ({ id }) => {
    this.props.history.push(subRouteParams(ROUTES.USER, { id }));
  };

  deleteItem = () => {
    const { deleteUser } = this.props;
    const { selectedItem } = this.state;

    deleteUser(selectedItem.id);
    this.setState({
      selectedItem: null,
      showDeleteModal: false,
    });
  };

  showDeleteModal = selectedItem => {
    this.setState({
      selectedItem,
      showDeleteModal: true,
    });
  };

  hideDeleteModal = event => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      selectedItem: null,
      showDeleteModal: false,
    });
  };

  updateUserRole = (role_id, row) => {
    this.props.updateUserRole({
      account_id: parseInt(role_id, 10) < 4 ? null : this.props.account.id,
      role_id,
      user_id: row.id,
    });
  };

  getColumns = (): ColumnOption[] => [
    {
      name: 'id',
      sortable: true,
      title: 'Id',
    },
    {
      maxWidth: 75,
      name: 'status',
      sortable: true,
      title: 'Status',
      values: {
        [STATUS_ACTIVE]: {
          items: [
            {
              icon: 'fas fa-circle text-success',
              type: 'icon',
            },
            {
              action: this.toggleActive,
              icon: 'fas fa-pause',
              tooltip: 'Deactivate',
              type: 'icon',
            },
            {
              action: this.showHistoryModal,
              condition: ({ logs }) => logs && logs.length > 0,
              icon: 'fas fa-history',
              tooltip: 'Changes history',
              type: 'icon',
            },
          ],
          type: 'list',
        },
        [STATUS_PAUSED]: {
          items: [
            {
              icon: 'fas fa-circle text-danger',
              type: 'icon',
            },
            {
              action: this.toggleActive,
              icon: 'fas fa-play',
              tooltip: 'Activate',
              type: 'icon',
            },
            {
              action: this.showHistoryModal,
              condition: ({ logs }) => logs && logs.length > 0,
              icon: 'fas fa-history',
              tooltip: 'Changes history',
              type: 'icon',
            },
          ],
          type: 'list',
        },
      },
    },
    {
      name: 'username',
      sortable: true,
      title: 'Username',
    },
    {
      name: 'firstname',
      sortable: true,
      title: 'First Name',
    },
    {
      name: 'lastname',
      sortable: true,
      title: 'Last Name',
    },
    {
      name: 'email',
      sortable: true,
      title: 'Email',
    },
    {
      action: this.updateUserRole,
      className: 'thin',
      field: 'role_id',
      list: () =>
        this.props.rolesList.map(item => ({
          title: item.name,
          value: item.id,
        })),
      name: 'role',
      title: 'Role',
      type: 'dropbox',
    },
    /*{
            field: 'acl_role.name',
            name: 'role',
            sortable: false,
            title: 'Role',
        },*/
    /*{
            name: 'role',
            sortable: false,
            title: 'Role',
            type: 'custom',
            value: (data) => get(data, 'acl_role.name'),
        },*/
    {
      className: 'text-center',
      items: [
        {
          action: this.editItem,
          icon: 'fas fa-pen-alt mr-1',
          tooltip: 'Edit item',
          type: 'icon',
        },
        {
          action: this.showPasswordModal,
          icon: 'fas fa-key',
          tooltip: 'Change password',
          type: 'icon',
        },
        {
          action: this.showDeleteModal,
          icon: 'fas fa-trash',
          tooltip: 'Delete Item',
          type: 'icon',
        },
      ],
      maxWidth: 25,
      name: 'actions',
      title: <i className="fas fa-cogs" />,
      type: 'list',
    },
  ];

  render() {
    const { criteria, data, loadUsers, loading, metadata, updateCriteria } =
      this.props;

    const {
      selectedItem,
      showPasswordModal,
      showHistoryModal,
      showDeleteModal,
    } = this.state;

    return (
      <div className="page">
        <div className="row">
          <div className="col-12">
            <Modal
              close={this.hidePasswordModal}
              title="Update User Password"
              show={showPasswordModal}
              hideButtons={true}
            >
              {selectedItem && (
                <>
                  <div>
                    Set new password for user
                    <> </>
                    <b>{selectedItem && selectedItem.username}</b>
                  </div>
                  <Form
                    onSubmit={this.updateUserPassword}
                    // @ts-ignore
                    component={UserPasswordForm}
                    validate={values => {
                      const errors: Record<string, string> = {};

                      if (values.password !== values.passwordConfirm) {
                        errors.passwordConfirm = 'Passwords mismatch.';
                      }

                      return errors;
                    }}
                  />
                </>
              )}
            </Modal>
            <Modal
              close={this.hideHistoryModal}
              title="Changes history"
              show={showHistoryModal}
            >
              {selectedItem && (
                <HistoryList
                  items={(selectedItem.logs || [])
                    .sort((a, b) => b.id - a.id)
                    .map(({ id, created, title, entity_type, message }) => {
                      let entity;

                      switch (entity_type) {
                        case 'campaign':
                          entity = (
                            <a href={subRouteParams(ROUTES.CAMPAIGN, { id })}>
                              {entity_type.split('_').join(' ')}
                            </a>
                          );
                          break;
                        default:
                          entity = entity_type.split('_').join(' ');
                          break;
                      }

                      return {
                        id,
                        message,
                        title: (
                          <>
                            {moment(created).format('YYYY-MM-DD HH:mm:ss')}
                            <> </>
                            {entity} <b>{title}</b>
                          </>
                        ),
                      };
                    })}
                />
              )}
            </Modal>
            <Modal
              close={this.hideDeleteModal}
              description={`Are you sure, you want remove "${
                selectedItem ? selectedItem.username : ''
              }"?`}
              title="Delete entity?"
              show={showDeleteModal}
              submit={this.deleteItem}
              submitLabel="deleteModal"
            />
            <Grid
              actions={[
                {
                  action: this.addItem,
                  title: (
                    <>
                      <i className="fas fa-plus mr-2" />
                      Create new
                    </>
                  ),
                },
              ]}
              columns={this.state.COLUMNS}
              criteria={criteria}
              data={data}
              entity="users"
              icon="fas fa-images pr-2"
              loading={loading}
              loadList={loadUsers}
              metadata={metadata}
              searchable={false}
              // @ts-ignore
              selectItem={this.selectItem}
              title="Users"
              updateCriteria={updateCriteria}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  store => ({
    account: store.accounts.current,
    criteria: store.users.criteria,
    data: store.users.data,
    loading: store.users.loading,
    metadata: store.users.metadata,
    rolesList: store.roles.data,
  }),
  {
    deleteUser,
    loadAccounts,
    loadRoles,
    loadUsers,
    updateCriteria,
    updateUserPassword,
    updateUserRole,
    updateUserStatus,
  },
)(List);
