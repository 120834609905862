import { Dispatch } from 'react';
import { DefaultRootState } from 'react-redux';

export const ASSETS_LIST_REQUEST = 'ASSETS_LIST_REQUEST';
export const ASSETS_LIST_SUCCESS = 'ASSETS_LIST_SUCCESS';
export const ASSETS_LIST_ERROR = 'ASSETS_LIST_ERROR';
export const ASSETS_LIST_CRITERIA = 'ASSETS_LIST_CRITERIA';

export const ASSET_ITEM_REQUEST = 'ASSET_ITEM_REQUEST';
export const ASSET_ITEM_SUCCESS = 'ASSET_ITEM_SUCCESS';
export const ASSET_ITEM_ERROR = 'ASSET_ITEM_ERROR';
export const ASSET_ITEM_RESET = 'ASSET_ITEM_RESET';

export const ASSET_CREATE_REQUEST = 'ASSET_CREATE_REQUEST';
export const ASSET_CREATE_SUCCESS = 'ASSET_CREATE_SUCCESS';
export const ASSET_CREATE_ERROR = 'ASSET_CREATE_ERROR';

export const ASSET_UPDATE_REQUEST = 'ASSET_UPDATE_REQUEST';
export const ASSET_UPDATE_SUCCESS = 'ASSET_UPDATE_SUCCESS';
export const ASSET_UPDATE_ERROR = 'ASSET_UPDATE_ERROR';

export const ASSET_DELETE_REQUEST = 'ASSET_DELETE_REQUEST';
export const ASSET_DELETE_SUCCESS = 'ASSET_DELETE_SUCCESS';
export const ASSET_DELETE_ERROR = 'ASSET_DELETE_ERROR';

export const loadAssets = (data: any) => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) =>
      axios.get('/creative', {
        params: data,
      }),
    types: [ASSETS_LIST_REQUEST, ASSETS_LIST_SUCCESS, ASSETS_LIST_ERROR],
  });

export const loadAsset = (id: string) => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) => axios.get(`/creative/${id}`),
    requestType: 'ASSET_ITEM',
  });

export const createAsset = (data: any) => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) => axios.post('/creative', data),
    requestType: 'ASSET_CREATE',
  });

export const updateAsset =
  (id: string, data: any) => (dispatch: Dispatch<any>) =>
    dispatch({
      request: (axios: AI) => axios.put(`/creative/${id}`, data),
      requestType: 'ASSET_UPDATE',
    });

export const deleteAsset = (id: string) => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) => axios.delete(`/creative/${id}`),
    requestType: 'ASSET_DELETE',
    successCallback: (
      response: any,
      dispatch: Dispatch<any>,
      getState: () => DefaultRootState,
    ) => {
      dispatch(
        loadAssets({
          criteria: getState().assets.criteria,
        }),
      );

      return {
        message: response.message,
      };
    },
  });

export const updateCriteria = (criteria: any) => ({
  criteria,
  type: ASSETS_LIST_CRITERIA,
});

export const resetAsset = () => (dispatch: Dispatch<any>) =>
  dispatch({
    type: ASSET_ITEM_RESET,
  });
