import cx from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent, RefCallback } from 'react';

interface Item {
  title: string;
  onClick?: (item: Item) => void;
}

interface IProps {
  onChange?: (item: Item) => void;
  items: Item[];
  className?: string;
  selectedItem: Item;
  isRight?: boolean;
}

interface IState {
  isOpen: boolean;
}

export default class DropDownButton extends PureComponent<IProps, IState> {
  static propTypes = {
    className: PropTypes.string,
    items: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    selectedItem: PropTypes.object.isRequired,
  };

  static defaultProps = {
    className: '',
  };

  state = {
    isOpen: false,
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutsideDropDown);
  }

  dropDownWrapperRef: HTMLElement | null = null;

  dropDownToggleRef: HTMLElement | null = null;

  setDropDownWrapperRef: RefCallback<HTMLElement> = node => {
    this.dropDownWrapperRef = node;
  };

  setDropDownToggleRef: RefCallback<HTMLElement> = node => {
    this.dropDownToggleRef = node;
  };

  handleClickOutsideDropDown = ({ target }: MouseEvent) => {
    if (
      target instanceof Node &&
      this.dropDownWrapperRef &&
      this.dropDownToggleRef &&
      !this.dropDownWrapperRef.contains(target) &&
      !this.dropDownToggleRef.contains(target)
    ) {
      this.setState({
        isOpen: false,
      });
    }
  };

  toggleDropDown = () => {
    this.setState(({ isOpen }) => ({
      isOpen: !isOpen,
    }));
  };

  selectItem = (item: Item) => {
    this.setState({
      isOpen: false,
    });

    if (item.onClick) {
      item.onClick(item);
    } else if (this.props.onChange) {
      this.props.onChange(item);
    }
  };

  render() {
    const { items, selectedItem, isRight } = this.props;

    const position = isRight ? 'dropdown-menu-right' : 'dropdown-menu-left';

    return (
      <div className="dropdown">
        <button
          type="button"
          className={cx('btn btn-primary btn-sm', this.props.className, {
            active: this.state.isOpen,
          })}
          ref={this.setDropDownToggleRef}
          onClick={this.toggleDropDown}
        >
          <span>{selectedItem.title}</span>
        </button>

        <div
          className={cx(`dropdown-menu ${position}`, {
            show: this.state.isOpen,
          })}
          ref={this.setDropDownWrapperRef}
        >
          {items.map(item => (
            <button
              key={item.title}
              type="button"
              className="dropdown-item"
              onClick={() => this.selectItem(item)}
            >
              {item.title}
            </button>
          ))}
        </div>
      </div>
    );
  }
}
