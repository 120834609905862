const required = value => (value || value === 0 ? undefined : 'Required field');
const minLength = min => value =>
  !value || value.length >= min
    ? undefined
    : `Value must be at least ${min} character long`;
const maxLength = max => value =>
  !value || value.length <= max
    ? undefined
    : `Value can be max ${max} character long`;
const max = max => value =>
  !value || value <= max ? undefined : `Value can be less or equal than ${max}`;
const min = min => value =>
  !value || value >= min
    ? undefined
    : `Value must be greater or equal than ${min}`;

export const startsWithHttpProtocols = value =>
  !value || value.match(/^https?:\/\//)
    ? undefined
    : 'URL must start with http:// or https://';
const composeValidators =
  (...validators) =>
  value =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined,
    );
const notEmptyItems = values =>
  !values || values.length === 0 ? 'Should be at least one' : undefined;
const compareValues = (values, fieldKeyToCompare, error) => value => {
  if (value !== values[fieldKeyToCompare]) {
    return error;
  }

  return undefined;
};

export {
  composeValidators,
  max,
  maxLength,
  min,
  minLength,
  required,
  notEmptyItems,
  compareValues,
};
