/**
 * AssetItemForm component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events, react/forbid-dom-props */

import cx from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Field } from 'react-final-form';

import {
  getEnvironmentsList,
  getPositionsList,
  getSensitiveCategoriesList,
  getViewabilityList,
} from '../../helpers/dictionaries';
import FormError from '../../components/FormError';
import SelectAdapter from '../../components/SelectAdapter';

export default class CampaignItemForm extends PureComponent<any, any> {
  static propTypes = {
    errors: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    loadLanguages: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
  };

  render() {
    const { loadLanguages, errors, form, handleSubmit, touched, values } =
      this.props;

    return (
      <form onSubmit={handleSubmit} id="campaignSettingsForm">
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="form-group required col-12 col-md-6 col-lg-6">
                <label htmlFor="settings.environment">Environment</label>
                <Field
                  id="settings.environment"
                  name="settings.environment"
                  component={SelectAdapter}
                  isMulti
                  options={getEnvironmentsList()}
                />
                <FormError name="settings.environment" />
              </div>
              <div className="form-group required col-12 col-md-6 col-lg-6">
                <label htmlFor="settings.position">Position</label>
                <Field
                  id="settings.position"
                  name="settings.position"
                  component={SelectAdapter}
                  isMulti
                  options={getPositionsList()}
                />
                <FormError name="settings.position" />
              </div>
            </div>
            <div className="row">
              <div className="form-group required col-12 col-md-6 col-lg-6">
                <label htmlFor="settings.viewability">Viewability</label>
                <Field
                  id="settings.viewability"
                  name="settings.viewability"
                  component={SelectAdapter}
                  options={getViewabilityList()}
                />
                <FormError name="settings.viewability" />
              </div>
              <div className="form-group required col-12 col-md-6 col-lg-6">
                <label htmlFor="settings.sensitiveCategory">Brand Safety</label>
                <Field
                  id="settings.sensitiveCategory"
                  name="settings.sensitiveCategory"
                  styles={{
                    multiValueRemove: (base, state) =>
                      state.data.isFixed
                        ? {
                            ...base,
                            display: 'none',
                          }
                        : base,
                  }}
                  isMulti
                  isClearable={(
                    (values.settings || {}).sensitiveCategory || []
                  ).some(val => !val.isFixed)}
                  component={SelectAdapter}
                  options={getSensitiveCategoriesList()}
                  onChange={(value, { action, removedValue }) => {
                    let sensitiveCategory = value;

                    switch (action) {
                      case 'remove-value':
                      case 'pop-value':
                        if (removedValue.isFixed) {
                          return;
                        }
                        break;
                      case 'clear':
                        sensitiveCategory = (
                          (values.settings || {}).sensitiveCategory || []
                        ).filter(val => val.isFixed);
                        break;
                      default:
                        break;
                    }

                    form.change('settings', {
                      ...values.settings,
                      sensitiveCategory,
                    });
                  }}
                />
                <FormError name="settings.sensitiveCategory" />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-12 col-md-6 col-lg-6">
                <label htmlFor="targeting_languages">Language(s)</label>
                <Field
                  id="targeting_languages"
                  name="targeting_languages"
                  component={SelectAdapter}
                  selectType="async"
                  isMulti
                  cacheOptions
                  defaultOptions
                  loadOptions={loadLanguages}
                  menuPlacement="auto"
                />
              </div>
            </div>
            <div className="row">
              {values.network_google && (
                <div className="form-group required col-12 col-md-6 col-lg-6">
                  <label htmlFor="googleads_campaign_margin">
                    Google Ads Margin
                  </label>
                  <Field
                    id="googleads_campaign_margin"
                    name="googleads_campaign_margin"
                    component="input"
                    type="number"
                    step={0.01}
                    className={cx('form-control', {
                      'is-invalid':
                        touched.googleads_campaign_margin &&
                        errors.googleads_campaign_margin,
                    })}
                  />
                  <FormError name="margin" />
                </div>
              )}
              {values.network_dv360 && (
                <div className="form-group required col-12 col-md-6 col-lg-6">
                  <label htmlFor="dv360_campaign_margin">
                    Display & Video 360 Margin
                  </label>
                  <Field
                    id="dv360_campaign_margin"
                    name="dv360_campaign_margin"
                    component="input"
                    type="number"
                    step={0.01}
                    className={cx('form-control', {
                      'is-invalid':
                        touched.dv360_campaign_margin &&
                        errors.dv360_campaign_margin,
                    })}
                  />
                  <FormError name="margin" />
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    );
  }
}
