/**
 * CampaignItem component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';

import {
  loadItem,
  resetItem,
  updateItem,
} from '../../actions/adgroups.actions';
import { addNotify } from '../../actions/notifications.actions';
import Loader from '../../components/Loader';

import AdgroupSettingsForm from './GroupSettingsForm';

class AdgroupSettings extends PureComponent<any, any> {
  static propTypes = {
    account: PropTypes.object,
    adgroup: PropTypes.object.isRequired,
    groupId: PropTypes.number,
    loading: PropTypes.bool.isRequired,
    loadItem: PropTypes.func.isRequired,
    resetItem: PropTypes.func.isRequired,
    updateItem: PropTypes.func.isRequired,
  };

  static defaultProps = {
    account: {},
    groupId: null,
  };

  componentDidMount() {
    const { groupId, loadItem } = this.props;

    groupId && loadItem(groupId);
  }

  componentWillUnmount() {
    this.props.resetItem();
  }

  handleSubmit = async values => {
    const { creative_id, ...data } = values;

    const { updateItem } = this.props;

    try {
      data.creative_id =
        creative_id && creative_id.value ? creative_id.value : null;
      const result = await updateItem(data.id, data);

      if (result.error) {
        addNotify('Oops! Something went wrong!', 'error');
      }

      return result;
    } catch (error) {
      addNotify('Oops! Something went wrong!', 'error');

      return error;
    }
  };

  render() {
    const { account, adgroup, loading } = this.props;

    return (
      <>
        {loading && <Loader />}
        {account && (
          <Form
            initialValues={adgroup}
            onSubmit={this.handleSubmit}
            // @ts-ignore
            component={AdgroupSettingsForm}
          />
        )}
      </>
    );
  }
}

export default connect(
  store => ({
    account: store.accounts.current,
    adgroup: store.adgroup.data,
    loading: store.adgroup.loading,
  }),
  {
    addNotify,
    loadItem,
    resetItem,
    updateItem,
  },
)(AdgroupSettings);
