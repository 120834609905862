import { Dispatch } from 'redux';

export const CAMPAIGNS_LIST_REQUEST = 'CAMPAIGNS_LIST_REQUEST';
export const CAMPAIGNS_LIST_SUCCESS = 'CAMPAIGNS_LIST_SUCCESS';
export const CAMPAIGNS_LIST_ERROR = 'CAMPAIGNS_LIST_ERROR';
export const CAMPAIGNS_LIST_CRITERIA = 'CAMPAIGNS_LIST_CRITERIA';
export const CAMPAIGNS_LIST_FILTER = 'CAMPAIGNS_LIST_FILTER';

export const CAMPAIGNS_LIST_ITEM_REQUEST = 'CAMPAIGNS_LIST_ITEM_REQUEST';
export const CAMPAIGNS_LIST_ITEM_SUCCESS = 'CAMPAIGNS_LIST_ITEM_SUCCESS';
export const CAMPAIGNS_LIST_ITEM_ERROR = 'CAMPAIGNS_LIST_ITEM_ERROR';

export const CAMPAIGN_ITEM_REQUEST = 'CAMPAIGN_ITEM_REQUEST';
export const CAMPAIGN_ITEM_SUCCESS = 'CAMPAIGN_ITEM_SUCCESS';
export const CAMPAIGN_ITEM_ERROR = 'CAMPAIGN_ITEM_ERROR';
export const CAMPAIGN_ITEM_RESET = 'CAMPAIGN_ITEM_RESET';

export const CAMPAIGN_CREATE_REQUEST = 'CAMPAIGN_CREATE_REQUEST';
export const CAMPAIGN_CREATE_SUCCESS = 'CAMPAIGN_CREATE_SUCCESS';
export const CAMPAIGN_CREATE_ERROR = 'CAMPAIGN_CREATE_ERROR';

export const CAMPAIGN_UPDATE_REQUEST = 'CAMPAIGN_UPDATE_REQUEST';
export const CAMPAIGN_UPDATE_SUCCESS = 'CAMPAIGN_UPDATE_SUCCESS';
export const CAMPAIGN_UPDATE_ERROR = 'CAMPAIGN_UPDATE_ERROR';

export const CAMPAIGNS_NETWORK_ITEM_REQUEST = 'CAMPAIGNS_NETWORK_ITEM_REQUEST';
export const CAMPAIGNS_NETWORK_ITEM_SUCCESS = 'CAMPAIGNS_NETWORK_ITEM_SUCCESS';
export const CAMPAIGNS_NETWORK_ITEM_ERROR = 'CAMPAIGNS_NETWORK_ITEM_ERROR';

export const CAMPAIGNS_NETWORK_UPDATE_REQUEST =
  'CAMPAIGNS_NETWORK_UPDATE_REQUEST';
export const CAMPAIGNS_NETWORK_UPDATE_SUCCESS =
  'CAMPAIGNS_NETWORK_UPDATE_SUCCESS';
export const CAMPAIGNS_NETWORK_UPDATE_ERROR = 'CAMPAIGNS_NETWORK_UPDATE_ERROR';

export const CAMPAIGNS_ADGROUPS_ITEM_REQUEST =
  'CAMPAIGNS_ADGROUPS_ITEM_REQUEST';
export const CAMPAIGNS_ADGROUPS_ITEM_SUCCESS =
  'CAMPAIGNS_ADGROUPS_ITEM_SUCCESS';
export const CAMPAIGNS_ADGROUPS_ITEM_ERROR = 'CAMPAIGNS_ADGROUPS_ITEM_ERROR';

export const CAMPAIGNS_ADGROUPS_UPDATE_REQUEST =
  'CAMPAIGNS_ADGROUPS_UPDATE_REQUEST';
export const CAMPAIGNS_ADGROUPS_UPDATE_SUCCESS =
  'CAMPAIGNS_ADGROUPS_UPDATE_SUCCESS';
export const CAMPAIGNS_ADGROUPS_UPDATE_ERROR =
  'CAMPAIGNS_ADGROUPS_UPDATE_ERROR';

export const CAMPAIGNS_ADGROUPS_CREATE_REQUEST =
  'CAMPAIGNS_ADGROUPS_CREATE_REQUEST';
export const CAMPAIGNS_ADGROUPS_CREATE_SUCCESS =
  'CAMPAIGNS_ADGROUPS_CREATE_SUCCESS';
export const CAMPAIGNS_ADGROUPS_CREATE_ERROR =
  'CAMPAIGNS_ADGROUPS_CREATE_ERROR';

export const CAMPAIGNS_ADGROUPS_DELETE_REQUEST =
  'CAMPAIGNS_ADGROUPS_DELETE_REQUEST';
export const CAMPAIGNS_ADGROUPS_DELETE_SUCCESS =
  'CAMPAIGNS_ADGROUPS_DELETE_SUCCESS';
export const CAMPAIGNS_ADGROUPS_DELETE_ERROR =
  'CAMPAIGNS_ADGROUPS_DELETE_ERROR';

export const loadCampaigns = (data: any) => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) =>
      axios.get('/campaign', {
        params: data,
      }),
    requestType: 'CAMPAIGNS_LIST',
  });

export const loadCampaignsItem = (id: string) => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) => axios.get(`/campaign/${id}`),
    requestType: 'CAMPAIGNS_LIST_ITEM',
  });

export const loadCampaign = (id: string) => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) => axios.get(`/campaign/${id}`),
    requestType: 'CAMPAIGN_ITEM',
  });

export const createCampaign = (data: any) => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) => axios.post('/campaign', data),
    requestType: 'CAMPAIGN_CREATE',
  });

export const updateCampaignStatus =
  (id: string, data: any) => (dispatch: Dispatch<any>) =>
    dispatch({
      request: (axios: AI) => axios.put(`/campaign/${id}/status`, data),
      requestType: 'CAMPAIGN_UPDATE',
    });

export const updateCampaign =
  (id: string, data: any) => (dispatch: Dispatch<any>) =>
    dispatch({
      request: (axios: AI) => axios.put(`/campaign/${id}`, data),
      requestType: 'CAMPAIGN_UPDATE',
    });

export const loadNetworkCampaignsItem =
  (id: string, networkCampaignId: string) => (dispatch: Dispatch<any>) =>
    dispatch({
      request: (axios: AI) =>
        axios.get(`/campaign/${id}/networkcampaigns/${networkCampaignId}`),
      requestType: 'CAMPAIGNS_NETWORK_ITEM',
    });

export const updateNetworkCampaignsItem =
  (id: string, networkCampaignId: string, data: any) =>
  (dispatch: Dispatch<any>) =>
    dispatch({
      request: (axios: AI) =>
        axios.put(
          `/campaign/${id}/networkcampaigns/${networkCampaignId}`,
          data,
        ),
      requestType: 'CAMPAIGNS_NETWORK_UPDATE',
    });

export const loadAdgroupItem =
  (id: string, networkCampaignId: string) => (dispatch: Dispatch<any>) =>
    dispatch({
      request: (axios: AI) =>
        axios.get(
          `/campaign/${id}/networkcampaigns/${networkCampaignId}/adgroups/${id}`,
        ),
      requestType: 'CAMPAIGNS_ADGROUP_ITEM',
    });

export const updateAdgroupItem =
  (id: string, networkCampaignId: string, data: any) =>
  (dispatch: Dispatch<any>) =>
    dispatch({
      request: (axios: AI) =>
        axios.put(
          `/campaign/${id}/networkcampaigns/${networkCampaignId}`,
          data,
        ),
      requestType: 'CAMPAIGNS_ADGROUP_UPDATE',
    });

export const createAdgroupItem =
  (id: string, networkCampaignId: string, data: any) =>
  (dispatch: Dispatch<any>) =>
    dispatch({
      request: (axios: AI) =>
        axios.put(
          `/campaign/${id}/networkcampaigns/${networkCampaignId}`,
          data,
        ),
      requestType: 'CAMPAIGNS_ADGROUP_UPDATE',
    });

export const deleteAdgroupItem =
  (id: string, networkCampaignId: string, data: any) =>
  (dispatch: Dispatch<any>) =>
    dispatch({
      request: (axios: AI) =>
        axios.put(
          `/campaign/${id}/networkcampaigns/${networkCampaignId}`,
          data,
        ),
      requestType: 'CAMPAIGNS_ADGROUP_UPDATE',
    });

export const updateCriteria = (criteria: any) => ({
  criteria,
  type: CAMPAIGNS_LIST_CRITERIA,
});

export const updateFilter = (filter: string) => ({
  filter,
  type: CAMPAIGNS_LIST_FILTER,
});

export const resetCampaign = () => (dispatch: Dispatch<any>) =>
  dispatch({
    type: CAMPAIGN_ITEM_RESET,
  });

export const loadCountries = (search: string) => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) =>
      axios.get('/campaign/countries', {
        params: {
          limit: 25,
          query: search,
        },
      }),
    requestType: 'CAMPAIGNS_COUNTIES_LIST',
  });
export const loadLanguages = (search: string) => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) =>
      axios.get('/campaign/languages', {
        params: {
          limit: 25,
          query: search,
        },
      }),
    requestType: 'CAMPAIGNS_LANGUAGES_LIST',
  });

export const CAMPAIGNS_PATCH_NETWORK_ADGROUP =
  'CAMPAIGNS_PATCH_NETWORK_ADGROUP';

export const patchNetworkAdgroup = (data: any) => ({
  payload: data,
  type: CAMPAIGNS_PATCH_NETWORK_ADGROUP,
});

export const CAMPAIGNS_PATCH_CAMPAIGN = 'CAMPAIGNS_PATCH_CAMPAIGN';

export const patchCampaign = (data: any) => ({
  payload: data,
  type: CAMPAIGNS_PATCH_CAMPAIGN,
});
