const getTimeOfDayList = () =>
  [...Array(24)].map((value, i) => ({
    label: `${
      i === 0
        ? '12 AM'
        : i === 12
        ? '12 PM'
        : i < 12
        ? i + ' AM'
        : i - 12 + ' PM'
    }`,
    value: i,
  }));

const getDaysList = () => [
  {
    label: 'Monday',
    value: 'MONDEY',
  },
  {
    label: 'Tuesday',
    value: 'TUESDAY',
  },
  {
    label: 'Wednesday',
    value: 'WEDNESDAY',
  },
  {
    label: 'Thursday',
    value: 'THURSDAY',
  },
  {
    label: 'Friday',
    value: 'FRIDAY',
  },
  {
    label: 'Saturday',
    value: 'SATURDAY',
  },
  {
    label: 'Sunday',
    value: 'SUNDAY',
  },
];

const getViewabilityList = () => [
  {
    label: '40% or greater',
    value: 'VIEWABILITY_40_PERCENT_OR_MORE',
  },
  {
    label: '50% or greater',
    value: 'VIEWABILITY_50_PERCENT_OR_MORE',
  },
  {
    label: '60% or greater',
    value: 'VIEWABILITY_60_PERCENT_OR_MORE',
  },
  {
    label: '70% or greater',
    value: 'VIEWABILITY_70_PERCENT_OR_MORE',
  },
  {
    label: '80% or greater',
    value: 'VIEWABILITY_80_PERCENT_OR_MORE',
  },
  {
    label: '90% or greater',
    value: 'VIEWABILITY_90_PERCENT_OR_MORE',
  },
];

const getPositionsList = () => [
  {
    label: 'Unknown',
    value: 'ON_SCREEN_POSITION_UNKNOWN',
  },
  {
    label: 'Above the fold',
    value: 'ON_SCREEN_POSITION_ABOVE_THE_FOLD',
  },
];

const getEnvironmentsList = () => [
  {
    label: 'Web',
    value: 'ENVIRONMENT_WEB_OPTIMIZED',
  },
  {
    label: 'App',
    value: 'ENVIRONMENT_APP',
  },
];

const getSensitiveCategoriesList = () => [
  {
    label: 'Adult',
    value: 'SENSITIVE_CATEGORY_ADULT',
  },
  {
    label: 'Derogatory',
    value: 'SENSITIVE_CATEGORY_DEROGATORY',
  },
  {
    label: 'Downloads & Sharing',
    value: 'SENSITIVE_CATEGORY_DOWNLOADS_SHARING',
  },
  {
    label: 'Weapons',
    value: 'SENSITIVE_CATEGORY_WEAPONS',
  },
  {
    label: 'Gambling',
    value: 'SENSITIVE_CATEGORY_GAMBLING',
  },
  {
    label: 'Violence',
    value: 'SENSITIVE_CATEGORY_VIOLENCE',
  },
  {
    label: 'Suggestive',
    value: 'SENSITIVE_CATEGORY_SUGGESTIVE',
  },
  {
    label: 'Profanity',
    value: 'SENSITIVE_CATEGORY_PROFANITY',
  },
  {
    label: 'Alcohol',
    value: 'SENSITIVE_CATEGORY_ALCOHOL',
  },
  {
    label: 'Drugs',
    value: 'SENSITIVE_CATEGORY_DRUGS',
  },
  {
    label: 'Tobacco',
    value: 'SENSITIVE_CATEGORY_TOBACCO',
  },
  {
    label: 'Politics',
    value: 'SENSITIVE_CATEGORY_POLITICS',
  },
  {
    label: 'Religion',
    value: 'SENSITIVE_CATEGORY_RELIGION',
  },
  {
    label: 'Tragedy',
    value: 'SENSITIVE_CATEGORY_TRAGEDY',
  },
  {
    label: 'Transportation accidents',
    value: 'SENSITIVE_CATEGORY_TRANSPORTATION_ACCIDENTS',
  },
  {
    label: 'Sensitive Social Issues',
    value: 'SENSITIVE_CATEGORY_SENSITIVE_SOCIAL_ISSUES',
  },
  {
    label: 'Shocking',
    value: 'SENSITIVE_CATEGORY_SHOCKING',
  },
];

export {
  getTimeOfDayList,
  getDaysList,
  getViewabilityList,
  getPositionsList,
  getEnvironmentsList,
  getSensitiveCategoriesList,
};
