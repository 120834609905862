import { AnyAction } from 'redux';

import {
  ACCOUNT_CREATE_ERROR,
  ACCOUNT_CREATE_REQUEST,
  ACCOUNT_CREATE_SUCCESS,
  ACCOUNT_ITEM_ERROR,
  ACCOUNT_ITEM_REQUEST,
  ACCOUNT_ITEM_RESET,
  ACCOUNT_ITEM_SUCCESS,
  ACCOUNT_UPDATE_ERROR,
  ACCOUNT_UPDATE_REQUEST,
  ACCOUNT_UPDATE_SUCCESS,
} from '../actions/accounts.actions';

interface AccountState {
  data: {
    network_accounts: any[];
    status: number;
  };
  loading: boolean;
}

export const INITIAL_STATE: Readonly<AccountState> = {
  data: {
    network_accounts: [],
    status: 1,
  },
  loading: false,
};

export default (
  state: Readonly<AccountState> = INITIAL_STATE,
  action: AnyAction,
): Readonly<AccountState> => {
  switch (action.type) {
    case ACCOUNT_ITEM_REQUEST:
    case ACCOUNT_CREATE_REQUEST:
    case ACCOUNT_UPDATE_REQUEST:
      return { ...state, loading: true };

    case ACCOUNT_ITEM_SUCCESS:
      return { ...state, data: action.data, loading: false };

    case ACCOUNT_CREATE_SUCCESS:
    case ACCOUNT_CREATE_ERROR:
    case ACCOUNT_UPDATE_SUCCESS:
    case ACCOUNT_UPDATE_ERROR:
    case ACCOUNT_ITEM_ERROR:
      return { ...state, loading: false };

    case ACCOUNT_ITEM_RESET:
      return { ...state, ...INITIAL_STATE };

    default:
      return state;
  }
};
