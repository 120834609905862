import cx from 'classnames';
import { Link } from 'react-router-dom';

import { ROUTES, subRouteParams } from '../../../constants/routes';
import { allowed, PermissionsMap } from '../../../helpers/permissions';
import { MANAGE_CAMPAIGNS } from '../../../constants/permissions';

import ss from './QuickActionsPanel.module.scss';

export interface IQuickActionsPanelData {
  userData: { perms?: PermissionsMap };
  selectedAccountId?: number;
  hidden?: boolean;
}

export const QuickActionsPanel = ({
  userData,
  selectedAccountId,
  hidden = false,
}: IQuickActionsPanelData) => {
  if (
    hidden ||
    !userData.perms ||
    !allowed(userData.perms, MANAGE_CAMPAIGNS, selectedAccountId)
  ) {
    return null;
  }

  return (
    <>
      <h2>Let&apos;s get started.</h2>
      <p>To begin, you can choose from any of the following actions.</p>
      <div className={ss.panel}>
        <Link
          className={cx(ss.actionContainer)}
          to={subRouteParams(ROUTES.CAMPAIGN, { id: 'new' })}
        >
          <div className={ss.actionIcon}>
            <i className="fas fa-solid fa-plus" />
          </div>
          <span className="mt-2">Create Campaign</span>
        </Link>
        <Link className={cx(ss.actionContainer)} to={ROUTES.CAMPAIGNS}>
          <div className={ss.actionIcon}>
            <i className="fas fa-solid fa-flag-checkered" />
          </div>
          <span className="mt-2">Manage Campaigns</span>
        </Link>
      </div>
    </>
  );
};
