import { NETWORK_DV360, NETWORK_GOOGLE_ADS } from '../constants';

const getNetworkFieldName = id =>
  ({
    [NETWORK_DV360]: 'dv360',
    [NETWORK_GOOGLE_ADS]: 'google',
  }[id]);

const getNetworkPrefix = id =>
  ({
    [NETWORK_DV360]: 'dv360',
    [NETWORK_GOOGLE_ADS]: 'googleads',
  }[id]);

const getNetworks = () => [
  {
    field: 'google',
    key: 'googleads',
    name: 'Google Ads',
  },
  {
    field: 'dv360',
    key: 'dv360',
    name: 'Display & Video 360',
  } /* {
    field: 'search360',
    key: 'srch360',
    name: 'Search 360',
}*/,
];

export { getNetworkFieldName, getNetworkPrefix, getNetworks };
