/**
 * GridSearch component script class
 *
 * @package    Components
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */

import { PureComponent } from 'react';

interface Props {
  onChange: (value: string) => void;
  value: string;
}

export default class GridSearch extends PureComponent<Props> {
  static defaultProps = {
    value: '',
  };

  onChange = event => {
    const target = event.target;
    const value = target.value;
    const { onChange } = this.props;

    onChange(value);
  };

  render() {
    const { value } = this.props;

    return (
      <div className="row mb-2 grid-search">
        <div className="col-12">
          <div className="form-inline">
            <div className="form-group ml-auto">
              <label htmlFor="search" className="mr-2">
                Search:
              </label>
              <input
                className="form-control"
                onChange={this.onChange}
                type="search"
                value={value}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
