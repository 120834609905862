import { connect } from 'react-redux';

import actions from './actions';
import { PerformanceReport } from './PerformanceReport';
import selector from './selector';

export const PerformanceReportConnected = connect(
  selector,
  actions,
)(PerformanceReport);
