/**
 * GridFooterPaginationButton component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */

import cx from 'classnames';
import { PureComponent } from 'react';

interface Props {
  active: boolean;
  disabled: boolean;
  label: React.ReactNode;
  onClick: (page: number) => void;
  page: number;
  textAfter: string;
  textBefore: string;
}

export default class GridFooterPaginationButton extends PureComponent<
  Props,
  any
> {
  static defaultProps = {
    active: false,
    disabled: false,
    textAfter: undefined,
    textBefore: undefined,
  };

  onClick = event => {
    event.preventDefault();
    const { onClick, page } = this.props;

    onClick(page);
  };

  render() {
    const { active, disabled, label, textAfter, textBefore } = this.props;

    return (
      <li
        className={cx('page-item', {
          active,
        })}
      >
        <button
          type="button"
          className={cx('btn', 'page-link', {
            disabled,
          })}
          disabled={disabled}
          onClick={this.onClick}
        >
          {textBefore && (
            <span className="d-none d-sm-inline mr-1">{textBefore}</span>
          )}
          {label}
          {textAfter && (
            <span className="d-none d-sm-inline ml-1">{textAfter}</span>
          )}
        </button>
      </li>
    );
  }
}
