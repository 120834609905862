import {
  downloadPdfReport,
  downloadReportAggregate,
  downloadReportDaily,
  downloadReportDailyAggregate,
  loadCharts,
  loadRecords,
  setupAdgroups,
  setupDateRange,
  setupMetrics,
} from '../../../actions/dashboard.actions';
import { loadUserAccounts } from '../../../actions/accounts.actions';

export default {
  downloadPdfReport,
  downloadReportAggregate,
  downloadReportDaily,
  downloadReportDailyAggregate,
  loadCharts,
  loadRecords,
  loadUserAccounts,
  setupAdgroups,
  setupDateRange,
  setupMetrics,
};
