import ReactEcharts from 'echarts-for-react';
import { useEffect, useState } from 'react';

import { EmptyChart } from '../EmptyChart/EmptyChart';
import ss from '../PerformanceReport.module.scss';
import { defaultBarOption } from '../chart-settings';

const DEFAULT_CHART_HEIGHT = 450;
const MAX_ITEMS = 11;

export const adjustDataset = dataset => dataset.slice(0, MAX_ITEMS);

export const prepareBarChartOption = (dataset, height) => ({
  ...defaultBarOption,
  dataset: {
    source: [...defaultBarOption.dataset.source, ...adjustDataset(dataset)],
  },
  grid: {
    ...defaultBarOption.grid,
    height: height - 35,
  },
});

export const getChartHeight = dataset => {
  const HEIGHT_ADDITION_ITEM = 35;
  const MIN_DEFAULT_AMOUNT = 6;
  const datasetCut = adjustDataset(dataset);

  if (datasetCut.length < MIN_DEFAULT_AMOUNT) {
    return DEFAULT_CHART_HEIGHT;
  }

  const heightAddition =
    HEIGHT_ADDITION_ITEM * (datasetCut.length - MIN_DEFAULT_AMOUNT);

  return DEFAULT_CHART_HEIGHT + heightAddition;
};

export const BarChartReport = ({ dataset }: { dataset: any[] }) => {
  const height = getChartHeight(dataset);
  const [option, setOption] = useState(defaultBarOption);

  useEffect(() => {
    setOption(prepareBarChartOption(dataset, height));
  }, [dataset, height]);

  if (!dataset || dataset.length === 0) {
    return <EmptyChart />;
  }

  return (
    <>
      <div className={ss.cont}>
        <ReactEcharts option={option} style={{ height }} />
      </div>
    </>
  );
};
