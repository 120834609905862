/**
 * AssetItemForm component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */

import cx from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Field } from 'react-final-form';

import FormError from '../../components/FormError';

export default class UserPasswordForm extends PureComponent<any, any> {
  static propTypes = {
    disabled: PropTypes.bool,
    errors: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    touched: PropTypes.object.isRequired,
  };

  static defaultProps = {
    disabled: false,
  };

  render() {
    const { disabled, errors, handleSubmit, pristine, submitting, touched } =
      this.props;

    return (
      <form onSubmit={handleSubmit}>
        <div className="card-body container-fluid">
          <div className="row">
            <div className="col-6">
              <div className="form-group required">
                <label htmlFor="password">Password</label>
                <Field
                  id="password"
                  name="password"
                  component="input"
                  type="password"
                  className={cx('form-control', {
                    'is-invalid': touched.username && errors.username,
                  })}
                />
                <FormError name="password" />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group required">
                <label htmlFor="passwordConfirm">Confirm Password</label>
                <Field
                  id="passwordConfirm"
                  name="passwordConfirm"
                  component="input"
                  type="password"
                  className={cx('form-control', {
                    'is-invalid':
                      touched.passwordConfirm && errors.passwordConfirm,
                  })}
                />
                <FormError name="passwordConfirm" />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <button
              type="submit"
              className="btn btn-primary float-right"
              disabled={disabled || pristine || submitting}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    );
  }
}
