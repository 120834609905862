/**
 * Modal component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */

import cx from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent, ReactNode } from 'react';
import ReactDOM from 'react-dom';

import './styles.css';

interface Props {
  children?: ReactNode;
  close?: React.MouseEventHandler<HTMLButtonElement>;
  description?: string;
  hideButtons?: boolean;
  show: boolean;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  submit?: () => void;
  submitDisabled?: boolean;
  title: string;
  // TODO: drop
  submitLabel?: any;
}

export default class Modal extends PureComponent<Props, any> {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    close: PropTypes.func,
    description: PropTypes.string,
    hideButtons: PropTypes.bool,
    show: PropTypes.bool.isRequired,
    size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
    submit: PropTypes.func,
    submitDisabled: PropTypes.bool,
    title: PropTypes.string.isRequired,
  };

  static defaultProps = {
    children: undefined,
    close: undefined,
    description: '',
    hideButtons: false,
    size: 'md',
    submit: undefined,
    submitDisabled: false,
  };

  state = {
    mounted: false,
    visible: false,
  };

  componentDidUpdate(prevProps) {
    const { show } = this.props;

    if (!prevProps.show && show) {
      document.body.classList.add('modal-open');
      document.body.classList.add('o-hidden');
      this.setState({
        mounted: true,
      });
      this.timeout = setTimeout(() => {
        this.setState({
          visible: true,
        });
      }, 300);
    }
    if (prevProps.show && !show) {
      document.body.classList.remove('modal-open');
      document.body.classList.remove('o-hidden');
      this.setState({
        visible: false,
      });
      this.timeout = setTimeout(() => {
        this.setState({
          mounted: false,
        });
      }, 300);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  timeout: any = null;

  render() {
    const {
      children,
      close,
      description,
      size,
      submit,
      submitDisabled,
      title,
      hideButtons,
    } = this.props;
    const { mounted, visible } = this.state;

    if (!mounted) {
      return null;
    }

    return ReactDOM.createPortal(
      <>
        <div
          className={cx('modal-backdrop', 'fade', {
            show: visible,
          })}
        />
        <div
          aria-hidden="true"
          aria-modal="true"
          className={cx('modal', 'fade', {
            show: visible,
          })}
          role="dialog"
          tabIndex={-1}
        >
          <div className={cx('modal-dialog', `modal-${size}`)} role="document">
            <div className="modal-content">
              <div className="modal-header">
                <div className="modal-title h4">{title}</div>
                {close && (
                  <button type="button" className="close" onClick={close}>
                    <span aria-hidden="true">×</span>
                    <span className="sr-only">Close</span>
                  </button>
                )}
              </div>

              <div className="modal-body">{children || description}</div>

              {(close || submit) && !hideButtons && (
                <div className="modal-footer">
                  {close && (
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={close}
                    >
                      Close
                    </button>
                  )}
                  {submit && (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={submit}
                      disabled={submitDisabled}
                    >
                      Submit
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </>,
      document.body,
    );
  }
}
