import { useState, ReactNode } from 'react';
import { Box, Tab, Tabs } from '@mui/material';

import { TabPanel } from './TabPanel';
import ss from './Tabs.module.scss';

const tabProps = (tab: number, name: string) => ({
  'aria-controls': `tabpanel-${tab}`,
  id: `${name}-tab-${tab}`,
});

export type TabProps = {
  key: string;
  label: string;
  index: number;
  content: any;
};
type Props = {
  tabs: TabProps[];
  defaultTab: number;
  name: string;
  actionButtons?: ReactNode;
  className?: string;
};

export const CommonTabs = ({
  tabs,
  defaultTab,
  name,
  actionButtons,
  className,
}: Props) => {
  const [activeTab, setActiveTab] = useState(defaultTab);

  const handleChange = (event: React.SyntheticEvent, selectedTab: number) => {
    setActiveTab(selectedTab);
  };

  return (
    <>
      <Box className={`${ss.tabsContainer} ${className || ''}`}>
        <Tabs value={activeTab} onChange={handleChange}>
          {tabs.map(tab => (
            <Tab
              key={tab.key}
              label={tab.label}
              className={ss.tabLabel}
              {...tabProps(tab.index, name)}
            />
          ))}
        </Tabs>
        {Boolean(actionButtons) && (
          <div className={ss.tabsActions}>{actionButtons}</div>
        )}
      </Box>
      {tabs.map(tab => (
        <TabPanel
          key={`${name}-panel-${tab.key}`}
          value={activeTab}
          index={tab.index}
          name={name}
        >
          {tab.content}
        </TabPanel>
      ))}
    </>
  );
};
