import { AnyAction } from 'redux';

import {
  LOAD_PROFILE_SUCCESS,
  LOGIN_ERROR,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  RESET_REQUEST,
  RESET_SUCCESS,
  RESET_ERROR,
  PASSWORD_UPDATE_REQUEST,
  PASSWORD_UPDATE_SUCCESS,
  PASSWORD_UPDATE_ERROR,
} from '../actions/auth.actions';

interface AuthState {
  authToken: string | null;
  isLoggedIn: boolean;
  loading: boolean;
  user: any;
}

export const INITIAL_STATE: Readonly<AuthState> = {
  authToken: null,
  isLoggedIn: false,
  loading: false,
  user: null,
};

export default (
  state: Readonly<AuthState> = INITIAL_STATE,
  action: AnyAction,
): Readonly<AuthState> => {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return { ...state, authToken: null, isLoggedIn: false, user: null };

    case LOGIN_REQUEST:
    case PASSWORD_UPDATE_REQUEST:
    case RESET_REQUEST:
      return { ...state, loading: true };

    case LOGIN_SUCCESS:
      return {
        ...state,
        authToken: action.data.auth_token,
        isLoggedIn: true,
        loading: false,
        user: action.data.user,
      };

    case LOGIN_ERROR:
    case PASSWORD_UPDATE_SUCCESS:
    case PASSWORD_UPDATE_ERROR:
    case RESET_SUCCESS:
    case RESET_ERROR:
      return { ...state, loading: false };

    case LOAD_PROFILE_SUCCESS:
      return {
        ...state,
        user: action.user,
      };

    default:
      return state;
  }
};
