/**
 * Loader component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */

import cx from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './styles.css';

export default class Loader extends PureComponent<any, any> {
  static propTypes = {
    global: PropTypes.bool,
    inverse: PropTypes.bool,
    withText: PropTypes.bool,
  };

  static defaultProps = {
    global: false,
    inverse: false,
    withText: true,
  };

  render() {
    const { global, inverse, withText } = this.props;

    return (
      <div
        id="loader"
        className={cx({
          global,
          inverse,
        })}
      >
        <div className="loader-content">
          <div className="loader-content-line" />
          <div className="loader-content-ball" />
          {withText && <h4>OnePortal</h4>}
        </div>
      </div>
    );
  }
}
