/**
 * AssetPreview component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { getTypeId, imagePath } from '../../helpers/creative';
import displayPreviewHeader from '../../styles/images/ads-header.png';
import videoPreviewHeader from '../../styles/images/ads-video-header.png';
import videoPreviewSidebar from '../../styles/images/ads-video-sidebar.png';
import placeholder from '../../styles/images/placeholder.png';

export default class AssetPreview extends PureComponent<any, any> {
  static propTypes = {
    data: PropTypes.object,
  };

  static defaultProps = {
    data: {},
  };

  render() {
    const { data } = this.props;

    return (
      <div className="preview-container">
        {data.type === getTypeId('image') && (
          <img src={displayPreviewHeader} alt="Preview" />
        )}
        {data.type === getTypeId('video') && (
          <img src={videoPreviewHeader} alt="Preview" />
        )}
        {data.type === getTypeId('image') && (
          <div className="preview-block with-left-padding">
            <div className="preview-item row preview-native">
              <div className="col-6 p-0">
                <img src={imagePath(data) || placeholder} alt={data.name} />
              </div>
              <div className="col-6 bg-white p-2">
                <p>
                  <b>{data.longHeadline}</b>
                </p>
                <p>
                  <span className="badge badge-success mr-1">Ad</span>
                  {data.descriptions[0]}
                </p>
                <p>{data.businessName}</p>
                {data.callToAction && (
                  <button type="button" className="btn btn-primary btn-sm">
                    {data.callToAction}
                  </button>
                )}
              </div>
            </div>

            <div className="preview-item row preview-image">
              <img src={imagePath(data) || placeholder} alt={data.name} />
              <p className="bg-dark text-white p-1 mb-0">
                <b>{data.shortHeadlines[0]}</b>
              </p>
              <div className="row p-2 bg-white m-0">
                <div className="col-9 p-0">
                  <p>
                    <b>{data.descriptions[0]}</b>
                  </p>
                  <p>{data.businessName}</p>
                </div>
                <div className="col-3 p-0">
                  <button
                    type="button"
                    className="btn btn-primary btn-circle float-right"
                  >
                    <i className="fas fa-angle-right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {data.type === getTypeId('video') && (
          <div className="preview-block">
            <div className="row preview-item preview-video">
              <div className="col-8 pr-0">
                {data.videoAsset && (
                  <div className="preview-video-wrapper">
                    <iframe
                      title="Video ads preview"
                      width="560"
                      height="315"
                      src={
                        data.videoAsset.includes('embed')
                          ? data.videoAsset
                          : data.videoAsset.replace('watch?v=', 'embed/')
                      }
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                )}
                <div className="preview-video-text row m-0">
                  <div className="col-9 p-0">
                    <p>
                      <b>{data.shortHeadlines[0]}</b>
                    </p>
                    <p>{data.displayUrl}</p>
                  </div>
                  {data.callToAction && (
                    <div className="col-3 p-0">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm float-right"
                      >
                        {data.callToAction}
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-4 pl-0">
                <img src={videoPreviewSidebar} alt="Preview" />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
