import { RootState } from '../reducers';

export const loadingSelector = (state: RootState) =>
  state.accountTracking.loading;

export const dcmPrivateSelector = (state: RootState) =>
  state.accountTracking.dcmPrivate;

export const networkSettingsSelector = (state: RootState) =>
  state.accountTracking.networkSettings;

export const networkSettingsTypesSelector = (state: RootState) =>
  state.accountTracking.networkSettingsTypes;
