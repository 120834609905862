import { CardElement } from '@stripe/react-stripe-js';

import './StripeCard.module.css';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      '::placeholder': {
        color: '#aab7c4',
      },
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

export const StripeCard = () => (
  <span>
    <p>Card details</p>
    <CardElement options={CARD_ELEMENT_OPTIONS} />
  </span>
);
