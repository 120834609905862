/**
 * AssetsList component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  createAccount,
  loadAccount,
  loadAdWordsLabel,
  resetAccount,
  updateAccount,
} from '../../actions/accounts.actions';
import { loadItems } from '../../actions/networks.actions';
import { addNotify } from '../../actions/notifications.actions';
import Loader from '../../components/Loader';
import { ROUTES } from '../../constants/routes';

import AccountItemForm from './Form';

class AccountItem extends PureComponent<any, any> {
  static propTypes = {
    account: PropTypes.object,
    addNotify: PropTypes.func.isRequired,
    createAccount: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    loadAccount: PropTypes.func.isRequired,
    loadAdWordsLabel: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    loadNetworks: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    networks: PropTypes.array.isRequired,
    resetAccount: PropTypes.func.isRequired,
    updateAccount: PropTypes.func.isRequired,
  };

  static defaultProps = {
    account: {},
  };

  async componentDidMount() {
    const {
      match: {
        params: { id },
      },
      loadAccount,
      loadNetworks,
    } = this.props;

    id !== 'new' && loadAccount(id);
    loadNetworks();
  }

  componentWillUnmount() {
    this.props.resetAccount();
  }

  handleSubmit = async values => {
    const {
      addNotify,
      createAccount,
      history,
      match: {
        params: { id },
      },
      updateAccount,
    } = this.props;
    const api = id === 'new' ? createAccount : updateAccount;
    const params = id === 'new' ? [values] : [id, values];

    try {
      const result = await api(...params);

      if (result.error) {
        addNotify('Oops! Something went wrong!', 'error');
      } else {
        history.push(ROUTES.ACCOUNTS);
      }

      return result;
    } catch (error) {
      addNotify('Oops! Something went wrong!', 'error');

      return error;
    }
  };

  render() {
    const { account, loading, networks } = this.props;

    return (
      <div className="card border-0 page">
        <h5 className="card-header bg-primary text-white">
          <i className="fas fa-images pr-2" />
          <Link to={ROUTES.ACCOUNTS} className="text-white">
            Accounts Library
          </Link>
          <> / Account</>
        </h5>
        {loading && <Loader />}
        {account && (
          <Form
            addNotify={this.props.addNotify}
            initialValues={account}
            onSubmit={this.handleSubmit}
            // @ts-ignore
            component={AccountItemForm}
            networks={this.props.networks}
            loadAdWordsLabel={this.props.loadAdWordsLabel}
            mutators={{
              addAccount: ([id], state, { changeValue }) => {
                changeValue(state, 'network_accounts', value => {
                  for (let i = 0; i < networks.length; i++) {
                    if (networks[i].id === id) {
                      value.push({
                        ad_network: networks[i],
                        status: true,
                      });
                    }
                  }

                  return value;
                });
              },
              removeAccount: ([index], state, { changeValue }) => {
                changeValue(state, 'network_accounts', value => {
                  value.splice(index, 1);

                  return value;
                });
              },
              updateValue: ([path, value], state, { changeValue }) => {
                changeValue(state, path, () => value);
              },
            }}
          />
        )}
      </div>
    );
  }
}

export default connect(
  store => ({
    account: store.account.data,
    loading: store.accounts.loading,
    networks: store.networks.data,
  }),
  {
    addNotify,
    createAccount,
    loadAccount,
    loadAdWordsLabel,
    loadNetworks: loadItems,
    resetAccount,
    updateAccount,
  },
)(AccountItem);
