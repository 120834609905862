/**
 * AssetItemForm component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

import cx from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import Sticky from 'react-sticky-el';

import ExpandableBlock from '../../components/ExpandableBlock';
import { AdType } from '../../external/google-ads-constants';
import AssetPreview from '../../components/AssetPreview';
import FormError from '../../components/FormError';
import Tooltip from '../../components/Tooltip';
import { getTypeId } from '../../helpers/creative';
import {
  composeValidators,
  maxLength,
  minLength,
  required,
} from '../../helpers/validation';

import ImageInput from './ImageInput';

const googleAdwordsFields = [
  [250, 250],
  [300, 250],
  [300, 600],
  [728, 90],
  [300, 50],
  [320, 50],
  [160, 600],
  [120, 600],
];

export default class AssetItemForm extends PureComponent<any, any> {
  static propTypes = {
    disabled: PropTypes.bool,
    errors: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    touched: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
  };

  static defaultProps = {
    disabled: false,
  };

  getDV360Form(values, touched, errors) {
    switch (values.type) {
      case getTypeId('image'):
        return (
          <>
            <div className="form-row">
              <div className="form-group col-6 required">
                <label htmlFor="cmImg250x250">Square Pop-Up</label>
                <Field
                  id="cmImg250x250"
                  name="cmImg250x250"
                  // @ts-ignore
                  component={ImageInput}
                  height={250}
                  width={250}
                  className={
                    touched.cmImg250x250 && errors.cmImg250x250
                      ? 'is-invalid'
                      : ''
                  }
                  validate={required}
                />
                <FormError name="cmImg250x250" />
              </div>
              <div className="form-group col-6 required">
                <label htmlFor="cmImg300x250">Medium Rectangle</label>
                <Field
                  id="cmImg300x250"
                  name="cmImg300x250"
                  // @ts-ignore
                  component={ImageInput}
                  height={250}
                  width={300}
                  className={
                    touched.cmImg300x250 && errors.cmImg300x250
                      ? 'is-invalid'
                      : ''
                  }
                  validate={required}
                />
                <FormError name="cmImg300x250" />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-6 required">
                <label htmlFor="cmImg300x600">Half Page Ad</label>
                <Field
                  id="cmImg300x600"
                  name="cmImg300x600"
                  // @ts-ignore
                  component={ImageInput}
                  height={600}
                  width={300}
                  className={
                    touched.cmImg300x600 && errors.cmImg300x600
                      ? 'is-invalid'
                      : ''
                  }
                  validate={required}
                />
                <FormError name="cmImg300x600" />
              </div>
              <div className="form-group col-6 required">
                <label htmlFor="cmImg728x90">Leaderboard</label>
                <Field
                  id="cmImg728x90"
                  name="cmImg728x90"
                  // @ts-ignore
                  component={ImageInput}
                  height={90}
                  width={728}
                  className={
                    touched.cmImg728x90 && errors.cmImg728x90
                      ? 'is-invalid'
                      : ''
                  }
                  validate={required}
                />
                <FormError name="cmImg728x90" />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-6 required">
                <label htmlFor="cmImg160x600">Wide Skyscraper</label>
                <Field
                  id="cmImg160x600"
                  name="cmImg160x600"
                  // @ts-ignore
                  component={ImageInput}
                  height={600}
                  width={160}
                  className={
                    touched.cmImg160x600 && errors.cmImg160x600
                      ? 'is-invalid'
                      : ''
                  }
                  validate={required}
                />
                <FormError name="cmImg160x600" />
              </div>
              <div className="form-group col-6 required">
                <label htmlFor="cmImg300x50">300 x 50</label>
                <Field
                  id="cmImg300x50"
                  name="cmImg300x50"
                  // @ts-ignore
                  component={ImageInput}
                  height={50}
                  width={300}
                  className={
                    touched.cmImg300x50 && errors.cmImg300x50
                      ? 'is-invalid'
                      : ''
                  }
                  validate={required}
                />
                <FormError name="cmImg300x50" />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-6 required">
                <label htmlFor="cmImg320x50">320 x 50</label>
                <Field
                  id="cmImg320x50"
                  name="cmImg320x50"
                  // @ts-ignore
                  component={ImageInput}
                  height={50}
                  width={320}
                  className={
                    touched.cmImg320x50 && errors.cmImg320x50
                      ? 'is-invalid'
                      : ''
                  }
                  validate={required}
                />
                <FormError name="cmImg320x50" />
              </div>
            </div>
          </>
        );
      case getTypeId('video'):
      default:
        return;
    }
  }

  getAdWordsForm(values, touched, errors) {
    switch (true) {
      case values.type === getTypeId('image') &&
        values.googleType === AdType.RESPONSIVE_DISPLAY_AD:
        return (
          <>
            <div className="form-row">
              <div className="form-group col-6 required">
                <label htmlFor="imageLandscape">Image Landscape</label>
                <Field
                  id="imageLandscape"
                  name="imageLandscape"
                  // @ts-ignore
                  component={ImageInput}
                  height={314}
                  width={600}
                  className={
                    touched.imageLandscape && errors.imageLandscape
                      ? 'is-invalid'
                      : ''
                  }
                  validate={required}
                />
                <FormError name="imageLandscape" />
              </div>
              <div className="form-group col-6 required">
                <label htmlFor="imageSquare">Image Square</label>
                <Field
                  id="imageSquare"
                  name="imageSquare"
                  // @ts-ignore
                  component={ImageInput}
                  height={300}
                  width={300}
                  className={
                    touched.imageSquare && errors.imageSquare
                      ? 'is-invalid'
                      : ''
                  }
                  validate={required}
                />
                <FormError name="imageSquare" />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-6 required">
                <label htmlFor="logoLandscape">Logo Landscape</label>
                <Field
                  id="logoLandscape"
                  name="logoLandscape"
                  // @ts-ignore
                  component={ImageInput}
                  height={300}
                  width={1200}
                  className={
                    touched.logoLandscape && errors.logoLandscape
                      ? 'is-invalid'
                      : ''
                  }
                  validate={required}
                />
                <FormError name="logoLandscape" />
              </div>
              <div className="form-group col-6 required">
                <label htmlFor="logoSquare">Logo Square</label>
                <Field
                  id="logoSquare"
                  name="logoSquare"
                  // @ts-ignore
                  component={ImageInput}
                  height={1200}
                  width={1200}
                  className={
                    touched.logoSquare && errors.logoSquare ? 'is-invalid' : ''
                  }
                  validate={required}
                />
                <FormError name="logoSquare" />
              </div>
            </div>
            <FieldArray name="shortHeadlines">
              {({ fields }) => (
                <>
                  {fields.map((name, index) => (
                    <div
                      className={cx('form-group', {
                        controlled: index > 0,
                        required: index === 0,
                      })}
                      key={name}
                    >
                      <label>
                        Short headline (
                        {30 - (values.shortHeadlines[index] || '').length}{' '}
                        characters remaining)
                        <Tooltip tooltip="The short headline is the first line of your ad, and appears in tight ad spaces where the long headline doesn't fit. Short headlines may appear with or without your description." />
                      </label>
                      <Field
                        name={name}
                        component="input"
                        type="text"
                        className={cx('form-control', {
                          'is-invalid':
                            touched[`shortHeadlines[${index}]`] &&
                            errors.shortHeadlines &&
                            errors.shortHeadlines[index],
                        })}
                        maxLength={30}
                        validate={
                          index === 0
                            ? composeValidators(required, maxLength(30))
                            : maxLength(30)
                        }
                      />
                      {index > 0 && (
                        <button
                          type="button"
                          onClick={event => {
                            event.preventDefault();
                            // @ts-ignore
                            fields.remove(index, 1);
                          }}
                          className="btn btn-primary mb-2"
                        >
                          X
                        </button>
                      )}
                      <FormError name={`shortHeadlines[${index}]`} />
                    </div>
                  ))}
                  {/* @ts-ignore */}
                  {fields.length < 5 && (
                    <button
                      type="button"
                      onClick={event => {
                        event.preventDefault();
                        fields.push('');
                      }}
                      className="btn btn-primary mb-2"
                    >
                      Add Headline
                    </button>
                  )}
                </>
              )}
            </FieldArray>
            <div className="form-group required">
              <label htmlFor="longHeadline">
                Long headline ({90 - (values.longHeadline || '').length}{' '}
                characters remaining)
                <Tooltip tooltip="The long headline is the first line of your ad, and appears instead of your short headline in larger ads. Long headlines may appear with or without your description. <b>Note</b>: When rendered in an ad, the long headline's length will depend on the site it appears on. If shortened, the long headline will end with ellipses." />
              </label>
              <Field
                id="longHeadline"
                name="longHeadline"
                component="textarea"
                className={cx('form-control', {
                  'is-invalid': touched.longHeadline && errors.longHeadline,
                })}
                maxLength={90}
                validate={composeValidators(required, maxLength(90))}
              />
              <FormError name="longHeadline" />
            </div>
            <FieldArray name="descriptions">
              {({ fields }) => (
                <>
                  {fields.map((name, index) => (
                    <div
                      className={cx('form-group', {
                        controlled: index > 0,
                        required: index === 0,
                      })}
                      key={name}
                    >
                      <label>
                        Description (
                        {90 - (values.descriptions[index] || '').length}{' '}
                        characters remaining)
                        <Tooltip tooltip="The description adds to the headline and invites people to take action. It can be up to 90 characters, and may appear after the (short or long) headline. <b>Note</b>: The length of the rendered description will depend on the site it appears on. If shortened, the description will end with ellipses." />
                      </label>
                      <Field
                        name={name}
                        component="textarea"
                        className={cx('form-control', {
                          'is-invalid':
                            touched[`descriptions[${index}]`] &&
                            errors.descriptions &&
                            errors.descriptions[index],
                        })}
                        maxLength={90}
                        validate={
                          index === 0
                            ? composeValidators(required, maxLength(90))
                            : maxLength(90)
                        }
                      />
                      {index > 0 && (
                        <button
                          type="button"
                          onClick={event => {
                            event.preventDefault();
                            // @ts-ignore
                            fields.remove(index, 1);
                          }}
                          className="btn btn-primary mb-2"
                        >
                          X
                        </button>
                      )}
                      <FormError name={`descriptions[${index}]`} />
                    </div>
                  ))}
                  {/* @ts-ignore */}
                  {fields.length < 5 && (
                    <button
                      type="button"
                      onClick={event => {
                        event.preventDefault();
                        fields.push('');
                      }}
                      className="btn btn-primary mb-2"
                    >
                      Add Description
                    </button>
                  )}
                </>
              )}
            </FieldArray>
            <div className="form-group required">
              <label htmlFor="businessName">
                Business Name
                <Tooltip tooltip="This is the name of your business or brand." />
              </label>
              <Field
                id="businessName"
                name="businessName"
                component="input"
                type="text"
                className={cx('form-control', {
                  'is-invalid': touched.businessName && errors.businessName,
                })}
                maxLength={255}
                validate={composeValidators(required, maxLength(255))}
              />
              <FormError name="businessName" />
            </div>
            <div className="form-group">
              <label htmlFor="promotionText">Promotion text</label>
              <Field
                id="promotionText"
                name="promotionText"
                component="input"
                type="text"
                className={cx('form-control', {
                  'is-invalid': touched.promotionText && errors.promotionText,
                })}
                maxLength={255}
              />
              <FormError name="promotionText" />
            </div>
          </>
        );
      case values.type === getTypeId('video') &&
        values.googleType === AdType.RESPONSIVE_DISPLAY_AD:
        return (
          <>
            <div className="form-group required">
              <label htmlFor="videoAsset">
                Video asset
                <Tooltip tooltip="Only YouTube video links are permitted for the ad. Should be 30 seconds or less." />
              </label>
              <Field
                id="videoAsset"
                name="videoAsset"
                component="input"
                type="url"
                className={cx('form-control', {
                  'is-invalid': touched.videoAsset && errors.videoAsset,
                })}
                maxLength={255}
                validate={required}
              />
              <FormError name="videoAsset" />
            </div>
            <div className="form-group">
              <label htmlFor="shortHeadlines[0]">Headline</label>
              <Field
                id="shortHeadlines[0]"
                name="shortHeadlines[0]"
                component="input"
                type="text"
                className={cx('form-control', {
                  'is-invalid':
                    touched['shortHeadlines[0]'] &&
                    errors.shortHeadlines &&
                    errors.shortHeadlines[0],
                })}
                maxLength={15}
                validate={maxLength(15)}
              />
              <FormError name="shortHeadlines[0]" />
            </div>
            <div className="form-group">
              <label htmlFor="displayUrl">Display URL</label>
              <Field
                id="displayUrl"
                name="displayUrl"
                component="input"
                type="text"
                className={cx('form-control', {
                  'is-invalid': touched.displayUrl && errors.displayUrl,
                })}
                maxLength={255}
                validate={maxLength(255)}
              />
              <FormError name="displayUrl" />
            </div>
          </>
        );
      case values.type === getTypeId('image') &&
        values.googleType === AdType.IMAGE_AD:
        return (
          <div className="form-row">
            {googleAdwordsFields.map(([width, height]) => {
              const key = `ga${width}x${height}`;

              return (
                <div key={key} className="form-group col-6">
                  <label htmlFor={key}>{`${width}x${height}`}</label>
                  <Field
                    id={key}
                    name={key}
                    // @ts-ignore
                    component={ImageInput}
                    width={width}
                    height={height}
                    className={touched[key] && errors[key] ? 'is-invalid' : ''}
                  />
                  <FormError name={key} />
                </div>
              );
            })}
          </div>
        );
      default:
        return;
    }
  }

  render() {
    const {
      disabled,
      errors,
      form,
      handleSubmit,
      pristine,
      submitting,
      touched,
      values,
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <div className="card-body container-fluid">
          {disabled && (
            <div className="row">
              <div className="col-12">
                <div className="alert alert-info">
                  Creative can not be modified, because it has related ads
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-7">
              <div className="form-group required">
                <label htmlFor="name">Name</label>
                <Field
                  id="name"
                  name="name"
                  component="input"
                  type="text"
                  className={cx('form-control', {
                    'is-invalid': touched.name && errors.name,
                  })}
                  maxLength={255}
                  validate={composeValidators(
                    required,
                    minLength(3),
                    maxLength(255),
                  )}
                />
                <FormError name="name" />
              </div>
              <div className="form-group required">
                <label htmlFor="type">Type</label>
                <Field
                  id="type"
                  name="type"
                  component="select"
                  className="form-control"
                  validate={required}
                >
                  <option value={getTypeId('image')}>Display</option>
                  <option value={getTypeId('video')}>Video</option>
                </Field>
              </div>
              <ExpandableBlock
                onClick={status => {
                  if (status) {
                    form.change('networkGoogle', true);
                  }
                }}
                isActive={values.networkGoogle}
                header={
                  <>
                    AdWords
                    <Field
                      id="networkGoogle"
                      name="networkGoogle"
                      component="input"
                      className="float right"
                      type="checkbox"
                    />
                  </>
                }
              >
                {values.networkGoogle && (
                  <>
                    <div className="col">
                      <div className="row">
                        <ul className="nav nav-tabs">
                          <li className="nav-item">
                            <span
                              onClick={() =>
                                form.change(
                                  'googleType',
                                  AdType.RESPONSIVE_DISPLAY_AD,
                                )
                              }
                              className={cx('nav-link btn', {
                                active:
                                  values.googleType ===
                                  AdType.RESPONSIVE_DISPLAY_AD,
                              })}
                            >
                              Responsive display ad
                            </span>
                          </li>
                          <li className="nav-item">
                            <span
                              onClick={() =>
                                form.change('googleType', AdType.IMAGE_AD)
                              }
                              className={cx('nav-link btn', {
                                active: values.googleType === AdType.IMAGE_AD,
                              })}
                            >
                              Image ad
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <br />
                    {this.getAdWordsForm(values, touched, errors)}
                    <div className="form-group">
                      <label htmlFor="callToAction">Call to action text</label>
                      <Field
                        id="callToAction"
                        name="callToAction"
                        component="select"
                        className={cx('form-control', {
                          'is-invalid':
                            touched.callToAction && errors.callToAction,
                        })}
                      >
                        <option value="" />
                        <option value="Apply Now">Apply Now</option>
                        <option value="Book Now">Book Now</option>
                        <option value="Contact Us">Contact Us</option>
                        <option value="Download">Download</option>
                        <option value="Learn More">Learn More</option>
                        <option value="Install">Install</option>
                        <option value="Visit Site">Visit Site</option>
                        <option value="Shop Now">Shop Now</option>
                        <option value="Sign Up">Sign Up</option>
                        <option value="Get Quote">Get Quote</option>
                        <option value="Subscribe">Subscribe</option>
                        <option value="See More">See More</option>
                      </Field>
                      <FormError name="callToAction" />
                    </div>
                  </>
                )}
              </ExpandableBlock>
              <br />
              <ExpandableBlock
                onClick={status => {
                  if (status) {
                    form.change('networkDV360', true);
                  }
                }}
                isActive={values.networkDV360}
                header={
                  <>
                    DV360
                    <Field
                      id="networkDV360"
                      name="networkDV360"
                      component="input"
                      className="float right"
                      type="checkbox"
                    />
                  </>
                }
              >
                {values.networkDV360 &&
                  this.getDV360Form(values, touched, errors)}
              </ExpandableBlock>
            </div>
            <div className="col-5">
              <Sticky>
                <h6 className="text-center">Preview</h6>
                <AssetPreview data={values} />
              </Sticky>
            </div>
          </div>
        </div>
        <div className="card-footer">
          <div className="row">
            <div className="col-12">
              <button
                type="button"
                className="btn btn-secondary"
                disabled={pristine || submitting}
                onClick={form.reset}
              >
                Reset
              </button>
              <button
                type="submit"
                className="btn btn-primary float-right"
                disabled={disabled || pristine || submitting}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}
