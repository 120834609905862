export const ACL_ROLES = [
  { id: 1, name: 'super-admin' },
  { id: 2, name: 'system-admin' },
  { id: 3, name: 'system-reporting' },
  { id: 4, name: 'agency-admin' },
  { id: 5, name: 'agency-standard' },
  { id: 6, name: 'agency-reporting' },
  { id: 7, name: 'client-admin' },
  { id: 8, name: 'client-standard' },
  { id: 9, name: 'client-reporting' },
];
