import moment from 'moment';

import { ColumnOption } from '../../../components/Grid/Grid.interfaces';
import {
  formatNumber0,
  formatNumber2,
  formatPercent,
  formatUSD,
} from '../../../helpers/number';
import { FUNNEL_TITLES } from '../../../constants';

import { averageAmounts, metrics, metricsObj } from './constants';

const adNetworkIcon = () => (
  <img
    alt="Ad Network"
    width="15"
    height="15"
    src="/resources/shared/images/network_logos/ron.png"
  />
);

const funnelTitle = (funnel: number | undefined) =>
  funnel ? FUNNEL_TITLES[funnel] : 'Not set';
const funnelClassName = (funnel: number | undefined) =>
  !funnel ? 'bg-warning' : '';

const tooltipDateFormatter = (date: string) =>
  `Date: ${moment(date).format('MMM D, Y')}`;

export const averageDataTypes = {
  average: 'average',
  future: 'future',
  spike: 'spike',
};

export const averageMetricsData = {
  averageCpm: {
    formatter: formatUSD,
    label: `${averageAmounts[0]} days cpm average`,
    type: averageDataTypes.average,
  },
  averageCtc: {
    formatter: formatNumber0,
    label: `${averageAmounts[0]} days ctc average`,
    type: averageDataTypes.average,
  },
  averageSpend: {
    formatter: formatUSD,
    label: `${averageAmounts[0]} days spend average`,
    type: averageDataTypes.average,
  },
  futureSpend: {
    formatter: formatUSD,
    label: `Future spend ${averageAmounts[0]} days average`,
    type: averageDataTypes.future,
  },
  roiSpikes: {
    formatter: value => formatNumber2(value) + 'x',
    label: 'Total ROI Spikes',
    type: averageDataTypes.spike,
  },
};

export const allMetricsColumns: ColumnOption[] = [
  {
    // locked: true,
    name: 'adgroup_id',
    sortable: true,
    title: 'ID',
    width: 60,
  },
  {
    // locked: true,
    name: 'network_adgroup.network_id',
    title: 'Ad Network',
    type: 'custom',
    value: adNetworkIcon,
    width: 50,
  },
  {
    // locked: true,
    name: 'network_adgroup.name',
    sortable: true,
    sortingType: 'text',
    title: 'Name',
    width: 175,
  },
  {
    dataClassName: funnelClassName,
    name: 'network_campaign.funnel',
    title: 'Funnel',
    type: 'custom',
    value: funnelTitle,
  },
  {
    name: 'spend',
    sortable: true,
    summaryRenderer: summary => summary !== null && formatUSD(summary),
    summaryType: 'sum',
    title: 'Spend',
    type: 'price',
  },
  {
    name: 'imp',
    sortable: true,
    summaryRenderer: formatNumber0,
    summaryType: 'sum',
    title: 'Impression',
    type: 'custom',
    value: formatNumber0,
  },
  {
    name: 'clk',
    sortable: true,
    summaryRenderer: formatNumber0,
    summaryType: 'sum',
    title: 'Clicks',
    type: 'custom',
    value: formatNumber0,
    width: 60,
  },
  {
    name: 'ctr',
    sortable: true,
    summaryRenderer: (_, summaryValues) => {
      const [, , , , , imp, clk] = summaryValues;
      const ctr = clk! / imp!;

      return formatPercent(isFinite(ctr) ? ctr : 0);
    },
    title: 'CTR',
    type: 'percent',
    width: 60,
  },
  {
    name: 'ctc',
    sortable: true,
    summaryRenderer: formatNumber0,
    summaryType: 'sum',
    title: 'CTC',
    type: 'custom',
    value: formatNumber0,
    width: 60,
  },
  {
    name: 'ctc_ov',
    sortable: true,
    summaryRenderer: summary => summary !== null && formatUSD(summary),
    summaryType: 'sum',
    title: 'CTC OV',
    type: 'price',
  },
  {
    name: 'vtc',
    sortable: true,
    summaryRenderer: formatNumber0,
    summaryType: 'sum',
    title: 'VTC',
    type: 'custom',
    value: formatNumber0,
    width: 60,
  },
  {
    name: 'vtc_ov',
    sortable: true,
    summaryRenderer: summary => summary !== null && formatUSD(summary),
    summaryType: 'sum',
    title: 'VTC OV',
    type: 'price',
  },
  {
    name: 'tc',
    sortable: true,
    summaryRenderer: formatNumber0,
    summaryType: 'sum',
    title: 'TC',
    type: 'custom',
    value: formatNumber0,
    width: 60,
  },
  {
    name: 'tc_ov',
    sortable: true,
    summaryRenderer: summary => summary !== null && formatUSD(summary),
    summaryType: 'sum',
    title: 'TC OV',
    type: 'price',
  },
  {
    name: 'cr',
    sortable: true,
    // TODO:
    summaryRenderer: (_, summaryValues) => {
      const [, , , , , , imp, , , , , , vtc_ov] = summaryValues;
      const cr = vtc_ov! / imp!;

      return formatPercent(isFinite(cr) ? cr : 0);
    },
    title: 'CR',
    type: 'percent',
    width: 60,
  },
  {
    name: 'cpm',
    sortable: true,
    summaryRenderer: (_, summaryValues) => {
      const [, , , , spend, imp] = summaryValues;
      const cpm = spend / (imp / 1000);

      return formatUSD(isFinite(cpm) ? cpm : 0);
    },
    title: 'CPM',
    type: 'price',
    width: 60,
  },
  {
    name: 'cpc',
    sortable: true,
    summaryRenderer: (_, summaryValues) => {
      const [, , , , spend, , clk] = summaryValues;
      const cpc = spend / clk;

      return formatUSD(isFinite(cpc) ? cpc : 0);
    },
    title: 'CPC',
    type: 'price',
    width: 60,
  },
  {
    name: 'rpv',
    sortable: true,
    summaryRenderer: (_, summaryValues) => {
      const [, , , , , , clk, , , , , , , tc_ov] = summaryValues;
      const rpv = tc_ov / clk;

      return formatUSD(isFinite(rpv) ? rpv : 0);
    },
    title: 'RPV',
    type: 'price',
    width: 60,
  },
  {
    name: 'cpa',
    sortable: true,
    summaryRenderer: (_, summaryValues) => {
      const [, , , , spend, , , , , , , , tc] = summaryValues;

      const cpa = spend / tc;

      return formatUSD(isFinite(cpa) ? cpa : 0);
    },
    title: 'CPA',
    type: 'price',
    width: 60,
  },
  {
    name: 'roi',
    sortable: true,
    summaryRenderer: (_, summaryValues) => {
      const [, , , , spend, , , , , , , , , tc_ov] = summaryValues;

      const roi = tc_ov / spend;

      return formatNumber2(roi) + 'x';
    },
    title: 'ROI',
    type: 'custom',
    value: value => formatNumber2(value) + 'x',
  },
];

export const overallKpiColumns: ColumnOption[] = [
  {
    // locked: true,
    name: 'name',
    title: 'Spend',
    width: 225,
  },
  {
    // locked: true,
    name: 'value',
    title: '',
    width: 125,
  },
];

export const metricFormatter = (
  value: number,
  metricName: string,
  showPriceFraction = true,
): [string, string] => {
  const metric = metrics.find(metric => metric.value === metricName);
  const column = allMetricsColumns.find(column => column.name === metricName);

  if (!metric || !column) {
    return [String(value), metricName];
  }

  if (column.value) {
    return [column.value(value) as string, metric.label];
  }

  if (column.type === 'percent') {
    return [formatPercent(value), metric.label];
  }

  if (column.type === 'price') {
    return [formatUSD(value, showPriceFraction ? 2 : 0), metric.label];
  }

  return [String(value), metric.label];
};

export const ctcTickFormatter = (value: number) =>
  metricFormatter(value, metricsObj.ctc.value, false)[0];
export const roiTickFormatter = (value: number) =>
  metricFormatter(value, metricsObj.roi.value, false)[0];
export const cpmTickFormatter = (value: number) =>
  metricFormatter(value, metricsObj.cpm.value, false)[0];

export const formatTooltipValue = (label: string, value: number) => {
  const metricData = metrics.find(
    ({ label: metricLabel }) => metricLabel === label,
  );

  if (metricData && metricData.value) {
    return metricFormatter(value, metricData.value, false)[0];
  }

  const averageMetricData = Object.values(averageMetricsData).find(
    ({ label: metricLabel }) => metricLabel === label,
  );

  if (averageMetricData && averageMetricData.formatter) {
    return averageMetricData.formatter(value);
  }

  return value;
};

export const lineTooltipFormatter = (
  params: {
    axisValue: string;
    marker: string;
    seriesName: string;
    value: number;
  }[],
) =>
  `${tooltipDateFormatter(params[0].axisValue)}${params.map(
    ({ marker, seriesName, value }) =>
      value !== undefined
        ? `<br/>${marker} ${seriesName}: ${formatTooltipValue(
            seriesName,
            value,
          )}`
        : '',
  )}`;

export const spendTickFormatter = (value: number) =>
  metricFormatter(value, metricsObj.spend.value, false)[0];
export const tcTickFormatter = (value: number) =>
  metricFormatter(value, metricsObj.tc.value, false)[0];
