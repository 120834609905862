import { DefaultRootState } from 'react-redux';
import { Dispatch } from 'redux';

export const ACCOUNTS_LIST_REQUEST = 'ACCOUNTS_LIST_REQUEST';
export const ACCOUNTS_LIST_SUCCESS = 'ACCOUNTS_LIST_SUCCESS';
export const ACCOUNTS_LIST_ERROR = 'ACCOUNTS_LIST_ERROR';
export const ACCOUNTS_LIST_CRITERIA = 'ACCOUNTS_LIST_CRITERIA';

export const ACCOUNTS_USER_LIST_REQUEST = 'ACCOUNTS_USER_LIST_REQUEST';
export const ACCOUNTS_USER_LIST_SUCCESS = 'ACCOUNTS_USER_LIST_SUCCESS';
export const ACCOUNTS_USER_LIST_ERROR = 'ACCOUNTS_USER_LIST_ERROR';

export const ACCOUNTS_NETWORK_UPDATE_REQUEST =
  'ACCOUNTS_NETWORK_UPDATE_REQUEST';
export const ACCOUNTS_NETWORK_UPDATE_SUCCESS =
  'ACCOUNTS_NETWORK_UPDATE_SUCCESS';
export const ACCOUNTS_NETWORK_UPDATE_ERROR = 'ACCOUNTS_NETWORK_UPDATE_ERROR';

export const ACCOUNT_ITEM_REQUEST = 'ACCOUNT_ITEM_REQUEST';
export const ACCOUNT_ITEM_SUCCESS = 'ACCOUNT_ITEM_SUCCESS';
export const ACCOUNT_ITEM_ERROR = 'ACCOUNT_ITEM_ERROR';
export const ACCOUNT_ITEM_RESET = 'ACCOUNT_ITEM_RESET';

export const ACCOUNT_LABEL_REQUEST = 'ACCOUNT_LABEL_REQUEST';
export const ACCOUNT_LABEL_SUCCESS = 'ACCOUNT_LABEL_SUCCESS';
export const ACCOUNT_LABEL_ERROR = 'ACCOUNT_LABEL_ERROR';

export const ACCOUNTS_ACTION_REQUEST = 'ACCOUNTS_ACTION_REQUEST';
export const ACCOUNTS_ACTION_SUCCESS = 'ACCOUNTS_ACTION_SUCCESS';
export const ACCOUNTS_ACTION_ERROR = 'ACCOUNTS_ACTION_ERROR';

export const ACCOUNT_CREATE_REQUEST = 'ACCOUNT_CREATE_REQUEST';
export const ACCOUNT_CREATE_SUCCESS = 'ACCOUNT_CREATE_SUCCESS';
export const ACCOUNT_CREATE_ERROR = 'ACCOUNT_CREATE_ERROR';

export const ACCOUNT_UPDATE_REQUEST = 'ACCOUNT_UPDATE_REQUEST';
export const ACCOUNT_UPDATE_SUCCESS = 'ACCOUNT_UPDATE_SUCCESS';
export const ACCOUNT_UPDATE_ERROR = 'ACCOUNT_UPDATE_ERROR';

export const ACCOUNT_DELETE_REQUEST = 'ACCOUNT_DELETE_REQUEST';
export const ACCOUNT_DELETE_SUCCESS = 'ACCOUNT_DELETE_SUCCESS';
export const ACCOUNT_DELETE_ERROR = 'ACCOUNT_DELETE_ERROR';

export const ACCOUNT_GET_NETWORK_SETTINGS_REQUEST =
  'ACCOUNT_GET_NETWORK_SETTINGS_REQUEST';
export const ACCOUNT_GET_NETWORK_SETTINGS_SUCCESS =
  'ACCOUNT_GET_NETWORK_SETTINGS_SUCCESS';
export const ACCOUNT_GET_NETWORK_SETTINGS_FAILURE =
  'ACCOUNT_GET_NETWORK_SETTINGS_FAILURE';

export const ACCOUNT_UPDATE_NETWORK_SETTINGS_REQUEST =
  'ACCOUNT_UPDATE_NETWORK_SETTINGS_REQUEST';
export const ACCOUNT_UPDATE_NETWORK_SETTINGS_SUCCESS =
  'ACCOUNT_UPDATE_NETWORK_SETTINGS_SUCCESS';
export const ACCOUNT_UPDATE_NETWORK_SETTINGS_FAILURE =
  'ACCOUNT_UPDATE_NETWORK_SETTINGS_FAILURE';

export const ACCOUNTS_SELECT = 'ACCOUNTS_SELECT';

export const loadAccounts = (data: any) => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) =>
      axios.get('/admin/accounts', {
        params: data,
      }),
    requestType: 'ACCOUNTS_LIST',
  });

export const loadUserAccounts = (data: any) => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) =>
      axios.get('/core/user/accounts', {
        params: data,
      }),
    requestType: 'ACCOUNTS_USER_LIST',
  });

export const downloadOptag = () => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) => axios.post('/admin/accounts/downloadOptag'),
    requestType: 'ACCOUNTS_ACTION',
  });

export const getAccountsList = () => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) => axios.get('/core/user/accounts'),
    requestType: 'ACCOUNTS_DICTIONARY',
  });

export const uploadOptag = () => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) => axios.post('/admin/accounts/uploadOptag'),
    requestType: 'ACCOUNTS_ACTION',
    successCallback: (response: any) => response.data,
  });

export const loadAdWordsLabel =
  (adWordsId: string) => (dispatch: Dispatch<any>) =>
    dispatch({
      request: (axios: AI) =>
        axios.get('/admin/accounts/getAdWordsLabel/' + adWordsId),
      requestType: 'ACCOUNTS_ACTION',
    });

export const selectAccount = (account: any) => (dispatch: Dispatch<any>) =>
  dispatch({
    data: account,
    type: ACCOUNTS_SELECT,
  });

export const loadAccount = (id: string) => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) => axios.get(`/admin/accounts/${id}`),
    requestType: 'ACCOUNT_ITEM',
  });

export const updateNetworkAccountsItem =
  (accountId: string, networkAccountId: string, data: any) =>
  (dispatch: Dispatch<any>) =>
    dispatch({
      request: (axios: AI) =>
        axios.put(
          `/admin/accounts/${accountId}/networkaccounts/${networkAccountId}`,
          data,
        ),
      requestType: 'ACCOUNTS_NETWORK_UPDATE',
    });

export const createAccount = (data: any) => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) => axios.post('/admin/accounts', data),
    requestType: 'ACCOUNT_CREATE',
  });

export const updateAccount =
  (id: string, data: any) => (dispatch: Dispatch<any>) =>
    dispatch({
      request: (axios: AI) => axios.put(`/admin/accounts/${id}`, data),
      requestType: 'ACCOUNT_UPDATE',
    });

export const deleteAccount = (id: string) => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) => axios.delete(`/admin/accounts/${id}`),
    requestType: 'ACCOUNT_DELETE',
    successCallback: (
      response: any,
      dispatch: Dispatch<any>,
      getState: () => DefaultRootState,
    ) => {
      dispatch(
        loadAccounts({
          criteria: getState().accounts.criteria,
        }),
      );

      return {
        message: response.message,
      };
    },
  });

export const updateCriteria = (criteria: any) => ({
  criteria,
  type: ACCOUNTS_LIST_CRITERIA,
});

export const resetAccount = () => (dispatch: Dispatch<any>) =>
  dispatch({
    type: ACCOUNT_ITEM_RESET,
  });
