import CryptoJS from 'crypto-js';
import { Dispatch } from 'redux';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const logout = () => (dispatch: Dispatch<any>) => {
  localStorage.removeItem('x-auth-token');
  localStorage.removeItem('userData');

  return dispatch({
    type: LOGOUT_SUCCESS,
  });
};

export const login = (data: any) => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) => axios.post('/auth/login', data),
    requestType: 'LOGIN',
    successCallback: (data: any) => {
      const userData = CryptoJS.AES.encrypt(
        JSON.stringify(data.user),
        data.auth_token,
      ).toString();

      localStorage.setItem('x-auth-token', data.auth_token);
      localStorage.setItem('userData', userData);

      return {
        data,
      };
    },
  });

export const LOAD_PROFILE_REQUEST = 'LOAD_PROFILE_REQUEST';
export const LOAD_PROFILE_SUCCESS = 'LOAD_PROFILE_SUCCESS';
export const LOAD_PROFILE_ERROR = 'LOAD_PROFILE_ERROR';

export const loadProfile = () => (dispatch: Dispatch<any>) =>
  dispatch({
    errorCallback: (res: any) => {
      if (res.code === 10002) {
        dispatch(logout());
      }

      return res;
    },
    request: (axios: AI) => axios.get('/auth/status'),
    requestType: 'LOAD_PROFILE',
    successCallback: (res: any) => ({
      user: res.user,
    }),
  });

export const RESET_SUCCESS = 'RESET_SUCCESS';
export const RESET_REQUEST = 'RESET_REQUEST';
export const RESET_ERROR = 'RESET_ERROR';

export const resetPasswordRequest = (data: any) => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) => axios.post('/auth/reset', data),
    requestType: 'RESET',
  });

export const verifyResetToken = (data: any) => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) => axios.post('/auth/reset/verify', data),
    requestType: 'RESET_VERIFY',
  });
export const PASSWORD_UPDATE_SUCCESS = 'PASSWORD_UPDATE_SUCCESS';
export const PASSWORD_UPDATE_REQUEST = 'PASSWORD_UPDATE_REQUEST';
export const PASSWORD_UPDATE_ERROR = 'PASSWORD_UPDATE_ERROR';

export const updatePassword = (data: any) => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) => axios.post('/auth/password/update', data),
    requestType: 'PASSWORD_UPDATE',
  });
