export const EDIT_APP_CONFIGS = 'edit-app-configs';
export const MANAGE_SYSTEM_USERS = 'manage-system-users';
export const MANAGE_SYSTEM_ROLES = 'manage-system-roles';
export const EDIT_USER_ROLES = 'edit-user-roles';
export const MANAGE_ACCOUNT_USERS = 'manage-account-users';
export const MANAGE_ACCOUNTS = 'manage-accounts';
export const VIEW_REPORTINGS = 'view-reportings';
export const MANAGE_CAMPAIGNS = 'manage-campaigns';
export const MANAGE_ADS = 'manage-ads';
export const MANAGE_CAMPAIGN_DEFAULTS = 'manage-campaign-defaults';

export const ACL_PERMISSIONS = {
  [EDIT_APP_CONFIGS]: 1,
  [EDIT_USER_ROLES]: 4,
  [MANAGE_ACCOUNT_USERS]: 5,
  [MANAGE_ACCOUNTS]: 6,
  [MANAGE_ADS]: 9,
  [MANAGE_CAMPAIGN_DEFAULTS]: 10,
  [MANAGE_CAMPAIGNS]: 8,
  [MANAGE_SYSTEM_ROLES]: 3,
  [MANAGE_SYSTEM_USERS]: 2,
  [VIEW_REPORTINGS]: 7,
};
