import { useCallback, useMemo } from 'react';

import ss from '../PerformanceReport.module.scss';
import DropDownButton from '../../../../components/DropDownButton';
import { ScheduleReportConnected as ScheduleReport } from '../Schedule';
import { pdfReportTitles, pdfReportTypes } from '../constants';
import { IExport } from '../types';
import { allowed } from '../../../../helpers/permissions';
import { MANAGE_SYSTEM_USERS } from '../../../../constants/permissions';
import {
  adminReportCommentId,
  adminReportTitleId,
} from '../FutureReport/FutureReport';

export const ExportOptions = ({
  data: {
    pdfLoading,
    perms,
    downloadPdfReport,
    selectedLegends,
    spikeRatio,
    adminFutureSpendChartData,
  },
}: {
  data: IExport;
}) => {
  const exportReport = useCallback(
    (reportType: number) => {
      const admin: any = {};

      if (reportType === pdfReportTypes.SA) {
        // @ts-ignore
        const commentTextArea: HTMLTextAreaElement | null =
          document.getElementById(adminReportCommentId);

        admin.comment = commentTextArea?.value || '';

        const chartTitle: HTMLElement | null =
          document.getElementById(adminReportTitleId);

        admin.chartTitle = chartTitle?.innerText;

        admin.chartData = adminFutureSpendChartData;
      }

      downloadPdfReport({
        admin,
        reportType,
        selectedLegends,
        spikeRatio,
      });
    },
    [downloadPdfReport, selectedLegends, spikeRatio, adminFutureSpendChartData],
  );

  const exportReportTitle = useMemo(
    () => ({ title: pdfLoading ? 'Loading...' : 'Export report' }),
    [pdfLoading],
  );
  const exportReportOptions = useMemo(() => {
    const options = [
      {
        onClick: () => exportReport(pdfReportTypes.CHART),
        title: pdfReportTitles[pdfReportTypes.CHART],
      },
      {
        onClick: () => exportReport(pdfReportTypes.SNAPSHOT),
        title: pdfReportTitles[pdfReportTypes.SNAPSHOT],
      },
      {
        onClick: () => exportReport(pdfReportTypes.ADVANCED),
        title: pdfReportTitles[pdfReportTypes.ADVANCED],
      },
    ];

    if (allowed(perms, MANAGE_SYSTEM_USERS)) {
      options.push({
        onClick: () => exportReport(pdfReportTypes.SA),
        title: pdfReportTitles[pdfReportTypes.SA],
      });
    }

    return options;
  }, [perms, exportReport]);

  return (
    <div
      className={`d-flex align-items-center justify-content-between ${ss.exportBlock}`}
    >
      <ScheduleReport />
      <DropDownButton
        className={`btn btn-primary ${ss.exportBtn}`}
        items={exportReportOptions}
        selectedItem={exportReportTitle}
        isRight={true}
      />
    </div>
  );
};
