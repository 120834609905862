/**
 * AssetItemForm component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events, react/forbid-dom-props */

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Slider } from '@mui/material';
import { Field } from 'react-final-form';
import moment from 'moment';
import cx from 'classnames';
import { OnChange } from 'react-final-form-listeners';

import Tooltip from '../../components/Tooltip';
import {
  API_DATE_FORMAT,
  CAMPAIGN_TYPES_LIST,
  CAMPAIGN_TYPES_MAP,
} from '../../constants';
import FormError from '../../components/FormError';
import SelectAdapter from '../../components/SelectAdapter';
import {
  composeValidators,
  maxLength,
  minLength,
  min,
  max,
  required,
} from '../../helpers/validation';
import { format } from '../../helpers/date';
import { getNetworks } from '../../helpers/campaign';

const TODAY = format(undefined, API_DATE_FORMAT);

export default class CampaignItemForm extends PureComponent<any, any> {
  static propTypes = {
    errors: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired,
    loadCountries: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    touched: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
  };

  state = {
    ...getNetworks().reduce((result, network) => {
      result[`${network.key}_campaign_budget`] = false;

      return result;
    }, {}),
  };

  calcBudgets(values, form, name?: string) {
    let budget = parseInt(values.budget || 0);
    let enabledNetworks = getNetworks().filter(
      network => values[`network_${network.field}`],
    );

    if (enabledNetworks.length === 0) {
      return;
    }
    let customBudgetNetworks = enabledNetworks.filter(
      network => this.state[`${network.key}_campaign_budget`],
    );
    let defaultBudgetNetworks = enabledNetworks.filter(
      network => !this.state[`${network.key}_campaign_budget`],
    );
    let customBudget = customBudgetNetworks.reduce(
      (prev, network) =>
        prev + parseInt(values[`${network.key}_campaign_budget`] || 0),
      0,
    );
    let targetDefaultBudget = budget - customBudget;

    if (targetDefaultBudget < 0) {
      // budget overflow
      let overflow = 0 - targetDefaultBudget;

      targetDefaultBudget = 0;
      if (name) {
        // limit only field that was changed
        form.change(name, parseInt(values[name] || 0) - overflow);
      } else {
        // limit all fields starting from the last
        customBudgetNetworks.reverse().map(network => {
          if (overflow > 0) {
            let oldValue = parseInt(
              values[`${network.key}_campaign_budget`] || 0,
            );
            let newValue = overflow > oldValue ? 0 : oldValue - overflow;

            overflow -= oldValue - newValue;
            form.change(`${network.key}_campaign_budget`, newValue);
          }

          return network;
        });
      }
    }
    defaultBudgetNetworks.map(network =>
      form.change(
        `${network.key}_campaign_budget`,
        Math.floor(targetDefaultBudget / defaultBudgetNetworks.length),
      ),
    );
  }

  render() {
    const {
      errors,
      form,
      handleSubmit,
      initialValues,
      loadCountries,
      pristine,
      submitting,
      touched,
      values,
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <OnChange name="start_date">
          {() => {
            if (values.end_date && values.start_date > values.end_date) {
              form.change('end_date', values.start_date);
            }
          }}
        </OnChange>
        <OnChange name="end_date">
          {() => {
            if (values.start_date && values.start_date > values.end_date) {
              form.change('start_date', values.end_date);
            }
          }}
        </OnChange>
        <OnChange name="budgetrange">
          {() => {
            values.budgetrange === 'start' &&
              form.change('start_date', moment().format('yyyy-MM-DD'));
          }}
        </OnChange>
        <OnChange name="budget">
          {() => {
            this.calcBudgets(values, form);
          }}
        </OnChange>
        {getNetworks().map(network => (
          <OnChange
            key={`on_change_network_${network.field} `}
            name={`network_${network.field}`}
          >
            {() => {
              if (!values[`network_${network.field}`]) {
                form.change(`${network.key}_campaign_budget`, 0);
                this.setState({
                  [`${network.key}_campaign_budget`]: false,
                });
              }
              setTimeout(() => this.calcBudgets(values, form), 0);
            }}
          </OnChange>
        ))}

        <div className="card-body container-fluid">
          <div className="row">
            <div className="col">
              <div className="row">
                <div className="form-group required col-12 col-md-6 col-lg-4">
                  <label htmlFor="name">Name</label>
                  <Field
                    id="name"
                    name="campaign_name"
                    component="input"
                    type="text"
                    className={cx('form-control', {
                      'is-invalid':
                        touched.campaign_name && errors.campaign_name,
                    })}
                    maxLength={60}
                    validate={composeValidators(
                      required,
                      minLength(3),
                      maxLength(60),
                    )}
                  />
                  <FormError name="campaign_name" />
                </div>
              </div>
              <div className="row">
                <div className="form-group required col-12 col-md-6 col-lg-4">
                  <label htmlFor="type">
                    Type
                    <Tooltip tooltip="Campaign types determine where customers see your ads and the settings and options available to you." />
                  </label>
                  <Field
                    id="campaign_type"
                    name="campaign_type"
                    component="select"
                    className="form-control"
                    validate={required}
                    disabled={true}
                  >
                    {CAMPAIGN_TYPES_LIST.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Field>
                  <FormError name="campaign_type" />
                  <span className="form-text text-muted">
                    {CAMPAIGN_TYPES_MAP[values.campaign_type]?.description}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="form-group required col-12 col-md-6 col-lg-4">
                  <label htmlFor="budget">
                    Daily Budget
                    <Tooltip tooltip="Define how much you'd like to spend. The total budget will be distribute evenly across all networks that you have selected. You can adjust the budget per network level after the campaign have been created." />
                  </label>
                  <Field
                    id="budget"
                    name="budget"
                    component="input"
                    type="number"
                    min={0}
                    className={cx('form-control', {
                      'is-invalid': touched.budget && errors.budget,
                    })}
                    validate={composeValidators(required, min(0))}
                    multiple
                  />
                  <FormError name="budget" />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  {getNetworks().map(network => (
                    <div key={`network_${network.key}`}>
                      <div className="row">
                        <div className="col-12 col-md-6 col-lg-4 form-group">
                          <div className="form-check form-check-inline networks">
                            <Field
                              id={`network_${network.field}`}
                              name={`network_${network.field}`}
                              component="input"
                              className={cx('form-check-input', {
                                'is-invalid':
                                  touched[`network_${network.field}`] &&
                                  errors[`network_${network.field}`],
                              })}
                              type="checkbox"
                              disabled={
                                Boolean(values.id) &&
                                Boolean(
                                  initialValues[`network_${network.field}`],
                                )
                              }
                            />
                            <label
                              htmlFor={`network_${network.field}`}
                              className="form-check-label"
                            >
                              {network.name}
                            </label>
                            <FormError name={`network_${network.field}`} />
                          </div>
                        </div>
                      </div>
                      {Boolean(values[`network_${network.field}`]) && (
                        <div className="row">
                          <div className="form-group required col-12 col-md-6 col-lg-4">
                            <div className="row">
                              <div className="form-group required col-md-6 col-lg-7">
                                <Slider
                                  value={
                                    values[`${network.key}_campaign_budget`]
                                  }
                                  onChange={(event, value) =>
                                    form.change(
                                      `${network.key}_campaign_budget`,
                                      value,
                                    )
                                  }
                                  onChangeCommitted={() => {
                                    this.setState(
                                      () => ({
                                        [`${network.key}_campaign_budget`]:
                                          true,
                                      }),
                                      () =>
                                        this.calcBudgets(
                                          values,
                                          form,
                                          `${network.key}_campaign_budget`,
                                        ),
                                    );
                                  }}
                                  aria-labelledby="input-slider"
                                  min={0}
                                  max={values.budget}
                                />
                              </div>
                              <div className="form-group required col-md-6 col-lg-2">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  disabled={
                                    !this.state[
                                      `${network.key}_campaign_budget`
                                    ]
                                  }
                                  onClick={() => {
                                    this.setState(
                                      () => ({
                                        [`${network.key}_campaign_budget`]:
                                          false,
                                      }),
                                      () => this.calcBudgets(values, form),
                                    );
                                  }}
                                >
                                  Default
                                </button>
                              </div>
                              <div className="form-group required col-md-6 col-lg-3">
                                <Field
                                  id={`${network.key}_campaign_budget`}
                                  name={`${network.key}_campaign_budget`}
                                  component="input"
                                  onChange={event => {
                                    form.change(
                                      `${network.key}_campaign_budget`,
                                      event.target.value,
                                    );
                                    this.calcBudgets(values, form);
                                  }}
                                  type="number"
                                  min={0}
                                  max={values.budget}
                                  className={cx('form-control', {
                                    'is-invalid':
                                      touched[
                                        `${network.key}_campaign_budget`
                                      ] &&
                                      errors[`${network.key}_campaign_budget`],
                                  })}
                                  validate={composeValidators(
                                    required,
                                    min(0),
                                    max(values.budget),
                                  )}
                                  multiple
                                />
                              </div>
                            </div>
                            <FormError
                              name={`${network.key}_campaign_budget`}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div className="row">
                <div className="form-group col-12 col-md-6 col-lg-4">
                  <div className="form-check form-check-inline">
                    <Field
                      id="budgetrange_end"
                      name="budgetrange"
                      component="input"
                      type="radio"
                      className="form-check-input"
                      value="end"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="budgetrange_end"
                    >
                      Set a start and end date
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <Field
                      id="budgetrange_start"
                      name="budgetrange"
                      component="input"
                      type="radio"
                      className="form-check-input"
                      value="start"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="budgetrange_start"
                    >
                      Run my campaign continuously starting today
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="form-group required col-12 col-md-6 col-lg-4">
                  <label htmlFor="start_date">Start</label>
                  <Field
                    id="start_date"
                    name="start_date"
                    component="input"
                    type="date"
                    min={TODAY}
                    disabled={values.budgetrange !== 'end'}
                    className={cx('form-control', {
                      'is-invalid': touched.start_date && errors.start_date,
                    })}
                    validate={required}
                    multiple
                  />
                  <FormError name="start_date" />
                </div>
                {values.budgetrange === 'end' && (
                  <div className="form-group required col-md-6 col-lg-4">
                    <label htmlFor="start_date">End</label>
                    <Field
                      id="end_date"
                      name="end_date"
                      component="input"
                      type="date"
                      min={values.start_date}
                      className={cx('form-control', {
                        'is-invalid': touched.end_date && errors.end_date,
                      })}
                      validate={
                        values.budgetrange === 'end' ? required : undefined
                      }
                      multiple
                    />
                    <FormError name="end_date" />
                  </div>
                )}
              </div>
              <div className="row">
                <div className="form-group col-12 col-md-6 col-lg-4">
                  <label htmlFor="targeting_location">Location(s)</label>
                  <Field
                    id="targeting_location"
                    name="targeting_location"
                    component={SelectAdapter}
                    selectType="async"
                    isMulti
                    cacheOptions
                    defaultOptions
                    loadOptions={loadCountries}
                    menuPlacement="auto"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <button
                type="submit"
                className="btn btn-primary float-right"
                disabled={(pristine && !values.id) || submitting}
              >
                <i className="fas fa-running mr-2" />
                {values.id ? 'Update' : 'Create Campaign'}
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}
