import { AnyAction } from 'redux';

import * as actions from '../actions/billing.actions';

interface IBillingState {
  clientSecret: string;
  last4?: string;
  loading: boolean;
  stripeCardAdded: boolean;
}

export const INITIAL_STATE: Readonly<IBillingState> = {
  clientSecret: '',
  last4: '',
  loading: false,
  stripeCardAdded: false,
};

export default (
  state: Readonly<IBillingState> = INITIAL_STATE,
  action: AnyAction,
): Readonly<IBillingState> => {
  switch (action.type) {
    case actions.GET_CLIENT_SECRET_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case actions.GET_CLIENT_SECRET_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case actions.GET_CLIENT_SECRET_SUCCESS:
      return {
        ...state,
        clientSecret: action.data.clientSecret,
        last4: action.data.last4,
        loading: false,
        stripeCardAdded: action.data.stripeCardAdded,
      };

    default:
      return state;
  }
};
