/**
 * CampaignsListSeeMore component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */

import cx from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { RouteComponentProps } from 'react-router';

import Grid from '../../../components/Grid';
import { ColumnOption } from '../../../components/Grid/Grid.interfaces';
import Modal from '../../../components/Modal';
import {
  STATUS_ACTIVE,
  STATUS_ACTIVE2,
  STATUS_PAUSED,
  STATUS_PENDING,
  STATUS_STOPPED,
} from '../../../constants';
import { ROUTES, subRouteParams } from '../../../constants/routes';
import GroupSettings from '../GroupSettings';

export interface Props {
  data: {
    id: string;
    network_campaigns: Record<string, any>[];
  };
  marginColumnShown: boolean;
  settingsShown: boolean;
  updateAdgroupsItem: (id: string, data: Record<string, any>) => void;
  history: RouteComponentProps['history'];
}

interface State {
  adgroupDropDownOpen: boolean;
  selectedItem: Record<string, any> | null;
  showSettingsModal: boolean;
}

export class GroupsList extends PureComponent<Props, State> {
  static propTypes = {
    data: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    marginColumnShown: PropTypes.bool.isRequired,
    settingsShown: PropTypes.bool.isRequired,
    updateAdgroupsItem: PropTypes.func.isRequired,
  } as never;

  state: State = {
    adgroupDropDownOpen: false,
    selectedItem: {},
    showSettingsModal: false,
  };

  componentDidMount() {
    document.addEventListener(
      'mousedown',
      this.handleClickOutsideAdgroupDropDown,
    );
  }

  adgroupDropDownWrapperRef: any = null;

  adgroupDropDownToggleRef: any = null;

  editItem = ({ id, campaign_id }) => {
    this.props.history.push(
      subRouteParams(ROUTES.ADGROUP, { campaign_id, id }),
    );
  };

  createItem = (campaign_id, network_id) => {
    this.props.history.push(
      subRouteParams(ROUTES.ADGROUP, { campaign_id, id: 'new' }),
      {
        network_id,
      },
    );
  };

  toggleActive = ({ id, local_status }) => {
    const { updateAdgroupsItem } = this.props;

    updateAdgroupsItem(id, {
      status: local_status === STATUS_PAUSED ? STATUS_ACTIVE : STATUS_PAUSED,
    });
  };

  saveSettings = () => {
    document.getElementById('groupSettingsForm')?.dispatchEvent(
      new Event('submit', {
        bubbles: true,
        cancelable: true,
      }),
    );

    this.setState({
      selectedItem: null,
      showSettingsModal: false,
    });
  };

  showSettingsModal = selectedItem => {
    this.setState({
      selectedItem,
      showSettingsModal: true,
    });
  };

  hideSettingsModal = event => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      showSettingsModal: false,
    });
  };

  setAdgroupDropDownWrapperRef = node => {
    this.adgroupDropDownWrapperRef = node;
  };

  setAdgroupDropDownToggleRef = node => {
    this.adgroupDropDownToggleRef = node;
  };

  handleClickOutsideAdgroupDropDown = event => {
    if (
      this.adgroupDropDownWrapperRef &&
      this.adgroupDropDownToggleRef &&
      !this.adgroupDropDownWrapperRef.contains(event.target) &&
      !this.adgroupDropDownToggleRef.contains(event.target)
    ) {
      this.setState({
        adgroupDropDownOpen: false,
      });
    }
  };

  toggleAdgroupDropdown = () => {
    this.setState(({ adgroupDropDownOpen }) => ({
      adgroupDropDownOpen: !adgroupDropDownOpen,
    }));
  };

  getColumns = (): ColumnOption[] => [
    {
      maxWidth: 75,
      name: 'local_status',
      title: 'Status',
      type: 'icon',
      values: {
        [STATUS_ACTIVE]: {
          items: [
            {
              icon: 'fas fa-play-circle text-success',
              tooltip:
                'Campaign is <strong>ACTIVE</strong> and will spend up to on the budget assigned.',
              type: 'icon',
            },
            {
              action: this.toggleActive,
              icon: 'fas fa-pause',
              tooltip: 'Deactivate',
              type: 'icon',
            },
          ],
          type: 'list',
        },
        [STATUS_ACTIVE2]: {
          items: [
            {
              icon: 'fas fa-play-circle text-success',
              tooltip:
                'Campaign is <strong>ACTIVE</strong> and will spend up to on the budget assigned.',
              type: 'icon',
            },
            {
              action: this.toggleActive,
              icon: 'fas fa-pause',
              tooltip: 'Deactivate',
              type: 'icon',
            },
          ],
          type: 'list',
        },
        [STATUS_PAUSED]: {
          items: [
            {
              errorInTooltip: true,
              icon: 'fas fa-pause-circle text-danger',
              tooltip:
                'Campaign is <strong>PAUSED</strong> and will not be spending.',
              type: 'icon',
            },
            {
              action: this.toggleActive,
              condition: ({ status }) => status !== STATUS_STOPPED,
              icon: 'fas fa-play',
              tooltip: 'Activate',
              type: 'icon',
            },
            {
              condition: ({ status }) => status === STATUS_STOPPED,
              icon: 'fas fa-play disabled',
              tooltip: 'You should un-archive main campaign first.',
              type: 'icon',
            },
          ],
          type: 'list',
        },
        [STATUS_PENDING]: {
          items: [
            {
              errorInTooltip: true,
              icon: 'fas fa-spinner text-warning',
              tooltip:
                'Campaign is in <strong>PENDING</strong> state or in progress to be setup.',
              type: 'icon',
            },
            {
              action: () => {},
              icon: 'fas fa-redo-alt',
              label: 'Check Status',
              type: 'icon',
            },
          ],
          type: 'list',
        },
      },
    },
    {
      maxWidth: 75,
      name: 'network_id',
      title: 'Network',
      values: {
        1: {
          icon: 'network_icon network_icon_googleads',
          type: 'icon',
        },
        2: {
          icon: 'network_icon network_icon_dv360',
          type: 'icon',
        },
        3: {
          icon: 'network_icon network_icon_facebook',
          type: 'icon',
        },
        4: {
          icon: 'network_icon network_icon_amazon',
          type: 'icon',
        },
      },
    },
    {
      link: true,
      name: 'name',
      title: 'Name',
      type: 'custom',
      value: (value, item) => `${item?.name} (${item?.network_campaign_name})`,
    },
    // TODO:
    // @ts-ignore
    this.props.marginColumnShown && {
      maxWidth: 150,
      name: 'margin',
      sortable: true,
      title: 'Margin',
    },
    {
      className: 'text-center',
      items: [
        {
          action: this.editItem,
          icon: 'fas fa-pen-alt mr-1',
          tooltip: 'Edit item',
          type: 'icon',
        },
        this.props.settingsShown && {
          action: this.showSettingsModal,
          icon: 'fas fa-cog',
          tooltip: 'Settings',
          type: 'icon',
        },
      ],
      maxWidth: 25,
      name: 'actions',
      title: <i className="fas fa-cogs" />,
      type: 'list',
    },
  ];

  render() {
    const {
      data: { network_campaigns = [], id: campaign_id },
    } = this.props;
    const { selectedItem, showSettingsModal, adgroupDropDownOpen } = this.state;
    const AdGroups: Record<string, any>[] = [];

    network_campaigns.forEach((network_campaign = {}) =>
      AdGroups.push(
        ...(network_campaign.network_adgroups || []).map(item => ({
          ...item,
          campaign_id: network_campaign.campaign_id,
          network_campaign_name: network_campaign.name,
        })),
      ),
    );

    return (
      <div className="col-12">
        <Modal
          close={this.hideSettingsModal}
          description="Additional settings"
          title={
            selectedItem && selectedItem.network_id === 1
              ? 'AdGroup settings'
              : ' Line item settings'
          }
          show={showSettingsModal}
          submit={this.saveSettings}
          size="xl"
          submitLabel="Update"
        >
          <GroupSettings groupId={selectedItem && selectedItem.id} />
        </Modal>
        <div className="dropdown">
          <button
            type="button"
            className={cx('btn btn-primary dropdown-toggle text-white', {
              active: adgroupDropDownOpen,
            })}
            ref={this.setAdgroupDropDownToggleRef}
            onClick={this.toggleAdgroupDropdown}
          >
            <i className="far fa-user" />
            <span>Create AdGroup</span>
          </button>

          <div
            className={cx('dropdown-menu dropdown-menu-left ml-2', {
              show: adgroupDropDownOpen,
            })}
            ref={this.setAdgroupDropDownWrapperRef}
          >
            {network_campaigns.map(network => (
              <button
                key={`btn_adgreoup_create_${network.id}`}
                type="button"
                className="dropdown-item"
                tabIndex={0}
                onClick={() => this.createItem(campaign_id, network.network.id)}
              >
                <i className="fas fa-sign-out-alt mr-3" />
                {network.network.name}
              </button>
            ))}
          </div>
        </div>
        <Grid
          className="custom-card-transparent"
          columns={this.getColumns()}
          // TODO:
          criteria={{} as any}
          data={AdGroups}
          loadList={() => {}}
          // TODO:
          metadata={{} as any}
          searchable={false}
          updateCriteria={() => {}}
        />
      </div>
    );
  }
}
