import moment from 'moment';
import { AnyAction } from 'redux';

import * as actions from '../actions/dashboard.actions';

export type AdminFutureChartData = any[] | null;

const METRICS_COMPARISON_KEY_DELIMITER = 'V';

export interface IDashboardState {
  adgroups: number[];
  charts: any[];
  futureChart: any[];
  metricCharts: {
    [vsKey: string]: any[];
  };
  endDate: string;
  loading: boolean;
  metric1: string;
  metric2: string;
  records: any[];
  startDate: string;
  timezone: number;
  adminFutureSpendChartData: AdminFutureChartData;
  pdfLoading: boolean;
}

export const INITIAL_STATE: IDashboardState = {
  adgroups: [],
  adminFutureSpendChartData: null,
  charts: [],
  endDate: moment().toISOString(),
  futureChart: [],
  loading: false,
  metric1: 'imp',
  metric2: 'tc',
  metricCharts: {},
  pdfLoading: false,
  records: [],
  startDate: moment().subtract(1, 'w').startOf('d').toISOString(),
  timezone: -8,
};

export default (
  state: IDashboardState = INITIAL_STATE,
  action: AnyAction,
): Readonly<IDashboardState> => {
  switch (action.type) {
    case actions.LOAD_CHARTS_REQUEST:
    case actions.LOAD_RECORDS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case actions.LOAD_CHARTS_FAILURE:
    case actions.LOAD_RECORDS_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case actions.LOAD_CHARTS_SUCCESS:
      return {
        ...state,
        charts: action.data.report,
        loading: false,
      };

    case actions.LOAD_FUTURE_CHART_SUCCESS:
      return {
        ...state,
        futureChart: action.data.report,
      };

    case actions.LOAD_SPECIFIED_CHARTS_SUCCESS: {
      if (action.data.report) {
        const { metric1, metric2 } = action;
        const vsKey = `${metric1}${METRICS_COMPARISON_KEY_DELIMITER}${metric2}`;

        return {
          ...state,
          metricCharts: {
            ...state.metricCharts,
            [vsKey]: action.data.report,
          },
        };
      }

      return {
        ...state,
        loading: false,
      };
    }

    case actions.LOAD_RECORDS_SUCCESS:
      return {
        ...state,
        loading: false,
        records: action.data.report,
      };

    case actions.SETUP_METRICS:
      return {
        ...state,
        adgroups: [],
        metric1: action.metrics.metric1 || state.metric1,
        metric2: action.metrics.metric2 || state.metric2,
      };

    case actions.SETUP_DATE_RANGE:
      return {
        ...state,
        adgroups: [],
        endDate: action.endDate,
        startDate: action.startDate,
      };

    case actions.SETUP_ADGROUPS:
      return {
        ...state,
        adgroups: action.ids,
      };

    case actions.SET_ADMIN_FUTURE_CHART_DATA:
      return {
        ...state,
        adminFutureSpendChartData: action.chartData,
      };

    case actions.DOWNLOAD_PDF_REPORT_REQUEST:
      return {
        ...state,
        pdfLoading: true,
      };

    case actions.DOWNLOAD_PDF_REPORT_SUCCESS:
    case actions.DOWNLOAD_PDF_REPORT_FAILURE:
      return {
        ...state,
        pdfLoading: false,
      };

    default:
      return state;
  }
};
