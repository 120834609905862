/**
 * AssetItemForm component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */

import cx from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Field } from 'react-final-form';
import { Link } from 'react-router-dom';

import FormError from '../../components/FormError';
import { required } from '../../helpers/validation';
import { ROUTES } from '../../constants/routes';

export default class LoginForm extends PureComponent<any, any> {
  static propTypes = {
    errors: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    touched: PropTypes.object.isRequired,
  };

  render() {
    const { errors, handleSubmit, submitting, touched } = this.props;

    return (
      <form className="login-form" onSubmit={handleSubmit}>
        <div className="card-body container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="form-group required">
                <label htmlFor="email">Email</label>
                <Field
                  id="email"
                  name="email"
                  component="input"
                  className={cx('form-control', {
                    'is-invalid': touched.email && errors.email,
                  })}
                  maxLength={90}
                  validate={required}
                />
                <FormError name="email" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group required">
                <label htmlFor="password">Password</label>
                <Field
                  id="password"
                  name="password"
                  component="input"
                  type="password"
                  className={cx('form-control', {
                    'is-invalid': touched.password && errors.password,
                  })}
                  validate={required}
                />
                <FormError name="password" />
              </div>
              <Link to={ROUTES.FORGOT} className="text-primary">
                Forgot password
              </Link>
            </div>
          </div>
        </div>
        <div className="card-footer">
          <div className="row">
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-primary float-right"
                disabled={submitting}
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}
