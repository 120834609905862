export const GA_250X250 = 'ga250x250';
export const GA_300X250 = 'ga300x250';
export const GA_300X600 = 'ga300x600';
export const GA_728X90 = 'ga728x90';
export const GA_300X50 = 'ga300x50';
export const GA_320X50 = 'ga320x50';
export const GA_160X600 = 'ga160x600';
export const GA_120X600 = 'ga120x600';

export const ASSET_TYPES_LIST = [
  GA_250X250,
  GA_300X250,
  GA_300X600,
  GA_728X90,
  GA_300X50,
  GA_320X50,
  GA_160X600,
  GA_120X600,
] as const;

export const ASSETS_ORDER = [
  GA_250X250,
  GA_300X250,
  GA_300X600,
  GA_160X600,
  GA_728X90,
  GA_300X50,
  GA_320X50,
  GA_120X600,
] as const;

export type AssetsMap = Partial<
  Record<typeof ASSET_TYPES_LIST[number], HTMLImageElement>
>;
export type AssetsBlobsMap = Partial<
  Record<typeof ASSET_TYPES_LIST[number], Blob>
>;

interface BlockDimentions {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface AssetLayout {
  additional: BlockDimentions | null;
  background: BlockDimentions | null;
  backgroundAlt: BlockDimentions | null;
  backgroundAltCta?: boolean;
  backgroundAltAdditional?: boolean;
  cta: BlockDimentions;
  ctaFontSize?: number;
  height: number;
  id: typeof ASSET_TYPES_LIST[number];
  logo: BlockDimentions;
  title: BlockDimentions;
  width: number;
}

export const CTA_OPTIONS = [
  { label: 'Apply Now', value: 'Apply Now' },
  { label: 'Book Now', value: 'Book Now' },
  { label: 'Contact Us', value: 'Contact Us' },
  { label: 'Download', value: 'Download' },
  { label: 'Learn More', value: 'Learn More' },
  { label: 'Install', value: 'Install' },
  { label: 'Visit Site', value: 'Visit Site' },
  { label: 'Shop Now', value: 'Shop Now' },
  { label: 'Sign Up', value: 'Sign Up' },
  { label: 'Get Quote', value: 'Get Quote' },
  { label: 'Subscribe', value: 'Subscribe' },
  { label: 'See More', value: 'See More' },
];

export const CTA_SHAPE_RECTANGLE = 'rectangle';
export const CTA_SHAPE_ROUNDED_RECTANGLE = 'rounded-rectangle';

export const CTA_SHAPES_OPTIONS = [
  { label: 'Rectangle', value: CTA_SHAPE_RECTANGLE },
  { label: 'Rounded rectangle', value: CTA_SHAPE_ROUNDED_RECTANGLE },
];

export const ASSET_LAYOUTS: AssetLayout[] = [
  {
    additional: { height: 70, width: 212, x: 19, y: 119 },
    background: { height: 250, width: 250, x: 0, y: 0 },
    backgroundAlt: { height: 147, width: 250, x: 0, y: 103 },
    cta: { height: 35, width: 106, x: 75, y: 204 },
    ctaFontSize: 17,
    height: 250,
    id: GA_250X250,
    logo: { height: 75, width: 75, x: 150, y: 15 },
    title: { height: 50, width: 120, x: 19, y: 25 },
    width: 250,
  },
  {
    additional: { height: 45, width: 173, x: 19, y: 72 },
    background: { height: 250, width: 300, x: 0, y: 0 },
    backgroundAlt: { height: 125, width: 300, x: 0, y: 125 },
    cta: { height: 30, width: 93, x: 31, y: 195 },
    ctaFontSize: 15,
    height: 250,
    id: GA_300X250,
    logo: { height: 71, width: 71, x: 212, y: 17 },
    title: { height: 48, width: 173, x: 19, y: 17 },
    width: 300,
  },
  {
    additional: { height: 50, width: 260, x: 20, y: 110 },
    background: { height: 600, width: 300, x: 0, y: 0 },
    backgroundAlt: { height: 330, width: 260, x: 20, y: 181 },
    cta: { height: 35, width: 111, x: 97, y: 536 },
    height: 600,
    id: GA_300X600,
    logo: { height: 70, width: 70, x: 210, y: 20 },
    title: { height: 48, width: 176, x: 20, y: 40 },
    width: 300,
  },
  {
    additional: null,
    background: { height: 90, width: 728, x: 0, y: 0 },
    backgroundAlt: null,
    cta: { height: 30, width: 93, x: 600, y: 30 },
    ctaFontSize: 15,
    height: 90,
    id: GA_728X90,
    logo: { height: 60, width: 60, x: 21, y: 15 },
    title: { height: 44, width: 461, x: 113, y: 23 },
    width: 728,
  },
  {
    additional: null,
    background: { height: 50, width: 77, x: 223, y: 0 },
    backgroundAlt: null,
    backgroundAltCta: true,
    cta: { height: 30, width: 93, x: 202, y: 10 },
    ctaFontSize: 15,
    height: 50,
    id: GA_300X50,
    logo: { height: 46, width: 46, x: 5, y: 2 },
    title: { height: 30, width: 141, x: 55, y: 10 },
    width: 300,
  },
  {
    additional: null,
    background: { height: 50, width: 92, x: 228, y: 0 },
    backgroundAlt: null,
    backgroundAltCta: true,
    cta: { height: 30, width: 93, x: 223, y: 10 },
    ctaFontSize: 15,
    height: 50,
    id: GA_320X50,
    logo: { height: 46, width: 46, x: 5, y: 2 },
    title: { height: 30, width: 162, x: 55, y: 10 },
    width: 320,
  },
  {
    additional: { height: 77, width: 130, x: 15, y: 158 },
    background: { height: 436, width: 160, x: 0, y: 165 },
    backgroundAlt: { height: 345, width: 160, x: 0, y: 256 },
    backgroundAltAdditional: true,
    cta: { height: 30, width: 93, x: 34, y: 532 },
    ctaFontSize: 15,
    height: 600,
    id: GA_160X600,
    logo: { height: 71, width: 71, x: 44, y: 20 },
    title: { height: 48, width: 130, x: 15, y: 102 },
    width: 160,
  },
  {
    additional: null,
    background: { height: 600, width: 120, x: 0, y: 0 },
    backgroundAlt: { height: 408, width: 120, x: 0, y: 192 },
    cta: { height: 30, width: 93, x: 14, y: 519 },
    ctaFontSize: 15,
    height: 600,
    id: GA_120X600,
    logo: { height: 70, width: 70, x: 25, y: 25 },
    title: { height: 53, width: 104, x: 8, y: 116 },
    width: 120,
  },
];

export const ASSET_LAYOUTS_ORDERED = ASSET_LAYOUTS.slice(0).sort(
  (a, b) => ASSETS_ORDER.indexOf(a.id) - ASSETS_ORDER.indexOf(b.id),
);
