import ss from '../PerformanceReport.module.scss';

import { VerticalLegendItem } from './VerticalLegendItem';

const maxItemsInVerticalCol = 8;

export const makeLegendColumns = legendItems => {
  const leftLegendItems: any[] = [];
  const rightLegendItems: any[] = [];

  if (legendItems.length >= maxItemsInVerticalCol) {
    const leftColumnAmount = Math.ceil(legendItems.length / 2);

    legendItems.forEach((item, i) => {
      if (leftColumnAmount < i + 1) {
        rightLegendItems.push({ ...item, i });
      } else {
        leftLegendItems.push({ ...item, i });
      }
    });
  } else {
    leftLegendItems.push(...legendItems.map((item, i) => ({ ...item, i })));
  }

  return [leftLegendItems, rightLegendItems];
};

const LegendCol = ({ items, highlight, half = false }) => (
  <div
    className={`d-flex justify-content-start flex-column ${
      half ? ss.legendCol : ''
    }`}
  >
    <div>
      {items.map(({ name, i }) => (
        <VerticalLegendItem key={name} {...{ highlight, i, name }} />
      ))}
    </div>
  </div>
);

// eslint-disable-next-line react/no-multi-comp
export const VerticalLegend = ({
  list,
  highlight,
}: {
  list: any[];
  highlight: (name: string | number, downplay?: boolean) => void;
}) => {
  const [leftLegendItems, rightLegendItems] = makeLegendColumns([...list]);

  if (rightLegendItems.length) {
    return (
      <div className="d-flex justify-content-around mt-4 mb-3 pl-sm-3 pr-sm-3">
        <LegendCol items={leftLegendItems} highlight={highlight} half={true} />
        <LegendCol items={rightLegendItems} highlight={highlight} half={true} />
      </div>
    );
  }

  return (
    <div className="d-flex justify-content-around mt-4 mb-3 pl-sm-3 pr-sm-3">
      <LegendCol items={leftLegendItems} highlight={highlight} />
    </div>
  );
};
