import cn from 'classnames';

import { AssetsMap } from './data';
import * as data from './data';
import ss from './PreviewGrid.module.scss';

interface Props {
  assetsMap: AssetsMap;
  clearAsset: (id: string) => void;
}

const gridData = [
  [data.GA_250X250, data.GA_300X250],
  [data.GA_300X600, data.GA_728X90],
  [data.GA_300X50, data.GA_320X50],
  [data.GA_160X600, data.GA_120X600],
];

export const PreviewGrid = ({ assetsMap, clearAsset }: Props) => (
  <>
    {gridData.map(row => (
      <div key={row[0]} className="row p-3">
        {row.map(id => (
          <div key={id} className="col-6">
            <span>{id.replace(/^ga/, '')}</span>
            {assetsMap[id] ? (
              <div>
                <div className={ss.imageWrapper}>
                  <button
                    type="button"
                    className={cn('fas fa-times', ss.clearIcon)}
                    onClick={() => clearAsset(id)}
                  />
                  <img src={assetsMap[id].src} alt="" />
                </div>
              </div>
            ) : null}
          </div>
        ))}
      </div>
    ))}
  </>
);
