import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import cn from 'classnames';
import React from 'react';

import Loader from '../Loader';

import { StripeCard } from './StripeCard';
import ss from './StripeForm.module.css';

interface IProps {
  clientSecret: string;
  last4?: string;
  email: string;
  getClientSecret: (reset?: boolean) => void;
  stripeCardAdded: boolean;
}

export const StripeForm = ({
  stripeCardAdded,
  clientSecret,
  last4,
  email,
  getClientSecret,
}: IProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const [cardAdded, setCardAdded] = React.useState(false);
  const [reset, setResetCard] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    getClientSecret();
  }, [getClientSecret]);

  React.useEffect(() => {
    setCardAdded(stripeCardAdded);
  }, [stripeCardAdded]);

  const resetCard = async () => {
    setLoading(true);
    try {
      await getClientSecret(true);
      setResetCard(true);
    } catch (err) {
      // @ts-ignore
      console.error(err.message);
      alert(
        'There was an issue to handle your request. Please try later or contact administrator.',
      );
    }
    setLoading(false);
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setLoading(true);

    const card = elements.getElement(CardElement)!;

    const result = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        billing_details: {
          email,
        },
        card,
      },
    });

    if (result.error) {
      console.error(result.error.message);
    } else {
      setCardAdded(true);
      setResetCard(false);
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit} className={ss.form}>
      {loading && <Loader />}
      {cardAdded && !reset ? (
        <>
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div>
              Card successfully added{' '}
              {last4 ? (
                <span className="text-secondary">(...{last4})</span>
              ) : null}
            </div>
            <button
              className="btn btn-sm btn-primary"
              type="button"
              onClick={resetCard}
            >
              Reset
            </button>
          </div>
        </>
      ) : (
        <>
          <StripeCard />
          <button
            disabled={!stripe}
            type="submit"
            className={cn(ss.submit, 'btn btn-primary btn-sm')}
          >
            Add card
          </button>
        </>
      )}
    </form>
  );
};
