import { Dispatch } from 'redux';
import { DefaultRootState } from 'react-redux';

export const REPORTS_ACTION_REQUEST = 'REPORTS_ACTION_REQUEST';
export const REPORTS_ACTION_SUCCESS = 'REPORTS_ACTION_SUCCESS';
export const REPORTS_ACTION_ERROR = 'REPORTS_ACTION_ERROR';

export const syncAccount = (data: any) => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) => axios.post('/reporting/sync/account', data),
    requestType: 'ACCOUNTS_ACTION',
    successCallback: (response: any) => response.data,
  });
export const syncNetwork = (data: any) => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) => axios.post('/reporting/sync/network', data),
    requestType: 'ACCOUNTS_ACTION',
    successCallback: (response: any) => response.data,
  });

export const REPORTS_SCHEDULE_LIST_REQUEST = 'REPORTS_SCHEDULE_LIST_REQUEST';
export const REPORTS_SCHEDULE_LIST_SUCCESS = 'REPORTS_SCHEDULE_LIST_SUCCESS';
export const REPORTS_SCHEDULE_LIST_ERROR = 'REPORTS_SCHEDULE_LIST_ERROR';

export const getReportScheduleList =
  (account_id?: number, user_id?: number, all?: boolean) =>
  (dispatch: Dispatch<any>, getState: () => DefaultRootState) =>
    dispatch({
      request: (axios: AI) =>
        axios.get(
          `/reporting/schedule/list/${
            account_id || getState().accounts.current?.id
          }`,
          {
            params: {
              all: all ? 1 : 0,
              user_id,
            },
          },
        ),
      types: [
        REPORTS_SCHEDULE_LIST_REQUEST,
        REPORTS_SCHEDULE_LIST_SUCCESS,
        REPORTS_SCHEDULE_LIST_ERROR,
      ],
    });

export const REPORTS_SCHEDULE_GET_REQUEST = 'REPORTS_SCHEDULE_GET_REQUEST';
export const REPORTS_SCHEDULE_GET_SUCCESS = 'REPORTS_SCHEDULE_GET_SUCCESS';
export const REPORTS_SCHEDULE_GET_ERROR = 'REPORTS_SCHEDULE_GET_ERROR';

export const getReportSchedule =
  (schedule_id?: number, account_id?: number, user_id?: number) =>
  (dispatch: Dispatch<any>, getState: () => DefaultRootState) =>
    dispatch({
      request: (axios: AI) =>
        axios.get(`/reporting/schedule/${schedule_id}`, {
          params: {
            account_id: account_id || getState().accounts.current?.id,
            user_id,
          },
        }),
      types: [
        REPORTS_SCHEDULE_GET_REQUEST,
        REPORTS_SCHEDULE_GET_SUCCESS,
        REPORTS_SCHEDULE_GET_ERROR,
      ],
    });

export const REPORTS_SCHEDULE_SAVE_REQUEST = 'REPORTS_SCHEDULE_SAVE_REQUEST';
export const REPORTS_SCHEDULE_SAVE_SUCCESS = 'REPORTS_SCHEDULE_SAVE_SUCCESS';
export const REPORTS_SCHEDULE_SAVE_ERROR = 'REPORTS_SCHEDULE_SAVE_ERROR';

export const saveReportSchedule = (data: any) => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) => axios.post('/reporting/schedule', data),
    successCallback: (response: any) => ({ ...response, message: 'Saved' }),
    types: [
      REPORTS_SCHEDULE_SAVE_REQUEST,
      REPORTS_SCHEDULE_SAVE_SUCCESS,
      REPORTS_SCHEDULE_SAVE_ERROR,
    ],
  });

export const REPORTS_SCHEDULE_TEST_REQUEST = 'REPORTS_SCHEDULE_TEST_REQUEST';
export const REPORTS_SCHEDULE_TEST_SUCCESS = 'REPORTS_SCHEDULE_TEST_SUCCESS';
export const REPORTS_SCHEDULE_TEST_ERROR = 'REPORTS_SCHEDULE_TEST_ERROR';

export const testReportSchedule =
  (data: { schedule_id: number; account_id: number }) =>
  (dispatch: Dispatch<any>) =>
    dispatch({
      request: (axios: AI) => axios.post('/reporting/schedule-test', data),
      successCallback: (response: any) => ({ ...response, message: 'Sent' }),
      types: [
        REPORTS_SCHEDULE_TEST_REQUEST,
        REPORTS_SCHEDULE_TEST_SUCCESS,
        REPORTS_SCHEDULE_TEST_ERROR,
      ],
    });

export const REPORTS_SCHEDULE_RESET = 'REPORTS_SCHEDULE_RESET';

export const resetReportSchedule = () => ({
  type: REPORTS_SCHEDULE_RESET,
});

export const fbSyncAccount = (data: any) => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) => axios.post('/fb/sync/account', data),
    requestType: 'FB_SYNC_ACCOUNT',
    successCallback: (response: any) => response.data,
  });

export const nonprofitsTest = account_id => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) => axios.post('/nonprofits/test', { account_id }),
    requestType: 'ADS_TEST_CONNECTION',
    successCallback: (response: any) => response.data,
  });
