import { Image, Label, Layer, Rect, Stage, Tag, Text } from 'react-konva';

import { SimpleTextEditorChangeData } from '../../components/SimpleTextEditor';

import { AssetLayout, CTA_SHAPE_ROUNDED_RECTANGLE } from './data';

const getFontStyle = (bold: boolean, italic: boolean) =>
  Object.entries({ bold, italic })
    .filter(([, enabled]) => enabled)
    .map(([style]) => style)
    .join(' ');

interface Props {
  additionalText: SimpleTextEditorChangeData | null;
  backgroundColor: string;
  backgroundColorEnabled: boolean;
  backgroundImage: HTMLImageElement | null;
  ctaButtonColor: string;
  ctaButtonTextColor: string;
  ctaValue: string;
  ctaShape: string;
  height: number;
  layout: AssetLayout | null;
  logoImage: HTMLImageElement | null;
  stageRef: import('react').RefObject<import('konva').default.Stage>;
  titleText: SimpleTextEditorChangeData | null;
  width: number;
}

export const AssetComposerStage = ({
  additionalText,
  backgroundColor,
  backgroundColorEnabled,
  backgroundImage,
  ctaButtonColor,
  ctaButtonTextColor,
  ctaShape,
  ctaValue,
  height,
  layout,
  logoImage,
  stageRef,
  titleText,
  width,
}: Props) => {
  if (!layout) {
    return null;
  }

  const showBackground = layout.backgroundAltCta
    ? !backgroundColorEnabled
    : Boolean(backgroundImage);

  const backgroundDimensions = backgroundColorEnabled
    ? layout.backgroundAlt
    : layout.background;

  return (
    <Stage ref={stageRef} width={width} height={height}>
      <Layer>
        <Rect
          width={width}
          height={height}
          fill={
            backgroundColorEnabled
              ? backgroundColor
                ? backgroundColor
                : '#fff'
              : backgroundColor
          }
        />
        {backgroundImage && backgroundDimensions && showBackground && (
          <Image
            height={backgroundDimensions.height}
            image={backgroundImage}
            width={backgroundDimensions.width}
            x={backgroundDimensions.x}
            y={backgroundDimensions.y}
          />
        )}
        {logoImage && (
          <Image
            height={layout.logo.height}
            image={logoImage}
            width={layout.logo.width}
            x={layout.logo.x}
            y={layout.logo.y}
          />
        )}
        {titleText && (
          <Label x={layout.title.x} y={layout.title.y}>
            <Tag lineJoin="round" />
            <Text
              align="center"
              fill={titleText.fontColor}
              fontFamily={titleText.font}
              fontSize={titleText.fontSize}
              fontStyle={getFontStyle(titleText.bold, titleText.italic)}
              padding={6}
              text={titleText.text}
              textDecoration={titleText.underline ? 'underline' : ''}
              width={layout.title.width}
              height={layout.title.height}
            />
          </Label>
        )}
        {layout.additional &&
          (layout.backgroundAltAdditional ? backgroundColorEnabled : true) && (
            <Label x={layout.additional.x} y={layout.additional.y}>
              <Tag lineJoin="round" />
              {additionalText && (
                <Text
                  align="center"
                  fill={additionalText.fontColor}
                  fontFamily={additionalText.font}
                  fontSize={additionalText.fontSize}
                  fontStyle={getFontStyle(
                    additionalText.bold,
                    additionalText.italic,
                  )}
                  padding={6}
                  text={additionalText.text}
                  textDecoration={additionalText.underline ? 'underline' : ''}
                  width={layout.additional.width}
                  height={layout.additional.height}
                />
              )}
            </Label>
          )}
        {Boolean(
          ctaValue && (layout.backgroundAltCta ? backgroundColorEnabled : true),
        ) && (
          <Label x={layout.cta.x} y={layout.cta.y}>
            <Tag
              fill={ctaButtonColor}
              lineJoin="round"
              cornerRadius={ctaShape === CTA_SHAPE_ROUNDED_RECTANGLE ? 7 : 0}
            />
            <Text
              align="center"
              fill={ctaButtonTextColor}
              fontFamily={titleText ? titleText.font : 'Calibri'}
              fontSize={layout.ctaFontSize || 18}
              height={layout.cta.height}
              padding={8}
              text={ctaValue}
              width={layout.cta.width}
            />
          </Label>
        )}
      </Layer>
    </Stage>
  );
};
