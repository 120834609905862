import { AnyAction } from 'redux';

import {
  CAMPAIGN_CREATE_ERROR,
  CAMPAIGN_CREATE_REQUEST,
  CAMPAIGN_CREATE_SUCCESS,
  CAMPAIGN_ITEM_ERROR,
  CAMPAIGN_ITEM_REQUEST,
  CAMPAIGN_ITEM_RESET,
  CAMPAIGN_ITEM_SUCCESS,
  CAMPAIGN_UPDATE_ERROR,
  CAMPAIGN_UPDATE_REQUEST,
  CAMPAIGN_UPDATE_SUCCESS,
} from '../actions/campaigns.actions';
import { API_DATE_FORMAT } from '../constants';
import {
  getNetworkFieldName,
  getNetworkPrefix,
  getNetworks,
} from '../helpers/campaign';
import { format } from '../helpers/date';

interface CampaignState {
  data: {
    budget: number;
    budgetrange: string;
    campaign_type: string;
    // network_${network.field} = false;
    // ${network.key}_campaign_budget = 0;
    // ${network.key}_campaign_margin = 0.75;
    settings: any;
    start_date: string | null;
    status: number;
    targeting_languages: any[];
    targeting_location: any[];
  };
  loading: boolean;
}

export const INITIAL_STATE: Readonly<CampaignState> = {
  data: {
    budget: 0,
    budgetrange: 'end',
    campaign_type: '1',
    ...getNetworks().reduce((result, network) => {
      result[`network_${network.field}`] = false;
      result[`${network.key}_campaign_budget`] = 0;
      result[`${network.key}_campaign_margin`] = 0.75;

      return result;
    }, {}),
    settings: {
      environment: [
        {
          label: 'Web',
          value: 'ENVIRONMENT_WEB_OPTIMIZED',
        },
        {
          label: 'App',
          value: 'ENVIRONMENT_APP',
        },
      ],
      position: [
        {
          label: 'Unknown',
          value: 'ON_SCREEN_POSITION_UNKNOWN',
        },
        {
          label: 'Above the fold',
          value: 'ON_SCREEN_POSITION_ABOVE_THE_FOLD',
        },
      ],
      sensitiveCategory: [
        {
          isFixed: true,
          label: 'Adult',
          value: 'SENSITIVE_CATEGORY_ADULT',
        },
        {
          isFixed: true,
          label: 'Derogatory',
          value: 'SENSITIVE_CATEGORY_DEROGATORY',
        },
        {
          isFixed: true,
          label: 'Downloads & Sharing',
          value: 'SENSITIVE_CATEGORY_DOWNLOADS_SHARING',
        },
        {
          isFixed: true,
          label: 'Weapons',
          value: 'SENSITIVE_CATEGORY_WEAPONS',
        },
        {
          isFixed: true,
          label: 'Gambling',
          value: 'SENSITIVE_CATEGORY_GAMBLING',
        },
        {
          isFixed: true,
          label: 'Sensitive Social Issues',
          value: 'SENSITIVE_CATEGORY_SENSITIVE_SOCIAL_ISSUES',
        },
        {
          isFixed: true,
          label: 'Shocking',
          value: 'SENSITIVE_CATEGORY_SHOCKING',
        },
      ],
      viewability: {
        label: '40% or greater',
        value: 'VIEWABILITY_40_PERCENT_OR_MORE',
      },
    },
    start_date: format(undefined, API_DATE_FORMAT),
    status: 0,
    targeting_languages: [
      {
        label: 'English',
        value: '1',
      },
    ],
    targeting_location: [
      {
        label: 'United States',
        value: '1',
      },
    ],
  },
  loading: false,
};

export default (
  state: Readonly<CampaignState> = INITIAL_STATE,
  action: AnyAction,
): Readonly<CampaignState> => {
  switch (action.type) {
    case CAMPAIGN_ITEM_REQUEST:
    case CAMPAIGN_CREATE_REQUEST:
    case CAMPAIGN_UPDATE_REQUEST:
      return { ...state, loading: true };

    case CAMPAIGN_ITEM_SUCCESS: {
      const data = {
        dv360_campaign_budget: 0,
        dv360_campaign_margin: 0.75,
        googleads_campaign_budget: 0,
        googleads_campaign_margin: 0.75,
        ...action.data,
      };

      data.campaign_name = data.name;
      data.budgetrange = data.end_date ? 'end' : 'start';
      data.start_date = format(data.start_date, API_DATE_FORMAT);
      data.end_date = format(data.end_date, API_DATE_FORMAT);
      data.targeting_location = data.countries.map(({ id, name }) => ({
        label: name,
        value: id,
      }));
      data.targeting_languages = data.languages.map(({ id, name }) => ({
        label: name,
        value: id,
      }));

      data.network_campaigns.forEach(({ budget, margin, network_id }) => {
        const fieldName = getNetworkFieldName(network_id);
        const prefix = getNetworkPrefix(network_id);

        data[`network_${fieldName}`] = true;
        data[`${prefix}_campaign_budget`] = Number(budget);
        data[`${prefix}_campaign_margin`] = Number(margin);
      });

      return { ...state, data, loading: false };
    }

    case CAMPAIGN_ITEM_ERROR:
    case CAMPAIGN_CREATE_SUCCESS:
    case CAMPAIGN_CREATE_ERROR:
    case CAMPAIGN_UPDATE_SUCCESS:
    case CAMPAIGN_UPDATE_ERROR:
      return { ...state, loading: false };

    case CAMPAIGN_ITEM_RESET:
      return { ...state, ...INITIAL_STATE };

    default:
      return state;
  }
};
