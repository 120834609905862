import { AnyAction } from 'redux';

import {
  ADGROUP_CREATE_ERROR,
  ADGROUP_CREATE_REQUEST,
  ADGROUP_CREATE_SUCCESS,
  ADGROUP_ITEM_ERROR,
  ADGROUP_ITEM_REQUEST,
  ADGROUP_ITEM_RESET,
  ADGROUP_ITEM_SUCCESS,
  ADGROUP_UPDATE_ERROR,
  ADGROUP_UPDATE_REQUEST,
  ADGROUP_UPDATE_SUCCESS,
} from '../actions/adgroups.actions';
import { AdGroupStatus } from '../external/google-ads-constants';

interface AdgroupStatus {
  data: {
    settings: any;
    status: AdGroupStatus.PAUSED;
  };
  loading: boolean;
}

export const INITIAL_STATE: Readonly<AdgroupStatus> = {
  data: {
    settings: {
      conversionCounting: {
        label: 'Count All conversions',
        value: 100,
      },
      environment: [
        {
          label: 'Web',
          value: 'ENVIRONMENT_WEB_OPTIMIZED',
        },
        {
          label: 'App',
          value: 'ENVIRONMENT_APP',
        },
      ],
      margin: 0.75,
      position: [
        {
          label: 'Unknown',
          value: 'ON_SCREEN_POSITION_UNKNOWN',
        },
        {
          label: 'Above the fold',
          value: 'ON_SCREEN_POSITION_ABOVE_THE_FOLD',
        },
      ],
      postClickWindow: 30,
      postViewWindow: 7,
      viewability: {
        label: '40% or greater',
        value: 'VIEWABILITY_40_PERCENT_OR_MORE',
      },
    },
    status: AdGroupStatus.PAUSED,
  },
  loading: false,
};

export default (
  state: Readonly<AdgroupStatus> = INITIAL_STATE,
  action: AnyAction,
): Readonly<AdgroupStatus> => {
  switch (action.type) {
    case ADGROUP_ITEM_REQUEST:
    case ADGROUP_CREATE_REQUEST:
    case ADGROUP_UPDATE_REQUEST:
      return { ...state, loading: true };

    case ADGROUP_ITEM_SUCCESS: {
      const data = {
        ...action.data,
      };

      data.creative_id = data.creative
        ? {
            ...data.creative,
            label: data.creative.name,
            value: data.creative.id,
          }
        : null;
      delete data.creative;

      return { ...state, data: data, loading: false };
    }

    case ADGROUP_ITEM_ERROR:
    case ADGROUP_CREATE_SUCCESS:
    case ADGROUP_CREATE_ERROR:
    case ADGROUP_UPDATE_SUCCESS:
    case ADGROUP_UPDATE_ERROR:
      return { ...state, loading: false };

    case ADGROUP_ITEM_RESET:
      return { ...state, ...INITIAL_STATE };

    default:
      return state;
  }
};
