import moment from 'moment';
import { addHours, startOfHour } from 'date-fns';

import {
  averageAmounts,
  metricsObj,
  pdfReportTitles,
  pdfReportTypes,
  REPORT_PERIOD,
  REPORT_PERIOD_TITLES,
  spikeRatios,
} from '../constants';
import { format } from '../../../../helpers/date';
import { DATE_TIME_FORMAT } from '../../../../constants';

export const REPORT_TYPES = [
  {
    label: pdfReportTitles[pdfReportTypes.CHART],
    value: pdfReportTypes.CHART,
  },
  {
    label: pdfReportTitles[pdfReportTypes.SNAPSHOT],
    value: pdfReportTypes.SNAPSHOT,
  },
  {
    label: pdfReportTitles[pdfReportTypes.ADVANCED],
    value: pdfReportTypes.ADVANCED,
  },
];

export const REPORT_PERIODS = [
  {
    label: REPORT_PERIOD_TITLES[REPORT_PERIOD.WEEK],
    value: REPORT_PERIOD.WEEK,
  },
  {
    label: REPORT_PERIOD_TITLES[REPORT_PERIOD.MONTH],
    value: REPORT_PERIOD.MONTH,
  },
  {
    label: REPORT_PERIOD_TITLES[REPORT_PERIOD.QUARTER],
    value: REPORT_PERIOD.QUARTER,
  },
];

export const TODAY = () =>
  format(startOfHour(addHours(new Date(), 1)), DATE_TIME_FORMAT);

export const formatSchedule = reportSchedule => {
  if (!reportSchedule) {
    return reportSchedule;
  }
  const { active, id, account_id, options, period, recipients, start_date } =
    reportSchedule;

  const localeStartDate = format(new Date(start_date), DATE_TIME_FORMAT);

  return {
    account_id,
    active: Boolean(active),
    average_amounts: options.average_amounts,
    id,
    metric1: { ...metricsObj[options.metric1] } || { ...metricsObj.imp },
    metric2: { ...metricsObj[options.metric2] } || { ...metricsObj.tc },
    recipients,
    report_name: options.report_name,
    report_period: REPORT_PERIODS.find(({ value }) => value === period),
    report_type: options.report_type.map(reportTypeItem =>
      REPORT_TYPES.find(({ value }) => value === reportTypeItem),
    ),
    spikes_ratio: `${options.spikes_ratio}`,
    start_date: localeStartDate,
  };
};

export const getDefaultSchedule = (user, accountId) =>
  formatSchedule({
    account_id: accountId,
    active: true,
    options: {
      average_amounts: {
        [`_${averageAmounts[0]}`]: true,
      },
      report_type: [REPORT_TYPES[1].value],
      spikes_ratio: spikeRatios[0],
    },
    period: REPORT_PERIODS[1].value,
    recipients: [
      {
        email: user?.email || '',
        name: user ? `${user.firstname} ${user.lastname}` : '',
      },
      {
        email: '',
        name: '',
      },
    ],
    start_date: TODAY(),
  });

export const formatForSave = (
  reportScheduleParams: any,
  accountId: number,
  user?: any,
) => {
  const {
    id,
    average_amounts,
    recipients,
    report_period,
    report_type,
    report_name,
    spikes_ratio,
    start_date,
    metric1,
    metric2,
    active,
  } = reportScheduleParams;

  return {
    account_id: accountId,
    active: active ? 1 : 0,
    id,
    options: {
      average_amounts,
      metric1: metric1.value,
      metric2: metric2.value,
      report_name,
      report_type: report_type.map(({ value }) => value),
      spikes_ratio,
    },
    period: report_period.value,
    recipients: recipients.filter(item => Boolean(item.email)),
    start_date: moment(start_date).toISOString(),
    user_id: user ? user.id : null,
  };
};
