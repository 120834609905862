import { Dispatch } from 'redux';

export const ROLES_LIST_REQUEST = 'ROLES_LIST_REQUEST';
export const ROLES_LIST_SUCCESS = 'ROLES_LIST_SUCCESS';
export const ROLES_LIST_ERROR = 'ROLES_LIST_ERROR';
export const ROLES_LIST_CRITERIA = 'ROLES_LIST_CRITERIA';

export const ROLE_ITEM_REQUEST = 'ROLE_ITEM_REQUEST';
export const ROLE_ITEM_SUCCESS = 'ROLE_ITEM_SUCCESS';
export const ROLE_ITEM_ERROR = 'ROLE_ITEM_ERROR';

export const ROLE_CREATE_REQUEST = 'ROLE_CREATE_REQUEST';
export const ROLE_CREATE_SUCCESS = 'ROLE_CREATE_SUCCESS';
export const ROLE_CREATE_ERROR = 'ROLE_CREATE_ERROR';

export const ROLE_UPDATE_REQUEST = 'ROLE_UPDATE_REQUEST';
export const ROLE_UPDATE_SUCCESS = 'ROLE_UPDATE_SUCCESS';
export const ROLE_UPDATE_ERROR = 'ROLE_UPDATE_ERROR';

export const ROLE_DELETE_REQUEST = 'ROLE_DELETE_REQUEST';
export const ROLE_DELETE_SUCCESS = 'ROLE_DELETE_SUCCESS';
export const ROLE_DELETE_ERROR = 'ROLE_DELETE_ERROR';

export const loadRoles = () => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) => axios.get('/admin/acl/roles'),
    requestType: 'ROLES_LIST',
  });

export const loadRole = (id: string) => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) => axios.get(`/admin/users/${id}`),
    requestType: 'ROLE_ITEM',
  });

export const createRole = (data: any) => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) => axios.post('/admin/users', data),
    requestType: 'ROLE_CREATE',
  });

export const updateRole =
  (id: string, data: any) => (dispatch: Dispatch<any>) =>
    dispatch({
      request: (axios: AI) => axios.put(`/admin/users/${id}`, data),
      requestType: 'ROLE_UPDATE',
    });

export const deleteRole = (id: string) => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: AI) => axios.delete(`/admin/users/${id}`),
    requestType: 'ROLE_DELETE',
    successCallback: (response: any, dispatch: Dispatch<any>) => {
      dispatch(loadRoles());

      return {
        message: response.message,
      };
    },
  });

export const updateCriteria = (criteria: any) => ({
  criteria,
  type: ROLES_LIST_CRITERIA,
});
