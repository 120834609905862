import { useMemo, useState } from 'react';
import { Dialog } from '@mui/material';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

import ss from '../PerformanceReport.module.scss';

import { ScheduleForm } from './ScheduleForm';
import { formatForSave, formatSchedule, getDefaultSchedule } from './constants';
import { ISchedule } from './types';
import { ScheduleList } from './ScheduleList';

const paperComponentProps = { sx: { backgroundColor: 'rgba(0, 0, 0, 0.5)' } };

export const Schedule = ({
  accountId,
  schedule,
  list,
  getReportSchedule,
  resetReportSchedule,
  saveReportSchedule,
  testReportSchedule,
  getReportScheduleList,
  loading,
  user,
}: ISchedule) => {
  const [settingsOpen, setSettingsOpen] = useState<boolean>(false);
  const [scheduleOpen, setScheduleOpen] = useState<boolean>(false);

  const initSchedule = useMemo(() => formatSchedule(schedule), [schedule]);
  const defaultSchedule = useMemo(
    () => getDefaultSchedule(user, accountId),
    [user, accountId],
  );
  const openSchedulingSettings = () => {
    getReportScheduleList();
    setSettingsOpen(true);
  };

  const editSchedule = (id: number) => {
    getReportSchedule(id);
    setScheduleOpen(true);
  };
  const handleNew = () => {
    resetReportSchedule();
    setScheduleOpen(true);
  };
  const handleBack = () => {
    getReportScheduleList();
    setScheduleOpen(false);
  };
  const handleClose = () => {
    resetReportSchedule();
    setSettingsOpen(false);
    setScheduleOpen(false);
  };
  const handleTest = id => {
    testReportSchedule({ account_id: accountId, schedule_id: id });
  };
  const handleSubmit = reportScheduleParams => {
    saveReportSchedule(formatForSave(reportScheduleParams, accountId));

    return {};
  };

  return (
    <>
      <Dialog
        fullScreen
        open={settingsOpen}
        onClose={handleClose}
        PaperProps={paperComponentProps}
      >
        <ScheduleList
          schedules={list}
          loading={loading}
          onEditClick={editSchedule}
          onNewClick={handleNew}
          onCloseClick={handleClose}
          show={!scheduleOpen}
        />
        {scheduleOpen ? (
          <Form
            initialValues={initSchedule || defaultSchedule}
            mutators={{
              ...arrayMutators,
            }}
            onSubmit={handleSubmit}
            component={props => (
              <ScheduleForm
                {...props}
                cancel={handleClose}
                back={handleBack}
                test={handleTest}
                loading={loading}
              />
            )}
          />
        ) : null}
      </Dialog>
      <button
        type="button"
        onClick={openSchedulingSettings}
        className={`btn btn-outline-secondary mr-3 ${ss.scheduleBtn}`}
      >
        Schedule report
      </button>
    </>
  );
};
