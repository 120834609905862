import {
  getReportSchedule,
  saveReportSchedule,
  getReportScheduleList,
  resetReportSchedule,
  testReportSchedule,
} from '../../../../actions/reports.actions';
import { loadUserAccounts } from '../../../../actions/accounts.actions';

export default {
  getReportSchedule,
  getReportScheduleList,
  loadUserAccounts,
  resetReportSchedule,
  saveReportSchedule,
  testReportSchedule,
};
