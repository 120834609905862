import { AnyAction } from 'redux';

import {
  USER_CREATE_ERROR,
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_ITEM_ERROR,
  USER_ITEM_REQUEST,
  USER_ITEM_RESET,
  USER_ITEM_SUCCESS,
  USER_UPDATE_ERROR,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
} from '../actions/users.actions';

interface UserState {
  data: {};
  loading: boolean;
}

export const INITIAL_STATE: Readonly<UserState> = {
  data: {},
  loading: false,
};

export default (
  state: Readonly<UserState> = INITIAL_STATE,
  action: AnyAction,
): Readonly<UserState> => {
  switch (action.type) {
    case USER_ITEM_REQUEST:
    case USER_CREATE_REQUEST:
    case USER_UPDATE_REQUEST:
      return { ...state, loading: true };

    case USER_ITEM_SUCCESS:
      return { ...state, data: action.data, loading: false };

    case USER_UPDATE_SUCCESS:
      return { ...state, loading: false };
    case USER_ITEM_ERROR:
    case USER_CREATE_SUCCESS:
    case USER_CREATE_ERROR:
    case USER_UPDATE_ERROR:
      return { ...state, loading: false };

    case USER_ITEM_RESET:
      return { ...state, ...INITIAL_STATE };

    default:
      return state;
  }
};
