import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { EChartsInstance } from 'echarts-for-react/src/types';

import { LineGraphCompare } from '../LineGraphCompare/LineGraphCompare';
import { computeAverage } from '../chart-computations';
import { metricsObj } from '../constants';
import { future } from '../chart-settings';
import { AdminFutureChartData } from '../../../../reducers/dashboard.reducer';
import { IReportTab } from '../types';

export interface IFutureReportData extends IReportTab {
  chart: any[];
  accountId: number;
  accountName?: string;
  startDate: string;
  endDate: string;
  timezone: number;
  loadChart: (metric1: string, metric2: string) => void;
  setAdminFutureSpendChartData: (chartData: AdminFutureChartData) => void;
}

const DEFAULT_COMMENT =
  'The "Annotations" i.e. the extra lines drawn on the chart are "Financial Market" based "Technical Charting" that we apply to our digital advertising ad serving.\n' +
  'The Bright Green Line shows how we "should" spend on a calendar basis due to major "marketing holidays", based on your actual return of data learning and what our proprietary data algorithms return.\n' +
  'This is all data coming directly from your campaigns. OnePortal is simply applying "technical chart annotations" upon your existing dashboard chart data utilizing proprietary technology including AI and ML (Machine Learning).';

const onGridMouseMove = (zr, eChartObj: EChartsInstance) => (params: any) => {
  let pointInPixel = [params.offsetX, params.offsetY];

  zr.setCursorStyle(
    eChartObj.containPixel('grid', pointInPixel) ? 'copy' : 'default',
  );
};

export const adminReportCommentId = 'admin-future-report-comment';
export const adminReportTitleId = 'admin-future-report-title';

export const FutureReport = ({
  chart = [],
  accountId,
  startDate,
  endDate,
  loadChart,
  setAdminFutureSpendChartData,
  options: { setChartSelectedLegends },
}: IFutureReportData) => {
  const [eChartObj, setEChartObj] = useState<EChartsInstance | null>(null);
  const [futurePoints, setFuturePoints] = useState<any[]>([]);
  const [newFuturePoint, setNewFuturePoint] = useState<{
    i: number;
    future: number;
  } | null>(null);
  const [comment, setComment] = useState<string>(DEFAULT_COMMENT);

  useEffect(() => {
    loadChart(metricsObj.spend.value, metricsObj.tc.value);
    setFuturePoints([]);
  }, [accountId, endDate, startDate, loadChart]);

  useEffect(
    () => () => {
      setAdminFutureSpendChartData(null);
    },
    [setAdminFutureSpendChartData],
  );

  useEffect(() => {
    if (newFuturePoint) {
      const existingPointIndex = futurePoints.findIndex(
        ({ i }) => i === newFuturePoint.i,
      );

      if (existingPointIndex > -1) {
        // existingPoint.future = newFuturePoint.future;
        futurePoints.splice(existingPointIndex, 1);
        setFuturePoints([...futurePoints]);
      } else {
        setFuturePoints([...futurePoints, newFuturePoint]);
      }
      setNewFuturePoint(null);
    }
  }, [newFuturePoint, futurePoints]);
  useEffect(() => {
    if (eChartObj) {
      let zr = eChartObj.getZr();

      zr.on('click', (params: any) => {
        let pointInPixel = [params.offsetX, params.offsetY];
        let pointInGrid = eChartObj.convertFromPixel('grid', pointInPixel);

        if (eChartObj.containPixel('grid', pointInPixel)) {
          setNewFuturePoint({ future: pointInGrid[1], i: pointInGrid[0] });
        }
      });
      const mouseMoveHandler = onGridMouseMove(zr, eChartObj);

      zr.on('mousemove', mouseMoveHandler);

      return () => {
        try {
          if (zr && zr.off) {
            zr.off('click');
            mouseMoveHandler && zr.off('mousemove', mouseMoveHandler);
          }
        } catch (err) {
          // todo: investigate
        }
      };
    }
  }, [eChartObj]);

  const futureChart = useMemo(
    () =>
      computeAverage(chart, metricsObj.spend.value).map((item, index) => {
        const newItem = { ...item };
        const futurePoint = futurePoints.find(({ i }) => i === index);

        if (futurePoint) {
          newItem.future = futurePoint.future;
        }

        return newItem;
      }),
    [chart, futurePoints],
  );

  useEffect(() => {
    setAdminFutureSpendChartData(futureChart);
  }, [futureChart, setAdminFutureSpendChartData]);

  const title = `${future.series.name}`
    .replace('_s', moment(chart[0]?.reportDate).format('MMM, YYYY'))
    .replace(
      '_e',
      moment(chart[chart.length - 1]?.reportDate).format('MMM, YYYY'),
    );

  return (
    <div className="row mt-5 mb-5">
      <div className="col">
        <h3 className="text-center m-3" id={adminReportTitleId}>
          {title}
        </h3>
        <LineGraphCompare
          chartId={future.id}
          chart={futureChart}
          metrics={future.metricParams}
          setEChartExt={setEChartObj}
          legendSelected={setChartSelectedLegends}
        />

        <div className="row w-100 p-5">
          <div className="col">
            <div className="form-group">
              <label htmlFor={adminReportCommentId}>Comment</label>
              <textarea
                id={adminReportCommentId}
                className="form-control"
                value={comment}
                onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                  setComment(event.target.value);
                }}
                rows={10}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
