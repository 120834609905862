import { DefaultRootState } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { IFutureReportData } from './FutureReport';

export default createStructuredSelector<
  DefaultRootState,
  Pick<
    IFutureReportData,
    | 'chart'
    | 'accountId'
    | 'accountName'
    | 'perms'
    | 'startDate'
    | 'endDate'
    | 'timezone'
  >
>({
  accountId: store => store.accounts.current?.id,
  accountName: store => store.accounts.current?.name,
  chart: store => store.dashboard.futureChart || [],
  endDate: store => store.dashboard.endDate,
  perms: store => store.auth.user.perms,
  startDate: store => store.dashboard.startDate,
  timezone: store => store.dashboard.timezone,
});
