import moment from 'moment';
import { DefaultRootState } from 'react-redux';
import { Dispatch } from 'redux';

import { query1, query2 } from '../middleware/graphql';
import { DownloadPdfReportOptions } from '../layouts/Dashboard/PerformanceReport/types';

const datePickerEndDateToEndDate = (date: string, timezone: number) =>
  moment(date).endOf('day').utcOffset(timezone, true).format();

const datePickerStartDateToStartDate = (date: string, timezone: number) =>
  moment(date).utcOffset(timezone, true).format();

export const LOAD_CHARTS_REQUEST = 'dashboard/LOAD_CHARTS_REQUEST';
export const LOAD_CHARTS_SUCCESS = 'dashboard/LOAD_CHARTS_SUCCESS';
export const LOAD_CHARTS_FAILURE = 'dashboard/LOAD_CHARTS_FAILURE';

export const LOAD_SPECIFIED_CHARTS_REQUEST =
  'dashboard/LOAD_SPECIFIED_CHARTS_REQUEST';
export const LOAD_SPECIFIED_CHARTS_SUCCESS =
  'dashboard/LOAD_SPECIFIED_CHARTS_SUCCESS';
export const LOAD_SPECIFIED_CHARTS_FAILURE =
  'dashboard/LOAD_SPECIFIED_CHARTS_FAILURE';

export const loadCharts =
  (metric1?: string, metric2?: string, startDateSpec?: string) =>
  (dispatch: Dispatch<any>, getState: () => DefaultRootState) =>
    getState().accounts.current?.id &&
    getState().dashboard.endDate &&
    dispatch({
      graphql: true,
      metricSpecified: { metric1, metric2 },
      request: (axios: AI) =>
        axios.post(
          '/graphql',
          query2(
            getState().accounts.current?.id,
            datePickerStartDateToStartDate(
              startDateSpec || getState().dashboard.startDate,
              getState().dashboard.timezone,
            ),
            datePickerEndDateToEndDate(
              getState().dashboard.endDate,
              getState().dashboard.timezone,
            ),
            metric1 || getState().dashboard.metric1,
            metric2 || getState().dashboard.metric2,
            getState().dashboard.adgroups,
          ),
        ),
      successCallback: (res: any) => ({
        metric1,
        metric2,
        ...res,
      }),
      types: metric1
        ? [
            LOAD_SPECIFIED_CHARTS_REQUEST,
            LOAD_SPECIFIED_CHARTS_SUCCESS,
            LOAD_SPECIFIED_CHARTS_FAILURE,
          ]
        : [LOAD_CHARTS_REQUEST, LOAD_CHARTS_SUCCESS, LOAD_CHARTS_FAILURE],
    });

export const LOAD_FUTURE_CHART_REQUEST = 'dashboard/LOAD_FUTURE_CHART_REQUEST';
export const LOAD_FUTURE_CHART_SUCCESS = 'dashboard/LOAD_FUTURE_CHART_SUCCESS';
export const LOAD_FUTURE_CHART_FAILURE = 'dashboard/LOAD_FUTURE_CHART_FAILURE';

export const loadFutureChart =
  (metric1: string, metric2: string) =>
  (dispatch: Dispatch<any>, getState: () => DefaultRootState) => {
    if (!(getState().accounts.current?.id && getState().dashboard.endDate)) {
      return false;
    }

    const { startDate, endDate } = getState().dashboard;

    let startDateSpec = startDate;

    if (moment(endDate).diff(startDate, 'days') < 90) {
      startDateSpec = moment(endDate).subtract(90, 'days').format();
    }

    return dispatch({
      graphql: true,
      request: (axios: AI) =>
        axios.post(
          '/graphql',
          query2(
            getState().accounts.current?.id,
            datePickerStartDateToStartDate(
              startDateSpec,
              getState().dashboard.timezone,
            ),
            datePickerEndDateToEndDate(endDate, getState().dashboard.timezone),
            metric1,
            metric2,
            getState().dashboard.adgroups,
          ),
        ),
      types: [
        LOAD_FUTURE_CHART_REQUEST,
        LOAD_FUTURE_CHART_SUCCESS,
        LOAD_FUTURE_CHART_FAILURE,
      ],
    });
  };

export const LOAD_RECORDS_REQUEST = 'dashboard/LOAD_RECORDS_REQUEST';
export const LOAD_RECORDS_SUCCESS = 'dashboard/LOAD_RECORDS_SUCCESS';
export const LOAD_RECORDS_FAILURE = 'dashboard/LOAD_RECORDS_FAILURE';

export const loadRecords =
  () => (dispatch: Dispatch<any>, getState: () => DefaultRootState) =>
    getState().accounts.current?.id &&
    getState().dashboard.endDate &&
    dispatch({
      graphql: true,
      request: (axios: AI) =>
        axios.post(
          '/graphql',
          query1(
            getState().accounts.current?.id,
            datePickerStartDateToStartDate(
              getState().dashboard.startDate,
              getState().dashboard.timezone,
            ),
            datePickerEndDateToEndDate(
              getState().dashboard.endDate,
              getState().dashboard.timezone,
            ),
          ),
        ),
      types: [LOAD_RECORDS_REQUEST, LOAD_RECORDS_SUCCESS, LOAD_RECORDS_FAILURE],
    });

export const SETUP_METRICS = 'dashboard/SETUP_METRICS';

export const setupMetrics = (metrics: {
  metric1?: string;
  metric2?: string;
}) => ({ metrics, type: SETUP_METRICS });

export const SETUP_DATE_RANGE = 'dashboard/SETUP_DATE_RANGE';

export const setupDateRange = (startDate: Date, endDate: Date | null) => ({
  endDate: endDate ? moment(endDate).format() : null,
  startDate: moment(startDate).format(),
  type: SETUP_DATE_RANGE,
});

export const DOWNLOAD_REPORT_DAILY_REQUEST =
  'dashboard/DOWNLOAD_REPORT_DAILY_REQUEST';
export const DOWNLOAD_REPORT_DAILY_SUCCESS =
  'dashboard/DOWNLOAD_REPORT_DAILY_SUCCESS';
export const DOWNLOAD_REPORT_DAILY_FAILURE =
  'dashboard/DOWNLOAD_REPORT_DAILY_FAILURE';

export const downloadReportDaily =
  () => (dispatch: Dispatch<any>, getState: () => DefaultRootState) =>
    dispatch({
      request: (axios: AI) =>
        axios.post('/reporting/download', {
          account_id: getState().accounts.current.id,
          agids: getState().dashboard.adgroups.length
            ? getState().dashboard.adgroups
            : undefined,
          end_date: datePickerEndDateToEndDate(
            getState().dashboard.endDate,
            getState().dashboard.timezone,
          ),
          report_type: 'daily_csv',
          start_date: datePickerStartDateToStartDate(
            getState().dashboard.startDate,
            getState().dashboard.timezone,
          ),
        }),
      types: [
        DOWNLOAD_REPORT_DAILY_REQUEST,
        DOWNLOAD_REPORT_DAILY_SUCCESS,
        DOWNLOAD_REPORT_DAILY_FAILURE,
      ],
    });

export const DOWNLOAD_REPORT_DAILY_AGGREGATE_REQUEST =
  'dashboard/DOWNLOAD_REPORT_DAILY_AGGREGATE_REQUEST';
export const DOWNLOAD_REPORT_DAILY_AGGREGATE_SUCCESS =
  'dashboard/DOWNLOAD_REPORT_DAILY_AGGREGATE_SUCCESS';
export const DOWNLOAD_REPORT_DAILY_AGGREGATE_FAILURE =
  'dashboard/DOWNLOAD_REPORT_DAILY_AGGREGATE_FAILURE';

export const downloadReportDailyAggregate =
  () => (dispatch: Dispatch<any>, getState: () => DefaultRootState) =>
    dispatch({
      request: (axios: AI) =>
        axios.post('/reporting/download', {
          account_id: getState().accounts.current.id,
          agids: getState().dashboard.adgroups.length
            ? getState().dashboard.adgroups
            : undefined,
          end_date: datePickerEndDateToEndDate(
            getState().dashboard.endDate,
            getState().dashboard.timezone,
          ),
          report_type: 'xlsx',
          start_date: datePickerStartDateToStartDate(
            getState().dashboard.startDate,
            getState().dashboard.timezone,
          ),
        }),
      types: [
        DOWNLOAD_REPORT_DAILY_AGGREGATE_REQUEST,
        DOWNLOAD_REPORT_DAILY_AGGREGATE_SUCCESS,
        DOWNLOAD_REPORT_DAILY_AGGREGATE_FAILURE,
      ],
    });

export const DOWNLOAD_REPORT_AGGREGATE_REQUEST =
  'dashboard/DOWNLOAD_REPORT_AGGREGATE_REQUEST';
export const DOWNLOAD_REPORT_AGGREGATE_SUCCESS =
  'dashboard/DOWNLOAD_REPORT_AGGREGATE_SUCCESS';
export const DOWNLOAD_REPORT_AGGREGATE_FAILURE =
  'dashboard/DOWNLOAD_REPORT_AGGREGATE_FAILURE';

export const downloadReportAggregate =
  () => (dispatch: Dispatch<any>, getState: () => DefaultRootState) =>
    dispatch({
      request: (axios: AI) =>
        axios.post('/reporting/download', {
          account_id: getState().accounts.current.id,
          agids: getState().dashboard.adgroups.length
            ? getState().dashboard.adgroups
            : undefined,
          end_date: datePickerEndDateToEndDate(
            getState().dashboard.endDate,
            getState().dashboard.timezone,
          ),
          report_type: 'aggregate_csv',
          start_date: datePickerStartDateToStartDate(
            getState().dashboard.startDate,
            getState().dashboard.timezone,
          ),
        }),
      types: [
        DOWNLOAD_REPORT_AGGREGATE_REQUEST,
        DOWNLOAD_REPORT_AGGREGATE_SUCCESS,
        DOWNLOAD_REPORT_AGGREGATE_FAILURE,
      ],
    });

export const SETUP_ADGROUPS = 'dashboard/SETUP_ADGROUPS';

export const setupAdgroups = (adgroups: any[]) => ({
  ids: adgroups.map(ag => ag.adgroup_id),
  type: SETUP_ADGROUPS,
});

export const SET_ADMIN_FUTURE_CHART_DATA =
  'dashboard/SET_ADMIN_FUTURE_CHART_DATA';

export const setAdminFutureSpendChartData = (chartData: any[] | null) => ({
  chartData,
  type: SET_ADMIN_FUTURE_CHART_DATA,
});

export const DOWNLOAD_PDF_REPORT_REQUEST =
  'dashboard/DOWNLOAD_PDF_REPORT_REQUEST';
export const DOWNLOAD_PDF_REPORT_SUCCESS =
  'dashboard/DOWNLOAD_PDF_REPORT_SUCCESS';
export const DOWNLOAD_PDF_REPORT_FAILURE =
  'dashboard/DOWNLOAD_PDF_REPORT_FAILURE';

export const downloadPdfReport =
  (pdfReportOptions: DownloadPdfReportOptions) =>
  (dispatch: Dispatch<any>, getState: () => DefaultRootState) =>
    dispatch({
      request: (axios: AI) =>
        axios.post('/reporting/download/report-pdf', {
          account_id: getState().accounts.current.id,
          adgroups: getState().dashboard.adgroups,
          agids: getState().dashboard.adgroups.length
            ? getState().dashboard.adgroups
            : undefined,
          end_date: datePickerEndDateToEndDate(
            getState().dashboard.endDate,
            getState().dashboard.timezone,
          ),
          metric1: getState().dashboard.metric1,
          metric2: getState().dashboard.metric2,
          pdf_report_options: pdfReportOptions,
          start_date: datePickerStartDateToStartDate(
            getState().dashboard.startDate,
            getState().dashboard.timezone,
          ),
        }),
      types: [
        DOWNLOAD_PDF_REPORT_REQUEST,
        DOWNLOAD_PDF_REPORT_SUCCESS,
        DOWNLOAD_PDF_REPORT_FAILURE,
      ],
    });
