export enum AdGroupStatus {
  UNSPECIFIED = 0,
  UNKNOWN = 1,
  ENABLED = 2,
  PAUSED = 3,
  REMOVED = 4,
}

export enum AdType {
  IMAGE_AD = 14,
  RESPONSIVE_DISPLAY_AD = 19,
}
