import { useMemo } from 'react';

import GridTable from '../../../../components/Grid/GridTable';
import { BarChartReport } from '../BarChartReport/BarChartReport';
import { PieChartReport } from '../PieChartReport/PieChartReport';
import {
  computeBudgetSpentAdTypeData,
  computeBudgetSpentData,
  computeBudgetSpentDspData,
  computeConversionsData,
  computeOverallKpiRecords,
} from '../chart-computations';
import { overallKpiColumns } from '../report-settings';
import {
  budgetSpent,
  budgetSpentAdType,
  budgetSpentDsp,
  conversions,
} from '../chart-settings';
import ss from '../PerformanceReport.module.scss';

type ISnapshot = {
  records: any[];
};

export const Snapshot = ({ records }: ISnapshot) => {
  const overallKpiData = useMemo(
    () => computeOverallKpiRecords(records),
    [records],
  );

  // Pie Charts data
  const budgetSpentData = useMemo(
    () => computeBudgetSpentData(records),
    [records],
  );
  const budgetSpentDspData = useMemo(
    () => computeBudgetSpentDspData(records),
    [records],
  );
  const budgetSpentAdTypeData = useMemo(
    () => computeBudgetSpentAdTypeData(records),
    [records],
  );
  const conversionsData = useMemo(
    () => computeConversionsData(records),
    [records],
  );

  return (
    <div className="row mt-5 mb-5">
      <div
        className={`ml-auto col-lg d-flex flex-column justify-content-between ${ss.vLineSep}`}
      >
        <h2>Overall KPI Metrics</h2>
        <div className="grid">
          <div className="grid-table">
            <GridTable
              columns={[
                overallKpiColumns[0],
                { ...overallKpiColumns[1], title: overallKpiData[0]?.value },
              ]}
              data={overallKpiData.slice(1)}
              blueRows
            />
          </div>
        </div>

        <BarChartReport dataset={conversionsData} />
        <h3 className="text-center m-3">{conversions.series.name}</h3>
      </div>
      <div className="ml-auto col-lg pl-lg-5 d-flex flex-column justify-content-between">
        <PieChartReport
          series={{ ...budgetSpent.series, data: budgetSpentData }}
        />
        <h3 className="text-center m-3">{budgetSpent.series.name}</h3>
        <div className={ss.hLineSep} />
        <PieChartReport
          series={{ ...budgetSpentDsp.series, data: budgetSpentDspData }}
        />
        <h3 className="text-center m-3">{budgetSpentDsp.series.name}</h3>
        <div className={ss.hLineSep} />
        <PieChartReport
          series={{ ...budgetSpentAdType.series, data: budgetSpentAdTypeData }}
        />
        <h3 className="text-center m-3">{budgetSpentAdType.series.name}</h3>
      </div>
    </div>
  );
};
