/**
 * GridTableHeader component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */

import { PureComponent } from 'react';

import { ColumnOption, GridColumnsSorting } from './Grid.interfaces';
import GridTableHeaderColumn from './GridTableHeaderColumn';

interface Props {
  columns: ColumnOption[];
  onSort?: (name: string) => void;
  seemore?: boolean;
  selectable?: boolean;
  multiple?: boolean;
  sort?: GridColumnsSorting;
  selectedAll: boolean;
  toggleAll: () => void;
}

export default class GridTableHeader extends PureComponent<Props> {
  static defaultProps = {
    seemore: false,
    sort: {},
  };

  render() {
    const {
      columns,
      seemore,
      onSort,
      sort,
      selectable,
      multiple,
      selectedAll,
      toggleAll,
    } = this.props;

    return (
      <thead>
        <tr>
          {selectable && multiple && (
            <th>
              <input
                type="checkbox"
                onChange={toggleAll}
                checked={selectedAll}
              />
            </th>
          )}
          {seemore && <th className="text-center seemore" />}
          {columns.map(column => (
            <GridTableHeaderColumn
              key={column.key || column.name}
              column={column}
              onSort={onSort}
              sort={sort && sort[column.name]}
            />
          ))}
        </tr>
      </thead>
    );
  }
}
