import { AnyAction } from 'redux';

import { ACCOUNTS_SELECT } from '../actions/accounts.actions';
import {
  CAMPAIGNS_LIST_CRITERIA,
  CAMPAIGNS_LIST_ERROR,
  CAMPAIGNS_LIST_FILTER,
  CAMPAIGNS_LIST_ITEM_ERROR,
  CAMPAIGNS_LIST_ITEM_REQUEST,
  CAMPAIGNS_LIST_ITEM_SUCCESS,
  CAMPAIGNS_LIST_REQUEST,
  CAMPAIGNS_LIST_SUCCESS,
  CAMPAIGNS_NETWORK_ITEM_ERROR,
  CAMPAIGNS_NETWORK_ITEM_REQUEST,
  CAMPAIGNS_NETWORK_ITEM_SUCCESS,
  CAMPAIGNS_NETWORK_UPDATE_ERROR,
  CAMPAIGNS_NETWORK_UPDATE_REQUEST,
  CAMPAIGNS_NETWORK_UPDATE_SUCCESS,
  CAMPAIGNS_PATCH_NETWORK_ADGROUP,
  CAMPAIGN_UPDATE_ERROR,
  CAMPAIGN_UPDATE_REQUEST,
  CAMPAIGN_UPDATE_SUCCESS,
} from '../actions/campaigns.actions';
import { DEFAULT_METADATA, DEFAULT_PER_PAGE } from '../constants';
import * as actions from '../actions/campaigns.actions';

interface CampaignsState {
  criteria: any;
  data: any[];
  loading: boolean;
  metadata: any;
}

export const INITIAL_STATE: Readonly<CampaignsState> = {
  criteria: {
    include: 'network_campaigns',
    page: 1,
    perPage: DEFAULT_PER_PAGE,
    sort: {
      name: 'ASC',
    },
    where: {
      account_id: Number(localStorage.getItem('saved-account-id')),
    },
  },
  data: [],
  loading: false,
  metadata: DEFAULT_METADATA,
};

export default (
  state: Readonly<CampaignsState> = INITIAL_STATE,
  action: AnyAction,
): Readonly<CampaignsState> => {
  switch (action.type) {
    case CAMPAIGNS_LIST_REQUEST:
    case CAMPAIGNS_LIST_ITEM_REQUEST:
    case CAMPAIGNS_NETWORK_ITEM_REQUEST:
    case CAMPAIGNS_NETWORK_UPDATE_REQUEST:
    case CAMPAIGN_UPDATE_REQUEST:
      return { ...state, loading: true };

    case CAMPAIGNS_LIST_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        metadata: action.metadata,
      };

    case CAMPAIGNS_LIST_ERROR:
    case CAMPAIGNS_LIST_ITEM_ERROR:
    case CAMPAIGNS_NETWORK_ITEM_ERROR:
    case CAMPAIGNS_NETWORK_UPDATE_ERROR:
    case CAMPAIGN_UPDATE_ERROR:
      return { ...state, loading: false };

    case CAMPAIGNS_LIST_ITEM_SUCCESS:
    case CAMPAIGN_UPDATE_SUCCESS:
      return {
        ...state,
        data: state.data.map(item =>
          item.id === action.data.id ? action.data : item,
        ),
        loading: false,
      };

    case CAMPAIGNS_NETWORK_ITEM_SUCCESS:
    case CAMPAIGNS_NETWORK_UPDATE_SUCCESS:
      return {
        ...state,
        data: state.data.map(item =>
          item.id === action.data.campaign_id
            ? {
                ...item,
                network_campaigns: item.network_campaigns.map(item =>
                  item.id === action.data.id ? action.data : item,
                ),
              }
            : item,
        ),
        loading: false,
      };

    case CAMPAIGNS_LIST_CRITERIA:
      return {
        ...state,
        criteria: { ...state.criteria, ...action.criteria },
      };

    case CAMPAIGNS_LIST_FILTER:
      return {
        ...state,
        criteria: {
          ...state.criteria,
          where: {
            ...state.criteria.where,
            campaign_type: action.filter.campaign_type || undefined,
          },
        },
      };

    case ACCOUNTS_SELECT:
      return {
        ...state,
        criteria: {
          ...state.criteria,
          where: {
            ...state.criteria.where,
            account_id: Number(localStorage.getItem('saved-account-id')),
          },
        },
      };

    case actions.CAMPAIGNS_PATCH_CAMPAIGN:
      return {
        ...state,
        data: state.data.map(campaign =>
          campaign.id === action.payload.id
            ? { ...campaign, ...action.payload }
            : campaign,
        ),
      };

    case CAMPAIGNS_PATCH_NETWORK_ADGROUP:
      return {
        ...state,
        data: state.data.map(campaign =>
          campaign.network_campaigns
            ? {
                ...campaign,
                network_campaigns: campaign.network_campaigns.map(
                  network_campaign =>
                    network_campaign.network_adgroups
                      ? {
                          ...network_campaign,
                          network_adgroups:
                            network_campaign.network_adgroups.map(
                              network_adgroup =>
                                network_adgroup.id === action.payload.id
                                  ? {
                                      ...network_adgroup,
                                      ...action.payload,
                                    }
                                  : network_adgroup,
                            ),
                        }
                      : network_campaign,
                ),
              }
            : campaign,
        ),
      };

    default:
      return state;
  }
};
