/**
 * CampaignItem component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */

import deepEqual from 'deep-equal';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  createCampaign,
  loadCampaign,
  loadCountries,
  resetCampaign,
  updateCampaign,
} from '../../actions/campaigns.actions';
import { addNotify } from '../../actions/notifications.actions';
import Loader from '../../components/Loader';
import { NETWORK_DV360, NETWORK_GOOGLE_ADS } from '../../constants';
import { ROUTES } from '../../constants/routes';
import { getNetworks } from '../../helpers/campaign';

import CampaignItemForm from './CampaignForm';

class CampaignItem extends PureComponent<any, any> {
  static propTypes = {
    account: PropTypes.object,
    addNotify: PropTypes.func.isRequired,
    campaign: PropTypes.object.isRequired,
    createCampaign: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    loadCampaign: PropTypes.func.isRequired,
    loadCountries: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    resetCampaign: PropTypes.func.isRequired,
    updateCampaign: PropTypes.func.isRequired,
  };

  static defaultProps = {
    account: {},
  };

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      loadCampaign,
    } = this.props;

    id !== 'new' && loadCampaign(id);
  }

  componentDidUpdate(prevProps) {
    const { account, history } = this.props;

    if (prevProps.account && !deepEqual(account, prevProps.account)) {
      history.push(ROUTES.CAMPAIGNS);
    }
  }

  componentWillUnmount() {
    this.props.resetCampaign();
  }

  handleSubmit = async values => {
    const {
      budgetrange,
      dv360_campaign_budget,
      dv360_campaign_margin,
      googleads_campaign_budget,
      googleads_campaign_margin,
      targeting_location,
      targeting_languages,
      ...data
    } = values;

    if (budgetrange === 'start') {
      delete data.end_date;
    }

    data.targeting_languages = targeting_languages
      ? targeting_languages.map(({ value }) => value)
      : [];
    data.targeting_location = targeting_location
      ? targeting_location.map(({ value }) => value)
      : [];
    data.network = [];

    if (data.network_google) {
      data.network.push(NETWORK_GOOGLE_ADS);
      data.googleads_campaign_budget = googleads_campaign_budget;
      data.googleads_campaign_margin = googleads_campaign_margin;
    }

    if (data.network_dv360) {
      data.network.push(NETWORK_DV360);
      data.dv360_campaign_budget = dv360_campaign_budget;
      data.dv360_campaign_margin = dv360_campaign_margin;
    }

    const {
      addNotify,
      createCampaign,
      history,
      match: {
        params: { id },
      },
      updateCampaign,
    } = this.props;
    const api = id === 'new' ? createCampaign : updateCampaign;
    const params = id === 'new' ? [data] : [id, data];

    try {
      const result = await api(...params);

      if (result.error) {
        addNotify('Oops! Something went wrong!', 'error');
      } else {
        history.push(ROUTES.CAMPAIGNS);
      }

      return result;
    } catch (error) {
      addNotify('Oops! Something went wrong!', 'error');

      return error;
    }
  };

  loadCountries = async search => {
    const { data } = await this.props.loadCountries(search);

    return data.map(({ id, name }) => ({
      label: name,
      value: id,
    }));
  };

  render() {
    const { account, campaign, loading, history } = this.props;

    return (
      <div className="card border-0 page">
        <h5 className="card-header bg-primary text-white">
          <i className="fas fa-building pr-2" />
          <Link to={ROUTES.CAMPAIGNS} className="text-white">
            Campaign Manager
          </Link>
          <> / Campaign</>
        </h5>
        {loading && <Loader />}
        {account && (
          <Form
            initialValues={{
              account_id: account.id,
              ...campaign,
              ...(history.location.state || {}),
            }}
            onSubmit={this.handleSubmit}
            // @ts-ignore
            component={CampaignItemForm}
            loadCountries={this.loadCountries}
            validate={values => {
              const errors = {};
              let networksError = true;

              getNetworks().forEach(item => {
                if (values[`network_${item.field}`]) {
                  networksError = false;
                }
              });
              if (networksError) {
                getNetworks().forEach(item => {
                  errors[`network_${item.field}`] =
                    'You must select at least one network';
                });
              }

              return errors;
            }}
          />
        )}
      </div>
    );
  }
}

export default connect(
  store => ({
    account: store.accounts.current,
    campaign: store.campaign.data,
    loading: store.campaign.loading,
  }),
  {
    addNotify,
    createCampaign,
    loadCampaign,
    loadCountries,
    resetCampaign,
    updateCampaign,
  },
)(CampaignItem);
