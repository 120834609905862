/**
 * GridTableBody component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */

import { PureComponent, ReactNode } from 'react';

import { ColumnOption, GridDataItem } from './Grid.interfaces';
import GridTableBodyRow from './GridTableBodyRow';

interface Props {
  columns: ColumnOption[];
  data: GridDataItem[];
  seemore?: (row: GridDataItem) => ReactNode;
  selectable?: boolean;
  multiple?: boolean;
  selectedRows: Set<GridDataItem>;
  selectItem?: (row: GridDataItem) => void;
}

export default class GridTableBody extends PureComponent<Props> {
  static defaultProps = {
    seemore: undefined,
    selectedRows: null,
    selectItem: undefined,
  };

  render() {
    const {
      columns,
      data,
      seemore,
      selectedRows,
      selectItem,
      selectable,
      multiple,
    } = this.props;

    return (
      <tbody>
        {data.map((row, index) => (
          <GridTableBodyRow
            key={row.id || index}
            className={index % 2 ? 'even' : 'odd'}
            columns={columns}
            row={row}
            seemore={seemore}
            selected={selectedRows.has(row)}
            selectItem={selectItem}
            selectable={selectable}
            multiple={multiple}
          />
        ))}
        {!data.length && (
          <tr>
            <td
              colSpan={columns.length + (seemore ? 1 : 0)}
              className="text-center"
            >
              No data
            </td>
          </tr>
        )}
      </tbody>
    );
  }
}
