/**
 * AssetsList component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */
import deepEqual from 'deep-equal';
import arrayMutators from 'final-form-arrays';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { AdType } from '../../external/google-ads-constants';
import {
  createAsset,
  loadAsset,
  resetAsset,
  updateAsset,
} from '../../actions/assets.actions';
import { addNotify } from '../../actions/notifications.actions';
import Loader from '../../components/Loader';
import { ROUTES } from '../../constants/routes';

import AssetItemForm from './Form';

class AssetItem extends PureComponent<any, any> {
  static propTypes = {
    account: PropTypes.object,
    addNotify: PropTypes.func.isRequired,
    asset: PropTypes.object.isRequired,
    createAsset: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    loadAsset: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    resetAsset: PropTypes.func.isRequired,
    updateAsset: PropTypes.func.isRequired,
  };

  static defaultProps = {
    account: {},
  };

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      loadAsset,
    } = this.props;

    id !== 'new' && loadAsset(id);
  }

  componentDidUpdate(prevProps) {
    const { account, history } = this.props;

    if (prevProps.account && !deepEqual(account, prevProps.account)) {
      history.push(ROUTES.ASSETS);
    }
  }

  componentWillUnmount() {
    this.props.resetAsset();
  }

  handleSubmit = async values => {
    const {
      addNotify,
      createAsset,
      history,
      match: {
        params: { id },
      },
      updateAsset,
    } = this.props;
    const api = id === 'new' ? createAsset : updateAsset;
    const params = id === 'new' ? [values] : [id, values];

    try {
      const result = await api(...params);

      if (result.error) {
        addNotify('Oops! Something went wrong!', 'error');
      } else {
        history.push(ROUTES.ASSETS);
      }

      return result;
    } catch (error) {
      addNotify('Oops! Something went wrong!', 'error');

      return error;
    }
  };

  render() {
    const { account, asset, loading } = this.props;

    return (
      <div className="card border-0 page">
        <h5 className="card-header bg-primary text-white">
          <i className="fas fa-images pr-2" />
          <Link to={ROUTES.ASSETS} className="text-white">
            Asset Library
          </Link>
          <> / Asset</>
        </h5>
        {loading && <Loader />}
        {account && (
          <Form
            initialValues={{
              accountId: account.id,
              googleType: AdType.RESPONSIVE_DISPLAY_AD,
              ...asset,
              descriptions: !asset.descriptions.length
                ? ['']
                : asset.descriptions,
              shortHeadlines: !asset.shortHeadlines.length
                ? ['']
                : asset.shortHeadlines,
            }}
            onSubmit={this.handleSubmit}
            // @ts-ignore
            component={AssetItemForm}
            mutators={{
              ...arrayMutators,
            }}
            disabled={asset && asset.id && asset.hasAds}
          />
        )}
      </div>
    );
  }
}

export default connect(
  store => ({
    account: store.accounts.current,
    asset: store.asset.data,
    loading: store.asset.loading,
  }),
  (dispatch: any) => ({
    addNotify: (message, type) => dispatch(addNotify(message, type)),
    createAsset: data => dispatch(createAsset(data)),
    loadAsset: id => dispatch(loadAsset(id)),
    resetAsset: () => dispatch(resetAsset()),
    updateAsset: (id, data) => dispatch(updateAsset(id, data)),
  }),
)(AssetItem);
