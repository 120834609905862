/* eslint-disable react/forbid-dom-props */
/**
 * GridTableHeaderColumn component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */

import cx from 'classnames';
import { PureComponent } from 'react';

import { ColumnOption } from './Grid.interfaces';

interface Props {
  column: ColumnOption;
  onSort?: (name: string) => void;
  sort?: string;
}

export default class GridTableHeaderColumn extends PureComponent<Props> {
  static defaultProps = {
    sort: '',
  };

  onClick = event => {
    event.preventDefault();
    const {
      column: { name, sortable },
      onSort,
    } = this.props;

    if (sortable && onSort) {
      onSort(name);
    }
  };

  render() {
    const {
      column: { maxWidth, sortable, title, className },
      sort,
    } = this.props;

    return (
      <th
        className={cx(className, {
          sortable,
          'sortable-asc': sort === 'ASC',
          'sortable-desc': sort === 'DESC',
        })}
        style={{ maxWidth }}
        tabIndex={sortable ? 0 : undefined}
        onClick={this.onClick}
      >
        {title}
      </th>
    );
  }
}
