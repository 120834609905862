/* eslint-disable react/forbid-dom-props */
import ss from '../PerformanceReport.module.scss';
import { reportColorsSet } from '../constants';

const markerColorsAmount = reportColorsSet.length;

export const calculateColorIndex = (index: number): number => {
  let colorIndex = index + 1;

  while (colorIndex > markerColorsAmount) {
    colorIndex -= markerColorsAmount;
  }

  return colorIndex;
};

export const VerticalLegendItem = ({
  name,
  i,
  highlight,
}: {
  name: string | number;
  i: number;
  highlight: (name: string | number, downplay?: boolean) => void;
}) => {
  const onItemHover = () => highlight(name);
  const onItemBlur = () => highlight(name, true);
  const colorIndex = calculateColorIndex(i);

  return (
    <div
      key={name}
      className="w-auto d-flex align-items-center"
      onMouseEnter={onItemHover}
      onMouseLeave={onItemBlur}
    >
      <span
        className={`${ss.legendMarker} marker-color-${colorIndex}`}
        style={{ backgroundColor: reportColorsSet[colorIndex - 1] }}
      />
      <span className={ss.legendText}>{name}</span>
    </div>
  );
};
