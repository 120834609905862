import cx from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Field } from 'react-final-form';

import FormError from '../../components/FormError';
import {
  composeValidators,
  required,
  compareValues,
} from '../../helpers/validation';

export default class ResetForm extends PureComponent<any, any> {
  static propTypes = {
    errors: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    touched: PropTypes.object.isRequired,
  };

  render() {
    const { errors, handleSubmit, submitting, touched, values } = this.props;

    return (
      <form className="reset-form" onSubmit={handleSubmit}>
        <div className="modal-body container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="form-group required">
                <label htmlFor="password">New Password</label>
                <Field
                  id="password"
                  name="password"
                  component="input"
                  type="password"
                  className={cx('form-control', {
                    'is-invalid': touched.password && errors.password,
                  })}
                  validate={required}
                />
                <FormError name="password" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group required">
                <label htmlFor="passwordRepeat">Repeat Password</label>
                <Field
                  id="passwordRepeat"
                  name="passwordRepeat"
                  component="input"
                  type="password"
                  className={cx('form-control', {
                    'is-invalid':
                      touched.passwordRepeat && errors.passwordRepeat,
                  })}
                  validate={composeValidators(
                    required,
                    compareValues(
                      values,
                      'password',
                      'Passwords are not the same',
                    ),
                  )}
                />
                <FormError name="passwordRepeat" />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer bg-light">
          <div className="row">
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-primary float-right"
                disabled={submitting}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}
