/**
 * Notification component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { deleteNotify } from '../../actions/notifications.actions';

import NotificationItem from './NotificationItem';
import './styles.css';

class NotificationList extends PureComponent<any, any> {
  static propTypes = {
    deleteNotify: PropTypes.func.isRequired,
    notifications: PropTypes.array.isRequired,
  };

  deleteNotify = id => {
    this.props.deleteNotify(id);
  };

  render() {
    const { notifications } = this.props;

    return (
      <div className="notification-list">
        {notifications.map(item => (
          <NotificationItem
            deleteNotify={this.deleteNotify}
            item={item}
            key={item.id}
          />
        ))}
      </div>
    );
  }
}

export default connect(
  store => ({
    notifications: store.notifications,
  }),
  dispatch => ({
    deleteNotify: id => dispatch(deleteNotify(id)),
  }),
)(NotificationList);
