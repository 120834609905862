/**
 * Notification Item component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */

import cx from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

const hashCode = str =>
  str.split('').reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0);

export default class HistoryItem extends PureComponent<any, any> {
  static propTypes = {
    item: PropTypes.object.isRequired,
  };

  state = {
    expanded: false,
  };

  toggleExpanded = () => {
    this.setState(({ expanded }) => ({
      expanded: !expanded,
    }));
  };

  render() {
    const {
      item: { message, title },
    } = this.props;
    const { expanded } = this.state;

    return (
      <div className="history-item">
        {/*<div className="expandable-header">{moment(created).format('YYYY-MM-DD HH:mm:ss')} <b>{title}</b> by { firstname + ' ' + lastname}*/}
        <div className="expandable-header">
          {title}
          {message !== '' && (
            <button
              className="close"
              onClick={this.toggleExpanded}
              type="button"
            >
              <i
                className={cx('fas', {
                  'fa-chevron-down': !expanded,
                  'fa-chevron-up': expanded,
                })}
              />
            </button>
          )}
        </div>
        {expanded && (
          <div className="message">
            {message.split('\n').map(item => (
              <p key={hashCode(item)}>{item}</p>
            ))}
          </div>
        )}
      </div>
    );
  }
}
