import { EChartsOption } from 'echarts-for-react/src/types';

import { formatNumber2, formatUSD } from '../../../helpers/number';

import {
  averageAmounts,
  chartOptionType,
  METRICS_COMPARISON_KEY_DELIMITER,
  metricsObj,
  reportColors,
  reportColorsSet,
  spikeOptions,
} from './constants';
import { computeAverage, computeSpikes } from './chart-computations';
import {
  averageMetricsData,
  spendTickFormatter,
  tcTickFormatter,
  ctcTickFormatter,
  roiTickFormatter,
  cpmTickFormatter,
  lineTooltipFormatter,
} from './report-settings';

const averageColors = averageAmounts.reduce((colorMap, days, i) => {
  colorMap[days] = [
    reportColors.redLine,
    reportColors.lightGreenLine,
    reportColors.blueLine,
  ][i];

  return colorMap;
}, {});

// Chart default option
export const defaultOption: EChartsOption = {
  grid: {
    bottom: 60,
    left: 90,
    right: 90,
    top: 30,
  },
  legend: {
    bottom: 0,
  },
  series: [],
  tooltip: {
    formatter: lineTooltipFormatter,
    trigger: 'axis',
  },
  xAxis: {
    axisLabel: {
      fontSize: 12,
      margin: 10,
    },
    boundaryGap: false,
    data: [],
    splitLine: {
      show: true,
    },
    type: 'category',
  },
  yAxis: {
    axisLabel: {
      fontSize: 12,
      formatter: '{value}',
      margin: 10,
    },
    nameGap: 65,
    nameLocation: 'middle',
    nameTextStyle: {
      fontFamily: '"Nunito Sans", "Open Sans", sans-serif',
      fontSize: 16,
    },
    type: 'value',
  },
};

export const defaultSeries = {
  connectNulls: true,
  data: [],
  emphasis: {
    focus: 'series',
  },
  lineStyle: {
    color: '',
    width: 1,
  },
  name: '',
  smooth: true,
  symbolSize: 5,
  type: chartOptionType.line,
};

export const averageOptions = {
  lineStyle: {
    width: 5,
  },
  smooth: 0.4,
  yAxisIndex: 0,
  zlevel: 10,
};

export const thinLineOptions = {
  lineStyle: {
    width: 1,
  },
};

export const spikesOptions = {
  ...averageOptions,
  barWidth: 5,
  connectNulls: false,
  label: {
    backgroundColor: reportColors.redLine,
    borderRadius: 15,
    distance: -7,
    formatter: () => '',
    height: 15,
    position: 'top',
    show: true,
    width: 15,
  },
  type: chartOptionType.bar,
};

export const futureOptions = {
  ...averageOptions,
  cursor: 'copy',
  lineStyle: {
    width: 7,
  },
  smooth: 0.1,
};

export const defaultPieOption: EChartsOption = {
  color: reportColorsSet,
  legend: {
    bottom: 0,
    left: 'center',
    orient: 'horizontal',
  },
  series: [
    {
      avoidLabelOverlap: false,
      bottom: 30,
      center: ['50%', '50%'],
      data: [],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
          shadowOffsetX: 0,
        },
        // label: {
        //   fontSize: 40,
        //   fontWeight: 'bold',
        //   show: true,
        // },
      },
      top: 0,
      type: chartOptionType.pie,
    },
  ],
  tooltip: {
    trigger: 'item',
  },
};
export const defaultPieVertical = {
  legend: {
    show: false,
  },
  series: {
    bottom: 0,
  },
};
export const defaultBarOption = {
  color: reportColorsSet,
  dataset: {
    source: [['value', 'campaign']],
  },
  grid: {
    bottom: 5,
    height: 350,
    left: 140,
    top: 10,
  },
  legend: {},
  series: [
    {
      colorBy: 'data',
      encode: {
        x: 'value',
        y: 'campaign',
      },
      label: {
        position: 'right',
        show: true,
      },
      type: chartOptionType.bar,
    },
  ],
  tooltip: {
    axisPointer: {
      type: 'shadow',
    },
    trigger: 'axis',
  },
  xAxis: {
    axisLine: {
      show: true,
    },
    axisTick: {
      show: true,
    },
    name: '',
  },
  yAxis: {
    axisLabel: {
      overflow: 'break',
      width: 120,
    },
    axisTick: {
      alignWithLabel: true,
    },
    inverse: true,
    type: 'category',
  },
};

// Bar
export const conversions = {
  id: 'conversions',
  series: {
    name: 'Conversions By Campaign',
  },
};
export const ctcConversions = {
  id: 'ctcConversions',
  series: {
    name: 'CTC Conversions By Campaign',
  },
};

// Line
export const spentVsConv = {
  compute: chart => computeAverage(chart, metricsObj.spend.value),
  id: 'spentVsConv',
  key: `${metricsObj.spend.value}${METRICS_COMPARISON_KEY_DELIMITER}${metricsObj.tc.value}`,
  metricParams: [
    {
      formatter: spendTickFormatter,
      key: metricsObj.spend.value,
      label: metricsObj.spend.label,
      options: thinLineOptions,
    },
    {
      formatter: tcTickFormatter,
      key: metricsObj.tc.value,
      label: metricsObj.tc.label,
      options: thinLineOptions,
    },
    ...averageAmounts.map(days => ({
      color: averageColors[days],
      formatter: spendTickFormatter,
      key: `average${days}`,
      label: `${averageMetricsData.averageSpend.label}`.replace(
        `${averageAmounts[0]}`,
        `${days}`,
      ),
      options: averageOptions,
    })),
  ],
  series: {
    name: 'Total Spend vs Total Conversions',
  },
};
export const spentVsCtcConv = {
  compute: chart => computeAverage(chart, metricsObj.ctc.value),
  id: 'spentVsCtcConv',
  key: `${metricsObj.spend.value}${METRICS_COMPARISON_KEY_DELIMITER}${metricsObj.ctc.value}`,
  metricParams: [
    {
      formatter: spendTickFormatter,
      key: metricsObj.spend.value,
      label: metricsObj.spend.label,
      options: thinLineOptions,
    },
    {
      formatter: ctcTickFormatter,
      key: metricsObj.ctc.value,
      label: metricsObj.ctc.label,
      options: thinLineOptions,
    },
    ...averageAmounts.map(days => ({
      color: averageColors[days],
      formatter: ctcTickFormatter,
      key: `average${days}`,
      label: `${averageMetricsData.averageCtc.label}`.replace(
        `${averageAmounts[0]}`,
        `${days}`,
      ),
      options: { ...averageOptions, yAxisIndex: 1 },
    })),
  ],
  series: {
    name: 'Total Spend vs Total CTC Conversion Value',
  },
};
export const spentVsRoi = {
  compute: (chart, ratio) =>
    computeSpikes(
      chart,
      metricsObj.roi.value,
      ratio,
      spikeOptions.nextComparisonRatio,
      spikeOptions.compareZeroValue,
      spikeOptions.filterValue,
    ),
  id: 'spentVsRoi',
  key: `${metricsObj.spend.value}${METRICS_COMPARISON_KEY_DELIMITER}${metricsObj.roi.value}`,
  metricParams: [
    {
      formatter: spendTickFormatter,
      key: metricsObj.spend.value,
      label: metricsObj.spend.label,
      options: thinLineOptions,
    },
    {
      formatter: roiTickFormatter,
      key: metricsObj.roi.value,
      label: metricsObj.roi.label,
      options: thinLineOptions,
    },
    {
      formatter: roiTickFormatter,
      key: 'spike',
      label: averageMetricsData.roiSpikes.label,
      options: { ...spikesOptions, yAxisIndex: 1 },
    },
  ],
  series: {
    name: 'Total Spend vs Total ROI Spikes',
  },
};
export const spentVsCpm = {
  compute: chart => computeAverage(chart, metricsObj.cpm.value),
  id: 'spentVsCpm',
  key: `${metricsObj.spend.value}${METRICS_COMPARISON_KEY_DELIMITER}${metricsObj.cpm.value}`,
  metricParams: [
    {
      formatter: spendTickFormatter,
      key: metricsObj.spend.value,
      label: metricsObj.spend.label,
      options: thinLineOptions,
    },
    {
      formatter: cpmTickFormatter,
      key: metricsObj.cpm.value,
      label: metricsObj.cpm.label,
      options: thinLineOptions,
    },
    ...averageAmounts.map(days => ({
      color: averageColors[days],
      formatter: cpmTickFormatter,
      key: `average${days}`,
      label: `${averageMetricsData.averageCpm.label}`.replace(
        `${averageAmounts[0]}`,
        `${days}`,
      ),
      options: {
        ...averageOptions,
        selectedMode: days <= averageAmounts[0],
        yAxisIndex: 1,
      },
    })),
  ],
  series: {
    name: 'Total Spend vs CPM (Cost per One Thousand Impressions)',
  },
};
export const future = {
  id: 'future',
  metricParams: [
    {
      formatter: spendTickFormatter,
      key: metricsObj.spend.value,
      label: metricsObj.spend.label,
      options: thinLineOptions,
    },
    {
      formatter: tcTickFormatter,
      key: metricsObj.tc.value,
      label: metricsObj.tc.label,
      options: thinLineOptions,
    },
    {
      color: reportColors.redLine,
      formatter: spendTickFormatter,
      key: `average${averageAmounts[0]}`,
      label: averageMetricsData.averageSpend.label,
      options: averageOptions,
    },
    {
      color: reportColors.lightGreenLine,
      formatter: spendTickFormatter,
      key: 'future',
      label: averageMetricsData.futureSpend.label,
      options: { ...futureOptions, yAxisIndex: 0 },
    },
  ],
  series: {
    name: '_s / _e Future Spend Curve',
  },
};

export const metricComparison = {
  id: 'metricComparisonSeries',
  series: {
    name: 'Metric Comparison',
  },
};

// Pie
export const budgetSpent = {
  id: 'budgetSpentFunnel',
  series: {
    label: {
      formatter: ({ value }) => formatUSD(value),
    },
    name: 'Budget By Funnel',
    radius: ['15%', '70%'],
    tooltip: {
      formatter: ({ name, value, marker, seriesName }) =>
        `<div>${seriesName}</div>${marker} ${name}: <b>${formatUSD(value)}</b>`,
    },
  },
};
export const budgetSpentDsp = {
  id: 'budgetSpentDsp',
  series: {
    label: {
      formatter: ({ value }) => formatUSD(value),
    },
    name: 'Budget By DSP',
    radius: ['20%', '80%'],
    tooltip: {
      formatter: ({ name, value, marker, seriesName }) =>
        `<div>${seriesName}</div>${marker} ${name}: <b>${formatUSD(value)}</b>`,
    },
  },
};
export const budgetSpentAdType = {
  id: 'budgetSpentAdType',
  series: {
    label: {
      formatter: ({ value }) => formatUSD(value),
    },
    name: 'Budget By Ad Type',
    radius: ['23%', '65%'],
    tooltip: {
      formatter: ({ name, value, marker, seriesName }) =>
        `<div>${seriesName}</div>${marker} ${name}: <b>${formatUSD(value)}</b>`,
    },
  },
};
export const clickServed = {
  id: 'clickServedFunnel',
  series: {
    label: {
      formatter: ({ value }) => value,
    },
    name: 'Clicks By Funnel',
    radius: ['20%', '80%'],
  },
};
export const ctcRoi = {
  id: 'ctcRoiFunnel',
  series: {
    label: {
      formatter: ({ value }) => formatNumber2(value) + 'x',
    },
    name: 'CTC ROI By Funnel',
    radius: ['23%', '65%'],
    tooltip: {
      formatter: ({ name, value, marker, seriesName }) =>
        `<div>${seriesName}</div>${marker} ${name}: <b>${
          formatNumber2(value) + 'x'
        }</b>`,
    },
  },
};
export const ctcOrderValue = {
  id: 'ctcOrderValueFunnel',
  series: {
    label: {
      formatter: ({ value }) => formatUSD(value),
    },
    name: 'CTC Order Value By Campaign',
    radius: ['25%', '80%'],
    tooltip: {
      formatter: ({ name, value, marker, seriesName }) =>
        `<div>${seriesName}</div>${marker} ${name}: <b>${formatUSD(value)}</b>`,
    },
  },
};
export const budgetSpentCampaign = {
  id: 'budgetSpentCampaign',
  series: {
    bottom: 10,
    label: {
      formatter: ({ value }) => formatUSD(value),
    },
    name: 'Budget By Campaign',
    radius: ['30%', '85%'],
    tooltip: {
      formatter: ({ name, value, marker, seriesName }) =>
        `<div>${seriesName}</div>${marker} ${name}: <b>${formatUSD(value)}</b>`,
    },
  },
};
export const clicksCampaign = {
  id: 'clicksCampaign',
  series: {
    label: {
      formatter: ({ value }) => value,
    },
    name: 'Clicks By Campaign',
    radius: ['55%', '75%'],
    tooltip: {
      formatter: ({ name, value, marker, seriesName }) =>
        `<div>${seriesName}</div>${marker} ${name}: <b>${value}</b>`,
    },
  },
};
