import ReactEcharts from 'echarts-for-react';
import {
  useRef,
  useEffect,
  useState,
  useLayoutEffect,
  useCallback,
} from 'react';
import { EChartsInstance } from 'echarts-for-react/src/types';
import moment from 'moment';

import ss from '../PerformanceReport.module.scss';
import { setSelectedMetricKeysByLegend } from '../chart-computations';
import { reportColors } from '../constants';
import { defaultSeries, defaultOption } from '../chart-settings';
import { SetChartSelectedLegends } from '../types';
import { EmptyChart } from '../EmptyChart/EmptyChart';

const colors = [
  reportColors.greenLine,
  reportColors.blueLine,
  reportColors.redLine,
  reportColors.lightGreenLine,
];

type Metric = {
  key: string;
  label: string | undefined;
  formatter: (value: number) => string;
  options?: any;
  color?: string;
};

interface ILineGraph {
  chartId: string;
  chart: { reportDate: string }[];
  metrics: Metric[];
  customOptions?: any;
  setEChartExt?: (eChart: EChartsInstance | null) => void;
  renderer?: 'canvas' | 'svg';
  legendSelected?: SetChartSelectedLegends;
}

const LEGEND_SELECT_CHANGED_EVENT_NAME = 'legendselectchanged';
const dateFormatter = (date: string) => moment(date).format('MMM DD');

const generateSeriesList = (dataList: any[], metrics: Metric[]): any[] =>
  metrics.map(({ key, label, options, color }, index) => ({
    ...defaultSeries,
    ...options,
    data: dataList.map(dataItem => ({
      name: `${dataItem[key]}`,
      value: dataItem[key],
    })),
    // id: key,
    itemStyle: {
      color: color || colors[index],
    },
    lineStyle: {
      color: color || colors[index],
      width: 2,
      ...(options?.lineStyle ? options.lineStyle : {}),
    },
    name: label,
    yAxisIndex:
      options?.yAxisIndex !== undefined ? options.yAxisIndex : index % 2,
  }));

export const prepareLineChartOption = (chart, metrics, customOptions) => ({
  ...defaultOption,
  legend: {
    ...defaultOption.legend,
    data: metrics.map(({ label }) => label),
    ...customOptions?.legend,
  },
  series: generateSeriesList(chart, metrics),
  xAxis: [
    {
      ...defaultOption.xAxis,
      axisLabel: {
        ...defaultOption.xAxis.axisLabel,
        // eslint-disable-next-line no-template-curly-in-string
        formatter: dateFormatter,
      },
      data: chart.map(({ reportDate }) => reportDate),
    },
  ],
  yAxis: metrics.map(({ label, formatter }, index) => ({
    ...defaultOption.yAxis,
    alignTicks: true,
    axisLabel: {
      ...defaultOption.yAxis.axisLabel,
      // eslint-disable-next-line no-template-curly-in-string
      formatter,
    },
    // interval: 2000,
    name: label,
    nameRotate: ((index + 1) * 2 - 1) * 90,
    show: index < 2,
  })),
});

export const LineGraphCompare = ({
  chartId,
  chart,
  metrics,
  customOptions,
  setEChartExt,
  renderer = 'svg',
  legendSelected,
}: ILineGraph) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const [option, setOption] = useState(defaultOption);
  const [eChartObj, setEChartObj] = useState<EChartsInstance | null>(null);

  const handleResize = useCallback(() => {
    if (!ref.current || !eChartObj) {
      return;
    }
    const { width } = ref.current.getBoundingClientRect();

    eChartObj.resize({
      height: width / 3,
      width: width,
    });
  }, [eChartObj]);

  const handleLegendSelectChanged = useCallback(
    event => {
      if (!legendSelected) {
        return;
      }
      legendSelected(chartId, setSelectedMetricKeysByLegend(event.selected));
    },
    [chartId, legendSelected],
  );

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    setOption(prepareLineChartOption(chart, metrics, customOptions));
  }, [chart, customOptions, metrics]);

  useEffect(() => {
    if (eChartObj) {
      eChartObj.off(
        LEGEND_SELECT_CHANGED_EVENT_NAME,
        handleLegendSelectChanged,
      );
      eChartObj.on(LEGEND_SELECT_CHANGED_EVENT_NAME, handleLegendSelectChanged);
    }

    return () => {
      try {
        eChartObj &&
          eChartObj.off(
            LEGEND_SELECT_CHANGED_EVENT_NAME,
            handleLegendSelectChanged,
          );
      } catch (err) {
        console.error(err);
      }
    };
  }, [eChartObj, handleLegendSelectChanged]);

  const onChartReady = eChart => {
    setEChartObj(eChart);
    setEChartExt && setEChartExt(eChart);
  };

  useLayoutEffect(() => handleResize(), [handleResize]);

  const hideChart = !chart || chart.length === 0;

  return (
    <div className={ss.cont} ref={ref}>
      <ReactEcharts
        option={option}
        onChartReady={onChartReady}
        style={{ display: hideChart ? 'none' : '', height: 'auto' }}
        opts={{ renderer }}
      />
      <div className={hideChart ? 'd-block' : 'd-none'}>
        <EmptyChart />
      </div>
    </div>
  );
};
