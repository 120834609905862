import { max, min, sum } from 'lodash';
import { useMemo } from 'react';

import { ColumnOption } from '../Grid.interfaces';

import ss from './GridTableFooter.module.css';

interface Props {
  columns: ColumnOption[];
  data: Record<string, any>[];
  selectable?: boolean;
}

const summaryFunctions = {
  average: (data: number[]) => (data.length ? sum(data) / data.length : 0),
  count: (data: any[]) => data.length,
  max,
  min,
  sum,
} as const;

export const GridTableFooter = ({ columns, data, selectable }: Props) => {
  const showFooter = useMemo(
    () =>
      Boolean(data.length) &&
      columns.some(column =>
        Boolean(column.summaryType || column.summaryRenderer),
      ),
    [columns, data],
  );

  const summaryValues = useMemo(
    () =>
      columns.map(col => {
        if (!col.summaryType) {
          return NaN;
        }

        const summaryFunction = summaryFunctions[col.summaryType];

        const columnSummary: number = summaryFunction(
          data.map(item => item[col.name]),
        );

        return columnSummary;
      }),
    [columns, data],
  );

  const summaryValuesToRender = useMemo(
    () =>
      columns.map((col, idx) => {
        const columnSummary = summaryValues[idx];

        return col.summaryRenderer
          ? col.summaryRenderer(columnSummary, summaryValues)
          : isFinite(columnSummary)
          ? columnSummary
          : null;
      }),
    [summaryValues, columns],
  );

  if (!showFooter) {
    return null;
  }

  return (
    <tbody>
      <tr className={ss.summary}>
        {selectable && <td />}
        {columns.map((column, idx) => (
          <td key={column.name}>{summaryValuesToRender[idx]}</td>
        ))}
      </tr>
    </tbody>
  );
};
