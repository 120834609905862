/**
 * CampaignItem component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */

import deepEqual from 'deep-equal';
import arrayMutators from 'final-form-arrays';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  createItem,
  loadItem,
  loadTopics,
  loadUserList,
  resetItem,
  updateItem,
} from '../../actions/adgroups.actions';
import { loadAssets } from '../../actions/assets.actions';
import { addNotify } from '../../actions/notifications.actions';
import Loader from '../../components/Loader';
import { ROUTES } from '../../constants/routes';

import AdgroupItemForm from './GroupForm';

class AdgroupItem extends PureComponent<any, any> {
  static propTypes = {
    account: PropTypes.object,
    addNotify: PropTypes.func.isRequired,
    adgroup: PropTypes.object.isRequired,
    createItem: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    loadAssets: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    loadItem: PropTypes.func.isRequired,
    loadTopics: PropTypes.func.isRequired,
    loadUserList: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    resetItem: PropTypes.func.isRequired,
    updateItem: PropTypes.func.isRequired,
  };

  static defaultProps = {
    account: {},
  };

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      loadItem,
    } = this.props;

    id !== 'new' && loadItem(id);
  }

  componentDidUpdate(prevProps) {
    const { account, history } = this.props;

    if (prevProps.account && !deepEqual(account, prevProps.account)) {
      history.push(ROUTES.CAMPAIGNS);
    }
  }

  componentWillUnmount() {
    this.props.resetItem();
  }

  handleSubmit = async values => {
    const { creative_id, ...data } = values;
    /*const {
            settings: {
                keywords,
                location,
                verticals,
                bid,
                viewability,
                url,
                environment,
                position,
                schedule,
            } = {},
            ...data
        } = values;

        set(data, 'settings.bid', bid ? bid : null);
        set(data, 'settings.url', url ? url : null);
        set(data, 'settings.viewability', viewability ? viewability.value : null);
        set(data, 'settings.keywords', keywords ? keywords.map(({ value }) => value) : []);
        set(data, 'settings.location', location ? location.map(({ value }) => value) : []);
        set(data, 'settings.verticals', verticals ? verticals.map(({ value }) => value) : []);
        set(data, 'settings.environment', environment ? environment.map(({ value }) => value) : []);
        set(data, 'settings.position', position ? position.map(({ value }) => value) : []);
        set(data, 'settings.schedule', schedule ? schedule.map((item) => ({
            dayOfWeek: item.dayOfWeek.value,
            endHour: item.endHour.value,
            startHour: item.startHour.value,
        })) : []);*/

    const {
      addNotify,
      createItem,
      history,
      match: {
        params: { id, campaign_id },
      },
      updateItem,
    } = this.props;

    data.campaign_id = campaign_id;
    data.creative_id =
      creative_id && creative_id.value ? creative_id.value : null;
    const api = id === 'new' ? createItem : updateItem;
    const params = id === 'new' ? [campaign_id, data] : [id, data];

    try {
      const result = await api(...params);

      if (result.error) {
        addNotify('Oops! Something went wrong!', 'error');
      } else {
        history.push(ROUTES.CAMPAIGNS);
      }

      return result;
    } catch (error) {
      addNotify('Oops! Something went wrong!', 'error');

      return error;
    }
  };

  loadTopics = async search => {
    const { data } = await this.props.loadTopics(search);

    return data.map(({ id, name }) => ({
      label: name,
      value: id,
    }));
  };

  loadAssets = fields => async search => {
    const { data } = await this.props.loadAssets({
      criteria: {
        perPage: 25,
        where: {
          search,
          ...fields,
        },
      },
    });

    return data.map(item => ({
      ...item,
      label: item.name,
      value: item.id,
    }));
  };

  loadUserList = async search => {
    const { account } = this.props;
    const { data } = await this.props.loadUserList(account.id, search);

    return data.map(({ resource_name, name }) => ({
      label: name,
      value: resource_name,
    }));
  };

  render() {
    const { account, adgroup, loading, history } = this.props;
    const historyState = history.location.state || {};

    return (
      <div className="card border-0 page">
        <h5 className="card-header bg-primary text-white">
          <i className="fas fa-building pr-2" />
          <Link to={ROUTES.CAMPAIGNS} className="text-white">
            Campaign Manager
          </Link>
          <> / AdGroup</>
        </h5>
        {loading && <Loader />}
        {account && (
          <Form
            initialValues={{
              account_id: account.id,
              network_id: historyState.network_id,
              ...adgroup,
            }}
            onSubmit={this.handleSubmit}
            // @ts-ignore
            component={AdgroupItemForm}
            loadAssets={this.loadAssets}
            loadTopics={this.loadTopics}
            loadUserList={this.loadUserList}
            mutators={{
              ...arrayMutators,
            }}
          />
        )}
      </div>
    );
  }
}

export default connect(
  store => ({
    account: store.accounts.current,
    adgroup: store.adgroup.data,
    loading: store.adgroup.loading,
  }),
  {
    addNotify,
    createItem,
    loadAssets,
    loadItem,
    loadTopics,
    loadUserList,
    resetItem,
    updateItem,
  },
)(AdgroupItem);
