/**
 * AssetsList component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */
import deepEqual from 'deep-equal';
import arrayMutators from 'final-form-arrays';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { accountOptionsSelector, roleOptions } from '../../selectors/user';
import { addNotify } from '../../actions/notifications.actions';
import {
  createUser,
  loadUser,
  resetUser,
  updateUser,
} from '../../actions/users.actions';
import Loader from '../../components/Loader';
import { ROUTES } from '../../constants/routes';

import ReportSchedule from './ReportSchedule';
import { UserItemFormConnected } from './Form';

class UserItem extends PureComponent<any, any> {
  static propTypes = {
    account: PropTypes.object,
    accountOptions: PropTypes.array.isRequired,
    addNotify: PropTypes.func.isRequired,
    createUser: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    loadUser: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    resetUser: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
  };

  static defaultProps = {
    account: {},
  };

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      loadUser,
    } = this.props;

    id !== 'new' && loadUser(id);
  }

  componentDidUpdate(prevProps) {
    const { account, history } = this.props;

    if (prevProps.account && !deepEqual(account, prevProps.account)) {
      history.push(ROUTES.USERS);
    }
  }

  componentWillUnmount() {
    this.props.resetUser();
  }

  handleSubmit = async values => {
    const submitVaules = {
      ...values,
      account: values.account.value,
      role: values.role.value,
    };

    const {
      addNotify,
      createUser,
      history,
      match: {
        params: { id },
      },
      updateUser,
    } = this.props;
    const api = id === 'new' ? createUser : updateUser;
    const params = id === 'new' ? [submitVaules] : [id, submitVaules];

    try {
      const result = await api(...params);

      if (result.error) {
        addNotify('Oops! Something went wrong!', 'error');
      } else {
        history.push(ROUTES.USERS);
      }

      return result;
    } catch (error) {
      addNotify('Oops! Something went wrong!', 'error');

      return error;
    }
  };

  render() {
    const { account, user, loading, accountOptions } = this.props;
    const userAccount = accountOptions.find(
      accountItem => accountItem.value === user.role?.account_id,
    );

    return (
      <div className="card border-0 page">
        <h5 className="card-header bg-primary text-white">
          <i className="fas fa-images pr-2" />
          <Link to={ROUTES.USERS} className="text-white">
            Users List
          </Link>
          <> / User</>
        </h5>
        {loading && <Loader />}
        {account && (
          <Form
            initialValues={{
              account: accountOptions.find(
                account => account.value === user.role?.account_id,
              ),
              accountId: account.id,
              ...user,
              role: roleOptions.find(role => role.value === user.role?.role_id),
            }}
            onSubmit={this.handleSubmit}
            component={UserItemFormConnected}
            mutators={{
              ...arrayMutators,
            }}
            validate={values => {
              const errors: Record<string, string> = {};

              if (!values.id && values.password !== values.passwordConfirm) {
                errors.passwordConfirm = 'Passwords mismatch.';
              }

              return errors;
            }}
          />
        )}

        {user && user.id ? (
          <ReportSchedule
            user={user}
            accountId={userAccount?.value || account?.id}
            accountName={userAccount?.label || account?.name}
          />
        ) : null}
      </div>
    );
  }
}

export default connect(
  store => ({
    account: store.accounts.current,
    accountOptions: accountOptionsSelector(store),
    loading: store.user.loading,
    user: store.user.data,
  }),
  {
    addNotify,
    createUser,
    loadUser,
    resetUser,
    updateUser,
  },
)(UserItem);
