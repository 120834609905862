import { DefaultRootState } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { ISchedule } from './types';

export default createStructuredSelector<
  DefaultRootState,
  Pick<
    ISchedule,
    'accountId' | 'accountName' | 'loading' | 'schedule' | 'user' | 'list'
  >
>({
  accountId: store => store.accounts.current?.id,
  accountName: store => store.accounts.current?.name,
  list: store => store.reports.scheduleList,
  loading: store => store.reports.scheduleLoading,
  schedule: store => store.reports.schedule,
  user: store => store.auth.user,
});
