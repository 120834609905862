/**
 * Notification actions script file
 *
 * @package    Actions
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */

import { Dispatch } from 'redux';

export const ADD_NOTIFY = 'ADD_NOTIFY';
export const DELETE_NOTIFY = 'DELETE_NOTIFY';

export const addNotify = (message: string, type = 'success') => {
  const id = Date.now();

  return (dispatch: Dispatch<any>) =>
    dispatch({
      data: {
        id,
        message,
        type,
      },
      type: ADD_NOTIFY,
    });
};

export const deleteNotify = (id: number) => ({
  id,
  type: DELETE_NOTIFY,
});
