import { useCallback, useEffect } from 'react';
import { Form } from 'react-final-form';
import { Link, RouteComponentProps } from 'react-router-dom';

import Loader from '../../components/Loader';
import { ROUTES } from '../../constants/routes';

import { AccountTrackingForm } from './AccountTrackingForm';

interface IProps {
  loading: boolean;
  initialValues: Record<string, any>;
  fields: { name: string; id: string };
  updateNetworkSettings(
    id: number,
    dcmPrivate: string,
    settings: Record<string, any>,
  ): Promise<void>;
  loadNetworkSettings(id: number): void;
  loadAccount(id: number): void;
  loadNetworks(): void;
}

export const AccountTracking = ({
  loading,
  match: {
    params: { id },
  },
  initialValues,
  fields,
  history,
  loadNetworkSettings,
  loadAccount,
  loadNetworks,
  updateNetworkSettings,
}: IProps & RouteComponentProps<{ id: any }>) => {
  useEffect(() => {
    loadAccount(id);
    loadNetworkSettings(id);
    loadNetworks();
  }, [id, loadAccount, loadNetworkSettings, loadNetworks]);

  const submit = useCallback(
    async values => {
      const { dcmPrivate, ...rest } = values;

      await updateNetworkSettings(id, dcmPrivate, Object.values(rest));

      history.push(ROUTES.ACCOUNTS);
    },
    [id, updateNetworkSettings, history],
  );

  return (
    <div className="card border-0 page">
      <h5 className="card-header bg-primary text-white">
        <i className="fas fa-images pr-2" />
        <Link to={ROUTES.ACCOUNTS} className="text-white">
          Accounts Library
        </Link>
        <> / Account tracking options</>
      </h5>
      {loading && <Loader />}
      {!loading && (
        <Form
          // @ts-ignore
          fields={fields}
          initialValues={initialValues}
          onSubmit={submit}
          // @ts-ignore
          component={AccountTrackingForm}
        />
      )}
    </div>
  );
};
