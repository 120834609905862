import { TYPE_DISPLAY, TYPE_VIDEO } from '../constants';

const getTypeId = type =>
  ({
    image: TYPE_DISPLAY.toString(),
    video: TYPE_VIDEO.toString(),
  }[type]);

const imagePath = asset => {
  const data =
    asset.type.toString() === getTypeId('image')
      ? asset.imageLandscape
      : asset.videoAsset;

  if (!data || data === '' || data.lastIndexOf('data', 0) === 0) {
    return data;
  }
  let response;

  if (asset.type && asset.type.toString() === getTypeId('video')) {
    const videoId = [
      ...data.matchAll(
        /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/gi,
      ),
    ];

    if (!videoId.length) {
      return 'https://img.youtube.com/vi/0.jpg';
    }

    response = `https://img.youtube.com/vi/${videoId[0][1]}/0.jpg`;
  } else {
    response = process.env.REACT_APP_API_URL + data;
  }

  return response;
};

export { imagePath, getTypeId };
