export const GET_CLIENT_SECRET_REQUEST = 'GET_CLIENT_SECRET_REQUEST';
export const GET_CLIENT_SECRET_SUCCESS = 'GET_CLIENT_SECRET_SUCCESS';
export const GET_CLIENT_SECRET_FAILURE = 'GET_CLIENT_SECRET_FAILURE';

export const getClientSecret =
  (reset: boolean = false) =>
  (dispatch: any) =>
    dispatch({
      request: (axios: any) => axios.post('/stripe/setup', { reset }),
      types: [
        GET_CLIENT_SECRET_REQUEST,
        GET_CLIENT_SECRET_SUCCESS,
        GET_CLIENT_SECRET_FAILURE,
      ],
    });
