import { AnyAction } from 'redux';

import {
  ROLES_LIST_CRITERIA,
  ROLES_LIST_ERROR,
  ROLES_LIST_REQUEST,
  ROLES_LIST_SUCCESS,
} from '../actions/roles.actions';
import { DEFAULT_METADATA, DEFAULT_PER_PAGE } from '../constants';

interface RolesState {
  criteria: any;
  data: any[];
  loading: boolean;
  metadata: any;
}

export const INITIAL_STATE: Readonly<RolesState> = {
  criteria: {
    page: 1,
    perPage: DEFAULT_PER_PAGE,
    sort: {
      id: 'ASC',
    },
    where: {},
  },
  data: [],
  loading: false,
  metadata: DEFAULT_METADATA,
};

export default (
  state: Readonly<RolesState> = INITIAL_STATE,
  action: AnyAction,
): Readonly<RolesState> => {
  switch (action.type) {
    case ROLES_LIST_REQUEST:
      return { ...state, loading: true };

    case ROLES_LIST_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        metadata: action.metadata,
      };

    case ROLES_LIST_ERROR:
      return { ...state, loading: false };

    case ROLES_LIST_CRITERIA:
      return {
        ...state,
        criteria: { ...state.criteria, ...action.criteria },
      };

    default:
      return state;
  }
};
