/**
 * GridFooterPagination component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */

import { PureComponent } from 'react';

import GridFooterPaginationButton from './GridFooterPaginationButton';

interface Props {
  onChange: (page: number) => void;
  page: number;
  pages: number;
  visible: number;
}

export default class GridFooterPagination extends PureComponent<Props> {
  static defaultProps = {
    page: 1,
    pages: 1,
    visible: 5,
  };

  render() {
    const { onChange, page, pages, visible } = this.props;

    if (pages <= 1) {
      return null;
    }
    let items = [] as number[];

    if (visible >= pages || visible > page) {
      items = Array.from(Array(Math.min(pages, visible) + 1).keys()).slice(1);
    } else if (pages - visible < page) {
      for (let i = pages - visible; i < pages; i++) {
        items.push(i + 1);
      }
    } else {
      const step = Math.ceil((visible - 1) / 2);

      for (let i = page - step; i <= page + step; i++) {
        items.push(i);
      }
    }

    return (
      <div className="col-12 col-sm-5">
        <ul className="pagination justify-content-center justify-content-sm-end">
          <GridFooterPaginationButton
            disabled={page === 1}
            label="&laquo;"
            onClick={onChange}
            page={1}
            textAfter="First"
          />
          {pages > 2 && (
            <GridFooterPaginationButton
              disabled={page === 1}
              label="&lsaquo;"
              onClick={onChange}
              page={page - 1}
              textAfter="Previous"
            />
          )}
          {items.map(item => (
            <GridFooterPaginationButton
              key={item}
              active={page === item}
              disabled={page === item}
              label={item}
              onClick={onChange}
              page={item}
            />
          ))}
          {pages > 2 && (
            <GridFooterPaginationButton
              disabled={page === pages}
              label="&rsaquo;"
              onClick={onChange}
              page={page + 1}
              textBefore="Next"
            />
          )}

          <GridFooterPaginationButton
            disabled={page === pages}
            label="&raquo;"
            onClick={onChange}
            page={pages}
            textBefore="Last"
          />
        </ul>
      </div>
    );
  }
}
