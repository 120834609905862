import moment from 'moment';
import { DefaultRootState } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';

import { IPerformanceReportData } from './types';

export default createStructuredSelector<
  DefaultRootState,
  Pick<
    IPerformanceReportData,
    | 'adgroups'
    | 'adminFutureSpendChartData'
    | 'chart'
    | 'records'
    | 'accountId'
    | 'accountName'
    | 'startDate'
    | 'endDate'
    | 'metric1'
    | 'metric2'
    | 'metricCharts'
    | 'maxDate'
    | 'perms'
    | 'pdfLoading'
  >
>({
  accountId: store => store.accounts.current?.id,
  accountName: store => store.accounts.current?.name,
  adgroups: store => store.dashboard.adgroups,
  adminFutureSpendChartData: store => store.dashboard.adminFutureSpendChartData,
  chart: store => store.dashboard.charts,
  endDate: createSelector(
    store => store.dashboard.endDate,
    endDate => (endDate ? new Date(endDate) : null),
  ),
  maxDate: createSelector(
    () => moment().startOf('d').format(),
    () => moment().startOf('d').toDate(),
  ),
  metric1: store => store.dashboard.metric1,
  metric2: store => store.dashboard.metric2,
  metricCharts: store => store.dashboard.metricCharts,
  pdfLoading: store => store.dashboard.pdfLoading,
  perms: store => store.auth.user?.perms || {},
  records: store => store.dashboard.records,
  startDate: createSelector(
    store => store.dashboard.startDate,
    startDate => new Date(startDate),
  ),
});
