/**
 * Grid component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */

import cx from 'classnames';
import { debounce } from 'debounce';
import deepEqual from 'deep-equal';
import { isArray, map } from 'lodash';
import { Fragment, PureComponent, ReactNode } from 'react';

import DropDownButton from '../DropDownButton';
import Loader from '../Loader';

import GridFooterPagination from './GridFooterPagination';
import GridFooterPerPageSelector from './GridFooterPerPageSelector';
import GridFooterText from './GridFooterText';
import GridSearch from './GridSearch';
import GridTable from './GridTable';
import './styles.css';
import {
  ColumnOption,
  Criteria,
  GridAction,
  GridDataItem,
  GridFilter,
  ListRequestParams,
  Metadata,
  UpdateRequestParams,
} from './Grid.interfaces';

interface Props {
  actions: GridAction[];
  className?: string;
  columns: ColumnOption[];
  criteria: Criteria;
  data: Record<string, any>[];
  editItem?: (data: any) => void; // TODO: is it used?
  entity?: string; // TODO: is it used?
  filters?: GridFilter[];
  icon?: string;
  loading?: boolean;
  loadList: (data: ListRequestParams) => void;
  metadata: Metadata;
  onChange?: (items: GridDataItem[], item: GridDataItem | null) => void;
  reloadable?: boolean;
  searchable?: boolean;
  seemore?: (row: GridDataItem) => ReactNode;
  selectable?: boolean;
  title?: string;
  updateCriteria: (data: UpdateRequestParams) => void;
}

export default class Grid extends PureComponent<Props> {
  static defaultProps = {
    actions: [],
    className: '',
    filters: [],
    icon: undefined,
    loading: false,
    onChange: undefined,
    reloadable: false,
    searchable: true,
    seemore: undefined,
    title: '',
  };

  constructor(props) {
    super(props);

    this.loadListWithDebounce = debounce(this.loadList, 300);
  }

  componentDidMount() {
    this.loadList();
  }

  componentDidUpdate(prevProps) {
    if (!deepEqual(this.props.criteria, prevProps.criteria)) {
      this.loadListWithDebounce();
    }
  }

  loadListWithDebounce: any;

  loadList = () => {
    const { criteria, loadList } = this.props;

    loadList({
      criteria,
    });
  };

  search = value => {
    const { updateCriteria } = this.props;

    updateCriteria({
      where: {
        search: value,
      },
    });
  };

  setSort = value => {
    const { criteria, updateCriteria } = this.props;
    const sort = {
      ...criteria.sort,
    };

    if (sort[value] === 'ASC') {
      sort[value] = 'DESC';
    } else if (sort[value] === 'DESC') {
      delete sort[value];
    } else {
      sort[value] = 'ASC';
    }

    updateCriteria({
      sort,
    });
  };

  setPerPage = value => {
    const { updateCriteria } = this.props;

    updateCriteria({
      page: 1,
      perPage: value,
    });
  };

  setPage = value => {
    const { updateCriteria } = this.props;

    updateCriteria({
      page: value,
    });
  };

  renderDropDownButtonFilter(filter: GridFilter, key: number) {
    const current =
      filter.items.filter(
        item => item.value === this.props.criteria.where.campaign_type,
      )[0] ?? filter.items[0];

    return (
      <Fragment key={key}>
        <label className="grid-filter-label">{filter.title}:</label>
        <DropDownButton
          items={filter.items}
          selectedItem={current}
          className="grid-filter-button"
          onChange={filter.onChange}
        />
      </Fragment>
    );
  }

  renderFilters() {
    if (!this.props.filters) {
      return null;
    }

    return this.props.filters.map((filter, idx) => {
      switch (filter.type) {
        case 'DropDownButton':
          return this.renderDropDownButtonFilter(filter, idx);
        default:
          return null;
      }
    });
  }

  render() {
    const {
      actions,
      className,
      columns,
      criteria: { sort, where } = {},
      data,
      icon,
      loading,
      metadata: { page, pages, perPage, total } = {},
      reloadable,
      searchable,
      seemore,
      selectable,
      onChange,
      title,
    } = this.props;

    return (
      <div className={cx('row', className)}>
        <div className="col-sm-12">
          <div className="card border-0">
            {title && (
              <div className="card-header bg-primary text-white">
                <div className="row">
                  <div className="col">
                    {icon && <i className={icon} />}
                    <h6 className="d-inline grid-header">{title}</h6>
                  </div>
                  {Boolean(this.props.filters && this.props.filters.length) &&
                    this.renderFilters()}

                  {Boolean(actions && isArray(actions) && actions.length) && (
                    <>
                      {map(actions, ({ action, title, render }, key) => (
                        <div className="col-auto text-right" key={key}>
                          {render && render()}
                          {!render && (
                            <button
                              type="button"
                              onClick={action}
                              className="btn btn-primary btn-sm"
                            >
                              {title}
                            </button>
                          )}
                        </div>
                      ))}
                    </>
                  )}
                  {reloadable && (
                    <div className="col-auto text-right">
                      <button
                        type="button"
                        onClick={this.loadList}
                        className="btn btn-primary btn-sm"
                      >
                        <i className="fas fa-sync-alt mr-2" />
                        Reload
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className="card-block grid p-2">
              {searchable && where && (
                <GridSearch value={where.search} onChange={this.search} />
              )}

              <div className="row grid-table">
                <div className="col-xs-12 col-sm-12">
                  {loading && <Loader />}

                  <GridTable
                    columns={columns}
                    data={data}
                    onSort={this.setSort}
                    seemore={seemore}
                    onChange={onChange}
                    sort={sort}
                    selectable={selectable}
                  />
                </div>
              </div>

              {Boolean(total && total > 0) && (
                <div className="row grid-footer">
                  <GridFooterText page={page} perPage={perPage} total={total} />

                  <GridFooterPerPageSelector
                    perPage={perPage}
                    onChange={this.setPerPage}
                  />

                  <GridFooterPagination
                    page={page}
                    pages={pages}
                    onChange={this.setPage}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
