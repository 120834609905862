import Toggle from 'react-toggle';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import cx from 'classnames';
import { Backdrop, CircularProgress } from '@mui/material';

import {
  composeValidators,
  maxLength,
  minLength,
  required,
} from '../../../../helpers/validation';
import FormError from '../../../../components/FormError';
import SelectAdapter from '../../../../components/SelectAdapter';
import {
  spikeRatios,
  averageAmounts,
  averageAmountLabel,
  pdfReportTypes,
  metrics,
} from '../constants';

import { REPORT_PERIODS, REPORT_TYPES, TODAY } from './constants';

const loadingBackdropStyles = { color: '#ffffff', zIndex: 20 };

const checkRecipientError = (errors, recipientName, fieldName) => {
  const index = Number(`${recipientName}`.replace(/([a-z]+|\[|\])/g, ''));

  return errors.recipients && errors.recipients[index]
    ? errors.recipients[index][fieldName]
    : null;
};

const validateRecipients = values => {
  if (!values || values.length === 0) {
    return 'Should be at least one recipient';
  }

  let allEmpty = true;

  for (const recipient of values) {
    if (recipient.email || recipient.name) {
      allEmpty = false;
      break;
    }
  }

  if (allEmpty) {
    return 'Should be at least one recipient';
  }

  return undefined;
};

export const ScheduleForm = props => {
  const {
    errors,
    form,
    handleSubmit,
    initialValues,
    submitting,
    touched,
    values,
    valid,
    cancel,
    back,
    test,
    loading,
  } = props;

  const recipientsError =
    errors?.recipients && errors?.recipients['FINAL_FORM/array-error'];
  const chooseMetrics = values.report_type.find(
    ({ value }) => value === pdfReportTypes.CHART,
  );

  return (
    <div className="page p-1 p-sm-3 p-xl-5">
      <form onSubmit={handleSubmit}>
        <Field
          id="report_schedule_id"
          name="id"
          type="hidden"
          component="input"
        />
        <div className="card">
          <div className="card-header bg-primary text-white p-2 pl-4">
            <div className="d-flex justify-content-between align-items-center">
              <h4 className="mb-0">
                Schedule report{' '}
                {initialValues.id ? `#${initialValues.id}` : null}
              </h4>

              <div>
                <button
                  type="button"
                  className="btn btn-success ml-3"
                  disabled={submitting || loading || !values.id}
                  onClick={() => test(values.id)}
                >
                  Test
                </button>
                <button
                  type="button"
                  className="btn btn-secondary ml-3"
                  disabled={submitting || loading}
                  onClick={back}
                >
                  Back
                </button>
                <button
                  type="button"
                  className="btn btn-light ml-3"
                  disabled={submitting || loading}
                  onClick={cancel}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
          <div className="card-body container-fluid">
            <div className="row d-flex justify-content-center">
              <div className="col-12 col-xl-8">
                <div className="row">
                  <div className="col-10 form-group">
                    <label htmlFor="period">Choose name (optional)</label>
                    <Field
                      id="report_name"
                      name="report_name"
                      component="input"
                      type="text"
                      className="form-control"
                    />
                    <FormError name="report_name" />
                  </div>
                  <div className="col-2 form-group text-right">
                    <label htmlFor="active" className="mr-2">
                      Active
                    </label>
                    <div className="pt-1">
                      <Toggle
                        checked={Boolean(values.active)}
                        onChange={() => form.change('active', !values.active)}
                        icons={false}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <FieldArray name="recipients" validate={validateRecipients}>
                      {({ fields }) => (
                        <div className="card mb-3">
                          <div className="card-header">
                            <div className="row">
                              <div className="col-10 d-flex justify-content-start align-items-center">
                                <h5 className="mb-0">Recipients</h5>
                              </div>
                              <div className="col-2 d-flex justify-content-end align-items-center">
                                <button
                                  type="button"
                                  onClick={event => {
                                    event.preventDefault();
                                    fields.push('');
                                  }}
                                  className="btn btn-primary"
                                >
                                  <div className="custom-tooltip">
                                    <i className="fas fa-plus" />
                                    <span className="custom-tooltip-wrapper">
                                      <span className="custom-tooltip-wrapper-text">
                                        Add
                                      </span>
                                    </span>
                                  </div>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="card-body">
                            {fields.map((name, index) => (
                              <div key={name} className="row position-relative">
                                <div className="form-group required col-12 col-md-6 col-lg-6">
                                  <label htmlFor={`${name}.email`}>
                                    Enter email
                                  </label>
                                  <Field
                                    id={`${name}.email`}
                                    name={`${name}.email`}
                                    component="input"
                                    type="email"
                                    className={cx('form-control', {
                                      'is-invalid':
                                        touched[`${name}.email`] &&
                                        checkRecipientError(
                                          errors,
                                          name,
                                          'email',
                                        ),
                                    })}
                                    maxLength={60}
                                    validate={composeValidators(
                                      minLength(3),
                                      maxLength(60),
                                    )}
                                  />
                                  <FormError name={`${name}.email`} />
                                </div>
                                <div className="form-group required col-12 col-md-6 col-lg-6">
                                  <div className="row">
                                    <div className="col-11">
                                      <label htmlFor={`${name}.name`}>
                                        Enter name
                                      </label>
                                      <Field
                                        id={`${name}.name`}
                                        name={`${name}.name`}
                                        component="input"
                                        type="text"
                                        className={cx('form-control', {
                                          'is-invalid':
                                            touched[`${name}.name`] &&
                                            checkRecipientError(
                                              errors,
                                              name,
                                              'name',
                                            ),
                                        })}
                                        maxLength={60}
                                        validate={composeValidators(
                                          minLength(1),
                                          maxLength(60),
                                        )}
                                      />
                                      <FormError name={`${name}.name`} />
                                    </div>
                                    <div className="col-1 p-0">
                                      <div className="mb-2">&nbsp;</div>
                                      <button
                                        type="button"
                                        className="pt-2"
                                        onClick={() => fields.remove(index)}
                                      >
                                        <div className="custom-tooltip">
                                          <i className="fas fa-trash" />
                                          <span className="custom-tooltip-wrapper">
                                            <span className="custom-tooltip-wrapper-text">
                                              Delete
                                            </span>
                                          </span>
                                        </div>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}

                            {recipientsError ? (
                              <div className="text-danger">
                                {recipientsError}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      )}
                    </FieldArray>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="row">
                      <div className="form-group required col-12 col-md-6 col-lg-6">
                        <label htmlFor="report_type">Choose report type</label>
                        <Field
                          id="report_type"
                          name="report_type"
                          selectType="select"
                          component={SelectAdapter}
                          isMulti
                          options={REPORT_TYPES}
                          validate={required}
                        />
                        <FormError name="report_type" />
                      </div>
                      <div className="form-group required col-12 col-md-6 col-lg-6">
                        <label htmlFor="period">
                          Choose default report period
                        </label>
                        <Field
                          id="report_period"
                          name="report_period"
                          selectType="select"
                          component={SelectAdapter}
                          options={REPORT_PERIODS}
                          validate={required}
                        />
                        <FormError name="period" />
                      </div>
                    </div>
                  </div>
                </div>
                {chooseMetrics ? (
                  <div className="row">
                    <div className="col">
                      <div className="row">
                        <div className="form-group col-12 col-md-6 col-lg-6">
                          <label htmlFor="metric1">Choose metrics</label>
                          <Field
                            id="metric1"
                            name="metric1"
                            selectType="select"
                            component={SelectAdapter}
                            options={metrics}
                          />
                          <FormError name="metric1" />
                        </div>
                        <div className="form-group col-12 col-md-6 col-lg-6">
                          <label htmlFor="metric2">&nbsp;</label>
                          <Field
                            id="metric2"
                            name="metric2"
                            selectType="select"
                            component={SelectAdapter}
                            options={metrics}
                          />
                          <FormError name="metric2" />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="row">
                  <div className="col">
                    <div className="row">
                      <div className="form-group required col-12 col-md-6 col-lg-6 mb-1">
                        <label htmlFor="start_date">
                          Start date to send reports
                        </label>
                        <Field
                          id="start_date"
                          name="start_date"
                          component="input"
                          type="datetime-local"
                          step={60}
                          min={values.id ? undefined : TODAY()}
                          className={cx('form-control', {
                            'is-invalid':
                              touched.start_date && errors.start_date,
                          })}
                          validate={required}
                        />
                        <FormError name="start_date" />
                      </div>
                      <div className="form-group required col-12 col-md-6 col-lg-6 mb-1" />
                    </div>
                    <div className="row mb-4">
                      <div className="col">
                        <small className="text-secondary">
                          Users can select a start date using the day picker and
                          specify the reporting period to generate reports. The
                          available standard report periods are weekly, monthly,
                          and quarterly. For example, if a user selects the
                          start date as the 1st of March (Friday):
                          <p className="pl-1 mb-0">
                            - Weekly: Reports will be generated every Friday
                            starting from 1st of March.
                          </p>
                          <p className="pl-1 mb-0">
                            - Monthly: Reports will be generated on the 1st of
                            each month starting from 1st of March.
                          </p>
                          <p className="pl-1 mb-0">
                            - Quarterly: Reports will be generated on the 1st of
                            every 3rd month starting from 1st of March. If you
                            want to receive reports for each calendar quarter -
                            pick the closest quarter start: the 1st of January,
                            1st of April, 1st of July, or 1st of October.
                          </p>
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="row">
                      <div className="form-group col-12 col-md-6 col-lg-6">
                        <div>
                          <label htmlFor="average_periods">
                            Default moving averages period
                          </label>
                        </div>
                        {averageAmounts.map(amount => (
                          <div
                            key={`average_amounts._${amount}`}
                            className="form-check form-check-inline"
                          >
                            <Field
                              id={`average_amounts._${amount}`}
                              name={`average_amounts._${amount}`}
                              component="input"
                              className={cx('form-check-input', {
                                'is-invalid':
                                  touched[`average_amounts._${amount}`] &&
                                  errors[`average_amounts._${amount}`],
                              })}
                              type="checkbox"
                              disabled={
                                Boolean(values.id) &&
                                Boolean(
                                  initialValues[`average_amounts._${amount}`],
                                )
                              }
                            />
                            <label
                              htmlFor={`average_amounts._${amount}`}
                              className="form-check-label"
                            >
                              {`${averageAmountLabel}`.replace(
                                `${averageAmounts[0]}`,
                                `${amount}`,
                              )}
                            </label>
                            <FormError name={`average_amounts._${amount}`} />
                          </div>
                        ))}
                      </div>
                      <div className="form-group col-12 col-md-6 col-lg-6">
                        <div>
                          <label htmlFor="spikes_ratio">
                            Default spikes ratio
                          </label>
                        </div>
                        {spikeRatios.map(spikeRatio => (
                          <div
                            className="form-check form-check-inline mr-1"
                            key={`spike_ratio_${spikeRatio}`}
                          >
                            <label
                              className="form-check-label pr-4 position-relative d-flex justify-content-center"
                              htmlFor={`spike_ratio_${spikeRatio}`}
                            >
                              <Field
                                id={`spike_ratio_${spikeRatio}`}
                                name="spikes_ratio"
                                component="input"
                                type="radio"
                                className="form-check-input"
                                value={`${spikeRatio}`}
                              />
                              <span>x{spikeRatio}</span>
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col d-flex align-items-center justify-content-start">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={submitting || !valid || loading}
                    >
                      {values.id ? 'Update' : 'Schedule'}
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary ml-3"
                      disabled={submitting || loading}
                      onClick={back}
                    >
                      Back
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <Backdrop sx={loadingBackdropStyles} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};
