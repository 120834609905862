/**
 * CampaignItem component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';

import { NETWORK_DV360, NETWORK_GOOGLE_ADS } from '../../constants';
import {
  loadCampaign,
  loadLanguages,
  resetCampaign,
  updateCampaign,
} from '../../actions/campaigns.actions';
import { addNotify } from '../../actions/notifications.actions';
import Loader from '../../components/Loader';

import CampaignSettingsForm from './CampaignSettingsForm';

class CampaignSettings extends PureComponent<any, any> {
  static propTypes = {
    account: PropTypes.object,
    campaign: PropTypes.object.isRequired,
    campaignId: PropTypes.number,
    loadCampaign: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    loadLanguages: PropTypes.func.isRequired,
    resetCampaign: PropTypes.func.isRequired,
    updateCampaign: PropTypes.func.isRequired,
  };

  static defaultProps = {
    account: {},
    campaignId: null,
  };

  componentDidMount() {
    const { campaignId, loadCampaign } = this.props;

    campaignId && loadCampaign(campaignId);
  }

  componentWillUnmount() {
    this.props.resetCampaign();
  }

  loadLanguages = async search => {
    const { data } = await this.props.loadLanguages(search);

    return data.map(({ id, name }) => ({
      label: name,
      value: id,
    }));
  };

  handleSubmit = async values => {
    const {
      id,
      budgetrange,
      targeting_languages,
      targeting_keywords,
      targeting_location,
      targeting_verticals,
      googleads_campaign_budget,
      googleads_campaign_margin,
      dv360_campaign_budget,
      dv360_campaign_margin,
      ...data
    } = values;

    if (budgetrange === 'start') {
      delete data.end_date;
    }

    data.targeting_languages = targeting_languages
      ? targeting_languages.map(({ value }) => value)
      : [];
    data.targeting_keywords = targeting_keywords
      ? targeting_keywords.map(({ value }) => value)
      : [];
    data.targeting_location = targeting_location
      ? targeting_location.map(({ value }) => value)
      : [];
    data.targeting_verticals = targeting_verticals
      ? targeting_verticals.map(({ value }) => value)
      : [];
    data.network = [];

    if (data.network_google) {
      data.network.push(NETWORK_GOOGLE_ADS);
      data.googleads_campaign_budget = googleads_campaign_budget;
      data.googleads_campaign_margin = googleads_campaign_margin;
    }

    if (data.network_dv360) {
      data.network.push(NETWORK_DV360);
      data.dv360_campaign_budget = dv360_campaign_budget;
      data.dv360_campaign_margin = dv360_campaign_margin;
    }

    const { updateCampaign } = this.props;

    try {
      const result = await updateCampaign(id, data);

      if (result.error) {
        addNotify('Oops! Something went wrong!', 'error');
      }

      return result;
    } catch (error) {
      addNotify('Oops! Something went wrong!', 'error');

      return error;
    }
  };

  render() {
    const { account, campaign, loading } = this.props;

    return (
      <>
        {loading && <Loader />}
        {account && (
          <Form
            initialValues={campaign}
            onSubmit={this.handleSubmit}
            loadLanguages={this.loadLanguages}
            // @ts-ignore
            component={CampaignSettingsForm}
          />
        )}
      </>
    );
  }
}

export default connect(
  store => ({
    account: store.accounts.current,
    campaign: store.campaign.data,
    loading: store.adgroup.loading,
  }),
  {
    addNotify,
    loadCampaign,
    loadLanguages,
    resetCampaign,
    updateCampaign,
  },
)(CampaignSettings);
