import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';

import { loadAccount } from '../../actions/accounts.actions';
import {
  loadNetworkSettings,
  updateNetworkSettings,
} from '../../actions/accountTracking.actions';
import { loadItems } from '../../actions/networks.actions';
import { NETWORK_SETTINGS_TYPES } from '../../constants/networkSettings';
import {
  dcmPrivateSelector,
  loadingSelector,
  networkSettingsSelector,
  networkSettingsTypesSelector,
} from '../../selectors/accountTracking';

import { AccountTracking } from './AccountTracking';

const fieldTypes = [
  NETWORK_SETTINGS_TYPES.ADWORDS_ID,
  NETWORK_SETTINGS_TYPES.CONVERSION_ID,
];

const requiredSettingsTypesSeelector = createSelector(
  networkSettingsTypesSelector,
  networkSettings =>
    networkSettings.filter(type => fieldTypes.includes(type.id)),
);

export const AccountTrackingConnected = connect<any, any, any, any>(
  createStructuredSelector({
    fields: createSelector(
      requiredSettingsTypesSeelector,
      networkSettingsTypes =>
        networkSettingsTypes.map(type => ({
          name: type.name,
          type: `type_${type.id}`,
        })),
    ),
    initialValues: createSelector(
      dcmPrivateSelector,
      networkSettingsSelector,
      requiredSettingsTypesSeelector,
      (dcmPrivate, networkSettings, networkSettingsTypes) =>
        networkSettingsTypes.reduce<any>(
          (acc, type) => ({
            ...acc,
            dcmPrivate,
            [`type_${type.id}`]: networkSettings.find(
              setting => setting.network_setting_id === type.id,
            ) || { network_setting_id: type.id },
          }),
          {},
        ),
    ),
    loading: loadingSelector,
    networkSettings: networkSettingsSelector,
  }),
  {
    loadAccount,
    loadNetworks: loadItems,
    loadNetworkSettings,
    updateNetworkSettings,
  },
)(AccountTracking);
