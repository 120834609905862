/**
 * Notification reducers script file
 *
 * @package    Reducers
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */

import { AnyAction } from 'redux';

import { ADD_NOTIFY, DELETE_NOTIFY } from '../actions/notifications.actions';

type NotificationsState = Array<{
  id: number;
  message: string;
  type: string;
}>;

export const INITIAL_STATE: Readonly<NotificationsState> = [];

export default (
  state: Readonly<NotificationsState> = INITIAL_STATE,
  action: AnyAction,
): Readonly<NotificationsState> => {
  switch (action.type) {
    case ADD_NOTIFY: {
      let exists = state.find(
        ({ message, type }) =>
          message === action.data.message && type === action.data.type,
      );

      if (!exists) {
        return state.concat(action.data);
      } else {
        return state;
      }
    }

    case DELETE_NOTIFY:
      return state.filter(({ id }) => id !== action.id);

    default:
      return state;
  }
};
