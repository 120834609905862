/**
 * AssetItemForm component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */

import cx from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import SelectAdapter from '../../components/SelectAdapter';
import { RootState } from '../../reducers';
import { accountOptionsSelector, roleOptions } from '../../selectors/user';
import FormError from '../../components/FormError';
import { required } from '../../helpers/validation';

export class UserItemForm extends PureComponent<any, any> {
  static propTypes = {
    accountsOptions: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
    errors: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    touched: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
  };

  static defaultProps = {
    disabled: false,
  };

  render() {
    const {
      disabled,
      errors,
      form,
      handleSubmit,
      pristine,
      submitting,
      touched,
      values,
      accountsOptions,
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <div className="card-body container-fluid">
          <div className="row">
            <div className="col-6">
              <div className="form-group required">
                <label htmlFor="username">Username</label>
                <Field
                  id="username"
                  name="username"
                  component="input"
                  className={cx('form-control', {
                    'is-invalid': touched.username && errors.username,
                  })}
                  maxLength={90}
                  validate={required}
                />
                <FormError name="username" />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group required">
                <label htmlFor="email">Email</label>
                <Field
                  id="email"
                  name="email"
                  component="input"
                  className={cx('form-control', {
                    'is-invalid': touched.email && errors.email,
                  })}
                  maxLength={90}
                  validate={required}
                />
                <FormError name="email" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group required">
                <label htmlFor="firstname">First name</label>
                <Field
                  id="firstname"
                  name="firstname"
                  component="input"
                  className={cx('form-control', {
                    'is-invalid': touched.firstname && errors.firstname,
                  })}
                  maxLength={90}
                  validate={required}
                />
                <FormError name="firstname" />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group required">
                <label htmlFor="lastname">Last Name</label>
                <Field
                  id="lastname"
                  name="lastname"
                  component="input"
                  className={cx('form-control', {
                    'is-invalid': touched.lastname && errors.lastname,
                  })}
                  maxLength={90}
                  validate={required}
                />
                <FormError name="lastname" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="account">Account</label>
                <Field
                  id="account"
                  name="account"
                  component={SelectAdapter}
                  className={cx({
                    'is-invalid': touched.account && errors.account,
                  })}
                  options={accountsOptions}
                />
                <FormError name="account" />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group required">
                <label htmlFor="role">Role</label>
                <Field
                  id="role"
                  name="role"
                  component={SelectAdapter}
                  className={cx({
                    'is-invalid': touched.role && errors.role,
                  })}
                  validate={required}
                  options={roleOptions}
                />
                <FormError name="role" />
              </div>
            </div>
          </div>
          {!values.id && (
            <div className="row">
              <div className="col-6">
                <div className="form-group required">
                  <label htmlFor="password">Password</label>
                  <Field
                    id="password"
                    name="password"
                    component="input"
                    type="password"
                    className={cx('form-control', {
                      'is-invalid': touched.password && errors.password,
                    })}
                    validate={required}
                  />
                  <FormError name="password" />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group required">
                  <label htmlFor="passwordConfirm">Confirm Password</label>
                  <Field
                    id="passwordConfirm"
                    name="passwordConfirm"
                    component="input"
                    type="password"
                    className={cx('form-control', {
                      'is-invalid':
                        touched.passwordConfirm && errors.passwordConfirm,
                    })}
                    validate={required}
                  />
                  <FormError name="passwordConfirm" />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="card-footer">
          <div className="row">
            <div className="col-12">
              <button
                type="button"
                className="btn btn-secondary"
                disabled={pristine || submitting}
                onClick={form.reset}
              >
                Reset
              </button>
              <button
                type="submit"
                className="btn btn-primary float-right"
                disabled={disabled || pristine || submitting}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export const UserItemFormConnected = connect(
  createStructuredSelector<RootState, {}>({
    accountsOptions: accountOptionsSelector,
  }),
  {},
)(UserItemForm);
