import { useCallback, useMemo, useState, ReactNode } from 'react';

import GridTable from '../../../../components/Grid/GridTable';
import { CommonTabs } from '../../../../components/Tabs/Tabs';
import {
  FUNNEL_PROSPECTING,
  FUNNEL_RETARGETING,
  FUNNEL_TITLES,
} from '../../../../constants';
import { allMetricsColumns } from '../report-settings';
import {
  applySorting,
  toggleSorting,
} from '../../../../components/Grid/Grid.utils';
import { GridColumnsSorting } from '../../../../components/Grid/Grid.interfaces';

const ALL = 0;
const tabs = [ALL, FUNNEL_PROSPECTING, FUNNEL_RETARGETING];

const checkAdGroups = records => {
  if (!records || records.length === 0) {
    return [];
  }

  const warningAdGroups: any[] = [];

  for (const record of records) {
    if (
      record.campaign?.campaign_type === null ||
      record.campaign?.campaign_type === undefined ||
      record.network_adgroup?.network_id === null ||
      record.network_adgroup?.network_id === undefined ||
      record.network_campaign?.funnel === null ||
      record.network_campaign?.funnel === undefined
    ) {
      warningAdGroups.push(record);
    }
  }

  return warningAdGroups;
};

type Props = {
  setupAdgroups: (rows: any[]) => void;
  records: any[];
  exportButton: ReactNode;
};

export const TableReport = ({
  setupAdgroups,
  records,
  exportButton,
}: Props) => {
  const [sorting, setSorting] = useState<GridColumnsSorting>({});
  const setSortingMemo = useCallback(
    (name: string) => {
      setSorting(toggleSorting(name, sorting, true));
    },
    [sorting],
  );

  const recordsWithSorting = useMemo(
    () => applySorting(records, allMetricsColumns, sorting),
    [records, sorting],
  );

  const tabRecords = useMemo(
    () => ({
      [ALL]: recordsWithSorting,
      [FUNNEL_PROSPECTING]: recordsWithSorting.filter(
        ({ network_campaign }) =>
          network_campaign?.funnel === FUNNEL_PROSPECTING,
      ),
      [FUNNEL_RETARGETING]: recordsWithSorting.filter(
        ({ network_campaign }) =>
          network_campaign?.funnel === FUNNEL_RETARGETING,
      ),
    }),
    [recordsWithSorting],
  );

  const warningAdGroups = checkAdGroups(records);

  const tabsData = tabs.map(tab => ({
    content: (
      <div className="grid">
        <div className="grid-table overflow-auto">
          <GridTable
            onChange={setupAdgroups}
            columns={allMetricsColumns}
            selectable
            multiple
            data={tabRecords[tab]}
            sort={sorting}
            onSort={setSortingMemo}
          />
        </div>
      </div>
    ),
    index: tab,
    key: `report-table-tab-${tab}`,
    label: FUNNEL_TITLES[tab] || 'All',
  }));

  return (
    <>
      <CommonTabs
        tabs={tabsData}
        name="table-report"
        defaultTab={ALL}
        actionButtons={exportButton}
      />
      {warningAdGroups.length > 0 ? (
        <h5 className="mb-5 ml-lg-5 mr-lg-5 text-center text-warning">
          Please note, one of your campaigns is missing ‘Funnel’ or ‘Campaign
          type’ parameters. For accurate data representation across all reports,
          kindly add these parameters.
        </h5>
      ) : null}
    </>
  );
};
