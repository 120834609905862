/**
 * AssetsList component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import Loader from '../../components/Loader';
import Modal from '../../components/Modal';
import {
  deleteAsset,
  loadAssets,
  updateCriteria,
} from '../../actions/assets.actions';
import AssetPreview from '../../components/AssetPreview';
import Grid from '../../components/Grid';
import {
  ColumnOption,
  Criteria,
  GridDataItem,
  Metadata,
  UpdateRequestParams,
} from '../../components/Grid/Grid.interfaces';
import { ROUTES, subRouteParams } from '../../constants/routes';

interface Props {
  account: Record<string, any>;
  criteria: Criteria;
  data: any[];
  deleteAsset: (id: string) => void;
  history: import('react-router-dom').RouteComponentProps['history'];
  loadAssets: (data: { criteria: Criteria }) => void;
  loading: boolean;
  metadata: Metadata;
  updateCriteria: (params: UpdateRequestParams) => void;
}

interface State {
  selectedItem: GridDataItem | null;
  showDeleteModal: boolean;
}

class AssetsList extends PureComponent<Props, State> {
  static propTypes = {
    account: PropTypes.object,
    criteria: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    deleteAsset: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    loadAssets: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    metadata: PropTypes.object.isRequired,
    updateCriteria: PropTypes.func.isRequired,
  } as never;

  static defaultProps = {
    account: {},
  };

  state: State = {
    selectedItem: null,
    showDeleteModal: false,
  };

  addItem = () => {
    this.props.history.push(subRouteParams(ROUTES.ASSET, { id: 'new' }));
  };

  editItem = ({ id }) => {
    this.props.history.push(subRouteParams(ROUTES.ASSET, { id }));
  };

  selectItem = (_: GridDataItem[], item: GridDataItem | null) => {
    this.setState({
      selectedItem: item,
    });
  };

  deleteItem = () => {
    const { deleteAsset } = this.props;
    const { selectedItem } = this.state;

    if (!selectedItem) {
      return;
    }

    deleteAsset(selectedItem.id);
    this.setState({
      selectedItem: null,
      showDeleteModal: false,
    });
  };

  showDeleteModal = selectedItem => {
    this.setState({
      selectedItem,
      showDeleteModal: true,
    });
  };

  hideDeleteModal = event => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      showDeleteModal: false,
    });
  };

  COLUMNS: ColumnOption[] = [
    {
      link: true,
      name: 'name',
      sortable: true,
      title: 'Name',
    },
    {
      maxWidth: 70,
      name: 'type',
      sortable: true,
      title: 'Type',
      values: {
        1: {
          icon: 'fas fa-ad text-warning',
          tooltip: 'Display Ad',
          type: 'icon',
        },
        2: {
          icon: 'fab fa-youtube text-danger',
          tooltip: 'Video Ad',
          type: 'icon',
        },
      },
    },
    {
      name: 'description',
      title: 'Description',
    },
    {
      className: 'text-center',
      items: [
        {
          action: this.editItem,
          icon: 'fas fa-pen-alt mr-1',
          tooltip: 'Edit item',
          type: 'icon',
        },
        {
          action: this.showDeleteModal,
          icon: 'fas fa-trash',
          tooltip: 'Delete Item',
          type: 'icon',
        },
      ],
      maxWidth: 25,
      name: 'actions',
      title: <i className="fas fa-cogs" />,
      type: 'list',
    },
  ];

  render() {
    const {
      account,
      criteria,
      data,
      loadAssets,
      loading,
      metadata,
      updateCriteria,
    } = this.props;
    const { selectedItem, showDeleteModal } = this.state;

    if (!account) {
      return <Loader />;
    }

    return (
      <div className="page">
        <div className="row">
          <div className="col-7">
            <Modal
              close={this.hideDeleteModal}
              description={`Are you sure, you want remove "${
                selectedItem ? selectedItem.name : ''
              }"?`}
              title="Delete entity?"
              show={showDeleteModal}
              submit={this.deleteItem}
              submitLabel="deleteModal"
            />
            <Grid
              actions={[
                {
                  action: this.addItem,
                  title: (
                    <>
                      <i className="fas fa-plus mr-2" />
                      Create new
                    </>
                  ),
                },
              ]}
              columns={this.COLUMNS}
              criteria={criteria}
              data={data}
              entity="assets"
              icon="fas fa-images pr-2"
              loading={loading}
              loadList={loadAssets}
              metadata={metadata}
              searchable={false}
              onChange={this.selectItem}
              title="Asset Library"
              updateCriteria={updateCriteria}
            />
          </div>
          <div className="col-5">
            <div className="card border-0">
              <div className="card-header bg-primary text-white">
                <i className="fas fa-eye pr-2" />
                <h6 className="d-inline grid-header">Preview</h6>
              </div>

              <div className="card-block">
                <AssetPreview data={selectedItem || {}} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  store => ({
    account: store.accounts.current,
    criteria: store.assets.criteria,
    data: store.assets.data,
    loading: store.assets.loading,
    metadata: store.assets.metadata,
  }),
  {
    deleteAsset,
    loadAssets,
    updateCriteria,
  },
)(AssetsList);
