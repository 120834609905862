import { AnyAction } from 'redux';

import { ACCOUNTS_SELECT } from '../actions/accounts.actions';
import {
  ASSETS_LIST_CRITERIA,
  ASSETS_LIST_ERROR,
  ASSETS_LIST_REQUEST,
  ASSETS_LIST_SUCCESS,
} from '../actions/assets.actions';
import { DEFAULT_METADATA, DEFAULT_PER_PAGE } from '../constants';

interface AssetsState {
  criteria: any;
  data: any[];
  loading: boolean;
  metadata: any;
}

export const INITIAL_STATE: Readonly<AssetsState> = {
  criteria: {
    page: 1,
    perPage: DEFAULT_PER_PAGE,
    sort: {
      name: 'ASC',
    },
    where: {
      account_id: Number(localStorage.getItem('saved-account-id')),
    },
  },
  data: [],
  loading: false,
  metadata: DEFAULT_METADATA,
};

export default (
  state: Readonly<AssetsState> = INITIAL_STATE,
  action: AnyAction,
): Readonly<AssetsState> => {
  switch (action.type) {
    case ASSETS_LIST_REQUEST:
      return { ...state, loading: true };

    case ASSETS_LIST_SUCCESS:
      return {
        ...state,
        data: action.data.map(asset => ({
          ...asset,
          description: [
            ...asset.shortHeadlines,
            asset.longHeadline,
            ...asset.descriptions,
          ]
            .filter(Boolean)
            .join(', '),
        })),
        loading: false,
        metadata: action.metadata,
      };

    case ASSETS_LIST_ERROR:
      return { ...state, loading: false };

    case ASSETS_LIST_CRITERIA:
      return {
        ...state,
        criteria: { ...state.criteria, ...action.criteria },
      };

    case ACCOUNTS_SELECT:
      return {
        ...state,
        criteria: {
          ...state.criteria,
          where: {
            ...state.criteria.where,
            account_id: Number(localStorage.getItem('saved-account-id')),
          },
        },
      };

    default:
      return state;
  }
};
