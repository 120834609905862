import { CommonTabs, TabProps } from '../../../../components/Tabs/Tabs';
import { reportTabsArr, reportTabs, reportTabsTitles } from '../constants';
import { MANAGE_SYSTEM_USERS } from '../../../../constants/permissions';
import { allowed } from '../../../../helpers/permissions';
import ss from '../PerformanceReport.module.scss';
import { IReportTab } from '../types';

import { Snapshot } from './Snapshot';
import { Advanced } from './Advanced';
import { Admin } from './Admin';

export const ReportTabs = (props: IReportTab) => {
  const tabs = reportTabsArr.reduce((acc: TabProps[], tab) => {
    if (tab === reportTabs.SA && !allowed(props.perms, MANAGE_SYSTEM_USERS)) {
      return acc;
    }
    let content;

    switch (tab) {
      case reportTabs.SNAPSHOT:
        content = <Snapshot {...props} />;
        break;
      case reportTabs.ADVANCED:
        content = <Advanced {...props} />;
        break;
      case reportTabs.SA:
        content = <Admin {...props} />;
        break;
    }

    acc.push({
      content,
      index: tab,
      key: `report-charts-${tab}`,
      label: reportTabsTitles[tab],
    });

    return acc;
  }, []);

  return (
    <CommonTabs
      tabs={tabs}
      name="report-charts"
      defaultTab={reportTabs.SNAPSHOT}
      className={ss.reportTabs}
    />
  );
};
