import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  fbSyncAccount,
  syncAccount,
  syncNetwork,
} from '../../actions/reports.actions';
import { ROUTES } from '../../constants/routes';
import {
  NETWORK_GOOGLE_ADS,
  NETWORK_DV360,
  NETWORK_NONPROFITS,
} from '../../constants';
import {
  MANAGE_ACCOUNTS,
  MANAGE_CAMPAIGNS,
  MANAGE_SYSTEM_USERS,
  VIEW_REPORTINGS,
} from '../../constants/permissions';
import { allowed } from '../../helpers/permissions';

class Menu extends PureComponent<any, any> {
  static propTypes = {
    account: PropTypes.object.isRequired,
    fbSyncAccount: PropTypes.func,
    perms: PropTypes.object,
    setWrapperRef: PropTypes.func.isRequired,
    syncAccount: PropTypes.func.isRequired,
    syncNetwork: PropTypes.func.isRequired,
    toggleActive: PropTypes.func.isRequired,
  };

  syncAdWords = () => {
    const { syncAccount } = this.props;

    syncAccount({
      account_id: this.props.account.id,
      date_range_type: 'LAST_30_DAYS',
      network_id: NETWORK_GOOGLE_ADS,
    });
  };

  syncDV360 = () => {
    const { syncNetwork } = this.props;

    syncNetwork({
      date_range_type: 'LAST_30_DAYS',
      network_id: NETWORK_DV360,
    });
  };

  syncNonProfits = () => {
    const { syncAccount } = this.props;

    syncAccount({
      account_id: this.props.account.id,
      date_range_type: 'LAST_30_DAYS',
      network_id: NETWORK_NONPROFITS,
    });
  };

  render() {
    const { setWrapperRef, toggleActive, account, perms } = this.props;

    const accountId = account && account.id;

    const menu = [
      {
        hide: !allowed(perms, MANAGE_CAMPAIGNS, accountId),
        icon: 'fa-plus',
        items: [
          {
            icon: 'fa-flag',
            link: ROUTES.CAMPAIGNS,
            perms: [MANAGE_CAMPAIGNS],
            title: 'Campaigns Management',
          },
          {
            action: event => {
              event.preventDefault();
              window.open(process.env.REACT_APP_OLD_APP2_URL);
            },
            hide: !allowed(perms, MANAGE_CAMPAIGNS, accountId),
            icon: 'fa-building',
            link: process.env.REACT_APP_OLD_APP2_URL,
            perms: [MANAGE_CAMPAIGNS],
            target: '_blank',
            title: 'GDN, DCM & Pixel ',
          },
        ],
        perms: [MANAGE_CAMPAIGNS],
        title: 'Create & Manage',
      },
      {
        hide: !allowed(perms, MANAGE_CAMPAIGNS, accountId),
        icon: 'fa-images',
        items: [
          {
            icon: 'fa-images',
            link: ROUTES.ASSETS,
            perms: [MANAGE_CAMPAIGNS],
            title: 'Asset Library',
          },
        ],
        perms: [MANAGE_CAMPAIGNS],
        title: 'Assets',
      },
      {
        hide: !allowed(perms, VIEW_REPORTINGS, accountId),
        icon: 'fa-chart-area',
        items: [
          {
            hide: !allowed(perms, VIEW_REPORTINGS, accountId),
            icon: 'fa-building',
            link: ROUTES.DASHBOARD,
            perms: [VIEW_REPORTINGS],
            title: 'Dashboard',
          },
          {
            action: event => {
              event.preventDefault();
              this.syncDV360();
            },
            hide: !allowed(perms, MANAGE_ACCOUNTS, accountId),
            icon: 'fa-building',
            link: '',
            title: 'Sync DV360',
          },
          {
            action: event => {
              event.preventDefault();
              this.syncAdWords();
            },
            hide: !allowed(perms, MANAGE_ACCOUNTS, accountId),
            icon: 'fa-building',
            link: '',
            title: 'Sync AdWords',
          },
          {
            action: event => {
              event.preventDefault();
              this.props.fbSyncAccount({ account_id: accountId });
            },
            hide: !allowed(perms, MANAGE_ACCOUNTS, accountId),
            icon: 'fa-building',
            link: '',
            title: 'Sync Facebook',
          },
          {
            action: event => {
              event.preventDefault();
              this.syncNonProfits();
            },
            hide: !allowed(perms, MANAGE_ACCOUNTS, accountId),
            icon: 'fa-building',
            link: '',
            title: 'Sync Non-Profits',
          },
        ],
        perms: [VIEW_REPORTINGS],
        title: 'Measure & Report',
      },
      {
        icon: 'fa-cog',
        items: [
          {
            hide: !allowed(perms, MANAGE_SYSTEM_USERS, accountId),
            icon: 'fa-users',
            link: ROUTES.USERS,
            perms: [MANAGE_SYSTEM_USERS],
            title: 'Users Management',
          },
          {
            hide: !allowed(perms, MANAGE_ACCOUNTS, accountId),
            icon: 'fa-building',
            link: ROUTES.ACCOUNTS,
            perms: [MANAGE_ACCOUNTS],
            title: 'Accounts Management',
          },
          {
            hide: !allowed(perms, MANAGE_ACCOUNTS, accountId),
            icon: 'fa-building',
            link: ROUTES.NETWORKS,
            perms: [MANAGE_ACCOUNTS],
            title: 'Networks Management',
          },
          {
            icon: 'fa-building',
            link: process.env.REACT_APP_LEGACY_PUBLIC_URL,
            render: item => (
              <a
                className="x-menu-item-link"
                href={item.link}
                unselectable="on"
                role="menuitem"
                aria-hidden="false"
                aria-disabled="false"
                tabIndex={-1}
              >
                <span
                  className="x-menu-item-text x-menu-item-text-default x-menu-item-indent-no-separator"
                  unselectable="on"
                  role="presentation"
                >
                  {item.title}
                </span>
                <div
                  role="presentation"
                  className={`x-menu-item-icon-default x-menu-item-icon fas ${item.icon}`}
                />
              </a>
            ),
            title: 'Old Frontend',
          },
          {
            icon: 'fa-file-invoice-dollar',
            link: ROUTES.BILLING,
            title: 'Billing',
          },
        ],
        title: 'Settings',
      },
    ];

    return (
      <div
        ref={setWrapperRef}
        className="x-panel x-menu x-menu-main ux-nav-menu x-layer x-panel-default x-border-box"
        role="menu"
        aria-hidden="true"
        aria-disabled="false"
      >
        <div className="x-panel-bodyWrap" role="presentation">
          <div
            className="x-panel-body ux-nav-menu-body x-menu-body x-unselectable x-panel-body-default x-box-layout-ct x-panel-body-default x-noborder-trbl"
            role="presentation"
          >
            <div
              role="presentation"
              className="x-box-inner x-box-scroller-body-vertical x-scroller x-menu-container"
            >
              <div role="presentation">
                <div
                  className="x-panel x-panel-default x-menu-top"
                  role="presentation"
                >
                  <div className="x-panel-bodyWrap" role="presentation">
                    <div
                      className="x-panel-body x-panel-body-default x-column-layout-ct x-panel-body-default x-noborder-trbl"
                      role="presentation"
                    >
                      <div
                        className="x-autocontainer-outerCt"
                        role="presentation"
                      >
                        <div
                          role="presentation"
                          className="x-autocontainer-innerCt"
                        >
                          {menu.map(
                            section =>
                              !section.hide && (
                                <div
                                  key={section.title}
                                  className="x-panel x-column x-panel-oneportal-nav"
                                  role="presentation"
                                >
                                  <div
                                    className="x-panel-header x-header x-header-noborder x-unselectable x-panel-header-oneportal-nav x-horizontal x-panel-header-horizontal x-panel-header-oneportal-nav-horizontal x-top x-panel-header-top x-panel-header-oneportal-nav-top x-docked-top x-panel-header-docked-top x-panel-header-oneportal-nav-docked-top x-box-layout-ct x-noborder-trl"
                                    role="presentation"
                                  >
                                    <div
                                      role="presentation"
                                      className="x-box-inner"
                                    >
                                      <div role="presentation">
                                        <div
                                          className="x-title x-panel-header-title x-panel-header-title-oneportal-nav  x-title-oneportal-nav x-title-rotate-none x-title-align-left"
                                          role="presentation"
                                          unselectable="on"
                                        >
                                          <div
                                            role="presentation"
                                            className="x-title-icon-wrap x-title-icon-wrap-oneportal-nav x-title-icon-left x-title-item"
                                          >
                                            <div
                                              role="presentation"
                                              unselectable="on"
                                              className={
                                                'x-title-icon x-title-icon-oneportal-nav fas' +
                                                section.icon
                                              }
                                            />
                                          </div>
                                          <div
                                            className="x-title-text x-title-text-oneportal-nav x-title-item"
                                            unselectable="on"
                                            role="presentation"
                                          >
                                            {section.title}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="x-panel-bodyWrap"
                                    role="presentation"
                                  >
                                    <div
                                      className="x-panel-body x-panel-body-oneportal-nav x-panel-body-oneportal-nav x-noborder-trbl"
                                      role="presentation"
                                    >
                                      <div
                                        className="x-autocontainer-outerCt"
                                        role="presentation"
                                      >
                                        <div
                                          role="presentation"
                                          className="x-autocontainer-innerCt"
                                        >
                                          <div
                                            className="x-menu x-menu-default"
                                            role="menu"
                                            aria-hidden="false"
                                            aria-disabled="false"
                                          >
                                            <div
                                              className="x-menu-bodyWrap"
                                              role="presentation"
                                            >
                                              <div
                                                className="x-menu-body x-menu-body x-unselectable x-menu-body-default x-box-layout-ct x-menu-body-default x-noborder-trbl"
                                                role="presentation"
                                              >
                                                <div
                                                  role="presentation"
                                                  className="x-box-inner x-box-scroller-body-vertical x-scroller"
                                                >
                                                  <div role="presentation">
                                                    {section.items.map(
                                                      item =>
                                                        !item.hide && (
                                                          <div
                                                            key={item.title}
                                                            className="x-menu-item x-menu-item-default"
                                                            role="presentation"
                                                          >
                                                            {Boolean(
                                                              item.render,
                                                            ) &&
                                                              item.render(item)}
                                                            {!item.render && (
                                                              <Link
                                                                className="x-menu-item-link"
                                                                to={item.link}
                                                                onClick={
                                                                  item.action ||
                                                                  toggleActive
                                                                }
                                                                target={
                                                                  item.target ||
                                                                  '_self'
                                                                }
                                                                // @ts-ignore
                                                                hidefocus="true"
                                                                unselectable="on"
                                                                role="menuitem"
                                                                aria-hidden="false"
                                                                aria-disabled="false"
                                                                tabIndex={-1}
                                                              >
                                                                <span
                                                                  className="x-menu-item-text x-menu-item-text-default x-menu-item-indent-no-separator"
                                                                  unselectable="on"
                                                                  role="presentation"
                                                                >
                                                                  {item.title}
                                                                </span>
                                                                <div
                                                                  role="presentation"
                                                                  className={
                                                                    'x-menu-item-icon-default x-menu-item-icon fas ' +
                                                                    item.icon
                                                                  }
                                                                />
                                                              </Link>
                                                            )}
                                                          </div>
                                                        ),
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ),
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  store => ({
    account: store.accounts.current,
    perms: store.auth.user.perms,
  }),
  {
    fbSyncAccount,
    syncAccount,
    syncNetwork,
  },
)(Menu);
