import { Dispatch } from 'redux';

import {
  ACCOUNT_GET_NETWORK_SETTINGS_REQUEST,
  ACCOUNT_GET_NETWORK_SETTINGS_SUCCESS,
  ACCOUNT_GET_NETWORK_SETTINGS_FAILURE,
  ACCOUNT_UPDATE_NETWORK_SETTINGS_REQUEST,
  ACCOUNT_UPDATE_NETWORK_SETTINGS_SUCCESS,
  ACCOUNT_UPDATE_NETWORK_SETTINGS_FAILURE,
} from './accounts.actions';

export const loadNetworkSettings = (id: number) => (dispatch: Dispatch<any>) =>
  dispatch({
    request: (axios: any) =>
      axios.get(`/admin/accounts/${id}/network-settings`),
    types: [
      ACCOUNT_GET_NETWORK_SETTINGS_REQUEST,
      ACCOUNT_GET_NETWORK_SETTINGS_SUCCESS,
      ACCOUNT_GET_NETWORK_SETTINGS_FAILURE,
    ],
  });

export const updateNetworkSettings =
  (accountId: number, dcmPrivate: string, settingsData: any[]) =>
  (dispatch: Dispatch<any>) =>
    dispatch({
      request: (axios: any) =>
        axios.put(`/admin/accounts/${accountId}/network-settings`, {
          dcmPrivate,
          settingsData,
        }),
      types: [
        ACCOUNT_UPDATE_NETWORK_SETTINGS_REQUEST,
        ACCOUNT_UPDATE_NETWORK_SETTINGS_SUCCESS,
        ACCOUNT_UPDATE_NETWORK_SETTINGS_FAILURE,
      ],
    });
