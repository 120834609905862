import { createSelector } from 'reselect';

import { ACL_ROLES } from '../constants/roles';
import { RootState } from '../reducers';

export const accountOptionsSelector = createSelector(
  (state: RootState) => state.accounts.data,
  accounts => [
    {
      label: '<all>',
      value: '',
    },
    ...accounts.map(account => ({
      label: account.name,
      value: account.id,
    })),
  ],
);

export const roleOptions = ACL_ROLES.map(role => ({
  label: role.name,
  value: role.id,
}));
