/**
 * AssetsList component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */
import arrayMutators from 'final-form-arrays';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  createItem,
  loadItem,
  resetItem,
  updateItem,
} from '../../actions/networks.actions';
import { addNotify } from '../../actions/notifications.actions';
import Loader from '../../components/Loader';
import { ROUTES } from '../../constants/routes';

import NetworkItemForm from './Form';

class NetworkItem extends PureComponent<any, any> {
  static propTypes = {
    addNotify: PropTypes.func.isRequired,
    createItem: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    item: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    loadItem: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    resetItem: PropTypes.func.isRequired,
    updateItem: PropTypes.func.isRequired,
  };

  static defaultProps = {
    item: {},
  };

  async componentDidMount() {
    const {
      match: {
        params: { id },
      },
      loadItem,
    } = this.props;

    id !== 'new' && loadItem(id);
  }

  componentWillUnmount() {
    this.props.resetItem();
  }

  handleSubmit = async values => {
    const {
      addNotify,
      createItem,
      history,
      match: {
        params: { id },
      },
      updateItem,
    } = this.props;
    const api = id === 'new' ? createItem : updateItem;
    const params = id === 'new' ? [values] : [id, values];

    try {
      const result = await api(...params);

      if (result.error) {
        addNotify('Oops! Something went wrong!', 'error');
      } else {
        history.push(ROUTES.NETWORKS);
      }

      return result;
    } catch (error) {
      addNotify('Oops! Something went wrong!', 'error');

      return error;
    }
  };

  render() {
    const { item, loading } = this.props;

    return (
      <div className="card border-0 page">
        <h5 className="card-header bg-primary text-white">
          <i className="fas fa-images pr-2" />
          <Link to={ROUTES.NETWORKS} className="text-white">
            Networks Library
          </Link>
          <> / Network</>
        </h5>
        {loading && <Loader />}
        {item && (
          <Form
            initialValues={item}
            onSubmit={this.handleSubmit}
            // @ts-ignore
            component={NetworkItemForm}
            mutators={{
              ...arrayMutators,
            }}
          />
        )}
      </div>
    );
  }
}

export default connect(
  store => ({
    item: store.network.data,
    loading: store.network.loading,
  }),
  {
    addNotify,
    createItem,
    loadItem,
    resetItem,
    updateItem,
  },
)(NetworkItem);
