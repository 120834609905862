import { AnyAction } from 'redux';

import * as actions from '../actions/reports.actions';

export type ReportSchedule = {
  recipients: { email: string; name: string }[];
  average_amounts: any;
  report_period: number;
  report_type: number;
  spikes_ratio: number;
  start_date: string;
  active: boolean;
} | null;

interface IReports {
  loading: boolean;
  scheduleLoading: boolean;
  schedule: ReportSchedule;
  scheduleList: ReportSchedule[];
}

export const INITIAL_STATE: IReports = {
  loading: false,
  schedule: null,
  scheduleList: [],
  scheduleLoading: false,
};

export default (state = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case actions.REPORTS_ACTION_REQUEST:
      return { ...state, loading: true };

    case actions.REPORTS_ACTION_ERROR:
    case actions.REPORTS_ACTION_SUCCESS:
      return { ...state, loading: false };

    case actions.REPORTS_SCHEDULE_GET_REQUEST:
    case actions.REPORTS_SCHEDULE_SAVE_REQUEST:
    case actions.REPORTS_SCHEDULE_LIST_REQUEST:
      return { ...state, scheduleLoading: true };

    case actions.REPORTS_SCHEDULE_SAVE_ERROR:
    case actions.REPORTS_SCHEDULE_GET_ERROR:
    case actions.REPORTS_SCHEDULE_LIST_ERROR:
      return { ...state, scheduleLoading: false };

    case actions.REPORTS_SCHEDULE_GET_SUCCESS:
    case actions.REPORTS_SCHEDULE_SAVE_SUCCESS:
      return {
        ...state,
        schedule: action.data.schedule,
        scheduleLoading: false,
      };

    case actions.REPORTS_SCHEDULE_LIST_SUCCESS:
      return {
        ...state,
        scheduleList: action.data.schedules,
        scheduleLoading: false,
      };

    case actions.REPORTS_SCHEDULE_RESET:
      return {
        ...state,
        schedule: null,
      };

    default:
      return state;
  }
};
