/* eslint-disable id-length */
/* eslint-disable react/forbid-dom-props */
/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createStaticRanges, DateRangePicker } from 'react-date-range';
import {
  Box,
  Backdrop,
  ClickAwayListener,
  CircularProgress,
  Popper,
} from '@mui/material';
import { noop } from 'lodash';

import DropDownButton from '../../../components/DropDownButton';

import { LineGraphCompare } from './LineGraphCompare/LineGraphCompare';
import { TableReport } from './TableReport/TableReport';
import { ExportOptions } from './Export/ExportOptions';
import { ReportTabs } from './ReportTabs/ReportTabs';
import {
  getStaticDateRangesWithIsSelected,
  metrics,
  metricsObj,
  spikeRatios,
} from './constants';
import { metricComparison } from './chart-settings';
import { metricFormatter } from './report-settings';
import ss from './PerformanceReport.module.scss';
import { IPerformanceReportData } from './types';

const getMetricName = (id: string) => {
  const metric = metrics.find(metric => metric.value === id);

  if (!metric) {
    return;
  }

  return metric.label;
};

export const PerformanceReport = ({
  loadCharts,
  loadRecords,
  loadUserAccounts,
  downloadReportDaily,
  downloadReportDailyAggregate,
  downloadReportAggregate,
  downloadPdfReport,
  setupMetrics,
  setupDateRange,
  setupAdgroups,
  endDate,
  startDate,
  accountId,
  accountName,
  chart,
  records,
  metric1,
  metric2,
  metricCharts,
  maxDate,
  adgroups,
  perms,
  adminFutureSpendChartData,
  pdfLoading,
}: IPerformanceReportData) => {
  const [spikeRatio, setSpikeRatio] = useState<number>(spikeRatios[0]);
  const [selectedLegends, setSelectedLegends] = useState<any>({});

  const setChartSelectedLegends = (chartId, items) => {
    setSelectedLegends({ ...selectedLegends, [chartId]: items });
  };

  useEffect(() => {
    loadRecords();
    loadCharts();
  }, [
    accountId,
    endDate,
    maxDate,
    startDate,
    metric1,
    metric2,
    loadCharts,
    loadRecords,
  ]);

  useEffect(() => {
    loadCharts();
  }, [adgroups, loadCharts]);

  useEffect(() => {
    loadCharts(metricsObj.spend.value, metricsObj.tc.value);
    loadCharts(metricsObj.spend.value, metricsObj.ctc.value);
    loadCharts(metricsObj.spend.value, metricsObj.roi.value);
    loadCharts(metricsObj.spend.value, metricsObj.cpm.value);
  }, [adgroups, accountId, endDate, maxDate, startDate, loadCharts]);

  useEffect(() => {
    loadUserAccounts({});
  }, [loadUserAccounts]);

  const exportReportTitle = useMemo(() => ({ title: 'Export CSV' }), []);
  const exportReportOptions = useMemo(
    () => [
      {
        onClick: downloadReportDailyAggregate,
        title: 'Daily and Aggregate (XLSX)',
      },
      {
        onClick: downloadReportDaily,
        title: 'Daily Report (CSV)',
      },
      {
        onClick: downloadReportAggregate,
        title: 'Aggregate Report (CSV)',
      },
    ],
    [
      downloadReportDailyAggregate,
      downloadReportDaily,
      downloadReportAggregate,
    ],
  );

  const setupMetric1 = useCallback(
    event => setupMetrics({ metric1: event.target.value }),
    [setupMetrics],
  );
  const setupMetric2 = useCallback(
    event => setupMetrics({ metric2: event.target.value }),
    [setupMetrics],
  );

  const [datePickerShown, setDatePickerShown] = useState(false);
  const toggleDatePicker = useCallback(() => {
    setDatePickerShown(
      document.activeElement === dateReadonlyInput.current
        ? true
        : !datePickerShown,
    );
  }, [datePickerShown]);

  const dateReadonlyInput = useRef<HTMLInputElement>(null);

  const setupDateRangeMem = useCallback(
    ({ range1: { startDate, endDate } }) => setupDateRange(startDate, endDate),
    [setupDateRange],
  );

  const displayDateFormat = 'MMM DD, YYYY';
  const displayDate =
    moment(startDate).format(displayDateFormat) +
    ' - ' +
    moment(endDate).format(displayDateFormat);
  const titleDateFormat = 'MMMM DD, YYYY';
  const titleDate =
    moment(startDate).format(titleDateFormat) +
    ' - ' +
    moment(endDate).format(titleDateFormat);

  const titleName = `${accountName} - `;
  const reportTitleName = `OnePortal - ${titleName}`;

  const y1TickFormatterMem = useCallback(
    (value: number) => metricFormatter(value, metric1, false)[0],
    [metric1],
  );
  const y2TickFormatterMem = useCallback(
    (value: number) => metricFormatter(value, metric2, false)[0],
    [metric2],
  );
  const chartMetrics = [
    {
      formatter: y1TickFormatterMem,
      key: metric1,
      label: getMetricName(metric1),
    },
    {
      formatter: y2TickFormatterMem,
      key: metric2,
      label: getMetricName(metric2),
    },
  ];

  return (
    <>
      <Backdrop sx={{ color: '#ffffff', zIndex: 10 }} open={pdfLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={ss.titleContainer}>
        <h1 className={ss.title}>
          {titleName}
          {displayDate}
        </h1>
        <ExportOptions
          data={{
            adminFutureSpendChartData,
            chart,
            chartMetrics,
            downloadPdfReport,
            metricCharts,
            pdfLoading,
            perms,
            records,
            reportTitleName,
            selectedLegends,
            spikeRatio,
            titleDate,
          }}
        />
      </div>
      <div className="d-flex m-4">
        <div className="mr-auto p-2 position-relative">
          <input
            size={25}
            readOnly
            ref={dateReadonlyInput}
            type="text"
            className="form-control"
            value={displayDate}
            onChange={noop}
            onClick={toggleDatePicker}
          />

          <ClickAwayListener onClickAway={toggleDatePicker}>
            <Popper
              open={datePickerShown}
              anchorEl={dateReadonlyInput.current}
              placement="bottom-start"
            >
              <Box sx={{ bgcolor: 'background.paper', border: 1, mt: 1, p: 1 }}>
                <DateRangePicker
                  ranges={[{ endDate: endDate || undefined, startDate }]}
                  onChange={setupDateRangeMem}
                  months={2}
                  direction="horizontal"
                  staticRanges={createStaticRanges(
                    getStaticDateRangesWithIsSelected(),
                  )}
                  inputRanges={[]}
                  maxDate={maxDate}
                />
              </Box>
            </Popper>
          </ClickAwayListener>
        </div>
        <div className="p-2">
          <select
            defaultValue={metric1}
            onChange={setupMetric1}
            className="form-control"
          >
            {metrics.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <div className="p-2">
          <select
            defaultValue={metric2}
            onChange={setupMetric2}
            className="form-control"
          >
            {metrics.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <LineGraphCompare
        chartId={metricComparison.id}
        chart={chart}
        metrics={chartMetrics}
        legendSelected={setChartSelectedLegends}
      />
      <br />

      <TableReport
        setupAdgroups={setupAdgroups}
        records={records}
        exportButton={
          <DropDownButton
            items={exportReportOptions}
            selectedItem={exportReportTitle}
            isRight={true}
          />
        }
      />

      <div className={ss.reportTitle}>
        <div>{reportTitleName}</div>
        <div>{titleDate}</div>
      </div>

      <div className={`mt-5 mb-5 ${ss.hLineSep}`} />

      <ReportTabs
        records={records}
        metricCharts={metricCharts}
        perms={perms}
        options={{
          selectedLegends,
          setChartSelectedLegends,
          setSpikeRatio,
          spikeRatio,
        }}
      />
    </>
  );
};
