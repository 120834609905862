import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { StripeFormConnected } from '../../components/Stripe';
import { AccountTrackingConnected } from '../Accounts';
import { DashboardConnected } from '../Dashboard';
import {
  getAccountsList,
  loadAccounts,
  loadUserAccounts,
  selectAccount,
} from '../../actions/accounts.actions';
import { logout } from '../../actions/auth.actions';
import NotificationList from '../../components/NotificationList';
import '../../styles/css/main.css';
import AccountItem from '../Accounts/Item';
import AccountsList from '../Accounts/List';
import AssetItem from '../Assets/Item';
import AssetsList from '../Assets/List';
import CampaignItem from '../Campaigns/CampaignItem';
import GroupItem from '../Campaigns/GroupItem';
import CampaignsList from '../Campaigns/List';
import LoginForm from '../Login';
import ForgotForm from '../Forgot';
import ResetForm from '../Reset';
import NetworkItem from '../Networks/Item';
import NetworksList from '../Networks/List';
import UserItem from '../Users/Item';
import UsersList from '../Users/List';
import { AssetComposerConnected } from '../AssetComposer';
import { ROUTES } from '../../constants/routes';
import { allowed } from '../../helpers/permissions';
import { MANAGE_CAMPAIGNS } from '../../constants/permissions';

import Header from './Header';
import Menu from './Menu';

class Layout extends PureComponent<any, any> {
  static propTypes = {
    accounts: PropTypes.object,
    getAccountsList: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool,
    loadAccounts: PropTypes.func.isRequired,
    loadUserAccounts: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    selectAccount: PropTypes.func.isRequired,
    user: PropTypes.object,
  };

  static defaultProps = {
    accounts: {},
    isLoggedIn: false,
    user: {},
  };

  state = {
    active: false,
  };

  componentDidMount() {
    this.checkAuth();
    document.addEventListener('mousedown', this.handleClickOutside);
    if (this.props.isLoggedIn) {
      this.props.loadUserAccounts();
    }
  }

  componentDidUpdate() {
    this.checkAuth();
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  menuWrapperRef: any = null;

  menuToggleRef: any = null;

  checkAuth = () => {
    let allowed = false;

    if (window.location.pathname === ROUTES.LOGIN) {
      allowed = true;
    }
    if (
      new RegExp(
        `${ROUTES.RESET}`.replace(
          new RegExp(':([a-z]+)', 'g'),
          '([0-9A-Za-z]+)',
        ),
      ).test(window.location.pathname)
    ) {
      allowed = true;
    }

    if (!this.props.isLoggedIn && !allowed) {
      window.location.href = ROUTES.LOGIN;
    }
  };

  setMenuWrapperRef = node => {
    this.menuWrapperRef = node;
  };

  setMenuToggleRef = node => {
    this.menuToggleRef = node;
  };

  handleClickOutside = event => {
    if (
      this.menuWrapperRef &&
      this.menuToggleRef &&
      !this.menuWrapperRef.contains(event.target) &&
      !this.menuToggleRef.contains(event.target)
    ) {
      this.setState({
        active: false,
      });
    }
  };

  toggleActive = () => {
    this.setState(({ active }) => ({
      active: !active,
    }));
  };

  render() {
    const { accounts, isLoggedIn, logout, selectAccount, user } = this.props;
    const { active } = this.state;

    const defaultRoute =
      isLoggedIn &&
      user &&
      allowed(
        user.perms,
        MANAGE_CAMPAIGNS,
        accounts.current?.id || user.defaultAccount,
      )
        ? ROUTES.CAMPAIGNS
        : ROUTES.DASHBOARD;

    return (
      <BrowserRouter>
        {isLoggedIn && (
          <>
            <Header
              accounts={accounts}
              getAccountsList={this.props.getAccountsList}
              active={active}
              logout={logout}
              selectAccount={selectAccount}
              setMenuToggleRef={this.setMenuToggleRef}
              toggleMenu={this.toggleActive}
              user={user}
            />

            {active && (
              <Menu
                setWrapperRef={this.setMenuWrapperRef}
                toggleActive={this.toggleActive}
              />
            )}
          </>
        )}

        <Switch>
          <Route exact path={ROUTES.ROOT}>
            <Redirect to={defaultRoute} />
          </Route>
          <Route exact path={ROUTES.USERS} component={UsersList} />
          <Route exact path={ROUTES.USER} component={UserItem} />
          <Route exact path={ROUTES.ACCOUNTS} component={AccountsList} />
          <Route exact path={ROUTES.ACCOUNT} component={AccountItem} />
          <Route
            exact
            path={ROUTES.ACCOUNT_TRACKING}
            component={AccountTrackingConnected}
          />
          <Route exact path={ROUTES.ASSETS} component={AssetsList} />
          <Route
            exact
            path={ROUTES.ASSETS_COMPOSER}
            component={AssetComposerConnected}
          />
          <Route exact path={ROUTES.ASSET} component={AssetItem} />
          <Route exact path={ROUTES.CAMPAIGNS} component={CampaignsList} />
          <Route exact path={ROUTES.CAMPAIGN} component={CampaignItem} />
          <Route exact path={ROUTES.ADGROUP} component={GroupItem} />
          <Route exact path={ROUTES.NETWORKS} component={NetworksList} />
          <Route exact path={ROUTES.NETWORK} component={NetworkItem} />
          <Route exact path={ROUTES.LOGIN} component={LoginForm} />
          <Route exact path={ROUTES.FORGOT} component={ForgotForm} />
          <Route exact path={ROUTES.RESET} component={ResetForm} />
          <Route exact path={ROUTES.BILLING} component={StripeFormConnected} />
          <Route exact path={ROUTES.DASHBOARD} component={DashboardConnected} />
        </Switch>

        <NotificationList />
      </BrowserRouter>
    );
  }
}

export default connect(
  store => ({
    accounts: store.accounts,
    isLoggedIn: store.auth.isLoggedIn,
    user: store.auth.user,
  }),
  {
    getAccountsList,
    loadAccounts,
    loadUserAccounts,
    logout,
    selectAccount,
  },
)(Layout);
