/**
 * CampaignsList component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */

import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import HistoryList from '../../components/HistoryList';
import Modal from '../../components/Modal';
import Tooltip from '../../components/Tooltip';
import { allowed } from '../../helpers/permissions';
import {
  loadCampaigns,
  loadCampaignsItem,
  updateCampaignStatus,
  updateCriteria,
  updateFilter,
} from '../../actions/campaigns.actions';
import Grid from '../../components/Grid';
import Loader from '../../components/Loader';
import {
  CAMPAIGN_TYPES_LIST,
  STATUS_ACTIVE,
  STATUS_ACTIVE2,
  STATUS_PAUSED,
  STATUS_PENDING,
  STATUS_STOPPED,
  TYPE_DISCOVERY,
  TYPE_DISPLAY,
  TYPE_SEARCH,
  TYPE_VIDEO,
} from '../../constants';
import {
  ColumnOption,
  GridFilter,
} from '../../components/Grid/Grid.interfaces';
import { ROUTES, subRouteParams } from '../../constants/routes';
import { MANAGE_CAMPAIGN_DEFAULTS } from '../../constants/permissions';

import CampaignSettings from './CampaignSettings';
import { GroupsListConnected } from './GroupsList';

interface State {
  campaignDropDownOpen: boolean;
  selectedItem: Record<string, any> | null;
  showHistoryModal: boolean;
  showSettingsModal: boolean;
}

class CampaignsList extends PureComponent<any, State> {
  static propTypes = {
    account: PropTypes.object,
    criteria: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    loadCampaigns: PropTypes.func.isRequired,
    loadCampaignsItem: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    metadata: PropTypes.object.isRequired,
    updateCampaignStatus: PropTypes.func.isRequired,
    updateCriteria: PropTypes.func.isRequired,
    updateFilter: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
  };

  static defaultProps = {
    account: {},
  };

  state: State = {
    campaignDropDownOpen: false,
    selectedItem: {},
    showHistoryModal: false,
    showSettingsModal: false,
  };

  componentDidMount() {
    document.addEventListener(
      'mousedown',
      this.handleClickOutsideCampaignDropDown,
    );
  }

  campaignDropDownWrapperRef: any = null;

  campaignDropDownToggleRef: any = null;

  addItem = campaign_type => {
    this.props.history.push(subRouteParams(ROUTES.CAMPAIGN, { id: 'new' }), {
      campaign_type,
    });
  };

  editItem = ({ id }) => {
    this.props.history.push(subRouteParams(ROUTES.CAMPAIGN, { id }));
  };

  checkStatus = ({ id }) => {
    const { loadCampaignsItem } = this.props;

    loadCampaignsItem(id);
  };

  toggleActive = ({ id, status }) => {
    const { updateCampaignStatus } = this.props;

    updateCampaignStatus(id, {
      status: status === STATUS_PAUSED ? STATUS_PENDING : STATUS_PAUSED,
    });
  };

  toggleArchive = ({ id, status }) => {
    const { updateCampaignStatus } = this.props;

    updateCampaignStatus(id, {
      status: status === STATUS_STOPPED ? STATUS_PAUSED : STATUS_STOPPED,
    });
  };

  showHistoryModal = selectedItem => {
    this.setState({
      selectedItem,
      showHistoryModal: true,
    });
  };

  hideHistoryModal = event => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      selectedItem: null,
      showHistoryModal: false,
    });
  };

  setCampaignDropDownWrapperRef = node => {
    this.campaignDropDownWrapperRef = node;
  };

  setCampaignDropDownToggleRef = node => {
    this.campaignDropDownToggleRef = node;
  };

  handleClickOutsideCampaignDropDown = event => {
    if (
      this.campaignDropDownWrapperRef &&
      this.campaignDropDownToggleRef &&
      !this.campaignDropDownWrapperRef.contains(event.target) &&
      !this.campaignDropDownToggleRef.contains(event.target)
    ) {
      this.setState({
        campaignDropDownOpen: false,
      });
    }
  };

  toggleCampaignDropdown = () => {
    this.setState(({ campaignDropDownOpen }) => ({
      campaignDropDownOpen: !campaignDropDownOpen,
    }));
  };

  saveSettings = () => {
    document.getElementById('campaignSettingsForm')?.dispatchEvent(
      new Event('submit', {
        bubbles: true,
        cancelable: true,
      }),
    );

    this.setState({
      selectedItem: null,
      showSettingsModal: false,
    });
  };

  showSettingsModal = selectedItem => {
    this.setState({
      selectedItem,
      showSettingsModal: true,
    });
  };

  hideSettingsModal = event => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      showSettingsModal: false,
    });
  };

  /* selectTypeFilter = (selectedItem) => {
        this.props.updateFilter({
            campaign_type: selectedItem.value,
        });
    } */

  getColumns = (): ColumnOption[] => [
    {
      maxWidth: 75,
      name: 'status',
      sortable: true,
      title: 'Status',
      values: {
        [STATUS_ACTIVE]: {
          items: [
            {
              icon: 'fas fa-play-circle text-success',
              tooltip:
                'Campaign is <strong>ACTIVE</strong> and will spend up to on the budget assigned.',
              type: 'icon',
            },
            {
              action: this.toggleActive,
              icon: 'fas fa-pause',
              tooltip: 'Deactivate',
              type: 'icon',
            },
            {
              action: this.toggleArchive,
              icon: 'fas fa-archive',
              tooltip: 'Archive',
              type: 'icon',
            },
            {
              action: this.showHistoryModal,
              condition: ({ logs }) => logs && logs.length > 0,
              icon: 'fas fa-history',
              tooltip: 'Changes history',
              type: 'icon',
            },
          ],
          type: 'list',
        },
        [STATUS_ACTIVE2]: {
          items: [
            {
              icon: 'fas fa-play-circle text-success',
              tooltip:
                'Campaign is <strong>ACTIVE</strong> and will spend up to on the budget assigned.',
              type: 'icon',
            },
            {
              action: this.toggleActive,
              icon: 'fas fa-pause',
              tooltip: 'Deactivate',
              type: 'icon',
            },
            {
              icon: 'fas fa-archive',
              tooltip: 'Archive',
              type: 'icon',
            },
            {
              action: this.showHistoryModal,
              condition: ({ logs }) => logs && logs.length > 0,
              icon: 'fas fa-history',
              tooltip: 'Changes history',
              type: 'icon',
            },
          ],
          type: 'list',
        },
        [STATUS_PAUSED]: {
          items: [
            {
              errorInTooltip: true,
              icon: 'fas fa-pause-circle text-danger',
              tooltip:
                'Campaign is <strong>PAUSED</strong> and will not be spending.',
              type: 'icon',
            },
            {
              action: this.toggleActive,
              icon: 'fas fa-play',
              tooltip: 'Activate',
              type: 'icon',
            },
            {
              action: this.toggleArchive,
              icon: 'fas fa-archive',
              tooltip: 'Archive',
              type: 'icon',
            },
            {
              action: this.showHistoryModal,
              condition: ({ logs }) => logs && logs.length > 0,
              icon: 'fas fa-history',
              tooltip: 'Changes history',
              type: 'icon',
            },
          ],
          type: 'list',
        },
        [STATUS_PENDING]: {
          items: [
            {
              icon: 'fas fa-spinner text-warning',
              tooltip:
                'Campaign is in <strong>PENDING</strong> state or in progress to be setup.',
              type: 'icon',
            },
            {
              action: this.checkStatus,
              icon: 'fas fa-redo-alt',
              tooltip: 'Check Status',
              type: 'icon',
            },
            {
              action: this.toggleArchive,
              icon: 'fas fa-archive',
              tooltip: 'Archive',
              type: 'icon',
            },
            {
              action: this.showHistoryModal,
              condition: ({ logs }) => logs && logs.length > 0,
              icon: 'fas fa-history',
              tooltip: 'Changes history',
              type: 'icon',
            },
          ],
          type: 'list',
        },
        [STATUS_STOPPED]: {
          items: [
            {
              icon: 'fas fa-times-circle text-danger',
              tooltip: 'Campaign is <strong>STOPPED</strong> or archived.',
              type: 'icon',
            },
            {
              action: this.toggleArchive,
              icon: 'fas fa-box-open',
              tooltip: 'Un-archive',
              type: 'icon',
            },
          ],
          type: 'list',
        },
      },
    },
    {
      link: true,
      name: 'name',
      sortable: true,
      title: 'Name',
    },
    {
      maxWidth: 70,
      name: 'campaign_type',
      sortable: true,
      title: 'Type',
      values: {
        [TYPE_DISCOVERY]: {
          icon: 'campaign_type google_discovery',
          tooltip: 'Google discovery',
          type: 'icon',
        },
        [TYPE_DISPLAY]: {
          icon: 'fas fa-ad text-warning',
          tooltip: 'Display Ad',
          type: 'icon',
        },
        [TYPE_SEARCH]: {
          icon: 'campaign_type google_search',
          tooltip: 'Google search',
          type: 'icon',
        },
        [TYPE_VIDEO]: {
          icon: 'fab fa-youtube text-danger',
          tooltip: 'Video Ad',
          type: 'icon',
        },
      },
    },
    {
      maxWidth: 60,
      name: 'network_campaigns',
      title: 'Networks',
      type: 'custom',
      value: (data = []) => {
        const networkClasses = {
          1: 'network_icon network_icon_googleads',
          2: 'network_icon network_icon_dv360',
          3: 'network_icon network_icon_facebook',
          4: 'network_icon network_icon_amazon',
        };

        return data.map(network_campaign => {
          const tooltip = `${network_campaign.network.name} (${
            network_campaign.status ? 'Active' : 'Inactive'
          })`;

          return (
            <Tooltip key={network_campaign.id} tooltip={tooltip}>
              <i
                className={cx(networkClasses[network_campaign.network.id], {
                  disabled: !network_campaign.status,
                })}
              />
            </Tooltip>
          );
        });
      },
    },
    {
      maxWidth: 150,
      name: 'budget',
      sortable: true,
      title: 'Budget',
      type: 'price',
    },
    {
      maxWidth: 150,
      name: 'start_date',
      sortable: true,
      title: 'Start Date',
      type: 'date',
    },
    {
      maxWidth: 150,
      name: 'end_date',
      sortable: true,
      title: 'End Date',
      type: 'date',
    },
    {
      className: 'text-center',
      items: [
        {
          action: this.editItem,
          icon: 'fas fa-pen-alt mr-1',
          tooltip: 'Edit item',
          type: 'icon',
        },
        this.props.user &&
        allowed(this.props.user.perms, MANAGE_CAMPAIGN_DEFAULTS)
          ? {
              action: this.showSettingsModal,
              icon: 'fas fa-cog',
              tooltip: 'Settings',
              type: 'icon',
            }
          : undefined,
      ].filter(Boolean),
      maxWidth: 25,
      name: 'actions',
      title: <i className="fas fa-cogs" />,
      type: 'list',
    },
  ];

  FILTERS: GridFilter[] = [
    {
      items: [
        {
          title: 'All',
          value: null,
        },
        ...CAMPAIGN_TYPES_LIST.map(({ id, name }) => ({
          title: name,
          value: id,
        })),
      ],
      name: 'campaign_type',
      onChange: selectedItem => {
        this.props.updateFilter({
          campaign_type: selectedItem.value,
        });
      },
      title: 'Campaign type',
      type: 'DropDownButton',
    },
  ];

  loadCampaignsForAccount = params => {
    const { loadCampaigns } = this.props;

    if (params.criteria?.where?.account_id) {
      return loadCampaigns({
        ...params,
        account_id: params.criteria.where.account_id,
      });
    }

    return loadCampaigns(params);
  };

  render() {
    const {
      account,
      criteria,
      data,
      loading,
      metadata = {},
      updateCriteria,
    } = this.props;

    const {
      showHistoryModal,
      showSettingsModal,
      selectedItem,
      campaignDropDownOpen,
    } = this.state;

    if (!account) {
      return <Loader />;
    }

    return (
      <div className="page">
        <Modal
          close={this.hideHistoryModal}
          title="Changes history"
          show={showHistoryModal}
        >
          {selectedItem && (
            <HistoryList
              items={(selectedItem.logs || [])
                .sort((a, b) => b.id - a.id)
                .map(
                  ({
                    id,
                    created,
                    title,
                    message,
                    user: { firstname, lastname },
                  }) => ({
                    id,
                    message,
                    title: (
                      <>
                        {moment(created).format('YYYY-MM-DD HH:mm:ss')}
                        <> </>
                        <b>{title}</b> by {firstname + ' ' + lastname}
                      </>
                    ),
                  }),
                )}
            />
          )}
        </Modal>
        <Modal
          close={this.hideSettingsModal}
          description="Additional settings"
          title="Additional settings"
          show={showSettingsModal}
          submit={this.saveSettings}
          size="xl"
          submitLabel="Update"
        >
          <CampaignSettings campaignId={selectedItem && selectedItem.id} />
        </Modal>
        <Grid
          actions={[
            {
              render: () => (
                <div className="dropdown">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    ref={this.setCampaignDropDownToggleRef}
                    onClick={this.toggleCampaignDropdown}
                  >
                    <i className="fas fa-plus mr-2" />
                    Create new
                  </button>
                  <div
                    className={cx('dropdown-menu dropdown-menu-left', {
                      show: campaignDropDownOpen,
                    })}
                    ref={this.setCampaignDropDownWrapperRef}
                  >
                    {CAMPAIGN_TYPES_LIST.map(({ id, name }) => (
                      <button
                        key={id}
                        type="button"
                        className="dropdown-item"
                        tabIndex={0}
                        onClick={() => this.addItem(id)}
                      >
                        <i className="fas fa-sign-out-alt mr-3" />
                        {name}
                      </button>
                    ))}
                  </div>
                </div>
              ),
            },
          ]}
          columns={this.getColumns()}
          criteria={criteria}
          data={data}
          editItem={this.editItem}
          entity="assets"
          filters={this.FILTERS}
          icon="fas fa-flag pr-2"
          loading={loading}
          loadList={this.loadCampaignsForAccount}
          metadata={metadata}
          reloadable
          searchable={false}
          seemore={data => (
            // @ts-ignore
            <GroupsListConnected data={data} history={this.props.history} />
          )}
          title="Campaign Manager"
          updateCriteria={updateCriteria}
        />
      </div>
    );
  }
}

export default connect(
  store => ({
    account: store.accounts.current,
    criteria: store.campaigns.criteria,
    data: store.campaigns.data,
    loading: store.campaigns.loading,
    metadata: store.campaigns.metadata,
    user: store.auth.user,
  }),
  {
    loadCampaigns,
    loadCampaignsItem,
    updateCampaignStatus,
    updateCriteria,
    updateFilter,
  },
)(CampaignsList);
