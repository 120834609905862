/**
 * AssetsList component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';

import { login } from '../../actions/auth.actions';
import { selectAccount, getAccountsList } from '../../actions/accounts.actions';
import { addNotify } from '../../actions/notifications.actions';
import Loader from '../../components/Loader';
import { ROUTES } from '../../constants/routes';
import { allowed } from '../../helpers/permissions';
import { MANAGE_ACCOUNTS } from '../../constants/permissions';

import LoginForm from './Form';

class Login extends PureComponent<any, any> {
  static propTypes = {
    addNotify: PropTypes.func.isRequired,
    getAccountsList: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    login: PropTypes.func.isRequired,
    selectAccount: PropTypes.func.isRequired,
  };

  componentDidMount() {
    if (this.props.isLoggedIn) {
      this.props.history.push(ROUTES.CAMPAIGNS);
    }
  }

  handleSubmit = async values => {
    const { addNotify, login, history, selectAccount, getAccountsList } =
      this.props;

    try {
      const result = await login({
        email: values.email,
        password: values.password,
      });

      if (!result.error) {
        const user = result.data?.user;

        if (!user) {
          throw new Error('No user data in the login response');
        }

        const getAccountsRes = await getAccountsList();
        const accounts = getAccountsRes.data || [];

        const storedAccount = accounts.find(
          ({ id }) => id === Number(localStorage.getItem('saved-account-id')),
        );
        const defaultAccount = accounts.find(
          ({ id }) => id === Number(user.defaultAccount),
        );
        const selectedAccount = storedAccount || defaultAccount;

        selectAccount(selectedAccount);

        if (allowed(user.perms, MANAGE_ACCOUNTS, selectedAccount.id)) {
          history.push(ROUTES.ACCOUNTS);
        } else {
          history.push(ROUTES.DASHBOARD);
        }
      }

      return result;
    } catch (error) {
      addNotify('Oops! Something went wrong!', 'error');

      return error;
    }
  };

  render() {
    const { loading } = this.props;

    return (
      <div className="card login-page border-0 page">
        {loading && <Loader />}
        <Form
          onSubmit={this.handleSubmit}
          // @ts-ignore
          component={LoginForm}
        />
      </div>
    );
  }
}

export default connect(
  store => ({
    isLoggedIn: store.auth.isLoggedIn,
    loading: store.auth.loading,
  }),
  {
    addNotify,
    getAccountsList,
    login,
    selectAccount,
  },
)(Login);
