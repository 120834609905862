import cx from 'classnames';
import { Field, FormRenderProps } from 'react-final-form';

import { required } from '../../helpers/validation';
import FormError from '../../components/FormError';

interface IProps {
  fields: any[];
}

export const AccountTrackingForm = ({
  handleSubmit,
  touched,
  errors,
  pristine,
  submitting,
  fields,
}: IProps & FormRenderProps) => (
  <form onSubmit={handleSubmit}>
    <div className="card-body container-fluid">
      <div className="row">
        <div className="col-4">
          <div className="form-group required">
            <label htmlFor="dcmPrivate">DCM Private</label>
            <div className="field-prefix">DC-</div>
            <Field
              id="dcmPrivate"
              name="dcmPrivate"
              component="input"
              className={cx('form-control', {
                'is-invalid': touched?.dcmPrivate && errors.dcmPrivate,
              })}
              maxLength={90}
              validate={required}
            />
            <FormError name="dcmPrivate" />
          </div>
        </div>

        {fields.map(({ name, type }) => (
          <div className="col-4" key={type}>
            <div className="form-group required">
              <label htmlFor={type}>{name}</label>
              <Field
                id={type}
                name={`${type}.data`}
                component="input"
                className={cx('form-control', {
                  'is-invalid': touched?.[`${type}.data`] && errors[type],
                })}
                maxLength={90}
                validate={required}
              />
              <FormError name={`${type}.data`} />
            </div>
          </div>
        ))}
      </div>
    </div>
    <div className="card-footer">
      <div className="row">
        <div className="col-12">
          <button
            type="submit"
            className="btn btn-primary float-right"
            disabled={
              Boolean(Object.keys(errors).length) || pristine || submitting
            }
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
);
