/**
 * Tooltip component script class
 *
 * @package    Common
 * @author     Vadym Karpenko <vadim.karpenko.306@gmail.com>
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './styles.css';

export default class Tooltip extends PureComponent<any, any> {
  static propTypes = {
    button: PropTypes.bool,
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.arrayOf(PropTypes.node),
    ]),
    tooltip: PropTypes.string.isRequired,
  };

  static defaultProps = {
    button: true,
    children: undefined,
  };

  render() {
    const { children, tooltip, button } = this.props;

    return (
      <div className="custom-tooltip" role={button ? 'button' : undefined}>
        {children || <i className="fas fa-info-circle text-muted" />}
        <span className="custom-tooltip-wrapper">
          <span
            className="custom-tooltip-wrapper-text"
            dangerouslySetInnerHTML={{
              __html: tooltip,
            }}
          />
        </span>
      </div>
    );
  }
}
